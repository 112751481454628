import { Button } from 'antd'
import styled, { css } from 'styled-components'

const btnFlexContentCenterStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const btnFontStyle = css`
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 400;
`

const tableCellBtnCommonStyles = css`
  height: 18px;

  line-height: 0;
`

export const OnHoldLinkButton = styled(Button)`
  padding: 0;

  text-decoration: underline;

  color: ${({ theme }) => theme.colors.primary700};

  ${btnFontStyle}
  ${btnFlexContentCenterStyle}
  ${tableCellBtnCommonStyles}
  
  &:active, &:focus, &:hover {
    text-decoration: underline !important;
  }
`
