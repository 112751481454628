import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { TeethExtractionSelection } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/TeethExtractionSelection'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Arch } from 'app/core/domain/ArchTools'
import { CrowdingOptions } from 'app/core/domain/PrimaryCasePreferences'
import { addOrRemoveFromArray, includes } from 'app/core/lang/ArrayUtils'

import {
  archTestKeyIds,
  archTreatmentToDisableCrowdingSettingsMap,
  archToTreatMap,
} from './ResolveArchCrowdingMap'

interface ResolveArchCrowdingProps {
  arch: Arch
}

export const ResolveArchCrowding = (props: ResolveArchCrowdingProps) => {
  const archKey = archTestKeyIds[props.arch]
  const archToTreat = archToTreatMap[props.arch]
  const archToDisable = archTreatmentToDisableCrowdingSettingsMap[props.arch]

  const propertyKey = `primaryCasePreferences.resolveCrowdingBy${archKey}`

  const form = useFormContext()
  const resolveCrowdingSettings = form.getValues(propertyKey)
  const treatArches = form.getValues('primaryCasePreferences.treatArches')

  const changeOptions = (value: CrowdingOptions) => {
    const res = addOrRemoveFromArray<CrowdingOptions>([value], resolveCrowdingSettings)

    form.setValue(propertyKey, res)
  }

  return (
    <Box display="block" data-testid={`${archKey}ResolveCrowding`}>
      <Typography
        data-testid={`${archKey}ResolveCrowding-Heading`}
        component="h3"
        variant="h6"
        textFontWeight="600"
        margin="0 0 8px 0"
      >
        <FormattedMessage id="caseWizard.casePrescription.arche" values={{ arch: archToTreat }} />
      </Typography>
      <Box justify="flex-start" data-testid={`${archKey}ResolveCrowding-useIPR`}>
        <OrmcoCheckbox
          checked={includes(CrowdingOptions.IPR, resolveCrowdingSettings)}
          onChange={() => changeOptions(CrowdingOptions.IPR)}
          disabled={treatArches === archToDisable}
          data-testid={`${archKey}ResolveCrowding-Input-useIPR`}
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.resolveCrowding"
              values={{ resolveCrowding: 'useIPR' }}
            />
          </Typography>
        </OrmcoCheckbox>
      </Box>

      <Box justify="flex-start" data-testid={`${archKey}ResolveCrowding-userProclination`}>
        <OrmcoCheckbox
          checked={includes(CrowdingOptions.Proclination, resolveCrowdingSettings)}
          onChange={() => changeOptions(CrowdingOptions.Proclination)}
          disabled={treatArches === archToDisable}
          data-testid={`${archKey}ResolveCrowding-Input-userProclination`}
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.resolveCrowding"
              values={{ resolveCrowding: 'userProclination' }}
            />
          </Typography>
        </OrmcoCheckbox>
      </Box>

      <Box justify="flex-start" data-testid={`${archKey}ResolveCrowding-useExpansion`}>
        <OrmcoCheckbox
          checked={includes(CrowdingOptions.Expansion, resolveCrowdingSettings)}
          onChange={() => changeOptions(CrowdingOptions.Expansion)}
          disabled={treatArches === archToDisable}
          data-testid={`${archKey}ResolveCrowding-Input-useExpansion`}
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.resolveCrowding"
              values={{ resolveCrowding: 'useExpansion' }}
            />
          </Typography>
        </OrmcoCheckbox>
      </Box>
      <TeethExtractionSelection
        arch={props.arch}
        data-testid={`TeethExtractionSelection-${archKey}`}
      />
    </Box>
  )
}
