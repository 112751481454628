enum ScanType {
  'SCANS' = 'SCANS',
  'THREESHAPESCANS' = 'THREESHAPESCANS',
  'ITEROSCANS' = 'ITEROSCANS',
  'MEDIT' = 'MEDIT',
  'Lythos' = 'Lythos',
  'iTeroNS' = 'iTeroNS',
  'DICOM' = 'DICOM',
}

export { ScanType }
