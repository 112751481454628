import { FormattedMessage } from 'react-intl'

import { useGetCasePerksQuery } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Region } from 'app/core/domain/Region'
import { ensure } from 'app/core/utils/ensure'

interface CasePerksHintMessageProps {
  'data-testid': string
}

export const CasePerksHintMessage = (props: CasePerksHintMessageProps) => {
  const { region, showShippedCasesPerks } = useGetCasePerksQuery(undefined, {
    selectFromResult: ({ data }) => ensure(data),
  })

  return (
    <Box display="block" data-testid={props['data-testid']}>
      {region === Region.AUSTRALIA || showShippedCasesPerks ? (
        <Typography color="primary800">
          <FormattedMessage id="perks.helpMessage" />
        </Typography>
      ) : (
        <>
          <Typography color="primary800" margin="0 0 12px">
            <FormattedMessage id="perks.fullHelpMessage1" />
            <FormattedMessage id="perks.fullHelpMessage3" />
          </Typography>
          <Typography color="primary800">
            <FormattedMessage id="perks.fullHelpMessage4" />
          </Typography>
        </>
      )}
    </Box>
  )
}
