import { FormattedMessage } from 'react-intl'

import { TypographyProps } from 'app/components/ui/SDS/common/Typography/interfaces'
import { ThemeColors, ThemeFontVariants } from 'styled-components'

import { Typography } from '../SDS/common/Typography'

interface OrmcoModalHeaderTextProps {
  'data-testid'?: string
  headerId: string
  variant?: keyof ThemeFontVariants
  color?: keyof ThemeColors
  whiteSpace?: TypographyProps['whiteSpace']
}

export const OrmcoModalHeaderText = (props: OrmcoModalHeaderTextProps) => {
  return (
    <Typography
      data-testid={props['data-testid']}
      component="h2"
      variant={props.variant || 'h4'}
      textFontWeight="600"
      letterSpacing="0.25px"
      color={props.color || 'black'}
      whiteSpace={props.whiteSpace}
    >
      <FormattedMessage id={props.headerId} />
    </Typography>
  )
}
