import { Dispatch, SetStateAction, useState } from 'react'

type SetValue<T> = Dispatch<SetStateAction<T>>

export const useSessionStorage = <T>(key: string, defaultValue?: T): [T, SetValue<T>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.sessionStorage.getItem(key)

      if (value !== null) {
        return JSON.parse(value)
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (error) {
      console.warn(`Error reading sessionStorage key “${key}”:`, error)
      return defaultValue
    }
  })

  const setValue: SetValue<T> = (newValue) => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(newValue))
    } catch (error) {
      console.warn(`Error setting sessionStorage key “${key}”:`, error)
      setStoredValue(newValue)
    }
  }

  return [storedValue, setValue]
}
