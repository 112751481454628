import { integratedHooksModalActions } from 'app/components/routes/BasicPage/IntegratedHooksModal/logic'
import { truGenXRModalActions } from 'app/components/routes/CaseWizard/steps/CasePrescription/TruGenXRSelectModal/logic'
import { COOKIES } from 'app/core/browser/COOKIES'
import { getCookie, setCookie } from 'app/core/browser/cookieUtils'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, ignoreElements, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { featureFlagActions } from '../features/logic'

const getMarketingMessageEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([appActions.userSettingsReceived, appActions.localeChanged])),
    switchMap(() => {
      const locale = appSelectors.getLocale()(state$.value)

      return axios
        .get(
          `/api/v1/doctors/${appSelectors.getUsername()(
            state$.value,
          )}/marketingmsg?locale=${locale}`,
        )
        .then((res) => appActions.marketingMessageReceived({ marketingMessage: res.data }))
    }),
  )

const markMarketingMessageAsReadEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.marketingMessageRead)),
    tap(() =>
      axios.put(
        `/api/v1/doctors/${appSelectors.getUsername()(state$.value)}/marketingmsg/visibility`,
        'false',
      ),
    ),
    ignoreElements(),
  )

const checkImpersonatedLocaleEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.userSettingsReceived)),
    filter(() => appSelectors.isImpersonated()(state$.value)),
    switchMap(() => {
      const lang = getCookie(COOKIES.impersonatorLocale)

      return of(appActions.localeChanged({ locale: lang }))
    }),
  )

const localeChangedEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.localeChanged)),
    tap(() => {
      const isImpersonated = appSelectors.isImpersonated()(state$.value)

      if (!isImpersonated) {
        axios.put(
          `/api/v1/users/${appSelectors.getUsername()(state$.value)}/locale`,
          JSON.stringify(appSelectors.getLocale()(state$.value)),
        )
      }
    }),
    ignoreElements(),
  )

const getUserSettingsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.profileInitialized)),
    switchMap(() => {
      const isOperator = appSelectors.isOperator()(state$.value)

      return from(
        axios.get(`/api/v1/users/${appSelectors.getUsername()(state$.value)}/settings`),
      ).pipe(
        switchMap((res) => {
          setCookie(`${COOKIES.impersonatorLocale}=${res.data?.languagesInfo?.locale}`)

          if (isOperator) {
            return of(appActions.userSettingsReceivedForOperator())
          }

          const { showPrimaryBrandingPopup, showSparkAdvancedBrandingPopup } = res.data

          return of(
            appActions.userSettingsReceived(res.data),
            integratedHooksModalActions.toggleIntegratedHooksModal(true),
            truGenXRModalActions.setInitialTruGenModalState({
              showPrimaryBrandingPopup,
              showSparkAdvancedBrandingPopup,
            }),
          )
        }),
      )
    }),
  )

const getEmployeeProfileEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        appActions.userSettingsReceived,
        appActions.employeeDetailsUpdated,
        appActions.myProfileMounted,
      ]),
    ),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(axios.get(`/api/v1/users/${username}/profileUserData`)).pipe(
        switchMap((res) =>
          of(
            appActions.employeeDetailsRequestFinished(res.data.employeeProfileDetails),
            featureFlagActions.featureFlagsRequestFinished(
              res.data.employeeProfileDetails.permissions,
            ),
          ),
        ),
        catchError((err) =>
          of(
            appActions.employeeDetailsRequestFailed({ message: err.toString() }),
            featureFlagActions.featureFlagsRequestFailed(),
          ),
        ),
      )
    }),
  )

const saveEmployeeProfileEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([appActions.saveEmployeeDetails])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const profileData = appSelectors.getEmployeeProfileDetails()(state$.value)

      return from(axios.put(`/api/v1/users/${username}/profileUserData`, { ...profileData })).pipe(
        switchMap(() => of(appActions.saveEmployeeDetailsFinished())),
        catchError((err) => of(appActions.saveEmployeeDetailsFailed({ message: err.toString() }))),
      )
    }),
  )

const getWebContentEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(appActions.webContentRequested)),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(axios.get(`/api/v1/doctors/${username}/webContent`)).pipe(
        switchMap((res) => of(appActions.webContentReceived(res.data))),
        catchError(() => of(appActions.webContentFailed())),
      )
    }),
  )

const appEpic = combineEpics(
  markMarketingMessageAsReadEpic,
  localeChangedEpic,
  getUserSettingsEpic,
  getMarketingMessageEpic,
  checkImpersonatedLocaleEpic,
  getEmployeeProfileEpic,
  saveEmployeeProfileEpic,
  getWebContentEpic,
)

export { appEpic }
