import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import styled, { css } from 'styled-components'

const ormcoInputMixin = () => css`
  height: 41px;
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 4px;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary800};
  }
`

const OrmcoInput = styled(Input)<InputProps>`
  ${ormcoInputMixin};
`

const OrmcoPasswordInput = styled(Input.Password)<InputProps>`
  ${ormcoInputMixin};
`

export { OrmcoInput, OrmcoPasswordInput }
