import { FC, ReactNode } from 'react'

import { Col } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface AuxTeethNumbersProps {
  caption: ReactNode
  type: string
  icon: JSX.Element
}

const AuxiliaryTypeSummary: FC<AuxTeethNumbersProps> = ({ caption, icon, children, type }) => (
  <Box marginTop="8px">
    <Col span={4}>
      <Box width="auto" data-testid={`AuxTeethNumbers-AuxIcon-${type}`}>
        {icon}
      </Box>
    </Col>
    <Col span={20}>
      <Typography
        data-testid={`AuxTeethNumbers-Caption-${type}`}
        component="h3"
        variant="small"
        textFontWeight="600"
        color="base900"
        isUppercase
      >
        {caption}
      </Typography>
      <NumbersContainer
        flexWrap="wrap"
        justify="flex-start"
        minHeight="32px"
        data-testid={`AuxTeethNumbers-NumbersBox-${type}`}
      >
        {children}
      </NumbersContainer>
    </Col>
  </Box>
)

const NumbersContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.base100};

  border-radius: 2px;
`

export { AuxiliaryTypeSummary }
