import { DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { CasePendingCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/CasePendingCard'
import { CreatedCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/CreatedCard'
import { DoctorCaseCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorCaseCard'
import { DoctorNameCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNameCard'
import { DoctorNote } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/DoctorNote'
import { DoctorStatusCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorStatusCard'
import { OrderCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/OrderCard'
import { PatientAgeCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/PatientAgeCard'
import { ShippedCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/ShippedCard'
import { SubmittedCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/SubmittedCard'
import { CaseField } from 'app/core/domain/CaseField'

const doctorColumnSetting = [
  { width: '224.7px', header: CaseField.NAME, contentComponent: DoctorCaseCard },
  { width: '60px', header: CaseField.AGE, contentComponent: PatientAgeCard },
  { width: '141px', header: CaseField.DOCTOR, contentComponent: DoctorNameCard },
  {
    width: '160.5px',
    header: CaseField.STATUS,
    contentComponent: DoctorStatusCard,
    sorting: false,
  },
  { width: '96.5px', header: CaseField.SUBMITTED_DATE, contentComponent: SubmittedCard },
  { width: '127.5px', header: CaseField.ORDER_TYPE, contentComponent: OrderCard },
  { width: '324px', header: CaseField.NOTES, contentComponent: DoctorNote },
]

const doctorShippedColumnSetting = [
  { width: '224.7px', header: CaseField.NAME, contentComponent: DoctorCaseCard },
  { width: '60px', header: CaseField.AGE, contentComponent: PatientAgeCard },
  { width: '141px', header: CaseField.DOCTOR, contentComponent: DoctorNameCard },
  {
    width: '160.5px',
    header: CaseField.STATUS,
    contentComponent: DoctorStatusCard,
    sorting: false,
  },
  { width: '96.5px', header: CaseField.SHIP_DATE, contentComponent: ShippedCard },
  { width: '127.5px', header: CaseField.ORDER_TYPE, contentComponent: OrderCard },
  { width: '324px', header: CaseField.NOTES, contentComponent: DoctorNote },
]

const doctorActionRequiredColumnSetting = [
  { width: '220px', header: CaseField.NAME, contentComponent: DoctorCaseCard },
  { width: '60px', header: CaseField.AGE, contentComponent: PatientAgeCard },
  { width: '141px', header: CaseField.DOCTOR, contentComponent: DoctorNameCard },
  {
    width: '166.5px',
    header: CaseField.STATUS,
    contentComponent: DoctorStatusCard,
    sorting: false,
  },
  {
    width: '148px',
    header: CaseField.PENDING,
    contentComponent: CasePendingCard,
  },
  { width: '96.5px', header: CaseField.CREATION_DATE, contentComponent: CreatedCard },
  { width: '127.5px', header: CaseField.ORDER_TYPE, contentComponent: OrderCard },
  { width: '176px', header: CaseField.NOTES, contentComponent: DoctorNote },
]

const doctorArchivedColumn = [
  { width: '220px', header: CaseField.NAME, contentComponent: DoctorCaseCard },
  { width: '60px', header: CaseField.AGE, contentComponent: PatientAgeCard },
  { width: '141px', header: CaseField.DOCTOR, contentComponent: DoctorNameCard },
  {
    width: '166.5px',
    header: CaseField.STATUS,
    contentComponent: DoctorStatusCard,
    sorting: false,
  },
  {
    width: '148px',
    header: CaseField.PENDING,
    contentComponent: CasePendingCard,
  },
  { width: '96.5px', header: CaseField.SUBMITTED_DATE, contentComponent: CreatedCard },
  { width: '127.5px', header: CaseField.ORDER_TYPE, contentComponent: OrderCard },
  { width: '176px', header: CaseField.NOTES, contentComponent: DoctorNote },
]

const doctorSearchColumnSettings = [
  {
    width: '220px',
    header: CaseField.NAME,
    contentComponent: DoctorCaseCard,
    sorting: false,
  },
  {
    width: '60px',
    header: CaseField.AGE,
    contentComponent: PatientAgeCard,
    sorting: false,
  },
  {
    width: '141px',
    header: CaseField.DOCTOR,
    contentComponent: DoctorNameCard,
    sorting: false,
  },
  {
    width: '166.5px',
    header: CaseField.STATUS,
    contentComponent: DoctorStatusCard,
    sorting: false,
  },
  {
    width: '133px',
    header: CaseField.PENDING,
    contentComponent: CasePendingCard,
    sorting: false,
  },
  {
    width: '96.5px',
    header: CaseField.CREATION_DATE,
    contentComponent: CreatedCard,
    sorting: false,
  },
  {
    width: '127.5px',
    header: CaseField.SHIP_DATE,
    contentComponent: ShippedCard,
    sorting: false,
  },
  {
    width: '176px',
    header: CaseField.NOTES,
    contentComponent: DoctorNote,
    sorting: false,
  },
]
const doctorColumnsMap = {
  [DoctorCaseTabs.shipped]: doctorShippedColumnSetting,
  [DoctorCaseTabs.archived]: doctorArchivedColumn,
  [DoctorCaseTabs.withOrmco]: doctorColumnSetting,
  [DoctorCaseTabs.actionRequired]: doctorActionRequiredColumnSetting,
}

export {
  doctorColumnSetting,
  doctorShippedColumnSetting,
  doctorActionRequiredColumnSetting,
  doctorSearchColumnSettings,
  doctorColumnsMap,
}
