import React from 'react'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'

import { AddressFormLabel } from '../AddressFormLabel'
import { addressSelectors } from '../logic'

import { AddressSummaryIcons } from './AddressSummaryIcons'

const AddressSummaryHeader: React.FC = () => {
  const addressResult = useSelector(addressSelectors.getAddress())

  return (
    <Box marginBottom="24px" justify="space-between">
      <AddressFormLabel text={addressResult.addressTag} />
      <AddressSummaryIcons />
    </Box>
  )
}

export { AddressSummaryHeader }
