import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { AssociatedUsersTooltip } from 'app/components/routes/Staff/AssociatedUsersTooltip'
import { StaffSelect } from 'app/components/routes/Staff/StaffSelect'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import styled from 'styled-components'

import { staffCreationActions, staffCreationSelectors } from './logic'

const AssociatedDoctors: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const label = intl.formatMessage({ id: 'staff.staffDetails.associatedDoctors.label' })
  const { setValue } = useFormContext<PersonalInformation>()
  const activeUsers = useSelector(staffCreationSelectors.getActiveUsers())
  const allActiveDoctorsSelected = useSelector(staffCreationSelectors.getAllActiveDoctorsSelected())

  const changeAllDoctorSelection = useCallback(() => {
    const manageDoctors = !allActiveDoctorsSelected ? activeUsers.map((user) => user.username) : []

    setValue('canManageCasesOf', manageDoctors)
    dispatch(staffCreationActions.associateAllDoctorsFlagChanged())
    dispatch(staffCreationActions.updateCanManageCasesOf(manageDoctors))
  }, [activeUsers, allActiveDoctorsSelected, dispatch, setValue])

  return (
    <StaffFormItemVertical
      colon={false}
      label={
        <Box justify="flex-start">
          <Typography component="label" textFontWeight="600">
            {label}
          </Typography>
          <AssociatedUsersTooltip />
        </Box>
      }
      marginBottom="0"
      hasFeedback
      data-testid="StaffDetails-AssociatedDoctors"
    >
      <Box flexDirection="column">
        <StaffSelect
          userList={activeUsers}
          disabled={allActiveDoctorsSelected}
          data-testid="StaffDetails-AssociatedDoctorsSelect"
        />
        <AssociateAllDoctorsCheckbox
          margin="5px 0 0"
          justify="flex-start"
          data-testid="StaffDetails-AssociateAllStaffCheckbox"
        >
          <OrmcoCheckbox onChange={changeAllDoctorSelection} checked={allActiveDoctorsSelected}>
            <Typography component="label">
              <FormattedMessage id="staff.staffDetails.associatedDoctors.allDoctors" />
            </Typography>
          </OrmcoCheckbox>
        </AssociateAllDoctorsCheckbox>
      </Box>
    </StaffFormItemVertical>
  )
}

const AssociateAllDoctorsCheckbox = styled(Box)`
  .ant-checkbox-wrapper {
    margin-right: 5px;
  }

  .ant-checkbox-inner {
    opacity: 0.6;

    border: 2px solid ${({ theme }) => theme.colors.primary800};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary800};

    ::after {
      left: 17.5%;
    }
  }
`

export { AssociatedDoctors }
