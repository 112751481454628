import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DoctorCaseCount } from 'app/components/routes/CaseList/DoctorCaseCount'
import { caseListActions } from 'app/components/routes/CaseList/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { useDidMount } from 'app/core/react/CustomHooks'
import { appSelectors } from 'app/logic/app/logic'

import { DoctorCaseList } from './DoctorCaseList/DoctorCaseList'

interface DoctorCaseListQueryUrlParams {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  tab?: keyof DoctorCaseCount
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  search?: string
}

const CaseList: React.FC<DoctorCaseListQueryUrlParams> = (props: DoctorCaseListQueryUrlParams) => {
  const dispatch = useDispatch()

  const isAdmin = useSelector(appSelectors.isAdmin())
  const isOperator = useSelector(appSelectors.isOperator())
  const isDoctor = useSelector(appSelectors.isDoctor())
  const isStaff = useSelector(appSelectors.isStaff())
  let caseListComponent

  if (isDoctor || isStaff) {
    caseListComponent = <DoctorCaseList />
  }
  if (isAdmin) {
    // not implemented yet
    caseListComponent = null
  }
  if (isOperator) {
    // not implemented yet
    caseListComponent = null
  }

  useDidMount(() => {
    dispatch(caseListActions.caseListMounted(props))
  })

  return <Box display="block">{caseListComponent}</Box>
}

export { CaseList, DoctorCaseListQueryUrlParams }
