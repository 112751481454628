import { Divider } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'

import { AnteriorPosteriorRelationship } from './AnteriorPosteriorRelationship/AnteriorPosteriorRelationship'
import { ArchesToTreat } from './ArchesToTreat'
import { IdbTeethSection } from './Auxiliaries/IdbTeethSection'
import { BracketPreferences } from './BracketPreferences/BracketPreferences'
import { DoctorPreferences } from './DoctorPreferences'
import { Midline } from './Midline'
import { MolarTubes } from './MolarTubes/MolarTubes'
import { Overbite } from './Overbite/Overbite'
import { Overjet } from './Overjet'
import { Product } from './Product/Product'
import { ResolveCrowding } from './ResolveCrowding'
import { TreatmentGoals } from './TreatmentGoals'

export const IdbPrescriptionContent = () => (
  <>
    <Box display="block">
      <Product />
    </Box>
    <Box display="block">
      <ArchesToTreat />
    </Box>
    <Box display="block">
      <DoctorPreferences />
    </Box>

    <Box display="block">
      <BracketPreferences />
    </Box>
    <Box display="block">
      <MolarTubes />
    </Box>
    <Box display="block">
      <IdbTeethSection />
    </Box>

    <Divider />
    <Box display="block">
      <ResolveCrowding />
    </Box>
    <Box display="block">
      <AnteriorPosteriorRelationship />
    </Box>
    <Box display="block">
      <Midline />
    </Box>
    <Box display="block">
      <Overjet />
    </Box>
    <Box display="block">
      <Overbite />
    </Box>
    <Box display="block">
      <TreatmentGoals />
    </Box>
  </>
)
