import { ReactComponent as BiterampsIcon } from 'app/resources/img/icons/teeth/auxiliaries/biteramps.svg'
import { ReactComponent as NoAttachmentsIcon } from 'app/resources/img/icons/teeth/auxiliaries/no-attachments.svg'
import { ReactComponent as UnmovableIcon } from 'app/resources/img/icons/teeth/auxiliaries/unmovable.svg'

import { createSVGIcon } from './misc'

const Biteramps = createSVGIcon(BiterampsIcon, '13px')
const NoAttachments = createSVGIcon(NoAttachmentsIcon, '16px')
const Unmovable = createSVGIcon(UnmovableIcon, '16px')

export { Biteramps, NoAttachments, Unmovable }
