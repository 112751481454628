import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { caseSubmittedModalActions } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import { HybridCaseWizardProgress } from 'app/components/routes/CaseWizard/hybrid/HybridCaseWizardProgress'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { hybridWizardSteps } from 'app/components/routes/CaseWizard/wizardSteps'
import { Error } from 'app/components/ui/Error'
import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { CaseStatus } from 'app/core/domain/CaseStatus'
import { useDidMount } from 'app/core/react/CustomHooks'
import { useReduxForm } from 'app/core/react/useReduxForm'

import { CaseName, FormContainer } from '../CaseWizard.style'

import { HybridWizardButtons } from './HybridWizardButtons'

const HybridCaseWizardPage: React.FC = () => {
  const steps = hybridWizardSteps
  const dispatch = useDispatch()
  const location = useLocation()
  const { id, step } = parseQuery<{ id?: string; step?: string }>(location.search)
  const activeFormIndex = useSelector(caseWizardSelectors.getActiveFormIndex())
  const selectedCaseStatus = useSelector(editScansSelectors.getCaseStatus())
  const { loading } = useSelector(caseWizardSelectors.getFormData())
  const draftData = useSelector(caseWizardSelectors.getDraftData())
  const isLoading = useSelector(caseWizardSelectors.getLoadingStatus())
  const errorMessage = useSelector(caseWizardSelectors.getErrorMessage())
  const isValidCase = useSelector(editScansSelectors.getValidCaseValue())
  const isSubmittedCase = selectedCaseStatus !== CaseStatus.SAVED
  const {
    patientDetails: { patientFirstName, patientLastName },
  } = draftData
  const formParams = {
    isLoading: loading,
    useFormProps: {
      mode: 'onChange',
    },
  } as const
  const form = useReduxForm<CaseDraft>(
    draftData,
    (key, part) => {
      dispatch(
        caseWizardActions.caseDraftPartUpdated({
          key,
          part,
        }),
      )
    },
    formParams,
  )

  useDidMount(() => {
    const selectedStep = Number(step)

    dispatch(caseWizardActions.caseWizardMounted({ maxFormsIndex: hybridWizardSteps.length - 1 }))
    if (selectedStep) {
      dispatch(caseWizardActions.instantCaseStepChosen(selectedStep))
    }
    if (id) {
      dispatch(caseWizardActions.setLoadingStatus(true))
      dispatch(editScansActions.getSelectedScannerRequest({ id, selectedStep }))
      dispatch(
        caseSubmittedModalActions.urlParamsParsed({
          caseId: id,
          isOpen: false,
          isSecondary: false,
        }),
      )
    }

    return () => {
      dispatch(caseWizardActions.caseWizardUnmounted())
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Box flexDirection="column">
      {errorMessage && <Error errorMessage={errorMessage} />}
      <FormProvider {...form}>
        <FormContainer>
          <CaseName data-testid="HybridCaseWizardProgress-CaseName">
            <Typography component="span" variant="medium">
              {`${patientFirstName} ${patientLastName}`}
            </Typography>
          </CaseName>
          <HybridCaseWizardProgress isSubmittedCase={isSubmittedCase} />
          {!isLoading && isValidCase ? steps[activeFormIndex].form : null}
          <HybridWizardButtons
            isSubmittedCase={isSubmittedCase}
            hidePrintPrescription={step === '2'}
            id={id}
            step={step}
          />
        </FormContainer>
      </FormProvider>
    </Box>
  )
}

export { HybridCaseWizardPage }
