import { FC } from 'react'

import { Typography } from '../SDS/common/Typography'

const FormCaption: FC = ({ children }) => (
  <Typography
    component="h2"
    variant="h4"
    margin="0 0 24px"
    letterSpacing="0.25px"
    textFontWeight="600"
    color="base900"
  >
    {children}
  </Typography>
)

export { FormCaption }
