import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { UserRole } from 'app/core/domain/UserRole'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { SearchInput } from './SearchInput'
import { StaffTable } from './StaffTable'
import { MyPracticeFilter, staffListActions, staffListSelectors } from './logic'

interface StaffListQueryUrlParams {
  search?: string
}
const TeamManagementTable: React.FC<StaffListQueryUrlParams> = (props: StaffListQueryUrlParams) => {
  const dispatch = useDispatch()

  useDidMount(() => {
    dispatch(staffListActions.staffListMounted(props))
  })

  const showUserFilter = useSelector(staffListSelectors.showUserFilter())
  const userFilter = useSelector(staffListSelectors.getUserFilter())

  const selectStaffFilterUserCallback = useCallback(
    (event: React.MouseEvent, data: MyPracticeFilter) => {
      event.stopPropagation()
      const updatedRole: Array<MyPracticeFilter> = userFilter.map((user) =>
        user.role === data.role ? { ...user, checked: !user.checked } : user,
      )

      dispatch(staffListActions.staffFilterCheckboxClicked(updatedRole))
    },
    [userFilter, dispatch],
  )

  return (
    <TableContainer display="block" width="850px" data-testid="TeamManagementTable">
      <TableUtilityBox padding="7px 16px">
        <FilterCheckboxContainer justify="space-evenly" width="auto">
          {userFilter.map((duser: MyPracticeFilter) => {
            const { role } = duser

            const userRole =
              role === UserRole.ROLE_NORMAL
                ? 'staff.teamManagement.checkbox.doctor'
                : 'staff.teamManagement.checkbox.staffMembers'

            return (
              <CheckBoxItem justify="space-evenly" key={role} width="auto">
                {showUserFilter && (
                  <OrmcoCheckbox
                    checked={duser.checked}
                    onClick={(event) => selectStaffFilterUserCallback(event, duser)}
                    variant="white"
                    data-testid={`TeamManagementTable-${role}`}
                  >
                    <Typography component="span" textFontWeight="500" isUppercase color="white">
                      <FormattedMessage id={userRole} />
                    </Typography>
                  </OrmcoCheckbox>
                )}
              </CheckBoxItem>
            )
          })}
        </FilterCheckboxContainer>
        <SearchInput data-testid="TeamManagementTable-Search" />
      </TableUtilityBox>
      <StaffTable />
    </TableContainer>
  )
}

const TableContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 3px;
  border-collapse: separate;
  border-spacing: 0;
`

const TableUtilityBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary800};
`

const FilterCheckboxContainer = styled(Box)`
  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const CheckBoxItem = styled(Box)`
  cursor: pointer;

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.primary800};

    background-color: ${({ theme }) => theme.colors.white};
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: ${({ theme }) => theme.colors.primary800} !important;
  }
`

export { TeamManagementTable, StaffListQueryUrlParams }
