import {
  noUsersAssociatedModalActions,
  noUsersAssociatedModalSelectors,
} from 'app/components/routes/Staff/NoUsersAssociatedModal/logic'
import {
  staffCreationActions,
  staffCreationSelectors,
} from 'app/components/routes/Staff/StaffDetails/logic'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const createStaffEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        staffCreationActions.createStaffButtonClicked,
        noUsersAssociatedModalActions.createStaffMemberButtonClicked,
        staffCreationActions.updateStaffButtonClicked,
        noUsersAssociatedModalActions.updateButtonClicked,
      ]),
    ),
    switchMap(() => {
      const loggedInUser = appSelectors.getUsername()(state$.value)
      const staff = staffCreationSelectors.getFormPersonalInfo()(state$.value)
      const isStaffUpdateFlow = staffCreationSelectors.isStaffUpdateFlow()(state$.value)
      const isNoUserAssociationAccepted =
        noUsersAssociatedModalSelectors.isNoUserAssociationAccepted()(state$.value)
      const { canManageCasesOf, username } = staff
      const showNoUserAssociatedModal =
        (canManageCasesOf === undefined || canManageCasesOf.length === 0) &&
        !isNoUserAssociationAccepted

      if (!isStaffUpdateFlow) {
        return from(
          axios.get(`/api/v1/users/${loggedInUser}/validateUsername?validateUserName=${username}`),
        ).pipe(
          switchMap((res) => {
            const usernameIsNotUnique = res.data

            if (usernameIsNotUnique) {
              return of(staffCreationActions.usernameIsNotUniqueValidationReceived())
            }
            if (showNoUserAssociatedModal) {
              return of(noUsersAssociatedModalActions.noUsersAssociatedModalTriggered())
            }
            return of(staffCreationActions.createStaffRequestTriggered(staff))
          }),
          catchError(() => of(staffCreationActions.usernameValidationRequestFailed())),
        )
      }
      if (showNoUserAssociatedModal) {
        return of(noUsersAssociatedModalActions.noUsersAssociatedModalTriggered())
      }
      return of(staffCreationActions.createStaffRequestTriggered(staff))
    }),
  )

const addUpdateStaffEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([staffCreationActions.createStaffRequestTriggered])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const staff = staffCreationSelectors.getFormPersonalInfo()(state$.value)
      const { canManageCasesOf } = staff

      return from(
        axios.put(`/api/v1/users/${username}/addUpdateUser`, {
          ...staff,
          canManageCasesOf: canManageCasesOf === undefined ? [] : canManageCasesOf,
        }),
      ).pipe(
        switchMap(() => of(staffCreationActions.createStaffRequestComplete())),
        catchError(() => of(staffCreationActions.createStaffRequestFailed())),
      )
    }),
  )

const getAssociatedDoctorsListEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([staffCreationActions.addStaffMounted])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(axios.get(`/api/v1/users/${username}/clinicusers?status=active`)).pipe(
        switchMap((res) => of(staffCreationActions.associatedDoctorsListReceived(res.data.users))),
        catchError((err) =>
          of(staffCreationActions.associatedDoctorsListRequestFailed({ message: err.toString() })),
        ),
      )
    }),
  )

const getEmployeeDetailsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([staffCreationActions.staffDetailsRequested])),
    switchMap((action) => {
      const loggedInUsername = appSelectors.getUsername()(state$.value)
      const { staffUsername } = action.payload

      return from(
        axios.get(
          `/api/v1/users/${loggedInUsername}/viewUserDetails?employeeUserName=${staffUsername}`,
        ),
      ).pipe(
        switchMap((res) => of(staffCreationActions.staffDetailsResponseSuccess(res.data))),
        catchError((err) =>
          of(staffCreationActions.staffDetailsResponseFailed({ message: err.toString() })),
        ),
      )
    }),
  )

const deleteStaffEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        staffCreationActions.deleteStaffButtonClicked,
      ]),
    ),
    switchMap((action) => {
      const { username } = action.payload
      const loggedInUsername = appSelectors.getUsername()(state$.value)

      return from(
        axios.delete(`/api/v1/users/${loggedInUsername}/deleteStaff?staffUsername=${username}`),
      ).pipe(
        switchMap((res) => of(staffCreationActions.staffDeletionRequestFinished(res.data))),
        catchError((err) => of(staffCreationActions.staffDeletionRequestFailed({ message: err }))),
      )
    }),
  )

const staffCreationEpic = combineEpics(
  createStaffEpic,
  getAssociatedDoctorsListEpic,
  getEmployeeDetailsEpic,
  deleteStaffEpic,
  addUpdateStaffEpic,
)

export { staffCreationEpic }
