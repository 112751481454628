import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { doctorNoteActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/logic'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { Button as LegacyButton } from 'app/components/ui/Button'
import { OrmcoTextArea } from 'app/components/ui/Form/OrmcoTextArea'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

const OfficeNoteModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(caseWizardSelectors.getOfficeNotesModalState())
  const { officeNotes } = useSelector(caseWizardSelectors.getFormPersonalInfo())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  const [notes, setNotes] = useState(officeNotes || '')

  const close = () => {
    dispatch(caseWizardActions.officeNotesButtonClicked(false))
  }

  const sendOfficeNoteToServer = () => {
    dispatch(
      caseWizardActions.caseDraftPartUpdated({ part: notes, key: 'patientDetails.officeNotes' }),
    )
    dispatch(doctorNoteActions.submitButtonClicked({ text: notes }))
  }

  return (
    <OrmcoModal isOpen={isOpen} onRequestClose={close}>
      <OfficeNoteModalHeader>
        <OrmcoModalHeaderText variant="h5" headerId="caseWizard.casePrescription.officeNotes" />
        <Button onClick={close} type="text" data-testid="Modal-CloseBtn">
          <Cancel />
        </Button>
      </OfficeNoteModalHeader>
      <OfficeNoteModalBody>
        <OrmcoTextArea
          onChange={(event) => setNotes(event.target.value)}
          rows={4}
          defaultValue={officeNotes}
          name="primaryCasePreferences.additionalInstruction"
          disabled={Boolean(featurePermissions?.disableOfficeNotes)}
        />
      </OfficeNoteModalBody>
      <OfficeNoteModalFooter>
        <LegacyButton variant="danger" onClick={close} data-testid="officeNotes-CancelButton">
          <FormattedMessage id="cases.list.doctor.notes.cancel" />
        </LegacyButton>
        <Padder width="15px" />
        {!featurePermissions?.disableOfficeNotes && (
          <LegacyButton onClick={sendOfficeNoteToServer} data-testid="officeNotes-SaveButton">
            <FormattedMessage id="cases.list.doctor.notes.save" />
          </LegacyButton>
        )}
      </OfficeNoteModalFooter>
    </OrmcoModal>
  )
}

export { OfficeNoteModal }

const OfficeNoteModalHeader = styled(OrmcoModalHeader)`
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: none;

  font-size: 24px;
  font-weight: 600;
`

const OfficeNoteModalBody = styled(OrmcoModalBody)`
  padding: 0 20px;

  & > div,
  & > p {
    margin: 10px 0;
    padding-left: 25px;

    border-bottom: none;
  }
`

const OfficeNoteModalFooter = styled(OrmcoModalFooter)`
  padding: 20px;

  justify-content: flex-end;

  border-top: none;
`
