import { Input } from 'antd'
import styled from 'styled-components'

const OrmcoTextArea = styled(Input.TextArea)`
  &&& {
    font-style: normal;

    border-radius: 4px;
    resize: none;

    textarea {
      resize: none;
    }

    &:focus {
      border: 2px solid ${({ theme }) => theme.colors.primary800};
    }
  }
`

export { OrmcoTextArea }
