import { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { RadioChangeEvent } from 'antd'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import { AlignerMaterial } from 'app/core/domain/AlignerMaterial'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { ProductTier } from 'app/core/domain/ProductTier'
import { ProductType } from 'app/core/domain/ProductType'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { TruGenRadioButtons } from '../TruGenRadioButtons'
import { TruGenXRSelectModal } from '../TruGenXRSelectModal/TruGenXRSelectModal'
import { truGenXRModalSelectors } from '../TruGenXRSelectModal/logic'

import { productDefaultValues } from './ProductDefaultValues'

const PRODUCT_KEY = 'productInfo'
const ALIGNER_MATERIAL_KEY = 'primaryCasePreferences.alignerMaterial'

const Product = () => {
  const form = useFormContext<CaseDraft>()
  const productId = form.getValues(PRODUCT_KEY)?.id ?? ProductType.SPARK_ADVANCED
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const alignerMaterialValue = form.getValues(ALIGNER_MATERIAL_KEY)
  const isAlignerMaterialFeatureEnabled = featurePermissions?.alignerPrimaryMaterial
  const isMaterialForAdvancedEnabled = featurePermissions?.truGenXrForSparkAdvanced
  const isIDBEnabled = featurePermissions?.IDB
  const isTrugenXRModelOpen = useSelector(truGenXRModalSelectors.getIsTrugenXRModelOpen())
  const isTrugenXRModalAdvancedModeOpen = useSelector(
    truGenXRModalSelectors.getIsTrugenXRModalAdvancedModeOpen(),
  )

  const isAlignerMaterialFeatureDisabled =
    featurePermissions !== undefined &&
    !featurePermissions.alignerPrimaryMaterial &&
    alignerMaterialValue === AlignerMaterial.TruGEN_XR

  const showAlignerMaterialsForSpark10 =
    isAlignerMaterialFeatureEnabled && productId === ProductType.SPARK_10
  const showAlignerMaterialsForSpark20 =
    isAlignerMaterialFeatureEnabled && productId === ProductType.SPARK_20
  const showAlignerMaterialsForAdvanced =
    isMaterialForAdvancedEnabled && productId === ProductType.SPARK_ADVANCED
  const disableSparkProductSelection = Boolean(featurePermissions?.disableSparkProductSelection)

  const sparkAdvancedValue =
    ProductType.SPARK_ADVANCED === productId &&
    (featurePermissions?.truGenXrForSparkAdvanced as boolean)

  const spark1020Value =
    !!isAlignerMaterialFeatureEnabled &&
    [ProductType.SPARK_10, ProductType.SPARK_20].includes(productId)

  const isModalOpen = sparkAdvancedValue ? isTrugenXRModalAdvancedModeOpen : isTrugenXRModelOpen

  const setProduct = useCallback(
    ({ target: { value } }: RadioChangeEvent) => {
      const productType = value

      form.setValue(PRODUCT_KEY, {
        id: productType,
      })

      const defaultValues = productDefaultValues.get(productType)

      if (!defaultValues) {
        return
      }

      for (const valueToSet of Object.keys(defaultValues)) {
        form.setValue(valueToSet as keyof CaseDraft, defaultValues[valueToSet])
      }
    },
    [form],
  )

  useEffect(() => {
    if (isAlignerMaterialFeatureDisabled) {
      form.setValue(ALIGNER_MATERIAL_KEY, AlignerMaterial.TruGEN)
    }
  }, [form, isAlignerMaterialFeatureDisabled])

  return (
    <OrmcoFormItemVertical
      hasFeedback
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.productTier.label" />
      }
      data-testid="CasePrescription-Product"
    >
      <Vertical>
        <OrmcoRadio
          onChange={setProduct}
          value={ProductType.SPARK_ADVANCED}
          checked={productId === ProductType.SPARK_ADVANCED}
          data-testid="CasePrescription-Product-SparkAdvanced"
          disabled={disableSparkProductSelection}
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.productTier"
              values={{ productTier: ProductTier.SPARK_ADVANCED }}
            />
          </Typography>
        </OrmcoRadio>
        {showAlignerMaterialsForAdvanced && <TruGenRadioButtons />}
        <OrmcoRadio
          onChange={setProduct}
          value={ProductType.SPARK_20}
          checked={productId === ProductType.SPARK_20}
          data-testid="CasePrescription-Product-Spark20"
          disabled={disableSparkProductSelection}
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.productTier"
              values={{ productTier: ProductTier.SPARK_20 }}
            />
          </Typography>
        </OrmcoRadio>
        {showAlignerMaterialsForSpark20 && <TruGenRadioButtons />}
        <OrmcoRadio
          onChange={setProduct}
          value={ProductType.SPARK_10}
          checked={productId === ProductType.SPARK_10}
          data-testid="CasePrescription-Product-Spark10"
          disabled={disableSparkProductSelection}
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.productTier"
              values={{ productTier: ProductTier.SPARK_10 }}
            />
          </Typography>
        </OrmcoRadio>
        {showAlignerMaterialsForSpark10 && <TruGenRadioButtons />}

        {isIDBEnabled && (
          <OrmcoRadio
            onChange={setProduct}
            value={ProductType.IDB}
            checked={productId === ProductType.IDB}
            data-testid="CasePrescription-Product-IDB"
          >
            <Typography component="span">
              <FormattedMessage
                id="caseWizard.casePrescription.productTier"
                values={{ productTier: ProductTier.IDB }}
              />
            </Typography>
          </OrmcoRadio>
        )}
      </Vertical>

      {(spark1020Value || sparkAdvancedValue) && <TruGenXRSelectModal isOpen={isModalOpen} />}
    </OrmcoFormItemVertical>
  )
}

export { Product }
