import { Link } from "react-router-dom"

import styled from "styled-components"

import { Box } from "../common/Box"

export const HeaderContaainer = styled(Box)`
  z-index: ${({ theme }) => theme.zIndex.header};

  background-color: ${({ theme }) => theme.colors.primary700};
`

export const StyledLogoContainerLink = styled(Link)`
  align-self: center;

  min-width: 172px;
`
