import React from 'react'
import { useSelector } from 'react-redux'

import { OrmcoDTXLogo } from 'app/components/ui/Icons/common'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

import { LanguageDropdown } from '../LanguageDropdown'
import { Box } from '../common/Box'

import { HeaderNavigation, ImpersonationSection } from './components'
import { HeaderContaainer, StyledLogoContainerLink } from './styles'

export const Header = () => {
  const isImpersonated = useSelector(appSelectors.isImpersonated())

  return (
    <HeaderContaainer
      data-testid="Header"
      padding="0 16px"
      boxSizing="border-box"
      justify="space-between"
    >
      <Box justify="flex-start" height="58px">
        <StyledLogoContainerLink to={SiteMap.homePage()} data-testid="Header-OrmcoLogo">
          <OrmcoDTXLogo />
        </StyledLogoContainerLink>
        {isImpersonated && <ImpersonationSection data-testid="Header-ImpersonationInfo" />}
      </Box>
      <Box justify="flex-end" height="58px">
        <HeaderNavigation data-testid="Header-NavigationBar" />
        <Box width="auto" marginLeft="32px" marginRight="0">
          {isImpersonated && <LanguageDropdown data-testid="Header-LanguageDropdown" isDark />}
        </Box>
      </Box>
    </HeaderContaainer>
  )
}
