import { Doctor } from 'app/core/domain/Doctor'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { LastStaffUpdateResult, ResultStatus } from 'app/core/domain/ResultStatus'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface DoctorDetailsState {
  doctorDetails: PersonalInformation
  activeStaff: Doctor[]
  allActiveStaffSelected: boolean
  isDoctorDetailsRequested: boolean
  isDoctorUpdateFlow: boolean
  lastDoctorUpdateResult?: LastStaffUpdateResult
  isDoctorDetailsLoading: boolean
  isUpdateButtonVisible: boolean
}

const defaultDoctorDetails: PersonalInformation = {
  firstName: '',
  username: '',
  lastName: '',
  email: '',
  canManageCasesOf: [],
}

const INITIAL_STATE: DoctorDetailsState = {
  doctorDetails: { ...defaultDoctorDetails },
  activeStaff: [],
  allActiveStaffSelected: false,
  isDoctorDetailsRequested: false,
  isDoctorUpdateFlow: false,
  lastDoctorUpdateResult: undefined,
  isDoctorDetailsLoading: false,
  isUpdateButtonVisible: false,
}

const doctorDetailsActions = {
  doctorListMounted: createAction('@DOCTOR_DETAILS/DOCTOR_LIST_MOUNTED')<{
    isLoadingEnabled: boolean
  }>(),
  doctorDetailsRequestFinished: createAction(
    '@DOCTOR_DETAILS/DOCTOR_DETAILS_REQUEST_FINISHED',
  )<PersonalInformation>(),
  doctorDetailsRequestFailed: createAction('@DOCTOR_DETAILS/DOCTOR_DETAILS_REQUEST_FAILED')<{
    message: string
  }>(),
  doctorUsernameRequested: createAction('@DOCTOR_DETAILS/DOCTOR_USERNAME_REQUESTED')<{
    username: string
  }>(),
  doctorDetailsUnmounted: createAction('@DOCTOR_DETAILS/DOCTOR_DETAILS_UNMOUNTED')(),
  associatedStaffListReceived: createAction('@DOCTOR_DETAILS/ASSOCIATED_STAFF_LIST_RECEIVED')<
    Doctor[]
  >(),
  associatedStaffListRequestFailed: createAction(
    '@DOCTOR_DETAILS/ASSOCIATED_STAFF_LIST_REQUEST_FAILED',
  )<{ message: string }>(),
  associateAllStaffFlagChanged: createAction('@DOCTOR_DETAILS/ASSOCIATE_ALL_STAFF_FLAG_CHANGED')(),
  updateDoctorRequestComplete: createAction('@DOCTOR_DETAILS/UPDATE_DOCTOR_REQUEST_COMPLETE')(),
  updateDoctorRequestFailed: createAction('@DOCTOR_DETAILS/UPDATE_DOCTOR_REQUEST_FAILED')(),
  updateDoctorButtonClicked: createAction(
    '@DOCTOR_DETAILS/UPDATE_DOCTOR_BUTTON_CLICKED',
  )<PersonalInformation>(),
  doctorDetailsInputFieldValueChanged: createAction(
    '@DOCTOR_DETAILS/DOCTOR_DETAILS_INPUT_FIELD_VALUE_CHANGED',
  )(),
}

type DoctorDetailsActions = ActionType<typeof doctorDetailsActions>
const doctorDetailsReducer = createReducer<DoctorDetailsState, DoctorDetailsActions>(INITIAL_STATE)
  .handleAction(doctorDetailsActions.doctorListMounted, (state, action) => ({
    ...state,
    allActiveStaffSelected: false,
    isDoctorDetailsRequested: false,
    isDoctorUpdateFlow: false,
    lastDoctorUpdateResult: undefined,
    usernameAvailable: undefined,
    doctorDetails: { ...defaultDoctorDetails },
    activeStaff: [],
    isDoctorDetailsLoading: action.payload.isLoadingEnabled,
    isUpdateButtonVisible: false,
  }))
  .handleAction(doctorDetailsActions.doctorDetailsRequestFinished, (state, action) => {
    const { canManageCasesOf } = action.payload

    return {
      ...state,
      doctorDetails: action.payload,
      isDoctorDetailsRequested: true,
      isDoctorUpdateFlow: true,
      allActiveStaffSelected:
        canManageCasesOf !== undefined && state.activeStaff.length === canManageCasesOf.length,
      isDoctorDetailsLoading: false,
    }
  })
  .handleAction(doctorDetailsActions.doctorDetailsUnmounted, (state) => ({
    ...state,
    lastDoctorUpdateResult: undefined,
  }))
  .handleAction(doctorDetailsActions.associateAllStaffFlagChanged, (state) => ({
    ...state,
    allActiveStaffSelected: !state.allActiveStaffSelected,
    isUpdateButtonVisible: true,
  }))
  .handleAction(doctorDetailsActions.associatedStaffListReceived, (state, action) => ({
    ...state,
    activeStaff: action.payload,
  }))
  .handleAction(doctorDetailsActions.updateDoctorRequestComplete, (state) => ({
    ...state,
    lastDoctorUpdateResult: {
      status: ResultStatus.OK,
      message: 'staff.doctorUpdate.success',
    },
  }))
  .handleAction(doctorDetailsActions.updateDoctorRequestFailed, (state) => ({
    ...state,
    lastDoctorUpdateResult: {
      message: 'staff.doctorUpdate.failure',
      status: ResultStatus.BAD_REQUEST,
    },
  }))
  .handleAction(doctorDetailsActions.updateDoctorButtonClicked, (state, action) => {
    const { canManageCasesOf } = action.payload

    return {
      ...state,
      doctorDetails: { ...state.doctorDetails, canManageCasesOf },
      lastDoctorUpdateResult: undefined,
    }
  })
  .handleAction(doctorDetailsActions.doctorDetailsInputFieldValueChanged, (state) => ({
    ...state,
    isUpdateButtonVisible: true,
  }))

const doctorDetailsSelectors = {
  getDoctorDetails: () => (state: RootState) => state.doctorDetails.doctorDetails,
  getActiveStaff: () => (state: RootState) => state.doctorDetails.activeStaff,
  getAllActiveStaffSelected: () => (state: RootState) => state.doctorDetails.allActiveStaffSelected,
  isDoctorDetailsRequested: () => (state: RootState) =>
    state.doctorDetails.isDoctorDetailsRequested,
  isDoctorUpdateFlow: () => (state: RootState) => state.doctorDetails.isDoctorUpdateFlow,
  getLastDoctorUpdateResult: () => (state: RootState) => state.doctorDetails.lastDoctorUpdateResult,
  isDoctorDetailsLoading: () => (state: RootState) => state.doctorDetails.isDoctorDetailsLoading,
  isUpdateButtonVisible: () => (state: RootState) => state.doctorDetails.isUpdateButtonVisible,
}

export {
  DoctorDetailsState,
  doctorDetailsActions,
  doctorDetailsReducer,
  doctorDetailsSelectors,
  DoctorDetailsActions,
}
