import { FC, SVGProps } from 'react'

import { ReactComponent as SdsArchiveFilledIcon } from 'app/resources/img/icons/sds/archive-filled.svg'
import { ReactComponent as SdsArrowRightOutlinedIcon } from 'app/resources/img/icons/sds/arrow-right-outlined.svg'
import { ReactComponent as SdsCloseOutlinedIcon } from 'app/resources/img/icons/sds/close_outlined.svg'
import { ReactComponent as CrossIcon } from 'app/resources/img/icons/sds/cross.svg'
import { ReactComponent as SdsDeliveryFilledIcon } from 'app/resources/img/icons/sds/delivery-filled.svg'
import { ReactComponent as SdsDesktopFilledIcon } from 'app/resources/img/icons/sds/desktop-filled.svg'
import { ReactComponent as SdsExclamationCircleFilledIcon } from 'app/resources/img/icons/sds/exclamation-circle-filled.svg'
import { ReactComponent as SdsEyeOutlinedIcon } from 'app/resources/img/icons/sds/eye-outlined.svg'
import { ReactComponent as SdsGlobalOutlinedIcon } from 'app/resources/img/icons/sds/global-outlined.svg'
import { ReactComponent as SdsNoteFilledIcon } from 'app/resources/img/icons/sds/note-filled.svg'
import { ReactComponent as SdsNoteOutlinedIcon } from 'app/resources/img/icons/sds/note-outlined.svg'
import { ReactComponent as SdsPaperClipOutlinedIcon } from 'app/resources/img/icons/sds/paperclip-outlined.svg'
import { ReactComponent as SdsPhoneFilledIcon } from 'app/resources/img/icons/sds/phone-filled.svg'
import { ReactComponent as SdsPhoneOutlinedIcon } from 'app/resources/img/icons/sds/phone-outlined.svg'
import { ReactComponent as SdsRightCircleFilledIcon } from 'app/resources/img/icons/sds/right-circle-filled.svg'
import { ReactComponent as SpinnerIcon } from 'app/resources/img/icons/sds/spinner.svg'
import { ReactComponent as SdsToolFilledIcon } from 'app/resources/img/icons/sds/tool-filled.svg'
import { ReactComponent as SdsToolOutlinedIcon } from 'app/resources/img/icons/sds/tool-outlined.svg'
import { ReactComponent as SdsUnarchiveFilledIcon } from 'app/resources/img/icons/sds/unarchive-filled.svg'
import { ReactComponent as SdsUnarchiveOutlinedIcon } from 'app/resources/img/icons/sds/unarchive-outlined.svg'
import styled, { css, ThemeColors } from 'styled-components'

export interface IconsProps {
  width?: string
  color?: keyof ThemeColors | 'inherit' | 'currentColor'
}

const iconStyle = css`
  display: block;

  width: 100%;
  height: auto;
`

export const createSVGIcon = (Icon: FC<SVGProps<SVGSVGElement>>, initWidth: string) => styled(
  Icon,
)<IconsProps>`
  min-width: ${({ width = initWidth }) => width};
  max-width: ${({ width = initWidth }) => width};

  fill: ${({ color = 'currentColor', theme }) => {
    if (color === 'inherit' || color === 'currentColor') {
      return color
    }

    return theme.colors[color]
  }};

  ${iconStyle}
`

const SdsEyeOutlined = createSVGIcon(SdsEyeOutlinedIcon, '24px')
const SdsNoteOutlined = createSVGIcon(SdsNoteOutlinedIcon, '24px')
const SdsPaperClipOutlined = createSVGIcon(SdsPaperClipOutlinedIcon, '24px')
const SdsPhoneOutlined = createSVGIcon(SdsPhoneOutlinedIcon, '24px')
const SdsToolOutlined = createSVGIcon(SdsToolOutlinedIcon, '24px')
const SdsExclamationCircleFilled = createSVGIcon(SdsExclamationCircleFilledIcon, '24px')
const SdsUnarchiveOutlined = createSVGIcon(SdsUnarchiveOutlinedIcon, '24px')
const SdsCloseOutlined = createSVGIcon(SdsCloseOutlinedIcon, '24px')
const SdsPhoneFilled = createSVGIcon(SdsPhoneFilledIcon, '24px')
const SdsArchiveFilled = createSVGIcon(SdsArchiveFilledIcon, '24px')
const SdsArrowRightOutlined = createSVGIcon(SdsArrowRightOutlinedIcon, '24px')
const SdsDeliveryFilled = createSVGIcon(SdsDeliveryFilledIcon, '24px')
const SdsDesktopFilled = createSVGIcon(SdsDesktopFilledIcon, '24px')
const SdsNoteFilled = createSVGIcon(SdsNoteFilledIcon, '24px')
const SdsRightCircleFilled = createSVGIcon(SdsRightCircleFilledIcon, '24px')
const SdsToolFilled = createSVGIcon(SdsToolFilledIcon, '24px')
const SdsUnarchiveFilled = createSVGIcon(SdsUnarchiveFilledIcon, '24px')
const SdsGlobalOutlined = createSVGIcon(SdsGlobalOutlinedIcon, '24px')

const Cross = createSVGIcon(CrossIcon, '24px')
const Spinner = createSVGIcon(SpinnerIcon, '24px')

export {
  SdsEyeOutlined,
  SdsNoteOutlined,
  SdsPaperClipOutlined,
  SdsPhoneOutlined,
  SdsToolOutlined,
  SdsExclamationCircleFilled,
  SdsUnarchiveOutlined,
  SdsCloseOutlined,
  Cross,
  Spinner,
  SdsPhoneFilled,
  SdsArchiveFilled,
  SdsArrowRightOutlined,
  SdsDeliveryFilled,
  SdsDesktopFilled,
  SdsNoteFilled,
  SdsRightCircleFilled,
  SdsToolFilled,
  SdsUnarchiveFilled,
  SdsGlobalOutlined,
}
