import React from 'react'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { DateCard } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DateCard'

const CreatedCard: React.FC<{ data: CaseTableData }> = (props) => (
  <DateCard date={props.data.case.creationDateTime} />
)

export { CreatedCard }
