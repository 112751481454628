import { PropsWithChildren } from 'react'

import cn from 'classnames'

import { CheckboxContainer, CheckboxInner, CheckboxInput, CheckboxLabel } from './checkbox.styles'
import { CheckboxProps } from './interfaces'

export const Checkbox = ({
  'data-testid': testId,
  intermediate,
  disabled,
  children,
  ...rest
}: PropsWithChildren<CheckboxProps>) => {
  const isIntermediate = intermediate && !rest.checked

  return (
    <CheckboxContainer
      data-testid={testId}
      className="sds-checkbox-wrapper"
      intermediate={isIntermediate}
      disabled={disabled}
    >
      <span
        className={cn(
          'sds-checkbox',
          { 'sds-checkbox-intermediate': isIntermediate },
          { 'sds-checkbox-disabled': disabled },
        )}
      >
        <CheckboxInput
          className="sds-checkbox-input"
          type="checkbox"
          disabled={disabled}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
        <CheckboxInner
          className="sds-checkbox-inner"
          intermediate={isIntermediate}
          disabled={disabled}
        />
      </span>
      <CheckboxLabel disabled={disabled}>{children}</CheckboxLabel>
    </CheckboxContainer>
  )
}
