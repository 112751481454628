import React from 'react'
import { useSelector } from 'react-redux'

import { appSelectors } from 'app/logic/app/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const Fax: React.FC = () => {
  const { fax } = useSelector(appSelectors.getEmployeePersonalDetails())

  return <ReadonlyValue data-testid="Fax-FaxContainer" labelId="myProfile.fax" value={fax} />
}

export { Fax }
