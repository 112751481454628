import { Button } from 'app/components/ui/SDS'
import styled, { css } from 'styled-components'

const InfoBlockStyle = css`
  margin-top: 8px;

  background: ${({ theme }) => theme.colors.base10};
  border-radius: 2px;
`

const EllipsisStyle = css`
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
`

export const HoldReasonComment = styled.div`
  padding: 10px 8px;

  ${EllipsisStyle}
  ${InfoBlockStyle}
`

export const HoldReasonPhotosArea = styled.div`
  padding: 10px 8px 10px 14px;

  overflow: auto;

  max-height: 150px;
  ${InfoBlockStyle}
`

export const PhotoItem = styled.div`
  display: flex;
  align-items: center;
`

export const PhotoName = styled.div`
  margin: 4px 10px;

  width: 400px;

  ${EllipsisStyle}
`

export const PhotoNameLabel = styled.div`
  ${EllipsisStyle}
`

export const OnHoldButtons = styled(Button)`
  margin-top: 8px;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 22px;
  border: 1px solid #065ca2;

  background: #00467f linear-gradient(to bottom, #337ab7 0, #065ca2 100%) repeat-x;
  border-radius: 4px;
`

export const LinkButton = styled(Button)`
  padding: 0;

  overflow: hidden;

  max-width: 250px;
  height: 22px;

  text-decoration: underline;

  font-size: 13px;
`

export const LinkButtonText = styled.div`
  width: 250px;

  ${EllipsisStyle}
`
