import styled from 'styled-components'

interface OrmcoModalFooterProps {
  justifyContent?: 'flex-end' | 'space-between'
}

const OrmcoModalFooter = styled.div<OrmcoModalFooterProps>`
  padding: 15px;

  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent ?? 'space-between'};

  border-top: 1px solid ${({ theme }) => theme.colors.base300};
`

export { OrmcoModalFooter }
