import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { Address } from 'app/core/domain/Address'

import { AddressInput } from './AddressInput'

const Address1 = () => {
  const intl = useIntl()
  const {
    formState: { errors },
  } = useFormContext<Address>()

  return (
    <StaffFormItemVertical
      colon={false}
      label={<InputLabel labelId="addresses.addressForm.address1" />}
      hasFeedback
      validateStatus={getValidationStatus('address1', errors)}
      help={<OrmcoFormErrorMessage errors={errors} name="address1" />}
      data-testid="AddAddress-Address1"
    >
      <AddressInput
        name="address1"
        rules={{
          required: intl.formatMessage({ id: 'addresses.addressForm.address.required' }),
        }}
      />
    </StaffFormItemVertical>
  )
}

export { Address1 }
