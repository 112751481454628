import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { appSelectors } from 'app/logic/app/logic'

const VisitWebsiteButton: React.FC = () => {
  const { websiteUrl } = useSelector(appSelectors.getEmployeeContactDetails())
  const isValidUrl = useMemo(() => {
    const regex = new RegExp(/^(http|https):\/\//g)

    return !!(websiteUrl && regex.test(websiteUrl))
  }, [websiteUrl])

  const openWebsiteUrl = useCallback(() => {
    if (isValidUrl) {
      window.open(websiteUrl)
    }
  }, [websiteUrl, isValidUrl])

  return (
    <LightBlueButtonWithBorder
      disabled={!isValidUrl}
      onClick={openWebsiteUrl}
      data-testid="VisitWebsiteButton-LightBlueButtonWithBorder"
    >
      <FormattedMessage id="website.visitWebsiteButton" />
    </LightBlueButtonWithBorder>
  )
}

export { VisitWebsiteButton }
