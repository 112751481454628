import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

export interface AmazonStaticImagePayload {
  method: string
  url?: string
}

export interface InitialTruGenModalStatePayload {
  showPrimaryBrandingPopup: boolean
  showSparkAdvancedBrandingPopup: boolean
}

interface TruGenXRModalState {
  initBrandingPopUpValue: boolean
  initBrandingPopUpValueInAdvancedMode: boolean
  isTrugenXRModelOpen: boolean
  isTrugenXRModalAdvancedModeOpen: boolean
  trugenXRGifUrl: Nullable<string>
}

const INITIAL_STATE = {
  initBrandingPopUpValue: false,
  initBrandingPopUpValueInAdvancedMode: false,
  isTrugenXRModelOpen: false,
  isTrugenXRModalAdvancedModeOpen: false,
  trugenXRGifUrl: null,
}

const truGenXRModalActions = {
  truGenModalUnmounted: createAction('@TRUGEN_MODAL/MODAL_UNMOUNTED')(),
  setInitialTruGenModalState: createAction(
    '@TRUGEN_MODAL/SET_INITIAL_TRUGEN_MODEL_STATE',
  )<InitialTruGenModalStatePayload>(),
  toggleTruGenModal: createAction('@TRUGEN_MODAL/TRUGEN_MODEL_OPEN')<boolean>(),
  trugenPopupsDontShowUpdateRequested: createAction(
    '@TRUGEN_MODAL/DONT_SHOW_POPUPS_UPDATE_REQUESTED',
  )<boolean>(),
  trugenPopupsDontShowUpdateFinished: createAction(
    '@TRUGEN_MODAL/DONT_SHOW_POPUPS_UPDATE_FINISHED',
  )(),
  trugenPopupsDontShowUpdateFailed: createAction('@TRUGEN_MODAL/DONT_SHOW_POPUPS_UPDATE_FAILED')(),
  primaryTruGenXRGifRequested: createAction('@TRUGEN_MODAL/TRUGEN_GIF_URL_REQUESTED')(),
  primaryTruGenXRGifReceived: createAction(
    '@TRUGEN_MODAL/TRUGEN_GIF_URL_RECEIVED',
  )<AmazonStaticImagePayload>(),
  primaryTruGenXRGifFailed: createAction('@TRUGEN_MODAL/TRUGEN_GIF_URL_FAILED')(),
}

type TruGenXRModalActions = ActionType<typeof truGenXRModalActions>
const truGenXRModalReducer = createReducer<TruGenXRModalState, TruGenXRModalActions>(INITIAL_STATE)
  .handleAction([truGenXRModalActions.setInitialTruGenModalState], (state, action) => {
    const { showPrimaryBrandingPopup, showSparkAdvancedBrandingPopup } = action.payload

    return {
      ...state,
      initBrandingPopUpValue: showPrimaryBrandingPopup,
      initBrandingPopUpValueInAdvancedMode: showSparkAdvancedBrandingPopup,
      isTrugenXRModelOpen: showPrimaryBrandingPopup,
      isTrugenXRModalAdvancedModeOpen: showSparkAdvancedBrandingPopup,
    }
  })
  .handleAction([truGenXRModalActions.toggleTruGenModal], (state, action) => ({
    ...state,
    isTrugenXRModelOpen: action.payload,
    isTrugenXRModalAdvancedModeOpen: action.payload,
  }))
  .handleAction([truGenXRModalActions.trugenPopupsDontShowUpdateFinished], (state) => ({
    ...state,
    initBrandingPopUpValue: false,
    initBrandingPopUpValueInAdvancedMode: false,
  }))
  .handleAction([truGenXRModalActions.primaryTruGenXRGifReceived], (state, action) => ({
    ...state,
    trugenXRGifUrl: action.payload?.url ? action.payload.url : null,
  }))
  .handleAction([truGenXRModalActions.truGenModalUnmounted], (state) => ({
    ...state,
    trugenXRGifUrl: null,
    isTrugenXRModelOpen: state.initBrandingPopUpValue,
    isTrugenXRModalAdvancedModeOpen: state.initBrandingPopUpValueInAdvancedMode,
  }))

const truGenXRModalSelectors = {
  getIsTrugenXRModelOpen: () => (state: RootState) => state.truGenXRModal.isTrugenXRModelOpen,
  getIsTrugenXRModalAdvancedModeOpen: () => (state: RootState) =>
    state.truGenXRModal.isTrugenXRModalAdvancedModeOpen,
  getPrimaryTruGenXRGif: () => (state: RootState) => state.truGenXRModal.trugenXRGifUrl,
}

export {
  TruGenXRModalState,
  TruGenXRModalActions,
  truGenXRModalActions,
  truGenXRModalSelectors,
  truGenXRModalReducer,
}
