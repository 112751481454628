import { ReactElement } from 'react'

import { Col, Row } from 'antd'
import { Arch, getArchTeethNumbers, Universal } from 'app/core/domain/ArchTools'
import { TeethImageType } from 'app/core/domain/TeethImageType'
import styled from 'styled-components'

import { ALIGNERS_TEETH_MATRIX } from './alignerTeethMatrix'
import { sortOnArch } from './sortOnArchHelper'

const REVERSIBLE_TEETH = [9, 10, 11, 12, 13, 14, 15, 16, 25, 26, 27, 28, 29, 30, 31, 32]

interface ToothIconProps {
  width?: string
  mirror?: boolean
  type?: keyof typeof TeethImageType
}
interface ClickableTeethProps {
  arches: Arch[]
  teethMatrix?: { [key: number]: (props: ToothIconProps) => JSX.Element }
  onToothClick?: (toothNumber: Universal) => void
  getToothType?: (toothNumber: Universal) => keyof typeof TeethImageType
  getAuxComponent?: (toothNumber: Universal) => ReactElement | null
  'data-testid'?: string
}

const ClickableTeeth = (props: ClickableTeethProps) => {
  const {
    arches,
    teethMatrix = ALIGNERS_TEETH_MATRIX,
    getAuxComponent,
    getToothType,
    onToothClick,
    'data-testid': testId,
  } = props

  return (
    <Col span={24}>
      {arches.map((arche) => (
        <ToothRow
          key={arche}
          align={arche === Arch.UPPER ? 'bottom' : 'top'}
          data-testid={`${testId}-${arche}`}
        >
          {getArchTeethNumbers(arche)
            .sort(sortOnArch(arche))
            .map((toothNumber) => {
              const Icon = teethMatrix[toothNumber as keyof typeof teethMatrix]
              const isReversed = REVERSIBLE_TEETH.includes(toothNumber)
              const toothType = (getToothType && getToothType(toothNumber)) ?? TeethImageType.PLAIN

              return (
                <Tooth
                  key={toothNumber}
                  onClick={() => onToothClick?.(toothNumber)}
                  disabled={toothType === TeethImageType.DISABLED}
                  data-testid={`${testId}-${toothNumber}`}
                >
                  <div data-testid={`ToothType-${toothType}`}>
                    <Icon mirror={isReversed} type={toothType} />
                  </div>
                  {getAuxComponent && getAuxComponent(toothNumber)}
                </Tooth>
              )
            })}
        </ToothRow>
      ))}
    </Col>
  )
}

const ToothRow = styled(Row)`
  margin-top: 8px;

  flex-wrap: nowrap;
  justify-content: center;
`

const Tooth = styled.div<{ disabled?: boolean }>`
  position: relative;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  overflow: hidden;

  height: fit-content;
`

export { ClickableTeeth }
