import React, { useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

const DoctorPreferences = () => {
  const fullName = useSelector(appSelectors.getFullName())
  const patientDetails = useSelector(caseWizardSelectors.getFormPersonalInfo())
  const dispatch = useDispatch()
  const click = useCallback(() => {
    dispatch(caseWizardActions.clinicalPreferencesModalToggled(true))
  }, [dispatch])

  const DoctorPrefs = () => (
    <LinkButton onClick={click} data-testid="DoctorPreferences-LinkButton">
      <Typography component="span" isUnderlined>
        {patientDetails.doctorName !== undefined ? patientDetails.doctorName : fullName}
        <FormattedMessage id="caseWizard.casePrescription.doctorPreferences.clinicalPreferences" />
      </Typography>
      <br />
    </LinkButton>
  )

  useEffect(() => {
    dispatch(caseWizardActions.clinicalPreferencesRequested(patientDetails.doctorUsername))
  }, [patientDetails.doctorUsername, dispatch])

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.doctorPreferences.label" />
      }
      hasFeedback
      data-testid="DoctorPreferences"
    >
      <Typography>
        <FormattedMessage
          id="caseWizard.casePrescription.doctorPreferences.text"
          values={{
            doctorPrefs: <DoctorPrefs />,
          }}
        />
      </Typography>
    </OrmcoFormItemVertical>
  )
}

const LinkButton = styled.span`
  cursor: pointer;

  align-self: flex-end;
`

export { DoctorPreferences }
