import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

import { Position } from './interfaces'

interface ProgressBarContainerProps {
  isSDS: boolean
}

export const ProgressBarContainer = styled.div<ProgressBarContainerProps>`
  position: relative;

  width: 100%;
  height: 10px;

  border: solid ${({ theme, isSDS }) => (isSDS ? theme.colors.base300 : theme.colors.base800)};
  border-width: 1px 0;

  background: ${({ theme, isSDS }) => isSDS && theme.colors.base10};

  border-collapse: collapse;
`

export const ProgressBarFilling = styled.div<{ width: string }>`
  position: absolute;
  left: 0;

  width: ${(props) => props.width};
  height: 100%;

  background-color: ${({ theme }) => theme.colors.primary300};
`

interface StepProps {
  isSDS: boolean
  width: string
  position: Position
}

export const Step = styled.span<StepProps>`
  position: absolute;
  top: ${({ position }) => (position === Position.top ? '-7px' : 'unset')};
  bottom: ${({ position }) => (position === Position.bottom ? '-7px' : 'unset')};
  left: ${({ width }) => width};

  font-size: 6px;

  color: ${({ theme, isSDS }) => isSDS && theme.colors.base300};
`

export const StepCaption = styled(Typography)<{ width: string; position: Position }>`
  position: absolute;
  top: ${(props) => (props.position === Position.top ? '-1.98rem' : 'unset')};
  bottom: ${(props) => (props.position === Position.bottom ? '-22px' : 'unset')};
  left: ${(props) => props.width};
`

export const StandardProgressContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`
