enum ValidationStatus {
  'success' = 'success',
  'warning' = 'warning',
  'error' = 'error',
  'validating' = 'validating',
}

const getValidationStatus = (key: string, errors: any) =>
  Object.keys(errors).some((value) => value === key) ? ValidationStatus.error : undefined

export { ValidationStatus, getValidationStatus }
