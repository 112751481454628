import React from 'react'

import { Cross } from 'app/components/ui/Icons/sds'
import { Box } from 'app/components/ui/SDS/common/Box'

import { CommonModalProps } from './interfaces'
import { StyledFooterContainer, StyledModal } from './styles'

export const CommonModal = ({
  children,
  footerContent,
  isOpen,
  onClose,
  header,
  closable,
  overlayClosable,
  'data-testid': testId,
  padding,
  zIndex,
}: React.PropsWithChildren<CommonModalProps>) => {
  return (
    <StyledModal
      title={header}
      maskClosable={overlayClosable}
      closeIcon={<Cross width="12px" />}
      onCancel={onClose}
      closable={closable}
      footer={null}
      open={isOpen}
      destroyOnClose
      width="500px"
      data-testid={testId}
      zIndex={zIndex}
    >
      <Box justify="flex-start" padding={padding}>
        {children}
      </Box>
      {footerContent && <StyledFooterContainer>{footerContent}</StyledFooterContainer>}
    </StyledModal>
  )
}
