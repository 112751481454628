import { SessionStorageKeysMap } from 'app/core/domain/SessionStorageKeysMap'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, ignoreElements, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { integratedHooksModalActions } from './logic'

const syncModalSessionStorageStatus: Epic<RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf([integratedHooksModalActions.toggleIntegratedHooksModal])),
    tap(({ payload }) => {
      if (!!payload) {
        return
      }

      window.sessionStorage.setItem(
        SessionStorageKeysMap.isIntegratedHooksModalOpen,
        payload.toString(),
      )
    }),
    ignoreElements(),
  )

const saveIntegratedHooksDecisionEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf([integratedHooksModalActions.integratedHooksModalDontShowUpdateRequested])),
    switchMap((action) => {
      const username = appSelectors.getUsername()(state$.value)
      const isChecked = action.payload

      return from(
        axios.put(`/api/v1/doctors/${username}/toggleIntegratedHooksFlag?selection=${!isChecked}`),
      ).pipe(
        switchMap(() =>
          of(integratedHooksModalActions.integratedHooksModalDontShowUpdateFinished()),
        ),
        catchError(() =>
          of(integratedHooksModalActions.integratedHooksModalDontShowUpdateFailed()),
        ),
      )
    }),
  )

const getIntegratedHooksModalImageEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([integratedHooksModalActions.integratedHooksModalImageRequested])),
    switchMap(() =>
      from(
        axios
          .get('/api/v1/webapi/staticS3Images/integratedHooks')
          .then((res) =>
            integratedHooksModalActions.integratedHooksModalImageRequestFinished(res.data),
          )
          .catch(() => integratedHooksModalActions.integratedHooksModalImageRequestFailed()),
      ),
    ),
  )

const integratedHooksModalEpic = combineEpics(
  syncModalSessionStorageStatus,
  saveIntegratedHooksDecisionEpic,
  getIntegratedHooksModalImageEpic,
)

export { integratedHooksModalEpic }
