import {
  termsActions,
  termsSelectors,
} from 'app/components/routes/BasicPage/TermsAndConditionsModal/logic'
import { TermsResponse } from 'app/core/domain/Http/TermsResponse'
import { SiteMap } from 'app/core/react/SiteMap'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const makeTermsRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([appActions.userSettingsReceived, appActions.termsPdfLinkRequested])),
    switchMap(() =>
      from(
        axios
          .get<TermsResponse>(`/api/v1/users/${appSelectors.getUsername()(state$.value)}/terms`, {
            params: { language: appSelectors.getLanguage()(state$.value) },
          })
          .then((res) => termsActions.termsRequestFinished(res.data))
          .catch((res) => termsActions.termsRequestFailed(res)),
      ),
    ),
  )

const makeDecisionRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([termsActions.decisionMade])),
    switchMap(() => {
      const { accepted, version } = termsSelectors.getTermsState()(state$.value)

      if (accepted) {
        return from(
          axios
            .put<TermsResponse>(`/api/v1/users/${appSelectors.getUsername()(state$.value)}/terms`, {
              accepted,
              version,
            })
            .then((res) => termsActions.termsRequestFinished(res.data))
            .catch((res) => termsActions.termsRequestFailed(res)),
        )
      }
      window.location.href = SiteMap.logout()
      return Promise.reject()
    }),
  )

const termsEpic = combineEpics(makeTermsRequestEpic, makeDecisionRequestEpic)

export { termsEpic }
