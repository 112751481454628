import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { AuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AuxComponent'
import { Unmovable } from 'app/components/ui/Icons/teeth-auxiliaries'
import { CaseDraft } from 'app/core/domain/CaseDraft'

import { ActivitySelection } from '../ActiveAuxiliary/ActivitySelection'

const getIdbAuxiliaries = (dontMoveTeeth: number[]) => [
  {
    arr: dontMoveTeeth,
    type: ActivitySelection.dontMoveTeeth,
    name: 'dontMove',
    Icon: Unmovable,
  },
]

export const useAvailableIdbAuxiliaries = () => {
  const form = useFormContext<CaseDraft>()

  const {
    primaryCasePreferences: { dontMoveTeeth },
  } = form.getValues()

  const availableAuxiliaries: AuxComponent<ActivitySelection>[] = useMemo(
    () => getIdbAuxiliaries(dontMoveTeeth),
    [dontMoveTeeth],
  )

  return availableAuxiliaries
}
