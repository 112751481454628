import React from 'react'
import { useController } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Checkbox } from 'app/components/ui/SDS/common/Checkbox'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'
import { appActions } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'

export const SDSReceiveEmail = () => {
  const dispatch = useDispatch()

  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  const receiveEmail = useController({ name: 'receiveEmail' })

  const updateUserProfileData = (event: React.ChangeEvent<HTMLInputElement>) => {
    receiveEmail.field.onChange(event)

    dispatch(
      appActions.saveEmployeeDetails({
        part: event.target.checked,
        key: 'employeeContactDetails.receiveEmail',
      }),
    )
  }

  return (
    <Box marginTop="5px" marginBottom="10px" justify="flex-start">
      <Checkbox
        onChange={updateUserProfileData}
        checked={receiveEmail.field.value}
        data-testid="ContactDetails-ReceiveEmail"
        disabled={featurePermissions?.disableUserProfile ?? false}
      >
        <FormattedMessage id="receive.email.updates" />
      </Checkbox>
      <OrmcoTooltip
        title={<TooltipText data-testid="RecieveMail-TooltipContent" textId="receive.email.help" />}
        placement="top"
        overlayInnerStyle={{
          padding: '11px',
          whiteSpace: 'break-spaces',
        }}
      >
        <HelpIcon data-testid="RecieveMail-TooltipIcon" />
      </OrmcoTooltip>
    </Box>
  )
}
