import { GetBottomMessageRendererProps } from './interfaces'

export const getBottomMessageRenderer = ({
  helpMessage,
  error,
  dataTestId,
}: GetBottomMessageRendererProps) => {
  if (error) {
    return (
      <span className="sds-select__error-message" data-testid={`${dataTestId}-ErrorMessage`}>
        {error}
      </span>
    )
  }

  if (helpMessage) {
    return (
      <span className="sds-select__help-message" data-testid={`${dataTestId}-HelpMessage`}>
        {helpMessage}
      </span>
    )
  }

  return null
}
