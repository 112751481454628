import React from 'react'
import { useSelector } from 'react-redux'

import { LogoutLink } from 'app/components/routes/BasicPage/Header/NavigationBar/LogoutLink/LogoutLink'
import { NavigationLink } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationLink/NavigationLink'
import { PatientText } from 'app/components/ui/Icons/common'
import { UserRole } from 'app/core/domain/UserRole'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

interface OperatorNavigationBarProps {
  'data-testid': string
}

const OperatorNavigationBar: React.FC<OperatorNavigationBarProps> = (props) => {
  const isDesign = useSelector(appSelectors.hasRole(UserRole.ROLE_DESIGN))
  const isDesignQC = useSelector(appSelectors.hasRole(UserRole.ROLE_DESIGNER_QC))
  const canViewClinicalPreferences = isDesign || isDesignQC

  return (
    <>
      <NavigationLink
        to={SiteMap.homePage()}
        icon={<PatientText />}
        data-testid={`${props['data-testid']}-patients`}
        linkTextId="header.navigation.patients"
      />

      {canViewClinicalPreferences && (
        <NavigationLink
          href={SiteMap.OPERATORS.clinicalPreferences()}
          data-testid={`${props['data-testid']}-clinicalPreferences`}
          linkTextId="header.navigation.clinicalPreferences"
        />
      )}

      <NavigationLink
        href={SiteMap.profile()}
        data-testid={`${props['data-testid']}-profile`}
        linkTextId="header.navigation.profile"
      />

      <LogoutLink data-testid={`${props['data-testid']}-LogoutLink`} />
    </>
  )
}

export { OperatorNavigationBar }
