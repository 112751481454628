import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Divider } from 'antd'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { AffectedArche } from 'app/core/domain/AffectedArches'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import {
  IntAnteriorTeeth,
  OverbiteActionPrimary,
  OverbitePrimary,
} from 'app/core/domain/OverbitePrimary'

import { BiteLowerUpper } from './BiteLowerUpper'

const PROPERTY_KEY = 'primaryCasePreferences.overbitePrimary'

const CorrectDeepBite = () => {
  const form = useFormContext<CaseDraft>()
  const overbitePrimary = form.getValues(PROPERTY_KEY)
  const theme = useContext(ThemeContext)
  const changeRadio = (res: OverbitePrimary) => {
    form.setValue(PROPERTY_KEY, res)
  }

  return (
    <ThemedBox data-testid="CorrectDeepBite-Section" contextTheme={theme}>
      <OrmcoFormItemVertical hasFeedback data-testid="CorrectDeepBite">
        <Vertical>
          <OrmcoRadio
            checked={
              overbitePrimary !== undefined &&
              overbitePrimary.intAnteriorTeeth === IntAnteriorTeeth.INT_ANT_TEETH_ONLY
            }
            onChange={() =>
              changeRadio({
                ...overbitePrimary,
                overbiteActionPrimary: OverbiteActionPrimary.CORRECT_DEEP_BITE,
                intAnteriorTeeth: IntAnteriorTeeth.INT_ANT_TEETH_ONLY,
              })
            }
            data-testid="CorrectDeepBite-IntrudeAnterior"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.overbiteAction.affectedTeeth.INTRUDE_ANTERIOR" />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            checked={
              overbitePrimary !== undefined &&
              overbitePrimary.intAnteriorTeeth === IntAnteriorTeeth.INT_ANT_EXT_POST_TEETH
            }
            onChange={() =>
              changeRadio({
                ...overbitePrimary,
                overbiteActionPrimary: OverbiteActionPrimary.CORRECT_DEEP_BITE,
                intAnteriorTeeth: IntAnteriorTeeth.INT_ANT_EXT_POST_TEETH,
              })
            }
            data-testid="CorrectDeepBite-IntrudeAnteriorAndPosterior"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.overbiteAction.affectedTeeth.INTRUDE_ANTERIOR_AND_POSTERIOR" />
            </Typography>
          </OrmcoRadio>
          <Divider />
          <BiteLowerUpper
            selectedArch={overbitePrimary?.biteLowerUpper}
            defaultArch={overbitePrimary?.biteLowerUpper ?? AffectedArche.BOTH}
            formatMessageKey="casePrescription.overbite"
            data-testid="CorrectDeepBite-LowerUpper"
          />
        </Vertical>
      </OrmcoFormItemVertical>
    </ThemedBox>
  )
}

export { CorrectDeepBite }
