import { useSelector } from 'react-redux'

import { caseListSelectors } from 'app/components/routes/CaseList/logic'
import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

import { CommonModal } from '../Modal/CommonModal'

import { ViewFileModalProps } from './interfaces'

export const ViewFileModal = ({
  isOpen,
  onClose,
  afterClose,
  'data-testid': dataTestId,
}: ViewFileModalProps) => {
  const holdCaseImageUrl = useSelector(caseListSelectors.holdCaseImageUrl())
  const holdCaseFileName = useSelector(caseListSelectors.holdCaseFileName())
  const isHoldCaseImageUrlLoading = useSelector(caseListSelectors.isHoldCaseImageUrlLoading())

  const isPdfFormat = holdCaseImageUrl.search(/.pdf/) != -1

  return (
    <CommonModal
      padding="8px"
      data-testid={dataTestId}
      onClose={onClose}
      zIndex={9002}
      header={
        <Typography padding="8px" variant="medium" component="span" textFontWeight="600">
          {holdCaseFileName}
        </Typography>
      }
      isOpen={isOpen}
      afterClose={afterClose}
    >
      <Box height="474px">
        {isHoldCaseImageUrlLoading ? (
          <Loader width="24px" height="25px" isAbsoluteCenter />
        ) : (
          <Box>
            {isPdfFormat ? (
              <iframe width="100%" height="474px" src={holdCaseImageUrl} />
            ) : (
              <img width="100%" height="auto" src={holdCaseImageUrl} alt="file-image" />
            )}
          </Box>
        )}
      </Box>
    </CommonModal>
  )
}
