import { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'

import { HybridScans } from 'app/components/routes/CaseWizard/hybrid/HybridScans'
import { EmptyStep } from 'app/components/routes/CaseWizard/steps/Empty'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'

import { HybridCasePrescription } from './hybrid/HybridCasePrescription'

interface FormSetting {
  name: string | ReactElement
  form: ReactElement
  validators?: []
  id: string
  link?: string
}

const hybridWizardSteps: Array<FormSetting> = [
  {
    name: <FormattedMessage id="caseWizard.navigation.hybrid.patientDetails" />,
    validators: [],
    form: <EmptyStep />,
    link: `${EnvironmentParams.ALIGNER_WEB_URL}/editPatientDetails?action=Edit&caseId=`,
    id: 'patientDetails',
  },
  {
    name: <FormattedMessage id="caseWizard.navigation.photos" />,
    validators: [],
    link: `${EnvironmentParams.ALIGNER_WEB_URL}/editPhotos?caseId=`,
    form: <EmptyStep />,
    id: 'photosAndScans',
  },
  {
    name: <FormattedMessage id="caseWizard.navigation.scans" />,
    validators: [],
    form: <HybridScans />,
    id: 'scans',
  },
  {
    name: <FormattedMessage id="caseWizard.navigation.hybrid.patientPrescription" />,
    validators: [],
    form: <HybridCasePrescription />,
    id: 'patientPrescription',
  },
  {
    name: <FormattedMessage id="caseWizard.navigation.hybrid.casePreferences" />,
    validators: [],
    form: <EmptyStep />,
    link: `${EnvironmentParams.ALIGNER_WEB_URL}/editPatientCasePreference?caseId=`,
    id: 'casePreferences',
  },
  {
    name: <FormattedMessage id="caseWizard.navigation.hybrid.review" />,
    validators: [],
    form: <EmptyStep />,
    link: `${EnvironmentParams.ALIGNER_WEB_URL}/navigationCaseEditReviewPage?caseId=`,
    id: 'review',
  },
]

export { hybridWizardSteps, FormSetting }
