/* eslint-disable no-inner-declarations */
enum UserRole {
  ROLE_NORMAL = 'ROLE_NORMAL',
  ROLE_STAFF = 'ROLE_STAFF',
  ROLE_USER_ADMIN = 'ROLE_USER_ADMIN',
  ROLE_OPERATOR = 'ROLE_OPERATOR',
  ROLE_DESIGN = 'ROLE_DESIGN',
  ROLE_DESIGNER_QC = 'ROLE_DESIGNER_QC',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace UserRole {
  export function values(): UserRole[] {
    return Object.values(UserRole) as UserRole[]
  }
}

export { UserRole }
