enum ResponseStatus {
  'BAD_REQUEST' = 'BAD_REQUEST',
  'OK' = 'OK',
}

interface UpdateResult<P = null> {
  status: ResponseStatus
  message: string
  payload?: P
}

export { ResponseStatus, UpdateResult }
