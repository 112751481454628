import { PATIENT_PRESCRIPTION_INDEX } from 'app/components/routes/CaseWizard/hybrid/HybridCaseWizardProgress'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { editScansSelectors } from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { STANDARD_DEBOUNCE_TIME } from 'app/core/constants/global'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { getCaseDraftUpdateUrl } from 'app/core/net/urls'
import { appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, debounceTime, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { hybridWizardSteps } from './wizardSteps'

const createCaseDraftEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseWizardActions.createCaseButtonClicked])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const patientDetails = caseWizardSelectors.getFormPersonalInfo()(state$.value)

      return from(
        axios.post<CaseDraft>(`/api/v1/doctors/${username}/cases`, { patientDetails }),
      ).pipe(
        switchMap((res) =>
          of(
            caseWizardActions.createCaseDraftRequestComplete(res.data),
            caseWizardActions.removeErrorMessage(),
          ),
        ),
        catchError((err) => of(caseWizardActions.requestFailed(err.message))),
      )
    }),
  )

const cancelCaseDraftEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseWizardActions.applyCancelButtonClicked])),
    switchMap(({ payload }) => {
      const id = caseWizardSelectors.getDraftId()(state$.value)
      const username = appSelectors.getUsername()(state$.value)

      return from(
        axios.post<CaseDraft>(`/api/v1/doctors/${username}/cases/cancel/${id}`, { ...payload }),
      ).pipe(
        switchMap(() =>
          of(caseWizardActions.setLoadingStatus(false), caseWizardActions.removeErrorMessage()),
        ),
        catchError((err) => of(caseWizardActions.requestFailed(err.message))),
      )
    }),
  )

const caseDraftPartUpdatedEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseWizardActions.caseDraftPartUpdated])),
    filter(() => caseWizardSelectors.getDraftId()(state$.value) !== undefined),
    debounceTime(STANDARD_DEBOUNCE_TIME),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const draft = caseWizardSelectors.getDraftData()(state$.value)
      const id = caseWizardSelectors.getDraftId()(state$.value)
      const isValidCase = editScansSelectors.getValidCaseValue()(state$.value)
      const activeIndex = caseWizardSelectors.getActiveFormIndex()(state$.value)

      if (isValidCase && activeIndex === PATIENT_PRESCRIPTION_INDEX) {
        const url = getCaseDraftUpdateUrl(id!, username, draft.patientDetails.treatmentType)

        return from(
          axios.put(url, draft, {
            params: {
              timestamp: new Date().getTime(),
            },
          }),
        ).pipe(
          switchMap(() => of(caseWizardActions.removeErrorMessage())),
          catchError((err) => of(caseWizardActions.requestFailed(err.message))),
        )
      }

      return of(caseWizardActions.requestFailed(''))
    }),
  )

const getCaseDraftEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseWizardActions.caseIdRequested])),
    filter(() => editScansSelectors.getValidCaseValue()(state$.value) === true),
    switchMap(({ payload }) => {
      const { id } = payload
      const username = appSelectors.getUsername()(state$.value)
      const isV6FormActive = featureFlagSelectors.getFeatureFlags()(
        state$.value,
      ).primaryPrescriptionV6

      return from(axios.get<CaseDraft>(`/api/v1/doctors/${username}/cases/${id}`)).pipe(
        switchMap((res) => {
          let formData = res.data

          if (isV6FormActive) {
            formData.primaryCasePreferences.versionNumber = '6'
          }

          return of(
            caseWizardActions.getCaseDraftRequestComplete(formData),
            caseWizardActions.removeErrorMessage(),
          )
        }),
        catchError((err) => of(caseWizardActions.requestFailed(err.message))),
      )
    }),
  )

const getClinicalPreferencesEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(caseWizardActions.clinicalPreferencesRequested)),
    switchMap((payload) => {
      const caseDoctor = payload.payload
      const username = appSelectors.getUsername()(state$.value)

      return from(
        axios.get(`/api/v1/doctors/${username}/clinicalPreferences`, {
          params: { doctorName: caseDoctor },
        }),
      ).pipe(
        switchMap((prefReult) =>
          from(axios.get(prefReult.data.url)).pipe(
            switchMap((res) =>
              of(
                caseWizardActions.clinicalPreferencesReceived(res.data.clinicalPreferences),
                caseWizardActions.removeErrorMessage(),
              ),
            ),
            catchError((err) => of(caseWizardActions.requestFailed(err.message))),
          ),
        ),
        catchError((err) => of(caseWizardActions.requestFailed(err.message))),
      )
    }),
  )

const updateCaseDraftEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseWizardActions.updateCaseDraftRequested])),
    filter(() => caseWizardSelectors.getDraftId()(state$.value) !== undefined),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const draft = caseWizardSelectors.getDraftData()(state$.value)
      const id = caseWizardSelectors.getDraftId()(state$.value)
      const isValidCase = editScansSelectors.getValidCaseValue()(state$.value)
      const activeIndex = caseWizardSelectors.getActiveFormIndex()(state$.value)

      if (isValidCase && activeIndex === PATIENT_PRESCRIPTION_INDEX) {
        const url = getCaseDraftUpdateUrl(id!, username, draft.patientDetails.treatmentType)

        return from(axios.put(url, draft)).pipe(
          switchMap((res) => {
            let index = caseWizardSelectors.getNextActiveFormIndex()(state$.value)

            if (index === undefined) {
              index = caseWizardSelectors.getActiveFormIndex()(state$.value)
            }
            let { link } = hybridWizardSteps[index]

            if (link) {
              link += id
              window.location.href = link
            }

            return of(
              caseWizardActions.updateCaseDraftRequestComplete(res.data),
              caseWizardActions.removeErrorMessage(),
            )
          }),
          catchError((err) => of(caseWizardActions.requestFailed(err.message))),
        )
      }

      return of(caseWizardActions.requestFailed(''))
    }),
  )

const getTruGenTermsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseWizardActions.truGenTermsRequested])),
    switchMap((action) =>
      from(
        axios
          .get<string>(
            `/api/v1/webapi${action.payload.truGenPdfURL}?userName=${appSelectors.getUsername()(
              state$.value,
            )}`,
            { params: { language: appSelectors.getLanguage()(state$.value) } },
          )
          .then((res) => caseWizardActions.truGenTermsReceived(res.data))
          .catch((res) => caseWizardActions.truGenTermsFailed(res)),
      ),
    ),
  )

const caseWizardEpic = combineEpics(
  cancelCaseDraftEpic,
  createCaseDraftEpic,
  getCaseDraftEpic,
  updateCaseDraftEpic,
  getClinicalPreferencesEpic,
  caseDraftPartUpdatedEpic,
  getTruGenTermsEpic,
)

export { caseWizardEpic }
