import { FormattedMessage } from 'react-intl'

import { Carret } from 'app/components/ui/Carret'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

interface NavigationContentProps {
  'data-testid'?: string
  icon?: JSX.Element
  carret?: boolean
  textId: string
  rawValue?: boolean
}

export const NavigationContent = (props: NavigationContentProps) => {
  const { icon, carret, textId, rawValue } = props

  return (
    <Box>
      {icon && (
        <Typography component="span" margin="0 8px 0" color="white">
          {icon}
        </Typography>
      )}

      <Typography data-testid={`${props['data-testid']}-Text`} component="span" color="white">
        {rawValue ? textId : <FormattedMessage id={textId} />}
      </Typography>

      {carret && <Carret />}
    </Box>
  )
}
