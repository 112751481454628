import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { emailValidatorUtil } from 'app/core/react/EmailValidationUtil'

const StaffEmail: React.FC = () => {
  const intl = useIntl()
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalInformation>()

  const emailValidator = (emailFieldValue?: string) =>
    !emailValidatorUtil(emailFieldValue) ? intl.formatMessage({ id: 'email.invalid' }) : true

  return (
    <StaffFormItemVertical
      colon={false}
      label={<InputLabel labelId="staff.staffDetails.staffEmail.label" />}
      marginBottom="0"
      hasFeedback
      validateStatus={getValidationStatus('email', errors)}
      help={<OrmcoFormErrorMessage errors={errors} name="email" />}
      data-testid="StaffDetails-StaffEmail"
    >
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <StaffInput
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
          />
        )}
        rules={{
          validate: { emailValidator },
          required: intl.formatMessage({ id: 'staff.staffDetails.staffEmail.required' }),
        }}
      />
    </StaffFormItemVertical>
  )
}

export { StaffEmail }
