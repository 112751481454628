import { FC } from 'react'

import { Box } from 'app/components/ui/SDS/common/Box'

export const BrandindModalBody: FC = ({ children }) => {
  return (
    <Box flexDirection="column" padding="25px">
      {children}
    </Box>
  )
}
