import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { ConfirmPassword } from 'app/components/routes/MyProfile/ChangePasswordModal/ConfirmPassword'
import { NewPassword } from 'app/components/routes/MyProfile/ChangePasswordModal/NewPassword'
import { OldPassword } from 'app/components/routes/MyProfile/ChangePasswordModal/OldPassword'
import {
  changePasswordModalActions,
  changePasswordModalSelectors,
} from 'app/components/routes/MyProfile/ChangePasswordModal/logic'
import { InfoButton, LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import {
  PasswordValidationMessages,
  UserProfilePassword,
} from 'app/core/domain/UserProfilePassword'
import { usePrevious } from 'app/core/react/CustomHooks'
import styled, { css } from 'styled-components'

const ChangePasswordModal: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const isOpen = useSelector(changePasswordModalSelectors.isOpen())
  const passwordUpdateResult = useSelector(changePasswordModalSelectors.getPasswordUpdateResult())
  const passwordForm = useForm<UserProfilePassword & { serverError?: string[] }>({
    mode: 'onChange',
  })
  const values = passwordForm.watch()
  const prevValues = usePrevious(values)
  const { clearErrors, formState, register, reset, setError, setValue } = passwordForm

  const disableButton = useMemo(
    () => !values.newPassword || !values.confirmPassword || !values.oldPassword,
    [values],
  )

  const close = useCallback(() => {
    reset()
    dispatch(changePasswordModalActions.closeModalClicked())
  }, [dispatch, reset])

  const onSubmit = useCallback(
    (password: UserProfilePassword) => {
      dispatch(changePasswordModalActions.updateButtonClicked(password))
    },
    [dispatch],
  )

  useEffect(() => {
    const { newPassword, confirmPassword, oldPassword } = values
    const { isSubmitted } = formState
    const isUpdated =
      newPassword !== prevValues.newPassword || confirmPassword !== prevValues.confirmPassword

    if (!isSubmitted || !newPassword || !confirmPassword) return

    if (formState.errors.serverError && isUpdated) {
      clearErrors('serverError')
    }

    if (formState.errors.newPassword && newPassword !== prevValues.newPassword) {
      clearErrors('newPassword')
    }

    if (formState.errors.oldPassword && oldPassword !== prevValues.oldPassword) {
      clearErrors('oldPassword')
    }

    if (formState.errors.confirmPassword && confirmPassword !== prevValues.confirmPassword) {
      clearErrors('confirmPassword')
    }
    if (!isOpen) {
      setValue('newPassword', '')
      setValue('confirmPassword', '')
      setValue('oldPassword', '')
    }
  }, [clearErrors, formState, prevValues, values, isOpen, setValue])

  useEffect(() => {
    if (
      passwordUpdateResult &&
      passwordUpdateResult.status === ResponseStatus.BAD_REQUEST &&
      Array.isArray(passwordUpdateResult.payload)
    ) {
      if (
        passwordUpdateResult.payload.includes(PasswordValidationMessages.OLD_PASSWORD_INCORRECT)
      ) {
        setError('oldPassword', { type: 'old-password-incorrect' })
      } else {
        clearErrors('oldPassword')
      }
      if (passwordUpdateResult.payload.includes(PasswordValidationMessages.NO_MATCH)) {
        setError('confirmPassword', { type: 'not-match' })
        setError('newPassword', { type: 'not-match' })
      } else {
        clearErrors('confirmPassword')
        clearErrors('newPassword')
      }

      const messages = passwordUpdateResult.payload.map((item) =>
        intl.formatMessage(
          {
            id: 'new.password.message',
          },
          {
            passwordValidationMessage: PasswordValidationMessages[item],
          },
        ),
      )

      register('serverError')
      setError('serverError', {
        type: 'server-error',
        message: messages.join('; '),
      })
    }

    if (passwordUpdateResult && passwordUpdateResult.status) {
      dispatch(changePasswordModalActions.changePasswordUnmounted())
    }
  }, [clearErrors, dispatch, intl, passwordUpdateResult, register, setError])

  return (
    <>
      <FormProvider {...passwordForm}>
        <OrmcoModal
          isOpen={isOpen}
          onRequestClose={close}
          additionalStyles={{
            content: {
              top: '10%',
              padding: '13px',
              width: '400px',
            },
            overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
          }}
          shouldCloseOnOverlayClick={false}
          data-testid="ChangePasswordModal"
        >
          <ChangePasswordModalHeader data-testid="ChangePasswordModal-Header">
            <OrmcoModalHeaderText headerId="change.password" />
            <Button type="text" onClick={close} data-testid="ChangePasswordModal-Close">
              <Cancel />
            </Button>
          </ChangePasswordModalHeader>
          <ChangePasswordModalBody data-testid="ChangePasswordModal-Body">
            <OldPassword />
            <NewPassword />
            <ConfirmPassword />
            <OrmcoFormErrorMessage errors={passwordForm.formState.errors} name="serverError" />
          </ChangePasswordModalBody>
          <ChangePasswordModalFooter data-testid="ChangePasswordModal-Footer">
            <LightBlueButtonWithBorder onClick={close} data-testid="ChangePasswordModal-Cancel">
              <FormattedMessage id="footer.cancel" />
            </LightBlueButtonWithBorder>
            <Padder width="10px" />
            <UpdateButton
              disabled={disableButton}
              onClick={passwordForm.handleSubmit(onSubmit)}
              data-testid="ChangePasswordModal-UpdateButton"
            >
              <FormattedMessage id="footer.update" />
            </UpdateButton>
          </ChangePasswordModalFooter>
        </OrmcoModal>
      </FormProvider>
    </>
  )
}

export { ChangePasswordModal }

const ChangePasswordModalHeader = styled(OrmcoModalHeader)`
  flex-direction: row;

  width: 370px;
  border-bottom: 0;
`

const ChangePasswordModalBody = styled(OrmcoModalBody)`
  width: 370px;
`

const ChangePasswordModalFooter = styled(OrmcoModalFooter)`
  justify-content: flex-end;

  border-top: 0;
`

interface UpdateButtonProps {
  disabled?: boolean
}

const updateButtonDisabledMixin = (props: UpdateButtonProps) =>
  props.disabled &&
  css`
    color: ${({ theme }) => theme.colors.base300};
    background: ${({ theme }) => theme.colors.primary200};
  `

const UpdateButton = styled(InfoButton)<UpdateButtonProps>`
  &&& {
    justify-content: center;

    width: 108px;
    height: 40px;

    font-weight: 600;

    ${updateButtonDisabledMixin};
  }
`
