import { Locale } from 'app/core/i18n/Locale'

export const getSameLanguagesMap = (locales: string[]) =>
  locales.reduce<{ [lang: string]: number }>((langMap, locale) => {
    const lang = new Locale(locale).languageCode
    const res = { ...langMap }

    if (lang) {
      res[lang] = langMap[lang] ? langMap[lang] + 1 : 1
    }
    return res
  }, {})
