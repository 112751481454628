import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  staffDeleteModalActions,
  staffDeleteModalSelectors,
} from 'app/components/routes/Staff/StaffDeleteModal/logic'
import { staffCreationActions } from 'app/components/routes/Staff/StaffDetails/logic'
import { LightBlueButtonWithBorder, RedButton } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBodyText } from 'app/components/ui/Modal/OrmcoModalBodyText'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

const StaffDeleteModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(staffDeleteModalSelectors.isOpen())
  const form = useFormContext<PersonalInformation>()

  const close = useCallback(() => {
    dispatch(staffDeleteModalActions.closeModalClicked())
  }, [dispatch])

  const onSubmit = useCallback(
    (personalInfo: PersonalInformation) => {
      dispatch(staffCreationActions.deleteStaffButtonClicked(personalInfo))
    },
    [dispatch],
  )

  useDidMount(() => {
    dispatch(staffDeleteModalActions.staffDeleteModalMounted())
  })
  return (
    <>
      <OrmcoModal
        isOpen={isOpen}
        onRequestClose={close}
        additionalStyles={{
          content: {
            top: '40%',
            padding: '13px',
          },
          overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
        }}
        shouldCloseOnOverlayClick={false}
        data-testid="StaffDeleteModal"
      >
        <StaffDeleteModalHeader data-testid="StaffDeleteModal-Heading">
          <OrmcoModalHeaderText headerId="staff.delete.modal.header" />
          <Button type="text" onClick={close} data-testid="StaffDeleteModal-Close">
            <Cancel />
          </Button>
        </StaffDeleteModalHeader>
        <OrmcoModalBodyText data-testid="StaffDeleteModal-Body" textId="staff.delete.modal.body" />
        <StaffDeleteModalFooter data-testid="StaffDeleteModal-Footer">
          <CancelButton onClick={close} data-testid="StaffDeleteModal-Cancel">
            <FormattedMessage id="staff.creation.cancel" />
          </CancelButton>
          <Padder width="10px" />
          <DeleteStaffButton
            onClick={form.handleSubmit(onSubmit)}
            data-testid="StaffDeleteModal-Delete"
          >
            <FormattedMessage id="staff.delete.modal" />
          </DeleteStaffButton>
        </StaffDeleteModalFooter>
      </OrmcoModal>
    </>
  )
}

export { StaffDeleteModal }

const StaffDeleteModalHeader = styled(OrmcoModalHeader)`
  flex-direction: row;
  justify-content: space-between;

  width: 560px;
  border-bottom: 0;
`

const StaffDeleteModalFooter = styled(OrmcoModalFooter)`
  margin-left: auto;

  justify-content: space-between;

  border-top: 0;
`

const DeleteStaffButton = styled(RedButton)`
  justify-content: center;

  width: 108px;
  height: 40px;

  font-weight: 600;
`

const CancelButton = styled(LightBlueButtonWithBorder)`
  justify-content: center;

  width: 108px;
  height: 40px;

  font-weight: 600;
`
