import { UserRole } from 'app/core/domain/UserRole'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { doctorDetailsActions, doctorDetailsSelectors } from './logic'

const getDoctorDetailsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([doctorDetailsActions.doctorUsernameRequested])),
    switchMap(({ payload }) => {
      const { username } = payload
      const loginUsername = appSelectors.getUsername()(state$.value)

      return from(
        axios.get(`/api/v1/users/${loginUsername}/viewUserDetails?employeeUserName=${username}`),
      ).pipe(
        switchMap((res) => of(doctorDetailsActions.doctorDetailsRequestFinished(res.data))),
        catchError((err) => of(doctorDetailsActions.doctorDetailsRequestFailed({ message: err }))),
      )
    }),
  )
const getAssociatedStaffListEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([doctorDetailsActions.doctorListMounted])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(
        axios.get(`/api/v1/users/${username}/clinicusers`, {
          params: {
            role: UserRole.ROLE_STAFF,
            status: 'active',
          },
        }),
      ).pipe(
        switchMap((res) => of(doctorDetailsActions.associatedStaffListReceived(res.data.users))),
        catchError((err) =>
          of(doctorDetailsActions.associatedStaffListRequestFailed({ message: err.toString() })),
        ),
      )
    }),
  )

const updateDoctorEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([doctorDetailsActions.updateDoctorButtonClicked])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const doctor = doctorDetailsSelectors.getDoctorDetails()(state$.value)
      const { canManageCasesOf } = doctor

      return from(
        axios.put(`/api/v1/users/${username}/addUpdateUser?updatePolicyOnly=true`, {
          ...doctor,
          canManageCasesOf: canManageCasesOf === undefined ? [] : canManageCasesOf,
        }),
      ).pipe(
        switchMap(() => of(doctorDetailsActions.updateDoctorRequestComplete())),
        catchError(() => of(doctorDetailsActions.updateDoctorRequestFailed())),
      )
    }),
  )
const doctorDetailsEpic = combineEpics(
  getDoctorDetailsEpic,
  getAssociatedStaffListEpic,
  updateDoctorEpic,
)

export { doctorDetailsEpic }
