enum PasswordValidationMessages {
  'HISTORY_VIOLATION' = 'HISTORY_VIOLATION',
  'ILLEGAL_WHITESPACE' = 'ILLEGAL_WHITESPACE',
  'ILLEGAL_WORD' = 'ILLEGAL_WORD',
  'INSUFFICIENT_ALPHABETICAL' = 'INSUFFICIENT_ALPHABETICAL',
  'INSUFFICIENT_DIGIT' = 'INSUFFICIENT_DIGIT',
  'INSUFFICIENT_LOWERCASE' = 'INSUFFICIENT_LOWERCASE',
  'INSUFFICIENT_UPPERCASE' = 'INSUFFICIENT_UPPERCASE',
  'NO_MATCH' = 'NO_MATCH',
  'OLD_PASSWORD_INCORRECT' = 'OLD_PASSWORD_INCORRECT',
  'PASSWORD_CHANGE_SUCCESS' = 'PASSWORD_CHANGE_SUCCESS',
  'SAME_PASSWORD' = 'SAME_PASSWORD',
  'SOURCE_VIOLATION' = 'SOURCE_VIOLATION',
  'TOO_LONG' = 'TOO_LONG',
  'TOO_SHORT' = 'TOO_SHORT',
}

type PasswordValidationCode = keyof typeof PasswordValidationMessages

interface UserProfilePassword {
  oldPassword?: string
  newPassword?: string
  confirmPassword?: string
}

export { PasswordValidationCode, PasswordValidationMessages, UserProfilePassword }
