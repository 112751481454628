import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PrimaryButton } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'

interface TruGenModalProps {
  isOpen: boolean
  onClose: () => void
}

const ThreeShapeAccountsWarningModal: React.FC<TruGenModalProps> = (props) => (
  <OrmcoModal
    isOpen={props.isOpen}
    data-testid="ThreeShapeAccountsWarningModal-OrmcoModal"
    onRequestClose={props.onClose}
  >
    <OrmcoModalHeader flexDirection="row">
      <OrmcoModalHeaderText variant="h5" headerId="3shape.connection" />
      <Button
        type="text"
        onClick={props.onClose}
        data-testid="ThreeShapeAccountsWarningModal-Close"
      >
        <Cancel />
      </Button>
    </OrmcoModalHeader>
    <OrmcoModalBody>
      <Typography
        data-testid="ThreeShapeAccountsWarningModal-Message"
        component="span"
        color="danger500"
      >
        <FormattedMessage id="3shape.connection.setupCompleteWarning" />
      </Typography>
    </OrmcoModalBody>
    <OrmcoModalFooter justifyContent="flex-end">
      <PrimaryButton onClick={props.onClose}>
        <FormattedMessage id="button.ok" />
      </PrimaryButton>
    </OrmcoModalFooter>
  </OrmcoModal>
)

export { ThreeShapeAccountsWarningModal }
