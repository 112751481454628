import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import styled from 'styled-components'

const StaffInput = styled(Input)<InputProps>`
  &&& {
    width: ${({ width }) => width ?? '321px'};
    height: 41px;

    font-weight: 500;

    border-radius: 4px;

    &:focus {
      border: 2px solid ${({ theme }) => theme.colors.primary800};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.base100};
      background-color: ${({ theme }) => theme.colors.base100};
    }
  }
`

export { StaffInput }
