import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface UnarchiveModalState {
  isOpen: boolean
  caseId: Nullable<string>
}

const INITIAL_STATE: UnarchiveModalState = {
  isOpen: false,
  caseId: null,
}

const unarchiveModalActions = {
  openModalButtonClicked: createAction('@UNARCHIVE_MODAL/OPEN_MODAL_BUTTON_CLICKED')<{
    caseId: string
  }>(),
  closeButtonClicked: createAction('@UNARCHIVE_MODAL/CLOSE_BUTTON_CLICKED')(),
  unarchiveButtonClicked: createAction('@UNARCHIVE_MODAL/UNARCHIVE_BUTTON_CLICKED')(),
  unarchiveRequestReceived: createAction('@UNARCHIVE_MODAL/UNARCHIVE_REQUEST_RECEIVED')(),
  unarchiveRequestFailed: createAction('@UNARCHIVE_MODAL/UNARCHIVE_REQUEST_FAILED')(),
}

type UnarchiveModalActions = ActionType<typeof unarchiveModalActions>

const unarchiveModalReducer = createReducer<UnarchiveModalState, UnarchiveModalActions>(
  INITIAL_STATE,
)
  .handleAction(unarchiveModalActions.openModalButtonClicked, (state, action) => ({
    ...state,
    caseId: action.payload.caseId,
    isOpen: true,
  }))
  .handleAction(
    [unarchiveModalActions.unarchiveRequestReceived, unarchiveModalActions.closeButtonClicked],
    (state) => ({
      ...state,
      caseId: null,
      isOpen: false,
    }),
  )

const unarchiveModalSelectors = {
  getOpenState: () => (state: RootState) => state.unarchive.isOpen,
  getCaseId: () => (state: RootState) => state.unarchive.caseId,
}

export {
  unarchiveModalReducer,
  UnarchiveModalState,
  UnarchiveModalActions,
  unarchiveModalActions,
  unarchiveModalSelectors,
}
