import { FormattedMessage } from 'react-intl'

import { HorizontalLine } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/HorizontalLine'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

import { ActivitySelection } from './ActiveAuxiliary/ActivitySelection'
import { IdbAuxiliariesSummary } from './Summary/IdbAuxiliariesSummary'
import { AuxClickableTeeth } from './TeethPanel/AuxClickableTeeth'
import { useAvailableIdbAuxiliaries } from './TeethPanel/useAvailableIdbAuxiliaries'

export const IdbTeethSection = () => {
  const availableAuxiliaries = useAvailableIdbAuxiliaries()

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.teethSection.label" />
      }
      hasFeedback
    >
      <Box minWidth="615px" flexDirection="column" align="stretch">
        <Box>
          <Typography component="label" color="base800">
            <FormattedMessage id="caseWizard.casePrescription.teethSection.instructions" />
          </Typography>
        </Box>
        <TeethSelectionContainer
          data-testid="TeethSelection"
          flexDirection="column"
          width="auto"
          margin="16px 0"
          padding="16px"
        >
          <Box data-testid="TeethSelection-ClickableTeeth">
            <AuxClickableTeeth
              auxiliaries={availableAuxiliaries}
              activeSelect={ActivitySelection.dontMoveTeeth}
              data-testid="TeethSelection-Aux"
            />
          </Box>
          <Box maxWidth="568px" height="38px" margin="24px 0 0" justify="space-between">
            <Typography data-testid="TeethSelection-Right" component="label">
              <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.right" />
            </Typography>
            <HorizontalLine color="base800" />
            <Typography data-testid="TeethSelection-Left" component="label">
              <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.left" />
            </Typography>
          </Box>
        </TeethSelectionContainer>
        <IdbAuxiliariesSummary auxiliaries={availableAuxiliaries} />
      </Box>
    </OrmcoFormItemVertical>
  )
}

const TeethSelectionContainer = styled(Box)`
  &&& {
    background-color: ${({ theme }) => theme.colors.base100};
    border-radius: 4px;
  }
`
