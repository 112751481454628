import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Col, Row } from 'antd'
import { AuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AuxComponent'
import { AuxiliaryTypeSummary } from 'app/components/routes/CaseWizard/form/Teeth/Summary/Legacy/AuxiliaryTypeSummary'
import { TeethNums } from 'app/components/routes/CaseWizard/form/Teeth/Summary/Legacy/TeethNums'
import styled from 'styled-components'

interface AuxiliariesSummaryProps {
  auxiliaries: AuxComponent<string>[]
}

export const AlignerAuxiliariesSummary: FC<AuxiliariesSummaryProps> = ({ auxiliaries }) => (
  <Container span={8} data-testid="TeethNumbersContainer">
    <Row />
    {auxiliaries.map(({ type, name, arr, Icon }) => (
      <AuxiliaryTypeSummary
        key={name}
        type={name}
        icon={<Icon width="22px" />}
        caption={
          <FormattedMessage
            id="caseWizard.casePrescription.auxiliaries.showTeethNumbers.caption"
            values={{ mode: type }}
          />
        }
      >
        <TeethNums data-testid="casePrescription-teethPlace" arr={arr} />
      </AuxiliaryTypeSummary>
    ))}
  </Container>
)

const Container = styled(Col)`
  padding: 15px;
`
