import { Region } from 'app/core/domain/Region'
import { ensure } from 'app/core/utils/ensure'
import { getYear, isWithinInterval } from 'date-fns'

import { Badge } from './interfaces'
import { useGetCasePerksQuery } from './logic'

export type CasePerksGradation = {
  value: number
  badge: Badge
}

export type CasePerksDates = {
  leftDateThreshold: Date
  rightDateThreshold: Date
}

const americaGradations = [
  { value: 30, badge: Badge.INSIDER },
  { value: 60, badge: Badge.PINNACLE },
]

export const useCasePerksProgressData = () => {
  const { showShippedCasesPerks, region, badge } = useGetCasePerksQuery(undefined, {
    selectFromResult: ({ data }) => ensure(data),
  })

  const now = new Date()

  if (region === Region.NORTH_AMERICA) {
    const firstHalfOfYear = {
      leftDateThreshold: new Date(getYear(now), 0, 1),
      rightDateThreshold: new Date(getYear(now), 5, 30),
    }
    const lastHalfOfYear = {
      leftDateThreshold: new Date(getYear(now), 6, 1),
      rightDateThreshold: new Date(getYear(now), 11, 31),
    }

    const dates = isWithinInterval(now, {
      start: firstHalfOfYear.leftDateThreshold,
      end: firstHalfOfYear.rightDateThreshold,
    })
      ? firstHalfOfYear
      : lastHalfOfYear

    return {
      showShippedCasesPerks,
      badge,
      casePerksGradations: americaGradations,
      dates,
      testId: 'AmericaCasePerksProgress',
    }
  }

  throw new Error('Not available region was specified to show case progress')
}
