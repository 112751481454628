import _ from 'lodash'
import styled from 'styled-components'

interface GlyphiconProps {
  type: IconType
}

const GlyphIcon = styled.div<GlyphiconProps>`
  position: relative;

  display: inline-block;

  font-family: 'Glyphicons Halflings';
  font-weight: 400;
  font-style: normal;

  color: ${(props) => props.color};

  &::before {
    content: '\\${(props) => props.type}';
  }
`

enum IconType {
  GEAR = 'e019',
  LOGOUT = 'e161',
  THUMBSUP = 'e125',
  THUMBSDOWN = 'e126',
  USER = 'e008',
  PLUS = '002b',
  SEARCH = 'e003',
  REMOVE = 'e014',
  REMOVE_SIGN = 'e083',
  OK_SIGN = 'e084',
  SAVED = 'e168',
}

/* eslint-disable no-inner-declarations */
// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace IconType {
  export function values(): IconType[] {
    return Object.values(IconType) as IconType[]
  }

  export function isIconType(value: string): value is IconType {
    return _.includes(IconType.values(), value)
  }
}

interface IconProps {
  icon: string
  size?: string
}

const defaultSize = '25px'
const Icon = styled.img.attrs((props: IconProps) => ({
  src: props.icon,
}))<IconProps>`
  height: ${(props) => props.size || defaultSize};
`

export { GlyphIcon, Icon, IconType }
