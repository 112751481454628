import { FC } from 'react'

import { Button as AntdButton } from 'antd'
import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button'
import cn from 'classnames'
import styled, { css } from 'styled-components'

import { getFillBtnColorScheme, getOutlineBtnColorScheme } from './colorScheme'
import {
  ButtonProps,
  BtnState,
  BgBorderTextColorStructure,
  OutlineColorsStateStructure,
} from './interfaces'

const SDSButton: FC<ButtonProps> = (props) => {
  const { className, type = 'outline', status, rightIcon, isDark, children, ...rest } = props
  let antdType = type

  if (antdType === 'fill') {
    antdType = 'primary'
  } else if (antdType === 'outline') {
    antdType = 'default'
  }

  return (
    <AntdButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      type={antdType}
      className={cn(
        className,
        { [`sds-btn-status-${status}`]: status },
        { [`sds-btn-${type}`]: type },
        { [`sds-btn-theme-dark`]: isDark },
        { 'sds-btn-no-children': !children },
      )}
    >
      {children}
      {rightIcon}
    </AntdButton>
  )
}

const bgBorderTextColors = (currentColors: OutlineColorsStateStructure, state: BtnState) => {
  const color = currentColors[state] as BgBorderTextColorStructure

  return css`
    border-color: ${color.borderColor};

    color: ${color.color};
    background-color: ${color.backgroundColor};
  `
}

const antdBtnTextStyle = css`
  &.ant-btn-text {
    border: none;
  }
`

const getAntdBtnStyle = (size: AntdButtonProps['size']) => {
  let padding = '4px 18px'

  if (size === 'small') {
    padding = '0 10px'
  } else if (size === 'large') {
    padding = '6px 18px'
  }
  return css`
    padding: ${padding};

    display: inline-flex;
    gap: 0 10px;
    align-items: center;
  `
}

export const Button = styled(SDSButton)`
  ${({ theme, type = 'fill', status, size = 'middle', isDark }) => {
    if (!status) {
      return css`
        &.ant-btn-text {
          padding: 0;
          background: transparent;
        }
      `
    }

    if (type === 'fill') {
      const currentColors = getFillBtnColorScheme(isDark, theme)[status || 'primary']

      return css`
        ${getAntdBtnStyle(size)}
        color: ${status === 'basic' ? theme.colors.base800 : theme.colors.white};
        border: none;
        background-color: ${currentColors.default.backgroundColor};

        &:hover {
          background-color: ${currentColors.hover.backgroundColor};
        }

        &:focus {
          background-color: ${currentColors.focus.backgroundColor};
        }

        ${status === 'basic' &&
        css`
          &:hover,
          &:focus {
            color: ${theme.colors.base800};
          }
        `}

        &[disabled], &[disabled]:hover, &[disabled]:focus {
          background-color: ${currentColors.disabled.backgroundColor};
          color: ${currentColors.disabled.color};

          svg {
            opacity: 0.5;
          }
        }
      `
    } else if (type === 'outline' || type === 'text') {
      const currentColors = getOutlineBtnColorScheme(isDark, theme)[status || 'primary']

      return css`
        ${getAntdBtnStyle(size)}
        color: ${currentColors.default.color};
        border-color: ${currentColors.default.borderColor};
        background: none;
        ${antdBtnTextStyle}

        &:hover {
          ${bgBorderTextColors(currentColors, 'hover')}
        }
        &:focus {
          ${bgBorderTextColors(currentColors, 'focus')}
        }

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus {
          ${bgBorderTextColors(currentColors, 'disabled')}
          ${antdBtnTextStyle}
          background: none;
          svg {
            opacity: 0.5;
          }
        }
      `
    } else {
      return css``
    }
  }}
`
