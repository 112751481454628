import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { SelectLegacy } from 'app/components/ui/Select/SelectLegacy'
import { SelectorDropdownIcon } from 'app/components/ui/Select/SelectorDropdownIcon'
import { ProductGroup } from 'app/core/domain/ProductGroup'
import styled from 'styled-components'

import { caseFilterActions, caseFilterSelectors } from '../logic'

export const ProductFilter = () => {
  const dispatch = useDispatch()

  const value = useSelector(caseFilterSelectors.getCaseFilterProductGroup())

  const onChange = useCallback(
    (productGroup: ProductGroup) => {
      dispatch(caseFilterActions.caseFilterProductGroupSelected(productGroup))
    },
    [dispatch],
  )

  return (
    <ProductFilterContainer width="134px" marginRight="8px" data-testid="CaseFilter-ProductFilter">
      <SelectLegacy
        data-testId="CaseFilter-ProductFilter-Dropdown"
        value={value}
        onChange={onChange}
        suffixIcon={<SelectorDropdownIcon />}
      >
        <Select.Option value={ProductGroup.All}>
          <FormattedMessage
            id="caseFilter.productGroup"
            values={{ productGroup: ProductGroup.All }}
          />
        </Select.Option>

        <Select.Option value={ProductGroup.Aligners}>
          <FormattedMessage
            id="caseFilter.productGroup"
            values={{ productGroup: ProductGroup.Aligners }}
          />
        </Select.Option>

        <Select.Option value={ProductGroup.IDB}>
          <FormattedMessage
            id="caseFilter.productGroup"
            values={{ productGroup: ProductGroup.IDB }}
          />
        </Select.Option>
      </SelectLegacy>
    </ProductFilterContainer>
  )
}

const ProductFilterContainer = styled(Box)`
  .ant-select {
    width: 100%;
  }
`
