import { FC } from 'react'

import { Button, Col, Row } from 'antd'
import { AuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AuxComponent'
import styled from 'styled-components'

import { ActivitySelection } from './ActivitySelection'

interface ActiveAuxiliarySelectorProps {
  auxiliaries: AuxComponent<ActivitySelection>[]
  selected: keyof typeof ActivitySelection
  onSelect: (selection: keyof typeof ActivitySelection) => void
  'data-testid': string
}

const ActiveAuxiliarySelector: FC<ActiveAuxiliarySelectorProps> = ({
  auxiliaries,
  selected,
  onSelect,
  'data-testid': testId,
}) => (
  <Row justify="center" align="middle" data-testid={testId}>
    <Col>
      <ActivityRow>
        {auxiliaries.map(({ name, type, Icon }) => (
          <Col key={name}>
            <ActivityButton
              isActive={selected === type}
              onClick={() => onSelect(type)}
              type="text"
              data-testid={`${testId}-${name}Teeth`}
            >
              <Icon width="16px" />
            </ActivityButton>
          </Col>
        ))}
      </ActivityRow>
    </Col>
  </Row>
)

const ActivityRow = styled(Row)`
  &&& {
    border: 1px solid ${({ theme }) => theme.colors.base800};

    background-color: ${({ theme }) => theme.colors.base100};
    border-radius: 4px;
  }
`

const ActivityButton = styled(Button)<{ isActive?: boolean }>`
  &&& {
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 37px;
    height: 37px;

    background-color: ${({ isActive, theme }) =>
      isActive ? theme.colors.base300 : theme.colors.base100};
  }
`

export { ActiveAuxiliarySelector }
