import styled from 'styled-components'

const Carret = styled.div`
  margin-top: 2px;
  margin-left: 3px;

  display: inline-block;

  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;

  color: white;

  vertical-align: middle;
`

export { Carret }
