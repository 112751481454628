import { FC } from 'react'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

export const ClinicalPreferencesBoxPropertyValue: FC<{ 'data-testid'?: string }> = (props) => {
  return (
    <PropertyValue data-testid={props['data-testid']}>
      <Typography
        data-testid={`${props['data-testid']}-Text`}
        component="span"
        letterSpacing="0.1px"
        textFontWeight="600"
        color="black"
      >
        {props.children}
      </Typography>
    </PropertyValue>
  )
}

export const ClinicalPreferencesPropertyValue: FC<{ 'data-testid'?: string }> = (props) => {
  return (
    <PropertyValue data-testid={props['data-testid']}>
      <Typography
        data-testid={`${props['data-testid']}-Text`}
        component="span"
        letterSpacing="0.1px"
        color="base900"
      >
        {props.children}
      </Typography>
    </PropertyValue>
  )
}

const PropertyValue = styled.div`
  display: flex;
  flex-basis: 70%;
  flex-direction: column;
`
