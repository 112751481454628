import { Checkbox } from 'antd'
import styled from 'styled-components'

const CheckboxInput = styled(Checkbox)<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled &&
    `
    .ant-checkbox-inner {
      background-color: ${theme.colors.base300} !important;
    }`}
`

export { CheckboxInput }
