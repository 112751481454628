import styled, { css } from 'styled-components'

import { CheckboxComponentProps, DisabledCheckboxComponentProps } from './interfaces'

export const CheckboxContainer = styled.label<CheckboxComponentProps>`
  margin: 0;
  padding: 0;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  display: inline-flex;

  align-items: center;

  ${({ disabled, intermediate }) =>
    !disabled &&
    css`
      &:hover {
        .sds-checkbox-input:checked + .sds-checkbox-inner {
          border: 1px solid ${({ theme }) => theme.colors.primary400};

          background: ${({ theme }) => theme.colors.primary400};
        }

        .sds-checkbox-inner {
          filter: drop-shadow(0px 1px 1px rgba(9, 122, 214, 0.15))
            drop-shadow(0px 1px 6px rgba(9, 122, 214, 0.15));

          ${intermediate
            ? css`
                &::before {
                  background: ${({ theme }) => theme.colors.primary400};
                }
              `
            : css`
                border: 1px solid ${({ theme }) => theme.colors.primary400};
              `}
        }
      }
    `}
`

export const CheckboxInner = styled.span<CheckboxComponentProps>`
  position: relative;

  display: block;

  width: 16px;
  height: 16px;

  border: 1px solid ${({ theme }) => theme.colors.base200};

  background: ${({ disabled, theme }) => (disabled ? theme.colors.base10 : theme.colors.white)};

  border-radius: 2px;

  transition: all 0.3s;

  border-collapse: separate;

  &::before {
    position: absolute;

    top: 50%;
    left: 50%;

    content: ' ';
    opacity: ${({ intermediate }) => (intermediate ? '1' : '0')};

    width: 8px;
    height: 8px;

    border: 0;

    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.base200 : theme.colors.primary500};

    transform: translate(-50%, -50%);

    transition: opacity 0.3s, background 0.3s;
  }

  &::after {
    position: absolute;

    top: 50%;
    left: 21.5%;

    content: ' ';
    opacity: 0;

    width: 6px;
    height: 9px;

    border: 2px solid
      ${({ disabled, theme }) => (disabled ? theme.colors.base300 : theme.colors.white)};
    border-top: 0;
    border-left: 0;

    transform: rotate(45deg) translate(-50%, -50%);

    transition: opacity 0.3s;
  }
`

export const CheckboxInput = styled.input<DisabledCheckboxComponentProps>`
  display: none;

  &:checked + .sds-checkbox-inner {
    ${({ disabled }) =>
      !disabled &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.primary500};

        background: ${({ theme }) => theme.colors.primary500};
      `}

    &::after {
      opacity: 1;
    }
  }
`

export const CheckboxLabel = styled.span<DisabledCheckboxComponentProps>`
  padding-right: 8px;
  padding-left: 8px;

  font-size: ${({ theme }) => theme.fontVariants.default.fontSize};
  font-weight: 400;
  line-height: ${({ theme }) => theme.fontVariants.default.lineHeight};

  color: ${({ disabled, theme }) => (disabled ? theme.colors.base300 : theme.colors.base900)};
`

export const VerticalCheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  .sds-checkbox-wrapper + .sds-checkbox-wrapper {
    margin-top: 16px;
  }
`

export const HorizontalCheckboxGroupContainer = styled.div`
  display: flex;

  .sds-checkbox-wrapper + .sds-checkbox-wrapper {
    margin-left: 8px;
  }
`
