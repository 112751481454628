enum OrderType {
  'Primary' = 'Primary',
  'Refinement' = 'Refinement',
  'Reorder' = 'Reorder',
  'Secondary' = 'Secondary',
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
namespace OrderType {
  // eslint-disable-next-line no-inner-declarations
  export function letter(orderType: OrderType): string {
    if (orderType === OrderType.Primary) {
      return 'P'
    }
    if (OrderType.Secondary) {
      return 'S'
    }
    return ''
  }
}

export { OrderType }
