import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { LightBlueButton } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { Loader } from 'app/components/ui/Loader'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { OrmcoTable } from 'app/components/ui/OrmcoTable/OrmcoTable'
import { Pagination } from 'app/components/ui/Pagination'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThreeShapeAccount } from 'app/core/domain/ThreeShapeAccount'
import { ThreeShapeCaseItem } from 'app/core/domain/ThreeShapeCaseList'

const MAX_CASE_AMOUNT_PER_PAGE = 10

interface TruGenModalProps {
  isOpen: boolean
  onClose: () => void
  selectedAccount: ThreeShapeAccount
}

type TableData = Pick<
  ThreeShapeCaseItem,
  'threeShapeCaseId' | 'caseId' | 'patientName' | 'updatedOn'
> & {
  key: React.Key
}

export const ThreeShapeModal: React.FC<TruGenModalProps> = (props) => {
  const { isOpen, onClose, selectedAccount } = props
  const dispatch = useDispatch()
  const caseList = useSelector(editScansSelectors.getThreeShapeCaseList())
  const [pageNum, setPageNum] = useState(0)

  const handleSelectScan = useCallback(
    (threeShapeCaseId: string) => {
      const selectedScan = caseList?.data.find((scan) => scan.threeShapeCaseId === threeShapeCaseId)

      if (selectedScan) {
        dispatch(
          editScansActions.threeShapeScanSelectRequested({
            caseId: String(selectedScan.caseId),
            threeshapeAccountId: String(selectedAccount.id),
            threeShapeCaseId: selectedScan.threeShapeCaseId,
            threeShapeScanHref: selectedScan.threeShapeScanHref,
          }),
        )
      }

      onClose()
    },
    [caseList, dispatch, onClose, selectedAccount],
  )

  const tableColumns = useMemo(
    () => [
      {
        dataIndex: 'patientName',
        title: (
          <ThreeShareModalTableHeader
            data-testid="ThreeShapeModal-TableHead-patientName"
            headerTextId="3shape.modal.patientName"
          />
        ),
        render: (patientName: string) => (
          <ThreeShareModalTableValue
            data-testid="ThreeShapeModal-TableCell-patientName"
            text={patientName}
          />
        ),
      },
      {
        dataIndex: 'updatedOn',
        title: (
          <ThreeShareModalTableHeader
            data-testid="ThreeShapeModal-TableHead-updatedOn"
            headerTextId="3shape.modal.updatedOn"
          />
        ),
        render: (updatedOn: string) => (
          <ThreeShareModalTableValue
            data-testid="ThreeShapeModal-TableCell-updatedOn"
            text={updatedOn}
          />
        ),
      },
      {
        dataIndex: 'threeShapeCaseId',
        key: 'actions',
        title: '',
        render: (threeShapeCaseId: string) => (
          <LightBlueButton
            as="button"
            onClick={() => handleSelectScan(threeShapeCaseId)}
            data-testid="ThreeShapeModal-TableCell-LightBlueButton"
          >
            <FormattedMessage id="3shape.modal.selectScans" />
          </LightBlueButton>
        ),
      },
    ],
    [handleSelectScan],
  )

  const tableData: TableData[] = useMemo(
    () =>
      (caseList?.data ?? []).map((caseItem) => ({
        key: caseItem.threeShapeCaseId,
        threeShapeCaseId: caseItem.threeShapeCaseId,
        caseId: caseItem.caseId,
        patientName: caseItem.patientName,
        updatedOn: caseItem.updatedOn,
      })),
    [caseList],
  )

  useEffect(() => {
    if (isOpen) {
      dispatch(
        editScansActions.threeShapeCaseListRequested({
          id: selectedAccount.id,
          pageNum,
          accountId: selectedAccount.id,
        }),
      )
    }
  }, [dispatch, isOpen, pageNum, selectedAccount.id])

  return (
    <OrmcoModal isOpen={isOpen} data-testid="ThreeShapeModal-OrmcoModal" onRequestClose={onClose}>
      <OrmcoModalHeader flexDirection="row" data-testid="ThreeShapeModal-OrmcoModalHeader">
        <OrmcoModalHeaderText variant="h5" headerId="3shape.modal.title" />
        <Button type="text" onClick={onClose} data-testid="ThreeShapeModal-CloseButton">
          <Cancel />
        </Button>
      </OrmcoModalHeader>
      <OrmcoModalBody>
        {caseList ? (
          <>
            <OrmcoTable
              columns={tableColumns}
              dataSource={tableData}
              pagination={false}
              data-testid="ThreeShapeModal-OrmcoTable"
            />

            <Pagination
              showingAmount={MAX_CASE_AMOUNT_PER_PAGE}
              totalAmount={caseList.count}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data-testid="ThreeShapeModal-Pagination"
            />
          </>
        ) : (
          <Loader margin="0 auto" />
        )}
      </OrmcoModalBody>
    </OrmcoModal>
  )
}

interface ThreeShareModalTableHeaderProps {
  'data-testid': string
  headerTextId: string
}

const ThreeShareModalTableHeader = (props: ThreeShareModalTableHeaderProps) => {
  return (
    <Typography
      data-testid={props['data-testid']}
      component="span"
      variant="small"
      color="primary700"
      textFontWeight="600"
      isUppercase
    >
      <FormattedMessage id={props.headerTextId} />
    </Typography>
  )
}

interface ThreeShareModalTableValueProps {
  'data-testid': string
  text: string
}

const ThreeShareModalTableValue = (props: ThreeShareModalTableValueProps) => {
  return (
    <Typography data-testid={props['data-testid']} component="span" variant="small">
      {props.text}
    </Typography>
  )
}
