import { useSelector } from 'react-redux'

import { CasePrescriptionForm } from 'app/components/routes/CaseWizard/CaseWizard.style'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { CancelCaseModal } from 'app/components/routes/CaseWizard/steps/CasePrescription/CancelCaseModal'
import { ClinicalPreferences } from 'app/components/routes/CaseWizard/steps/CasePrescription/ClinicalPreferences/ClinicalPreferences'
import { OfficeNoteModal } from 'app/components/routes/CaseWizard/steps/CasePrescription/OfficeNoteModal'
import { EmptyStep } from 'app/components/routes/CaseWizard/steps/Empty'
import { Box } from 'app/components/ui/SDS/common/Box'
import { ThemeContext, ThemeType } from 'app/components/ui/Theme/ThemeContext'
import { TreatmentType } from 'app/core/domain/PrimaryCasePreferences'
import styled from 'styled-components'

import { AlignerPrescriptionContent } from '../steps/CasePrescription/AlignerPrescriptionContent'
import { IdbPrescriptionContent } from '../steps/CasePrescription/IdbPrescriptionContent'
import { useDefaultPrescriptionValues } from '../steps/CasePrescription/useDefaultPrescriptionValues'

const HybridCasePrescription = () => {
  const isLoading = useSelector(caseWizardSelectors.getLoadingStatus())
  const treatmentType = useSelector(caseWizardSelectors.getTreatmentType())

  useDefaultPrescriptionValues()

  return (
    <>
      {isLoading && <EmptyStep />}
      <ThemeContext.Provider value={ThemeType.BOOTSTRAP}>
        <CasePrescriptionContainer isLoading={isLoading}>
          <CancelCaseModal />
          <OfficeNoteModal />
          <ClinicalPreferences />
          <CasePrescriptionForm>
            <Box display="block" padding="12px 40px">
              {treatmentType === TreatmentType.IDB && <IdbPrescriptionContent />}

              {treatmentType !== TreatmentType.IDB && <AlignerPrescriptionContent />}
            </Box>
          </CasePrescriptionForm>
        </CasePrescriptionContainer>
      </ThemeContext.Provider>
    </>
  )
}

const CasePrescriptionContainer = styled.div<{ isLoading: boolean }>`
  padding-top: 16px;

  display: ${(props) => (props.isLoading ? 'none' : 'block')};
`

export { HybridCasePrescription }
