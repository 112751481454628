import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface DeleteAddressModalState {
  isOpen: boolean
}

const INITIAL_STATE: DeleteAddressModalState = {
  isOpen: false,
}

const deleteAddressModalActions = {
  deleteAddressModalMounted: createAction('@DELETE_ADDRESS_MODAL/DELETE_ADDRESS_MODAL_MOUNTED')(),
  deleteAddressModalTriggered: createAction(
    '@DELETE_ADDRESS_MODAL/DELETE_ADDRESS_MODAL_TRIGGERED',
  )(),

  closeDeleteModalClicked: createAction('@DELETE_ADDRESS_MODAL/CLOSE_DELETE_MODAL_CLICKED')(),
}

type DeleteAddressModalActions = ActionType<typeof deleteAddressModalActions>

const deleteAddressModalReducer = createReducer<DeleteAddressModalState, DeleteAddressModalActions>(
  INITIAL_STATE,
)
  .handleAction(deleteAddressModalActions.deleteAddressModalTriggered, (state) => ({
    ...state,
    isOpen: true,
  }))
  .handleAction(
    [
      deleteAddressModalActions.closeDeleteModalClicked,
      deleteAddressModalActions.deleteAddressModalMounted,
    ],
    (state) => ({
      ...state,
      isOpen: false,
    }),
  )

const deleteAddressModalSelectors = {
  isOpen: () => (state: RootState) => state.deleteAddressModal.isOpen,
}

export {
  DeleteAddressModalState,
  DeleteAddressModalActions,
  deleteAddressModalActions,
  deleteAddressModalReducer,
  deleteAddressModalSelectors,
}
