import { Arch } from 'app/core/domain/ArchTools'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'

export const archTestKeyIds = {
  [Arch.LOWER]: 'Lower',
  [Arch.UPPER]: 'Upper',
}

export const archToTreatMap = {
  [Arch.LOWER]: TreatArches.LOWER_ONLY,
  [Arch.UPPER]: TreatArches.UPPER_ONLY,
}

export const archTreatmentToDisableCrowdingSettingsMap = {
  [Arch.LOWER]: TreatArches.UPPER_ONLY,
  [Arch.UPPER]: TreatArches.LOWER_ONLY,
}
