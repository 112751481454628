import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { ClickableTeeth } from 'app/components/routes/CaseWizard/form/Teeth/ClickableTeeth'
import { sortOnArch } from 'app/components/routes/CaseWizard/form/Teeth/sortOnArchHelper'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import {
  Arch,
  getConverterToTeethSystem,
  getArchTeethNumbers,
  Universal,
} from 'app/core/domain/ArchTools'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { TeethImageType } from 'app/core/domain/TeethImageType'
import { addOrRemoveFromArray } from 'app/core/lang/ArrayUtils'
import { appSelectors } from 'app/logic/app/logic'

import { HorizontalLine } from './HorizontalLine'

interface TeethExtractionSelectionProps {
  arch: Arch
  'data-testid': string
}

const PROPERTY_KEY = 'primaryCasePreferences.extractionTeeth'

const TeethExtractionSelection: React.FC<TeethExtractionSelectionProps> = (props) => {
  const form = useFormContext()
  const [disabled, setDisabled] = useState(false)
  const toothSystem = useSelector(appSelectors.getToothNumberingSystem())
  const archTeethNumbers = useMemo(() => getArchTeethNumbers(props.arch), [props.arch])
  const convert = getConverterToTeethSystem(toothSystem)

  const treatArches: TreatArches = useMemo(
    () => form.getValues('primaryCasePreferences.treatArches'),
    [form],
  )

  const extractionTeeth: number[] = useMemo(() => form.getValues(PROPERTY_KEY) ?? [], [form])

  useEffect(() => {
    const resetAllTeethInArch = () => {
      archTeethNumbers.forEach((teethNumber) => {
        if (extractionTeeth.indexOf(teethNumber) > -1) {
          extractionTeeth.splice(extractionTeeth.indexOf(teethNumber), 1)
        }
      })
    }
    let needToSetDisabled

    if (props.arch === Arch.LOWER) {
      needToSetDisabled = treatArches && treatArches === TreatArches.UPPER_ONLY
    } else if (props.arch === Arch.UPPER) {
      needToSetDisabled = treatArches && treatArches === TreatArches.LOWER_ONLY
    }
    setDisabled(!!needToSetDisabled)
    if (needToSetDisabled) {
      resetAllTeethInArch()
    }
  }, [treatArches, archTeethNumbers, extractionTeeth, props.arch])

  const getToothType = useCallback(
    (toothNumber: Universal) => {
      if (disabled) {
        return TeethImageType.DISABLED
      }
      if (extractionTeeth && extractionTeeth.includes(toothNumber)) {
        return TeethImageType.EXTRACTED
      }
      return TeethImageType.PLAIN
    },
    [extractionTeeth, disabled],
  )

  const teethClick = (value: number) => {
    form.setValue(PROPERTY_KEY, addOrRemoveFromArray(value, extractionTeeth))
  }

  return (
    <Box display="block" data-testid={`${props['data-testid']}-container`}>
      <Box data-testid={`${props['data-testid']}-heading`}>
        <Typography
          data-testid={`${props['data-testid']}-heading-text`}
          margin="14px 0 0"
          component="label"
          variant="small"
          letterSpacing="0.5px"
          textFontWeight="600"
          color="base900"
          isUppercase
        >
          <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction" />
        </Typography>
      </Box>
      <Box height="28px" margin="14px auto" width="258px">
        <ClickableTeeth
          arches={[props.arch]}
          onToothClick={(toothNumber: Universal) => !disabled && teethClick(toothNumber)}
          getToothType={(toothNumber: Universal) => getToothType(toothNumber)}
          data-testid={props['data-testid']}
        />
      </Box>

      <Box marginTop="14px" justify="space-between">
        <Typography data-testid={`${props['data-testid']}-right`} component="label">
          <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.right" />
        </Typography>
        <HorizontalLine />
        <Typography data-testid={`${props['data-testid']}-left`} component="label">
          <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.left" />
        </Typography>
      </Box>

      <Box>
        {extractionTeeth
          ?.filter((i) => archTeethNumbers.indexOf(i) > -1)
          .sort(sortOnArch(props.arch))
          .map((num, index, arr) => (
            <span key={`teethNumber--${num}`}>
              {convert && convert(num)}
              {index < arr.length - 1 && ','}
            </span>
          ))}
      </Box>
    </Box>
  )
}

export { TeethExtractionSelection }
