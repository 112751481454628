import React from 'react'
import { Link } from 'react-router-dom'

import { NavigationContent } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationContent/NavigationContent'
import {
  StyledNavigationBackgroundMixin,
  StyledNavigationMixin,
} from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/StyledNavigationMixin'
import styled from 'styled-components'

interface NavigationLinkProps {
  isActive?: boolean
  icon?: JSX.Element
  href?: string
  linkTextId: string
  to?: string
  onClick?: React.DOMAttributes<HTMLAnchorElement>['onClick']
  'data-testid': string
}

const NavigationLink = (props: NavigationLinkProps) => {
  const { to, icon, href, linkTextId, onClick } = props

  if (to) {
    return (
      <StyledRouterNavigationLink to={to} onClick={onClick} data-testid={props['data-testid']}>
        <StyledNavigationBackground isActive={to === window.location.pathname}>
          <NavigationContent data-testid={props['data-testid']} icon={icon} textId={linkTextId} />
        </StyledNavigationBackground>
      </StyledRouterNavigationLink>
    )
  }
  return (
    <StyledNavigationLink
      href={href}
      isActive={href === window.location.pathname}
      onClick={onClick}
      data-testid={props['data-testid']}
    >
      <NavigationContent data-testid={props['data-testid']} icon={icon} textId={linkTextId} />
    </StyledNavigationLink>
  )
}

const StyledNavigationLink = styled.a<{ isActive?: boolean }>`
  ${StyledNavigationMixin}
  ${StyledNavigationBackgroundMixin}
`
const StyledRouterNavigationLink = styled(Link)`
  ${StyledNavigationMixin}
`
const StyledNavigationBackground = styled.div`
  ${StyledNavigationBackgroundMixin}
`

export { NavigationLink }
