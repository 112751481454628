import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface BasicPageState {
  myTeamLinkClicked: boolean
}
const INITIAL_STATE = {
  myTeamLinkClicked: false,
}

const basicPageActions = {
  myTeamLinkClicked: createAction('@BASIC_PAGE/MY_TEAM_LINK_CLICKED')(),
}

type BasicPageActions = ActionType<typeof basicPageActions>
const basicPageReducer = createReducer<BasicPageState, BasicPageActions>(
  INITIAL_STATE,
).handleAction(basicPageActions.myTeamLinkClicked, (state) => ({
  ...state,
  myTeamLinkClicked: !state.myTeamLinkClicked,
}))
const basicPageSelectors = {
  isMyTeamLinkClicked: () => (state: RootState) => state.basicPage.myTeamLinkClicked,
}

export { BasicPageState, BasicPageActions, basicPageActions, basicPageSelectors, basicPageReducer }
