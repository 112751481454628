import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'antd'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { LabeledTooltip } from 'app/components/ui/Tooltip/LabeledTooltip'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { useDidMount } from 'app/core/react/CustomHooks'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

import { TruGenErrorModal } from './TruGenErrorModal'

const TruGenTooltip: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const language = useSelector(appSelectors.getLanguage())
  const webContent = useSelector(appSelectors.getWebContent())
  const truGenTerms = useSelector(caseWizardSelectors.getTruGenTerms())
  const hasError = truGenTerms?.status === ResponseStatus.BAD_REQUEST
  const webContentSelected = webContent?.[language] ?? webContent?.en ?? {}
  const truGenPdfURL =
    webContentSelected?.patient_primary_prescription__material__truegen_link ?? ''

  const regex = new RegExp(/^(http|https):\/\//g)
  const isUrl = regex.test(truGenPdfURL)
  const openLinkUrl = isUrl ? truGenPdfURL : truGenTerms?.payload
  const labelText = intl.formatMessage({
    id: 'caseWizard.casePrescription.productTier.trugen.title',
  })

  useDidMount(() => {
    if (!isUrl) {
      dispatch(caseWizardActions.truGenTermsRequested({ truGenPdfURL }))
    }

    return () => {
      dispatch(caseWizardActions.truGenTermsUnmounted())
    }
  })

  return (
    <>
      <LabeledTooltip
        data-testid="TruGenTooltip"
        text={labelText}
        title={
          <div>
            <Typography variant="small" color="white" margin="0 0 10px">
              {webContentSelected.patient_primary_prescription__material__truegen__popup}
            </Typography>

            <TooltipLinkBox>
              {hasError && !isUrl ? (
                <ButtonLink onClick={() => setModalOpen(true)} type="link">
                  <FormattedMessage id="truGen.learnMore" />
                </ButtonLink>
              ) : (
                <ButtonLink type="link" href={openLinkUrl} target="_blank">
                  <FormattedMessage id="truGen.learnMore" />
                </ButtonLink>
              )}
            </TooltipLinkBox>
          </div>
        }
        overlayInnerStyle={{
          textAlign: 'justify',
          padding: '11px',
        }}
      >
        <HelpIcon />
      </LabeledTooltip>

      <TruGenErrorModal isOpen={isModalOpen && hasError} onClose={() => setModalOpen(false)} />
    </>
  )
}

export { TruGenTooltip, TooltipLinkBox, ButtonLink }

const ButtonLink = styled(Button)`
  padding: 0;

  height: auto;

  font-size: 11px;
`

const TooltipLinkBox = styled.div`
  text-align: right;
`
