import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  CasePrescriptionForm,
  FormContainer,
} from 'app/components/routes/CaseWizard/CaseWizard.style'
import { CancelCaseModal } from 'app/components/routes/CaseWizard/steps/CasePrescription/CancelCaseModal'
import { OfficeNoteModal } from 'app/components/routes/CaseWizard/steps/CasePrescription/OfficeNoteModal'
import { DicomSection } from 'app/components/routes/CaseWizard/steps/EditScans/DicomSection'
import { Scans } from 'app/components/routes/CaseWizard/steps/EditScans/Scans'
import { editScansSelectors } from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { ThemeContext, ThemeType } from 'app/components/ui/Theme/ThemeContext'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { CaseStatus } from 'app/core/domain/CaseStatus'
import { HoldReason } from 'app/core/domain/HoldReason'
import { featureFlagSelectors } from 'app/logic/features/logic'

const HybridScans: React.FC = () => {
  const form = useForm<CaseDraft>({
    mode: 'onChange',
  })
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const selectedCaseStatus = useSelector(editScansSelectors.getCaseStatus())
  const caseOnHold = useSelector(editScansSelectors.getOnHoldStatus())
  const holdReason = useSelector(editScansSelectors.getHoldReason())
  const isStatusSaved = selectedCaseStatus === CaseStatus.SAVED
  const isStatusNew = selectedCaseStatus === CaseStatus.NEW
  const externalHold = holdReason === HoldReason.EXTERNAL
  const displayDicomSection =
    featurePermissions?.dicoms && (isStatusSaved || (isStatusNew && caseOnHold && externalHold))

  return (
    <ThemeContext.Provider value={ThemeType.BOOTSTRAP}>
      <Box display="block">
        <CancelCaseModal />
        <OfficeNoteModal />
        <CasePrescriptionForm>
          <Box flexDirection="column">
            <FormProvider {...form}>
              <FormContainer>
                <Scans />
              </FormContainer>
              {displayDicomSection && (
                <FormContainer>
                  <DicomSection />
                </FormContainer>
              )}
            </FormProvider>
          </Box>
        </CasePrescriptionForm>
      </Box>
    </ThemeContext.Provider>
  )
}

export { HybridScans }
