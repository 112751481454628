import AuthenticationManager from 'app/core/auth/AuthenticationManager'
import axios, { AxiosRequestConfig } from 'axios'
import URIJS from 'urijs'

const axiosAuthMiddleware = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const reqUrl = URIJS(config.url)
  const isSameDomainButOauthPath =
    reqUrl.path() === '/oauth/token' &&
    reqUrl.is('absolute') &&
    reqUrl.origin() === URIJS(window.location.href).origin()

  if (
    (reqUrl.is('absolute') && reqUrl.origin() !== URIJS(window.location.href).origin()) ||
    isSameDomainButOauthPath
  ) {
    return Promise.resolve(config)
  }

  return AuthenticationManager.updateToken()
    .then(() => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${AuthenticationManager.accessToken}`,
      },
    }))
    .catch((error) => {
      if (!axios.isAxiosError(error)) {
        return Promise.resolve(config)
      }

      if (error.response?.status === 401) {
        console.error(`Received 401 in response. Token expired or invalid. Redirecting to login `)
      } else {
        console.error(`Recieved ${error.response?.status} in response. Redirecting to login page`)
      }

      return Promise.resolve(AuthenticationManager.authenticate()) as never
    })
}

export { axiosAuthMiddleware }
