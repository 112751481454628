import { FormattedMessage } from 'react-intl'

import { Typography } from '../SDS/common/Typography'

interface TooltipTextProps {
  textId: string
  'data-testid': string
}

export const TooltipText = ({ textId, 'data-testid': dataTestid }: TooltipTextProps) => {
  return (
    <Typography data-testid={dataTestid} variant="small" color="white">
      <FormattedMessage id={textId} />
    </Typography>
  )
}
