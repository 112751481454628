import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'formdata-polyfill/formdata.min'
import 'scroll-behavior-polyfill'
import 'intl-pluralrules'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from 'app/App'
import 'app/core/net/axiosConfig'
import AuthenticationManager from 'app/core/auth/AuthenticationManager'
import { SystemDictionaries } from 'app/core/domain/Http/SystemDictionaries'
import { SiteMap } from 'app/core/react/SiteMap'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { store } from 'app/logic/store'
import axios from 'axios'

const initApp = async () => {
  const profile = await AuthenticationManager.authenticate()
  const dictionaries = await axios.get<SystemDictionaries>('/api/v1/systemInfo/dictionaries')

  if (!profile || !dictionaries.data) {
    throw new Error('Failed to init application')
  }

  store.dispatch(appActions.profileInitialized(profile))
  store.dispatch(appActions.systemInfoRequestFinished(dictionaries.data))
  const isDoctor = appSelectors.isDoctor()(store.getState())
  const isStaff = appSelectors.isStaff()(store.getState())

  if (!isDoctor && !isStaff) {
    // currently not implemented for any other role
    window.location.replace(SiteMap.legacyHomePage())
  } else {
    ReactDOM.render(<App />, document.getElementById('root'))
  }
}

initApp()
