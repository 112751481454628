import React from 'react'

import { NoteEdit } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/NoteEdit'
import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

import { FullNoteLink } from './FullNoteLink'

interface FullNoteProps {
  isCurrentDoctorNoteEditing: boolean
  notes?: string
  onClick: Function
  'data-testid'?: string
}

const FullNote: React.FC<FullNoteProps> = (props) => {
  const { isCurrentDoctorNoteEditing, notes } = props

  return (
    <FullNotesContainer
      padding="10px"
      boxSizing="border-box"
      minHeight="100%"
      onClick={props.onClick()}
      isCurrentDoctorNoteEditing={isCurrentDoctorNoteEditing}
    >
      {!isCurrentDoctorNoteEditing ? (
        <FullNoteLink notes={notes} data-testid={props['data-testid']} />
      ) : (
        <NoteEdit notes={notes} data-testid={props['data-testid']} />
      )}
    </FullNotesContainer>
  )
}

const FullNotesContainer = styled(Box)<{ isCurrentDoctorNoteEditing?: boolean }>`
  position: absolute;
  top: 0;
  z-index: ${({ isCurrentDoctorNoteEditing, theme }) =>
    isCurrentDoctorNoteEditing ? theme.zIndex.layer2 : theme.zIndex.layer1};

  border: 1px solid ${({ theme }) => theme.colors.black};

  white-space: pre-wrap;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`

export { FullNote }
