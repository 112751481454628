import React from 'react'
import { FormattedMessage } from 'react-intl'

import { LogoutLink } from 'app/components/routes/BasicPage/Header/NavigationBar/LogoutLink/LogoutLink'
import { NavigationDropdown } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationDropdown/NavigationDropdown'
import { NavigationLink } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationLink/NavigationLink'
import { PatientText } from 'app/components/ui/Icons/common'
import { SiteMap } from 'app/core/react/SiteMap'

import { DropdownItem } from '../NavigationItems/NavigationDropdown/DropdownItem'

interface AdminNavigationBarProps {
  'data-testid': string
}

const AdminNavigationBar: React.FC<AdminNavigationBarProps> = (props) => (
  <>
    <NavigationLink
      to={SiteMap.homePage()}
      icon={<PatientText />}
      data-testid={`${props['data-testid']}-patients`}
      linkTextId="admin.header.navigation.patients"
    />

    <NavigationLink
      href={SiteMap.profile()}
      data-testid={`${props['data-testid']}-profile`}
      linkTextId="admin.header.navigation.profile"
    />

    <NavigationLink
      href={SiteMap.doctors()}
      data-testid={`${props['data-testid']}-doctors`}
      linkTextId="admin.header.navigation.doctors"
    />

    <NavigationDropdown
      titleTextId="admin.header.navigation.reports"
      data-testid={`${props['data-testid']}-reports`}
    >
      <DropdownItem
        href={SiteMap.adminReport()}
        data-testid={`${props['data-testid']}-reports-detailReport`}
      >
        <FormattedMessage id="admin.header.navigation.reports.detailReport" />
      </DropdownItem>
      <DropdownItem
        href={SiteMap.qualityRatingReport()}
        data-testid={`${props['data-testid']}-reports-quality`}
      >
        <FormattedMessage id="admin.header.navigation.reports.quality" />
      </DropdownItem>
    </NavigationDropdown>

    <NavigationDropdown
      titleTextId="admin.header.navigation.system"
      data-testid={`${props['data-testid']}-system`}
    >
      <DropdownItem
        href={SiteMap.employees()}
        data-testid={`${props['data-testid']}-system-employees`}
      >
        <FormattedMessage id="admin.header.navigation.system.employees" />
      </DropdownItem>
      <DropdownItem
        href={SiteMap.settings()}
        data-testid={`${props['data-testid']}-system-settings`}
      >
        <FormattedMessage id="admin.header.navigation.system.settings" />
      </DropdownItem>
      <DropdownItem
        href={SiteMap.tShapeConfig()}
        data-testid={`${props['data-testid']}-system-shapeconfig`}
      >
        <FormattedMessage id="admin.header.navigation.system.shapeconfig" />
      </DropdownItem>
      <DropdownItem
        href={SiteMap.webContent()}
        data-testid={`${props['data-testid']}-system-webConfig`}
      >
        <FormattedMessage id="admin.header.navigation.system.webConfig" />
      </DropdownItem>
      <DropdownItem href={SiteMap.support()} data-testid={`${props['data-testid']}-system-support`}>
        <FormattedMessage id="admin.header.navigation.system.support" />
      </DropdownItem>
    </NavigationDropdown>

    <LogoutLink data-testid={`${props['data-testid']}-LogoutLink`} />
  </>
)

export { AdminNavigationBar }
