import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { appActions, appSelectors } from 'app/logic/app/logic'
import parse from 'html-react-parser'
import styled from 'styled-components'

interface MarketingMessageProps {
  'data-testid': string
}

const MarketingMessage: React.FC<MarketingMessageProps> = (props) => {
  let message
  let isVisible = false

  const marketingMessage = useSelector(appSelectors.getMarketingMessage())

  if (marketingMessage) {
    message = marketingMessage.message
    isVisible = marketingMessage.isVisible
  }
  const dispatch = useDispatch()
  const onHideNotificationClick = useCallback(() => {
    dispatch(appActions.marketingMessageRead())
  }, [dispatch])

  return isVisible ? (
    <MarketingMessageContainer
      marginLeft="20px"
      padding="8px 16px 4px 16px"
      width="370px"
      display="block"
      data-testid={props['data-testid']}
    >
      <MarketingMessageText data-testid={`${props['data-testid']}-Text`}>
        <Typography component="span" color="primary800" textFontWeight="600">
          <FormattedMessage id="marketingMessage.title" />
        </Typography>

        {message && <Typography component="span">{parse(message)}</Typography>}
      </MarketingMessageText>

      <HideNotificationButton
        type="text"
        onClick={onHideNotificationClick}
        data-testid={`${props['data-testid']}-HideNotificationButton`}
      >
        <FormattedMessage id="marketingMessage.hide" />
      </HideNotificationButton>
    </MarketingMessageContainer>
  ) : null
}

const HideNotificationButton = styled(Button)`
  display: none;

  text-decoration: underline;

  font-size: 13px;

  font-weight: 600;

  color: ${({ theme }) => theme.colors.primary800};
  float: right;

  &:hover {
    color: #40a9ff;
  }
`

const MarketingMessageText = styled.p`
  margin: 0;

  cursor: default;

  overflow: hidden;

  width: 100%;
  height: 20px;

  white-space: nowrap;
  text-overflow: ellipsis;

  line-height: 16px;

  a {
    cursor: pointer;

    text-decoration: underline;

    font-weight: 600;

    color: ${({ theme }) => theme.colors.primary800};
  }
`

const MarketingMessageContainer = styled(Box)`
  z-index: ${({ theme }) => theme.zIndex.notification};

  cursor: default;

  background: ${({ theme }) => theme.colors.primary10};
  border-radius: 2px;

  &:hover ${HideNotificationButton} {
    display: block;
  }

  &:hover ${MarketingMessageText} {
    height: 100%;

    white-space: normal;
  }
`

export { MarketingMessage }
