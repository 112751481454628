import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { Button } from 'app/components/ui/SDS/common/Button'
import styled from 'styled-components'

import { staffListActions, staffListSelectors } from './logic'

interface SearchInputProps {
  'data-testid': string
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const intl = useIntl()
  const valueIsValid = (value?: string) => value && value !== ' '
  const dispatch = useDispatch()
  const clearSearchCallback = useCallback(
    () => dispatch(staffListActions.clearSearchButtonClicked()),
    [dispatch],
  )
  const onSearchCallback = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (valueIsValid(e.currentTarget.value)) {
        dispatch(staffListActions.searchDoctorInputChanged(e.currentTarget.value))
      } else {
        dispatch(staffListActions.clearSearchButtonClicked())
      }
    },
    [dispatch],
  )
  const { search: inputText } = useSelector(staffListSelectors.getStaffListParams())

  return (
    <InputWithIcon data-testid={props['data-testid']}>
      <SearchInputContainer
        onChange={onSearchCallback}
        data-testid="SearchInput-Staff"
        type="text"
        placeholder={intl.formatMessage({ id: 'staff.teamManagement.searchPlaceholder' })}
        value={inputText || ''}
      />
      {inputText ? (
        <CancelBtn type="text" onClick={clearSearchCallback} data-testid="SearchInput-ClearBtn">
          <GlyphIcon type={IconType.REMOVE} />
        </CancelBtn>
      ) : (
        ''
      )}
    </InputWithIcon>
  )
}
const SearchInputContainer = styled.input`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;

  width: 321px;
  height: 35px;
  border: 1px solid ${({ theme }) => theme.colors.base800};

  border-radius: 4px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.base700};
  }
  :focus {
    outline: 0;

    border-color: ${({ theme }) => theme.colors.primary200};

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  ::-ms-clear {
    display: none;
  }
`

const InputWithIcon = styled.div`
  margin-left: auto;
  position: relative;
`

const CancelBtn = styled(Button)`
  position: absolute;
  right: 10px;

  font-size: 13px;
  font-weight: 100;

  color: ${({ theme }) => theme.colors.base700};
`

export { SearchInput }
