import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { Nullable } from 'app/core/types/utils'

export const LOWER_FIRST_MOLAR_TUBE_KEY = 'primaryCasePreferences.lowerFirstMolar'
export const LOWER_SECOND_MOLAR_TUBE_KEY = 'primaryCasePreferences.lowerSecondMolar'
export const UPPER_FIRST_MOLAR_TUBE_KEY = 'primaryCasePreferences.upperFirstMolar'
export const UPPER_SECOND_MOLAR_TUBE_KEY = 'primaryCasePreferences.upperSecondMolar'

interface ArchTubeKeys {
  firstMolarKey: string
  secondMolarKey: string
}

const upperArchTubeKeys: ArchTubeKeys = {
  firstMolarKey: UPPER_FIRST_MOLAR_TUBE_KEY,
  secondMolarKey: UPPER_SECOND_MOLAR_TUBE_KEY,
}

const lowerArchTubeKeys: ArchTubeKeys = {
  firstMolarKey: LOWER_FIRST_MOLAR_TUBE_KEY,
  secondMolarKey: LOWER_SECOND_MOLAR_TUBE_KEY,
}

export const getMolarTubeKeys = (arch: TreatArches) => {
  let result: Nullable<ArchTubeKeys> = null

  switch (arch) {
    case TreatArches.UPPER_ONLY: {
      result = upperArchTubeKeys
      break
    }
    case TreatArches.LOWER_ONLY: {
      result = lowerArchTubeKeys
      break
    }
  }

  return result
}

export const getUntreatedTubeKeys = (arch: TreatArches | undefined) => {
  switch (arch) {
    case TreatArches.UPPER_ONLY: {
      return getMolarTubeKeys(TreatArches.LOWER_ONLY)
    }
    case TreatArches.LOWER_ONLY: {
      return getMolarTubeKeys(TreatArches.UPPER_ONLY)
    }
    default: {
      return getMolarTubeKeys(arch ?? TreatArches.BOTH)
    }
  }
}
