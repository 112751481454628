import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { AlignerAuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AlignerAuxComponent'
import { AuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AuxComponent'
import { IdbAuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/IdbAuxComponent'
import { ClickableTeeth } from 'app/components/routes/CaseWizard/form/Teeth/ClickableTeeth'
import { ALIGNERS_TEETH_MATRIX } from 'app/components/routes/CaseWizard/form/Teeth/alignerTeethMatrix'
import { IDB_TEETH_MATRIX } from 'app/components/routes/CaseWizard/form/Teeth/idbTeethMatrix'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import {
  Arch,
  BITE_RAMPS_TEETH_DISABLED,
  getArchTeethNumbers,
  Universal,
} from 'app/core/domain/ArchTools'
import { TreatArches, TreatmentType } from 'app/core/domain/PrimaryCasePreferences'
import { TeethImageType } from 'app/core/domain/TeethImageType'
import { addOrRemoveFromArray } from 'app/core/lang/ArrayUtils'

import { ActivitySelection } from '../ActiveAuxiliary/ActivitySelection'

interface AuxClickableTeethProps {
  auxiliaries: AuxComponent<ActivitySelection>[]
  activeSelect: keyof typeof ActivitySelection
  'data-testid': string
}

const AuxClickableTeeth = (props: AuxClickableTeethProps) => {
  const { activeSelect, 'data-testid': testId } = props
  const arches = [Arch.UPPER, Arch.LOWER]
  const key = `primaryCasePreferences.${activeSelect}`
  const { setValue, watch } = useFormContext()
  const treatmentType = useSelector(caseWizardSelectors.getTreatmentType())

  const selectedTeeth: number[] = watch(key)
  const treatArches: string = watch('primaryCasePreferences.treatArches')

  const teethMatrix = treatmentType === TreatmentType.IDB ? IDB_TEETH_MATRIX : ALIGNERS_TEETH_MATRIX

  const getAuxComponents = useCallback(
    (toothNumber: Universal) => {
      const images: Pick<AuxComponent<ActivitySelection>, 'type' | 'Icon'>[] = []

      for (const array of props.auxiliaries) {
        if (array.arr?.includes(toothNumber)) {
          images.push({
            type: array.type,
            Icon: array.Icon,
          })
        }
      }

      return (
        <>
          {images.map(({ type, Icon }, index) => {
            return treatmentType === TreatmentType.IDB ? (
              <IdbAuxComponent key={index} type={type} Icon={Icon} />
            ) : (
              <AlignerAuxComponent
                key={index}
                auxIndex={index}
                auxCount={images.length}
                toothNumber={toothNumber}
                type={type}
                Icon={Icon}
              />
            )
          })}
        </>
      )
    },
    [props.auxiliaries, treatmentType],
  )

  const isToothDisabled = useCallback((toothNumber: Universal, selectedActivity, archToTreat) => {
    if (
      archToTreat === TreatArches.UPPER_ONLY &&
      getArchTeethNumbers(Arch.LOWER).includes(toothNumber)
    ) {
      return true
    }
    if (
      archToTreat === TreatArches.LOWER_ONLY &&
      getArchTeethNumbers(Arch.UPPER).includes(toothNumber)
    ) {
      return true
    }
    if (selectedActivity === ActivitySelection.biteRampTeeth) {
      return BITE_RAMPS_TEETH_DISABLED.includes(toothNumber)
    }
    return false
  }, [])

  const onToothClick = useCallback(
    (teethNumber) => {
      if (!isToothDisabled(teethNumber, activeSelect, treatArches)) {
        const res = addOrRemoveFromArray([teethNumber], selectedTeeth)

        setValue(key, [...res])
      }
    },
    [selectedTeeth, setValue, key, activeSelect, treatArches, isToothDisabled],
  )

  const getToothType = useCallback(
    (toothNumber) => {
      if (isToothDisabled(toothNumber, activeSelect, treatArches)) {
        return TeethImageType.DISABLED
      }
      return TeethImageType.PLAIN
    },
    [activeSelect, treatArches, isToothDisabled],
  )

  return (
    <ClickableTeeth
      onToothClick={onToothClick}
      arches={arches}
      teethMatrix={teethMatrix}
      getAuxComponent={getAuxComponents}
      getToothType={getToothType}
      data-testid={`${testId}-ClickableTeeth`}
    />
  )
}

export { AuxClickableTeeth }
