import React from 'react'
import { Link } from 'react-router-dom'

import { Typography } from 'app/components/ui/SDS/common/Typography'

import { HeaderNavigationLinkProps } from './interfaces'
import { StyledNavigationLink } from './styles'

export const HeaderNavigationLink = ({
  icon,
  href,
  labelText,
  isLocalRoute,
  isActive,
  isDark,
  'data-testid': dataTestId,
  onClick,
}: HeaderNavigationLinkProps) => {
  const content = (
    <>
      {icon}
      <Typography component="span">{labelText}</Typography>
    </>
  )

  if (isLocalRoute) {
    return (
      <StyledNavigationLink
        as={Link}
        to={href}
        onClick={onClick}
        data-testid={dataTestId}
        isActive={isActive}
        isDark={isDark}
      >
        {content}
      </StyledNavigationLink>
    )
  }

  return (
    <StyledNavigationLink
      href={href}
      onClick={onClick}
      data-testid={dataTestId}
      isActive={isActive}
      isDark={isDark}
    >
      {content}
    </StyledNavigationLink>
  )
}
