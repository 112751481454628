import React from 'react'
import { FormattedDate } from 'react-intl'
import { useSelector } from 'react-redux'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import { appSelectors } from 'app/logic/app/logic'
import { utcToZonedTime } from 'date-fns-tz'

const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
const DateCard: React.FC<{ date?: string }> = (props) => {
  const timeZoneIdentifier = useSelector(appSelectors.getTimeZoneIdentifier())
  const selectedTimeZone = timeZoneIdentifier ? timeZoneIdentifier : timeZone
  const zonedDate = props.date ? utcToZonedTime(new Date(props.date), selectedTimeZone) : false

  return (
    <Typography component="span" padding="10px">
      {zonedDate && <FormattedDate value={zonedDate} />}
    </Typography>
  )
}

export { DateCard }
