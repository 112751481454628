import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { useGetCasePerksQuery } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { ThemeColors } from 'styled-components'

import { CasePerksHint } from '../CasePerksHint'

export const ShippedCasePerks = () => {
  const { totalCases } = useGetCasePerksQuery(undefined, {
    selectFromResult: ({ data }) => ({ totalCases: data?.totalCases ?? 0 }),
  })
  const isSDS = useSelector(featureFlagSelectors.isSDS())
  const textColor: keyof ThemeColors = isSDS ? 'base600' : 'base900'

  return (
    <Box data-testid="ShippedCasePerks" height="36px" padding="0 36px">
      <Box>
        <Typography color={textColor} data-testid="ShippedCasePerks-Total" component="span">
          {totalCases > 0 && (
            <Typography color={textColor} component="span" textFontWeight="600">
              {totalCases}
            </Typography>
          )}
          &nbsp;
          <FormattedMessage id="perks.progress.total" values={{ count: totalCases }} />
        </Typography>
      </Box>
      <Box width="auto" marginLeft="auto">
        <CasePerksHint data-testid="ShippedCasePerks-Hint" />
      </Box>
    </Box>
  )
}
