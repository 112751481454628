import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { AssociatedStaff } from 'app/components/routes/Staff/DoctorDetails/AssociatedStaff'
import { staffListActions, staffListSelectors } from 'app/components/routes/Staff/StaffTable/logic'
import { FormCaption } from 'app/components/ui/Form/FormCaption'
import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Notification } from 'app/components/ui/notification/Notification'
import { notificationActions, notificationSelectors } from 'app/components/ui/notification/logic'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { NotificationType } from 'app/core/domain/NotificationType'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { ResultStatus } from 'app/core/domain/ResultStatus'
import { useDidMount } from 'app/core/react/CustomHooks'
import image from 'app/resources/img/pictures/doctors.png'
import styled from 'styled-components'

import { VerticalReadonlyValue } from '../VerticalReadonlyValue'

import { UpdateDoctor } from './UpdateDoctor'
import { doctorDetailsActions, doctorDetailsSelectors } from './logic'

const DoctorDetails: React.FC = () => {
  const form = useForm<PersonalInformation>({
    mode: 'onSubmit',
  })
  const dispatch = useDispatch()
  const location = useLocation()
  const { firstName, lastName, email, canManageCasesOf } = useSelector(
    doctorDetailsSelectors.getDoctorDetails(),
  )
  const doctorUsername = useSelector(staffListSelectors.getUsername())
  let { username }: { username?: string } = parseQuery<{ username?: string }>(location.search)

  username = doctorUsername !== undefined ? doctorUsername : username
  const activeStaff = useSelector(doctorDetailsSelectors.getActiveStaff())
  const isDoctorDetailsRequested = useSelector(doctorDetailsSelectors.isDoctorDetailsRequested())
  const isDoctorUpdateFlow = useSelector(doctorDetailsSelectors.isDoctorUpdateFlow())
  const lastDoctorUpdateResult = useSelector(doctorDetailsSelectors.getLastDoctorUpdateResult())
  const isNotificationVisible = useSelector(notificationSelectors.isOpen())
  const isLoading = useSelector(doctorDetailsSelectors.isDoctorDetailsLoading())
  const isUpdateButtonVisible = useSelector(doctorDetailsSelectors.isUpdateButtonVisible())

  useDidMount(() => {
    dispatch(doctorDetailsActions.doctorListMounted({ isLoadingEnabled: username !== undefined }))
    if (username !== undefined) {
      dispatch(staffListActions.userInformationClicked(username))
      dispatch(doctorDetailsActions.doctorUsernameRequested({ username }))
    }
    form.reset()
    return () => {
      dispatch(doctorDetailsActions.doctorDetailsUnmounted())
      dispatch(notificationActions.notificationUnMounted())
    }
  })

  useEffect(() => {
    const isDoctorDetailsRequestValid =
      !isDoctorDetailsRequested && activeStaff !== undefined && activeStaff.length > 0

    if (isDoctorDetailsRequestValid && username) {
      dispatch(doctorDetailsActions.doctorUsernameRequested({ username }))
    }

    if (isDoctorUpdateFlow) {
      if (!form.formState.isDirty) {
        form.reset(
          {
            firstName,
            username,
            email,
            canManageCasesOf,
          },
          { keepValues: true },
        )
      }
      const isFormInputChanged = Object.keys(form.formState.dirtyFields).length > 0

      if (isFormInputChanged && !isUpdateButtonVisible) {
        dispatch(doctorDetailsActions.doctorDetailsInputFieldValueChanged())
      }
    }

    const isDoctorUpdateSuccess =
      isDoctorUpdateFlow &&
      lastDoctorUpdateResult !== undefined &&
      lastDoctorUpdateResult.status === ResultStatus.OK

    if (isDoctorUpdateSuccess) {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'staff.doctorUpdate.success',
          type: NotificationType.info,
        }),
      )
      dispatch(doctorDetailsActions.doctorDetailsUnmounted())
    }

    const isDoctorUpdateFail =
      lastDoctorUpdateResult !== undefined &&
      lastDoctorUpdateResult.status === ResultStatus.BAD_REQUEST

    if (isDoctorUpdateFail) {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'staff.doctorUpdate.failure',
          type: NotificationType.error,
        }),
      )
      dispatch(doctorDetailsActions.doctorDetailsUnmounted())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDoctorDetailsRequested,
    activeStaff,
    isDoctorUpdateFlow,
    username,
    form.formState.isDirty,
    isUpdateButtonVisible,
    lastDoctorUpdateResult,
    ResultStatus,
  ])

  return (
    <DoctorDetailsContainer flexDirection="column" justify="flex-start">
      {isNotificationVisible && <Notification />}
      <FormProvider {...form}>
        <FormContainer margin="54px auto" padding="32px" justify="space-between" width="732px">
          {isLoading ? (
            <Loader />
          ) : (
            <DoctorDetailsForm name="doctorDetailsForm">
              <Box
                flexDirection="column"
                justify="space-around"
                align="flex-start"
                boxSizing="border-box"
                width="320px"
              >
                <FormCaption>
                  <FormattedMessage id="staff.doctorDetails" />
                </FormCaption>
                <VerticalReadonlyValue
                  labelTextId="staff.doctorDetails.doctorName.label"
                  value={`${firstName} ${lastName}`}
                />
                <VerticalReadonlyValue
                  labelTextId="staff.doctorDetails.doctorUsername.label"
                  value={username}
                />
                <VerticalReadonlyValue
                  labelTextId="staff.doctorDetails.doctorEmail"
                  value={email}
                />
                <AssociatedStaff />
              </Box>
            </DoctorDetailsForm>
          )}
          <Box margin="48px 32px 0 0" width="232px" height="232px">
            <img width="100%" height="auto" src={image} alt="Doctor Details logo" />
          </Box>
        </FormContainer>
        <UpdateDoctor />
      </FormProvider>
    </DoctorDetailsContainer>
  )
}

const DoctorDetailsForm = styled.form`
  word-break: break-word;
`

const FormContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 8px;
`

const DoctorDetailsContainer = styled(Box)`
  .ant-form-item-has-error .ant-input {
    border-color: ${({ theme }) => theme.colors.danger400};
  }

  .ant-tooltip {
    max-width: 300px;
  }

  .ant-alert-info .ant-alert-message {
    color: ${({ theme }) => theme.colors.primary800} !important;
  }

  .ant-alert-error .ant-alert-message {
    color: ${({ theme }) => theme.colors.danger400} !important;
  }
`

export { DoctorDetails }
