import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { caseListActions } from 'app/components/routes/CaseList/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { SdsPhoneFilled } from 'app/components/ui/Icons/sds'
import { Tooltip } from 'app/components/ui/SDS/common/Tooltip'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case } from 'app/core/domain/Case'

interface ContactSupportButtonProps {
  caseId?: Case['caseId']
  withoutText?: boolean
  showTooltip?: boolean
}

const ContactSupportButton: React.FC<ContactSupportButtonProps> = ({
  caseId,
  withoutText,
  showTooltip,
}) => {
  const dispatch = useDispatch()
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(caseListActions.supportButtonClicked())
  }

  const button = (
    <ActionButton
      data-testid={`ContactSupportButton-${caseId}`}
      onClick={handleClick}
      height="18px"
    >
      <SdsPhoneFilled width="10px" color="secondary300" />
      {!withoutText && (
        <Typography textOverflow="ellipsis" color="base700">
          <FormattedMessage id="cases.list.doctor.button.support" />
        </Typography>
      )}
    </ActionButton>
  )

  return showTooltip ? (
    <Tooltip placement="top" title={<FormattedMessage id="cases.list.doctor.button.support" />}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}

export { ContactSupportButton }
