interface CancelCase {
  description: string
  cancellationType: CancellationType
}

enum CancellationType {
  'LEAD_TIME' = 'LEAD_TIME',
  'CHANGED_MIND' = 'CHANGED_MIND',
  'OTHER' = 'OTHER',
}

export { CancelCase, CancellationType }
