import React from 'react'

import { ActionButton } from 'app/components/ui/ActionButton'
import { SdsRightCircleFilled } from 'app/components/ui/Icons/sds'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case } from 'app/core/domain/Case'

const SubmitCaseButton: React.FC<{ caseData: Case }> = ({ caseData: { caseId }, children }) => (
  <ActionButton data-testid={`SubmitCaseButton-${caseId}`} height="18px">
    <SdsRightCircleFilled width="12px" color="warning400" />
    <Typography textOverflow="ellipsis" color="base700">
      {children}
    </Typography>
  </ActionButton>
)

export { SubmitCaseButton }
