import { FC } from 'react'

import { Select, SelectProps } from 'antd'
import styled from 'styled-components'

interface SelectLegacyProps<T> extends Omit<SelectProps<T>, 'popupClassName'> {}

export const SelectLegacy = <T,>(props: SelectLegacyProps<T>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SelectContainer<FC<SelectProps<T>>> {...props} popupClassName="select-legacy-dropdown">
    {props.children}
  </SelectContainer>
)

const SelectContainer = styled(Select)`
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 4px;

  &:not(.ant-select-customize-input) > .ant-select-selector {
    padding: 0 8px;

    height: 30px;
    border: none !important;

    box-shadow: none !important;
  }

  .ant-select-selection-item {
    font-size: 13px;
  }

  &.ant-select-open .ant-select-selection-item {
    color: ${({ theme }) => theme.colors.base900};
  }
`
