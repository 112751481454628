import { Checkbox } from 'antd'
import styled, { css } from 'styled-components'

interface OrmcoCheckboxProps {
  variant?: 'white' | 'blue'
}

const ormcoCheckboxMixin = css<OrmcoCheckboxProps>`
  &:hover {
    .ant-checkbox-inner {
      border-color: ${({ theme, variant }) =>
        theme.colors[variant === 'white' ? 'white' : 'primary700']};

      background-color: ${({ theme }) => theme.colors.white};
    }

    .ant-checkbox-checked::after {
      border-color: ${({ theme, variant }) =>
        theme.colors[variant === 'white' ? 'white' : 'primary700']};
    }
  }

  .ant-checkbox-checked::after {
    border-color: ${({ theme, variant }) =>
      theme.colors[variant === 'white' ? 'white' : 'primary700']};
  }

  .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    border-color: ${({ theme, variant }) =>
      theme.colors[variant === 'white' ? 'white' : 'primary700']};

    background-color: ${({ theme, variant }) =>
      theme.colors[variant === 'white' ? 'white' : 'primary700']};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme, variant }) =>
      theme.colors[variant === 'white' ? 'white' : 'primary700']};
  }
`

const OrmcoCheckbox = styled(Checkbox)<OrmcoCheckboxProps>`
  white-space: normal;

  ${ormcoCheckboxMixin};
`

export { OrmcoCheckbox }
