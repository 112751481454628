import {
  DirectionPrimary,
  MidlinePreferencesPrimary,
} from 'app/core/domain/MidlinePreferencesPrimary'

import { ValidationError } from '../interfaces'

export const getV6MidlineSelectionErrors = (
  midlinePrimaryPreferences: MidlinePreferencesPrimary | undefined,
): Array<ValidationError> => {
  if (!midlinePrimaryPreferences) {
    return []
  }

  if (
    midlinePrimaryPreferences.directionPrimary ===
      DirectionPrimary.MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE &&
    !midlinePrimaryPreferences.goodQualityPhotoProvided
  ) {
    return [
      {
        field: 'primaryCasePreferences.midlinePreferencesPrimary.goodQualityPhotoProvided',
        message: 'caseWizard.casePrescription.midline.goodQualityPhotosCheckbox.validationMessage',
      },
    ]
  }

  return []
}
