import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { PrimaryButton } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { FILE_STATUS_MAX_PERCENT } from 'app/core/constants/global'
import { each } from 'lodash'
import styled from 'styled-components'

import { editScansActions, editScansSelectors } from './logic'

interface STLUploadModalProps {
  isOpen: boolean
  onClose: () => void
}

const STLUploadModal: React.FC<STLUploadModalProps> = (props) => {
  const { isOpen, onClose } = props
  const dispatch = useDispatch()
  const filesUploading = useSelector(editScansSelectors.getFilesUploading())
  const filesUploadProgressList = useSelector(editScansSelectors.getFilesUploadProgressList())
  const selectedScanner = useSelector(editScansSelectors.getSelectedScanner())
  const fileUploadedScanner = useSelector(editScansSelectors.getFileUploadedScanner())
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [skippedFiles, setSkippedFiles] = useState<string[]>([])
  const isSubmitButtonDisabled = selectedFiles.length === 0 || filesUploading

  const upload = () => {
    if (selectedScanner?.name !== fileUploadedScanner?.name) {
      dispatch(
        editScansActions.scanDeleteRequested({
          hideButtonsOnEnd: true,
          updateTimestamp: false,
        }),
      )
    }

    selectedFiles.forEach((file, index) => {
      dispatch(
        editScansActions.uploadSTLFileRequested({
          file,
          fileVersion: `v${index + 1}${new Date().getTime()}`,
          onProgress(progressEvent: ProgressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * FILE_STATUS_MAX_PERCENT) / progressEvent.total,
            )

            dispatch(
              editScansActions.uploadFileStatus({
                percentCompleted,
                fileIndex: index,
              }),
            )
          },
          onUploadEnd() {
            onClose()
            setSelectedFiles([])
            setSkippedFiles([])
            dispatch(editScansActions.uploadAllSTLFilesComplete())
          },
        }),
      )
      if (file.size === 0) {
        dispatch(
          editScansActions.uploadFileStatus({
            percentCompleted: 100,
            fileIndex: index,
          }),
        )
      }
    })
  }

  const fileChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(/\.stl$/i)
    const skippedFilesLocal: string[] = []
    const selectedFilesLocal: File[] = []

    if (!event.target.files) {
      return
    }

    each(event.target.files, (file) => {
      if (regex.test(file.name)) {
        selectedFilesLocal.push(file)
      } else {
        skippedFilesLocal.push(file.name)
      }
    })

    setSkippedFiles(skippedFilesLocal)
    setSelectedFiles(selectedFilesLocal)
  }, [])

  return (
    <OrmcoModal
      isOpen={isOpen}
      onRequestClose={onClose}
      additionalStyles={{
        content: {
          top: '10%',
          padding: '0px',
          width: '700px',
        },
        overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
      }}
      data-testid="UploadModal"
    >
      <OrmcoModalHeader flexDirection="row">
        <OrmcoModalHeaderText variant="h5" headerId="upload.popup.header" />
        <HeaderClose onClick={onClose} data-testid="UploadModal-Close">
          <Cancel />
        </HeaderClose>
      </OrmcoModalHeader>
      <UploadModalBody>
        <UploadPopupContentStrong>
          <Typography>
            <FormattedMessage id="upload.popup.label" />
          </Typography>
        </UploadPopupContentStrong>
        <UploadPopupContentStrong>
          <input type="file" onChange={fileChangeHandler} accept=".stl" multiple />
        </UploadPopupContentStrong>
        <UploadPopupContentStrong>
          {selectedFiles.length > 0 && (
            <SelectedFilesBox>
              <Typography>
                <FormattedMessage id="upload.popup.selectedFiles" />
              </Typography>

              <SelectedFilesList>
                {selectedFiles.map((file, index) => (
                  <li key={index}>
                    {file !== undefined && (
                      <Typography component="span" variant="medium">
                        {file.name}
                      </Typography>
                    )}
                  </li>
                ))}
              </SelectedFilesList>
            </SelectedFilesBox>
          )}
        </UploadPopupContentStrong>
        {skippedFiles.length > 0 && (
          <WarningModal>
            <Typography textFontWeight="600">
              <FormattedMessage id="upload.popup.warningMsg" />
            </Typography>
            <Typography>
              <FormattedMessage id="upload.popup.stlFormatMsg" />
            </Typography>
            <SkippedFiles>
              {skippedFiles.map((file, index) => (
                <li key={index}>
                  <Typography component="span">{file}</Typography>
                </li>
              ))}
            </SkippedFiles>
          </WarningModal>
        )}

        {filesUploadProgressList.length > 0 && (
          <UploadModalProgressBox>
            {filesUploadProgressList.map((progress, index) => (
              <FileProgressDiv key={index}>
                <CurrentUploadProgress currentWidth={`${progress}%`} />
              </FileProgressDiv>
            ))}
          </UploadModalProgressBox>
        )}

        <UploadModalFooter>
          <ModalFooter>
            <PrimaryButton
              as="button"
              onClick={upload}
              data-testid="Submit-button"
              disabled={isSubmitButtonDisabled}
            >
              <FormattedMessage id="upload.popup.submit" />
            </PrimaryButton>
          </ModalFooter>
        </UploadModalFooter>
      </UploadModalBody>
    </OrmcoModal>
  )
}

export { STLUploadModal }

const CurrentUploadProgress = styled.div<{ currentWidth: string }>`
  width: ${({ currentWidth }) => currentWidth};
  height: 4px;

  background-color: ${({ theme }) => theme.colors.primary600};
`

const FileProgressDiv = styled.div`
  margin-top: 5%;
  margin-right: auto;
  margin-left: auto;

  overflow: hidden;

  width: 75%;
  height: 5px;
  border: 1px solid ${({ theme }) => theme.colors.black};

  background-color: ${({ theme }) => theme.colors.base300};
`

const SkippedFiles = styled(OrmcoModalHeader)`
  margin-top: 10px;
  padding: 10px;

  display: flex;
  align-items: flex-start;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`

const WarningModal = styled(OrmcoModalHeader)`
  padding: 20px;

  display: flex;
  align-items: flex-start;

  border-color: ${({ theme }) => theme.colors.danger100};

  background-color: ${({ theme }) => theme.colors.danger10};
  border-radius: 8px;
`

const ModalFooter = styled(OrmcoModalHeader)`
  justify-content: flex-end;

  border-top: 0;
  border-bottom: none;
`

const UploadModalBody = styled(OrmcoModalHeader)`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-bottom: none;
`

const UploadPopupContentStrong = styled(OrmcoModalHeader)`
  padding-bottom: 0;
  padding-left: 25px;

  align-self: start;

  border-bottom: none;
`

const SelectedFilesBox = styled.div`
  padding-left: 24px;
`

const SelectedFilesList = styled.ul`
  margin: 0;
  padding-top: 4px;
  padding-left: 20px;
`

const UploadModalFooter = styled(OrmcoModalHeader)`
  padding-top: 1em;
  padding-bottom: 2em;
  padding-left: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: start;

  border-bottom: none;

  font-size: 13px;
`

const HeaderClose = styled.div`
  cursor: pointer;

  width: 16px;
  height: 16px;

  border-bottom: none;
`

const UploadModalProgressBox = styled.div`
  overflow: auto;

  width: 100%;

  max-height: 240px;
`
