import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { AddAddressButtons } from 'app/components/routes/Address/AddAddressButtons'
import { FormCaption } from 'app/components/ui/Form/FormCaption'
import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Notification } from 'app/components/ui/notification/Notification'
import { notificationActions, notificationSelectors } from 'app/components/ui/notification/logic'
import { Address } from 'app/core/domain/Address'
import { NotificationType } from 'app/core/domain/NotificationType'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { useDidMount } from 'app/core/react/CustomHooks'
import { appActions, appSelectors } from 'app/logic/app/logic'
import image from 'app/resources/img/pictures/addAddress.png'
import styled from 'styled-components'

import { AddAddressFormFields } from './AddAddressFormFields'
import { AddressDefaultsUpdate } from './AddressDefaultsUpdate'
import { DeleteAddressModal } from './DeleteAddressModal/DeleteAddressModal'
import { addressActions, addressSelectors } from './logic'

export interface IAddressRouteParams {
  id: string
  username: string
}

const AddAddress = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id, username } = useParams<IAddressRouteParams>()
  const form = useForm<Address>({
    mode: 'onChange',
  })
  const profileDataReceived = useSelector(appSelectors.isProfileDataReceived())
  const lastAddressAddResult = useSelector(addressSelectors.getLastAddressAddResult())
  const lastAddressDeleteResult = useSelector(addressSelectors.getLastAddressDeleteResult())
  const practiceCountry = useSelector(appSelectors.getPracticeCountry())
  const isNotificationOpen = useSelector(notificationSelectors.isOpen())
  const isLoading = useSelector(addressSelectors.getLoadingStatus())
  const addressCaptionId = id !== undefined ? 'address' : 'add.address'
  const formCaptionId = id !== undefined ? <AddressDefaultsUpdate /> : <AddAddressFormFields />

  useDidMount(() => {
    if (!profileDataReceived && !id) {
      dispatch(appActions.myProfileMounted())
    }

    if (id !== undefined) {
      dispatch(addressActions.getAddressRequested({ id, username }))
    }

    return () => {
      dispatch(addressActions.addressUnmounted())
    }
  })

  useDidMount(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    if (practiceCountry) {
      form.register('country')
      form.setValue('country', practiceCountry)
    }

    return () => {
      if (practiceCountry) {
        form.unregister('country')
      }
    }
  }, [form, practiceCountry])

  useEffect(() => {
    const anySuccessfulAddressAction =
      lastAddressDeleteResult?.status === ResponseStatus.OK ||
      lastAddressAddResult?.status === ResponseStatus.OK

    if (anySuccessfulAddressAction) {
      dispatch(appActions.employeeDetailsUpdated())
      history.push('/myProfile?scrollTo=address')
    }

    if (!lastAddressDeleteResult) return undefined

    dispatch(
      notificationActions.notificationTriggered({
        messageId: lastAddressDeleteResult.message,
        type: NotificationType[
          lastAddressDeleteResult.status === ResponseStatus.OK ? 'info' : 'error'
        ],
      }),
    )
  }, [dispatch, history, lastAddressDeleteResult, lastAddressAddResult])

  return (
    <AddressPageContainer data-testid="AddAddress" flexDirection="column" boxSizing="border-box">
      {isNotificationOpen && <Notification data-testid="AddAddress-Notification" />}
      {isLoading ? (
        <Loader />
      ) : (
        <FormProvider {...form}>
          <DeleteAddressModal />
          <FormContainer margin="54px auto" padding="32px" justify="space-around" width="732px">
            <Box padding="0 40px" display="block" width="auto">
              <Box
                as="form"
                name="addAddressForm"
                data-testid="AddAddress-Form"
                flexDirection="column"
                align="flex-start"
                justify="space-around"
                boxSizing="border-box"
                width="320px"
              >
                <FormCaption data-testid="AddAddress-Caption">
                  <FormattedMessage id={addressCaptionId} />
                </FormCaption>
                {formCaptionId}
              </Box>
            </Box>
            <img
              src={image}
              width="auto"
              height="276px"
              alt="Add Address logo"
              data-testid="AddAddress-Logo"
            />
          </FormContainer>
          <AddAddressButtons size={Object.keys(form.formState.dirtyFields).length} id={id} />
        </FormProvider>
      )}
    </AddressPageContainer>
  )
}

const AddressPageContainer = styled(Box)`
  .ant-form-item-has-error .ant-input {
    border-color: ${({ theme }) => theme.colors.danger400};
  }

  .ant-tooltip {
    max-width: 300px;
  }

  .ant-alert-info .ant-alert-message {
    color: ${({ theme }) => theme.colors.primary800} !important;
  }

  .ant-alert-error .ant-alert-message {
    color: ${({ theme }) => theme.colors.danger400} !important;
  }
`

const FormContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 8px;
`

export { AddAddress }
