import { Controller } from 'react-hook-form'

import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { InputLabel } from 'app/components/ui/Input/InputLabel'

const Country = () => {
  return (
    <StaffFormItemVertical
      colon={false}
      label={<InputLabel labelId="addresses.addressForm.country" />}
      hasFeedback
      data-testid="AddAddress-Country"
    >
      <Controller
        render={({ field }) => (
          <StaffInput
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            readOnly
          />
        )}
        name="country"
      />
    </StaffFormItemVertical>
  )
}

export { Country }
