import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { StaffCreationButtons } from 'app/components/routes/Staff/StaffCreationButtons'
import { StaffDetails } from 'app/components/routes/Staff/StaffDetails/StaffDetails'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Notification } from 'app/components/ui/notification/Notification'
import { notificationActions, notificationSelectors } from 'app/components/ui/notification/logic'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { NotificationType } from 'app/core/domain/NotificationType'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { ResultStatus } from 'app/core/domain/ResultStatus'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { NoUsersAssociatedModal } from './NoUsersAssociatedModal/NoUsersAssociatedModal'
import { StaffDeleteModal } from './StaffDeleteModal/StaffDeleteModal'
import { staffCreationActions, staffCreationSelectors } from './StaffDetails/logic'
import { staffListActions, staffListSelectors } from './StaffTable/logic'

const AddStaffPage: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { firstName, middleName, lastName, email, canManageCasesOf, username } = useSelector(
    staffCreationSelectors.getFormPersonalInfo(),
  )
  const isOpen = useSelector(notificationSelectors.isOpen())

  const activeUsers = useSelector(staffCreationSelectors.getActiveUsers())
  const isStaffDetailsRequested = useSelector(staffCreationSelectors.isStaffDetailsRequested())
  const isStaffUpdateFlow = useSelector(staffCreationSelectors.isStaffUpdateFlow())
  const isStaffUpdateButtonVisible = useSelector(
    staffCreationSelectors.isStaffUpdateButtonVisible(),
  )
  const lastStaffUpdateResult = useSelector(staffCreationSelectors.getLastStaffUpdateResult())
  const userName = useSelector(staffListSelectors.getUsername())
  let { staffUsername }: { staffUsername?: string } = parseQuery<{ staffUsername?: string }>(
    location.search,
  )

  staffUsername = userName !== undefined ? userName : staffUsername
  const form = useForm<PersonalInformation>({
    mode: 'onChange',
  })

  useDidMount(() => {
    if (isStaffUpdateFlow) {
      form.register('firstName')
      form.register('middleName')
      form.register('lastName')
      form.register('username')
      form.register('email')
      form.register('canManageCasesOf')
    }
    dispatch(
      staffCreationActions.addStaffMounted({ isLoadingEnabled: staffUsername !== undefined }),
    )
    if (staffUsername !== undefined) {
      dispatch(staffListActions.userInformationClicked(staffUsername))
    }
    form.reset()
    return () => {
      if (isStaffUpdateFlow) {
        form.unregister('firstName')
        form.unregister('middleName')
        form.unregister('lastName')
        form.unregister('username')
        form.unregister('email')
        form.unregister('canManageCasesOf')
      }
      dispatch(staffCreationActions.addStaffUnMounted())
      dispatch(notificationActions.notificationUnMounted())
    }
  })
  useEffect(() => {
    const isStaffDetailsRequestValid =
      !isStaffDetailsRequested && activeUsers !== undefined && activeUsers.length > 0

    if (isStaffDetailsRequestValid && staffUsername) {
      dispatch(staffCreationActions.staffDetailsRequested({ staffUsername }))
    }

    if (isStaffUpdateFlow) {
      if (!form.formState.isDirty) {
        form.setValue('firstName', firstName)
        form.setValue('middleName', middleName)
        form.setValue('lastName', lastName)
        form.setValue('username', username)
        form.setValue('email', email)
        form.setValue('canManageCasesOf', canManageCasesOf)
      }

      const isFormInputChanged = Object.keys(form.formState.dirtyFields).length > 0

      if (isFormInputChanged && !isStaffUpdateButtonVisible) {
        dispatch(staffCreationActions.staffInputFieldValueChanged())
      }
    }

    const isStaffUpdateFailed =
      lastStaffUpdateResult !== undefined &&
      lastStaffUpdateResult.status === ResultStatus.BAD_REQUEST

    if (isStaffUpdateFailed) {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'staff.creation.failure',
          type: NotificationType.error,
        }),
      )
      dispatch(staffCreationActions.addStaffUnMounted())
    }
  })

  return (
    <AddStaffPageContainer display="block" data-testid="AddStaff">
      {isOpen && <Notification />}
      <FormProvider {...form}>
        <NoUsersAssociatedModal />
        <StaffDeleteModal />
        <FormContainer margin="54px auto" padding="32px" justify="space-between" width="732px">
          <StaffDetails />
        </FormContainer>
        <StaffCreationButtons />
      </FormProvider>
    </AddStaffPageContainer>
  )
}

export { AddStaffPage }

const AddStaffPageContainer = styled(Box)`
  &&& {
    .ant-form-item-has-error .ant-input {
      border-color: ${({ theme }) => theme.colors.danger400};
    }

    .ant-tooltip {
      max-width: 300px;
    }

    .ant-alert-info .ant-alert-message {
      color: ${({ theme }) => theme.colors.primary800} !important;
    }

    .ant-alert-error .ant-alert-message {
      color: ${({ theme }) => theme.colors.danger400} !important;
    }
  }
`

const FormContainer = styled(Box)`
  width: 737px;
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 8px;
`
