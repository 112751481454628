import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { SdsCloseOutlined } from 'app/components/ui/Icons/sds'
import { BrandindModalHeader } from 'app/components/ui/Modal/Branding/BrandindModalHeader'
import { BrandindModalBody } from 'app/components/ui/Modal/Branding/BrandingModalBody'
import { BrandingModalFooter } from 'app/components/ui/Modal/Branding/BrandingModalFooter'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { AlignerMaterial } from 'app/core/domain/AlignerMaterial'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled, { css } from 'styled-components'

import { truGenXRModalActions, truGenXRModalSelectors } from './logic'

interface TruGenXRSelectModalProps {
  isOpen: boolean
}

const TruGenXRSelectModal: React.FC<TruGenXRSelectModalProps> = (props) => {
  const dispatch = useDispatch()

  const [doNotShowAgain, setDoNotShowAgain] = useState(false)
  const handleToggleDoNotShowAgain = () => setDoNotShowAgain((state) => !state)

  const alignerMaterialKey = 'primaryCasePreferences.alignerMaterial'
  const trugenXRGifUrl = useSelector(truGenXRModalSelectors.getPrimaryTruGenXRGif())
  const { primaryCasePreferences } = useSelector(caseWizardSelectors.getDraftData())
  const { alignerMaterial } = primaryCasePreferences

  const { isOpen } = props

  useDidMount(() => {
    dispatch(truGenXRModalActions.primaryTruGenXRGifRequested())

    return () => {
      dispatch(truGenXRModalActions.truGenModalUnmounted())
    }
  })

  const close = useCallback(() => {
    if (doNotShowAgain) {
      dispatch(truGenXRModalActions.trugenPopupsDontShowUpdateRequested(false))
    }
    dispatch(truGenXRModalActions.toggleTruGenModal(false))
  }, [doNotShowAgain, dispatch])

  return (
    <OrmcoModal
      isOpen={isOpen}
      onRequestClose={close}
      additionalStyles={{
        content: {
          top: '10%',
          padding: '0px',
          width: 'auto',
          minWidth: '700px',
          maxWidth: '900px',
        },
        overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
      }}
      shouldCloseOnOverlayClick={false}
      data-testid="TruGenXRSelectModal-OrmcoModal"
    >
      <BrandindModalHeader>
        <OrmcoModalHeaderText
          variant="h5"
          color="white"
          whiteSpace="nowrap"
          headerId="trugenXR.popup.header"
        />
        <Box
          backgroundColor="success100"
          padding="3px 8px"
          width="auto"
          marginLeft="8px"
          borderRadius="2px"
        >
          <Typography
            data-testid="header-new-label"
            component="span"
            textFontWeight="400"
            letterSpacing="0.25px"
            color="success500"
            whiteSpace="nowrap"
            padding="2px"
            variant="small"
          >
            NEW
          </Typography>
        </Box>
        <ButtonClose type="text" onClick={close} data-testid="TruGenXRSelectModal-HeaderClose">
          <SdsCloseOutlined width="16px" color="white" />
        </ButtonClose>
      </BrandindModalHeader>
      <BrandindModalBody>
        <img alt="turgenXR" src={trugenXRGifUrl ?? ''} height="200" width="470" />
        <Box flexDirection="column" align="flex-start" margin="16px 0">
          <Typography variant="medium" color="base900" textFontWeight="600">
            <FormattedMessage id="trugenXR.popup.content1" />
          </Typography>
          <Typography variant="medium" color="base900">
            <FormattedMessage id="trugenXR.popup.content2" />
          </Typography>
        </Box>
        <BrandingModalFooter>
          <WideOrmcoCheckbox
            onChange={handleToggleDoNotShowAgain}
            checked={doNotShowAgain}
            data-testid="TruGenXRSelectModal-WideOrmcoCheckbox"
          >
            <Typography component="span">
              <FormattedMessage id="trugenXR.popup.doNotShowAgain" />
            </Typography>
          </WideOrmcoCheckbox>
          {alignerMaterial === AlignerMaterial.TruGEN_XR ? (
            <LightBlueButtonWithBorderChangedBg
              as="button"
              onClick={close}
              data-testid="TruGenXRSelectModal-TruGenXrClose"
            >
              <FormattedMessage id="trugenXR.popup.close" />
            </LightBlueButtonWithBorderChangedBg>
          ) : (
            <LightBlueButtonWithBorderChangedBg
              as="button"
              onClick={() => {
                dispatch(
                  caseWizardActions.caseDraftPartUpdated({
                    part: AlignerMaterial.TruGEN_XR,
                    key: alignerMaterialKey,
                  }),
                )
                close()
              }}
              data-testid="TruGenXRSelectModal-TruGenXrButton"
            >
              <FormattedMessage id="trugenXR.popup.tryTrugenxr" />
            </LightBlueButtonWithBorderChangedBg>
          )}
        </BrandingModalFooter>
      </BrandindModalBody>
    </OrmcoModal>
  )
}

export { TruGenXRSelectModal }

const BtnWhiteColorStyle = css`
  color: ${({ theme }) => theme.colors.white};
  svg {
    fill: currentColor;
  }
`

const ButtonClose = styled(Button)`
  margin-left: 11px;

  width: 20px;
  ${BtnWhiteColorStyle}

  &:hover, &:focus {
    ${BtnWhiteColorStyle}
  }
`

const WideOrmcoCheckbox = styled(OrmcoCheckbox)`
  width: max-content;
`

const LightBlueButtonWithBorderChangedBg = styled(LightBlueButtonWithBorder)`
  background-color: ${({ theme }) => theme.colors.primary100} !important;
`
