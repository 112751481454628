import {
  doctorNoteActions,
  doctorNotesSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/logic'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const makeDoctorNoteRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([doctorNoteActions.submitButtonClicked])),
    switchMap((action) => {
      const notes = action.payload.text

      const caseId = doctorNotesSelectors.getEditingCaseId()(state$.value)
      const id = caseId ?? caseWizardSelectors.getDraftId()(state$.value)

      return from(axios.put(`/api/v1/cases/${id}/notes`, { caseNote: notes })).pipe(
        switchMap(() =>
          of(
            doctorNoteActions.submitRequestComplete({ caseId, notes }),
            caseWizardActions.officeNotesButtonClicked(false),
          ),
        ),
        catchError((err) => of(doctorNoteActions.submitRequestFailed({ message: err }))),
      )
    }),
  )

const doctorNoteActionsEpic = combineEpics(makeDoctorNoteRequestEpic)

export { doctorNoteActionsEpic }
