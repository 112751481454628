import { Doctor } from 'app/core/domain/Doctor'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { LastStaffUpdateResult, ResultStatus } from 'app/core/domain/ResultStatus'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface StaffCreationState {
  staffDetails: PersonalInformation
  lastStaffUpdateResult?: LastStaffUpdateResult
  usernameAvailable?: boolean
  activeUsers: Doctor[]
  allActiveDoctorsSelected: boolean
  isStaffUpdateFlow: boolean
  isStaffDetailsRequested: boolean
  deleteStaffButtonClickFlag: boolean
  lastStaffDeleteResult?: LastStaffUpdateResult
  isStaffLoading: boolean
  isStaffUpdateButtonVisible: boolean
}

const defaultStaffDetails: PersonalInformation = {
  firstName: '',
  middleName: '',
  lastName: '',
  username: '',
  email: '',
}

const INITIAL_STATE: StaffCreationState = {
  staffDetails: { ...defaultStaffDetails },
  lastStaffUpdateResult: undefined,
  usernameAvailable: undefined,
  activeUsers: [],
  allActiveDoctorsSelected: false,
  isStaffUpdateFlow: false,
  isStaffDetailsRequested: false,
  deleteStaffButtonClickFlag: false,
  lastStaffDeleteResult: undefined,
  isStaffLoading: false,
  isStaffUpdateButtonVisible: false,
}

const staffCreationActions = {
  addStaffMounted: createAction('@CREATE_STAFF/ADD_STAFF_MOUNTED')<{ isLoadingEnabled: boolean }>(),
  addStaffUnMounted: createAction('@CREATE_STAFF/ADD_STAFF_UNMOUNTED')(),
  createStaffButtonClicked: createAction(
    '@CREATE_STAFF/CREATE_STAFF_BUTTON_CLICKED',
  )<PersonalInformation>(),
  createStaffRequestTriggered: createAction(
    '@CREATE_STAFF/CREATE_STAFF_REQUEST_TRIGGERED',
  )<PersonalInformation>(),
  createStaffRequestComplete: createAction('@CREATE_STAFF/CREATE_STAFF_REQUEST_COMPLETE')(),
  createStaffRequestFailed: createAction('@CREATE_STAFF/CREATE_STAFF_REQUEST_FAILED')(),

  updateStaffButtonClicked: createAction(
    '@CREATE_STAFF/UPDATE_STAFF_BUTTON_CLICKED',
  )<PersonalInformation>(),
  usernameValidationRequested: createAction(
    '@CREATE_STAFF/USERNAME_VALIDATION_REQUESTED',
  )<string>(),
  usernameIsNotUniqueValidationReceived: createAction(
    '@CREATE_STAFF/USERNAME_IS_NOT_UNIQUE_VALIDATION_RECEIVED',
  )(),
  usernameValidationRequestFailed: createAction(
    '@CREATE_STAFF/USERNAME_VALIDATION_REQUEST_FAILED',
  )(),
  associatedDoctorsListReceived: createAction('@CREATE_STAFF/ASSOCIATED_DOCTORS_LIST_RECEIVED')<
    Doctor[]
  >(),
  associatedDoctorsListRequestFailed: createAction(
    '@CREATE_STAFF/ASSOCIATED_DOCTORS_LIST_REQUEST_FAILED',
  )<{ message: string }>(),
  associateAllDoctorsFlagChanged: createAction(
    '@CREATE_STAFF/ASSOCIATE_ALL_DOCTORS_FLAG_CHANGED',
  )(),
  staffDetailsRequested: createAction('@CREATE_STAFF/STAFF_DETAILS_REQUESTED')<{
    staffUsername: string
  }>(),
  staffDetailsResponseSuccess: createAction(
    '@CREATE_STAFF/STAFF_DETAILS_RESPONSE_SUCCESS',
  )<PersonalInformation>(),
  staffDetailsResponseFailed: createAction('@CREATE_STAFF/STAFF_DETAILS_RESPONSE_FAILED')<{
    message: string
  }>(),
  deleteStaffButtonClicked: createAction(
    '@CREATE_STAFF/DELETE_STAFF_BUTTON_CLICKED',
  )<PersonalInformation>(),
  staffDeletionRequestFinished: createAction(
    '@CREATE_STAFF/STAFF_DELETION_REQUEST_FINISHED',
  )<PersonalInformation>(),
  staffDeletionRequestFailed: createAction('@CREATE_STAFF/STAFF_DELETION_REQUEST_FAILED')<{
    message: string
  }>(),

  staffInputFieldValueChanged: createAction('@CREATE_STAFF/STAFF_INPUT_FIELD_VALUE_CHANGED')(),
  updateCanManageCasesOf: createAction('@CREATE_STAFF/UPDATE_CAN_MANAGE_CASES_OF')<string[]>(),
}

type StaffCreationActions = ActionType<typeof staffCreationActions>
const staffCreationReducer = createReducer<StaffCreationState, StaffCreationActions>(INITIAL_STATE)
  .handleAction(staffCreationActions.associateAllDoctorsFlagChanged, (state) => ({
    ...state,
    allActiveDoctorsSelected: !state.allActiveDoctorsSelected,
    isStaffUpdateButtonVisible: true,
  }))
  .handleAction(staffCreationActions.usernameIsNotUniqueValidationReceived, (state) => ({
    ...state,
    usernameAvailable: false,
  }))
  .handleAction(staffCreationActions.addStaffUnMounted, (state) => ({
    ...state,
    lastStaffUpdateResult: undefined,
    lastStaffDeleteResult: undefined,
    isStaffUpdateFlow: false,
  }))
  .handleAction(staffCreationActions.addStaffMounted, (state, action) => ({
    ...state,
    staffDetails: { ...defaultStaffDetails },
    activeUsers: [],
    lastStaffUpdateResult: undefined,
    usernameAvailable: undefined,
    allActiveDoctorsSelected: false,
    isStaffDetailsRequested: false,
    isStaffUpdateFlow: false,
    lastStaffDeleteResult: undefined,
    isStaffLoading: action.payload.isLoadingEnabled,
    isStaffUpdateButtonVisible: false,
  }))
  .handleAction(
    [staffCreationActions.createStaffButtonClicked, staffCreationActions.updateStaffButtonClicked],
    (state, action) => ({
      ...state,
      staffDetails: action.payload,
      usernameAvailable: undefined,
      lastStaffUpdateResult: undefined,
    }),
  )
  .handleAction(staffCreationActions.createStaffRequestComplete, (state) => ({
    ...state,
    lastStaffUpdateResult: {
      status: ResultStatus.OK,
      message: 'staff.creation.success',
    },
  }))
  .handleAction(
    [
      staffCreationActions.createStaffRequestFailed,
      staffCreationActions.usernameValidationRequestFailed,
    ],
    (state) => ({
      ...state,
      lastStaffUpdateResult: {
        message: 'staff.creation.failure',
        status: ResultStatus.BAD_REQUEST,
      },
      usernameAvailable: undefined,
    }),
  )
  .handleAction(staffCreationActions.associatedDoctorsListReceived, (state, action) => ({
    ...state,
    activeUsers: action.payload,
  }))
  .handleAction(staffCreationActions.staffDetailsRequested, (state) => ({
    ...state,
    isStaffDetailsRequested: true,
  }))
  .handleAction(staffCreationActions.staffDetailsResponseSuccess, (state, action) => {
    const { canManageCasesOf } = action.payload

    return {
      ...state,
      staffDetails: action.payload,
      isStaffUpdateFlow: true,
      allActiveDoctorsSelected:
        canManageCasesOf !== undefined && state.activeUsers.length === canManageCasesOf.length,
      isStaffLoading: false,
    }
  })
  .handleAction(staffCreationActions.staffDeletionRequestFinished, (state, action) => ({
    ...state,
    staffDetails: action.payload,
    lastStaffDeleteResult: { status: ResultStatus.OK, message: 'staff.deletion.success' },
  }))
  .handleAction(staffCreationActions.deleteStaffButtonClicked, (state, action) => ({
    ...state,
    staffDetails: action.payload,
    lastStaffDeleteResult: undefined,
    deleteStaffButtonClickFlag: true,
  }))
  .handleAction(staffCreationActions.staffDeletionRequestFailed, (state) => ({
    ...state,
    lastStaffDeleteResult: { message: 'staff.deletion.failure', status: ResultStatus.BAD_REQUEST },
  }))
  .handleAction(staffCreationActions.staffInputFieldValueChanged, (state) => ({
    ...state,
    isStaffUpdateButtonVisible: true,
  }))
  .handleAction(staffCreationActions.updateCanManageCasesOf, (state, action) => {
    const canManageCasesOf = [...action.payload]

    return {
      ...state,
      staffDetails: { ...state.staffDetails, canManageCasesOf },
    }
  })

const staffCreationSelectors = {
  getFormPersonalInfo: () => (state: RootState) => state.staffCreation.staffDetails,
  getLastStaffUpdateResult: () => (state: RootState) => state.staffCreation.lastStaffUpdateResult,
  isUsernameAvailable: () => (state: RootState) => state.staffCreation.usernameAvailable,
  getActiveUsers: () => (state: RootState) => state.staffCreation.activeUsers,
  getAllActiveDoctorsSelected: () => (state: RootState) =>
    state.staffCreation.allActiveDoctorsSelected,
  isStaffUpdateFlow: () => (state: RootState) => state.staffCreation.isStaffUpdateFlow,
  isStaffDetailsRequested: () => (state: RootState) => state.staffCreation.isStaffDetailsRequested,
  getDeleteButtonClickFlag: () => (state: RootState) =>
    state.staffCreation.deleteStaffButtonClickFlag,
  getLastStaffDeleteResult: () => (state: RootState) => state.staffCreation.lastStaffDeleteResult,
  isStaffLoading: () => (state: RootState) => state.staffCreation.isStaffLoading,
  isStaffUpdateButtonVisible: () => (state: RootState) =>
    state.staffCreation.isStaffUpdateButtonVisible,
}

export {
  StaffCreationState,
  staffCreationActions,
  staffCreationReducer,
  staffCreationSelectors,
  StaffCreationActions,
  defaultStaffDetails,
}
