import { Typography } from 'app/components/ui/SDS/common/Typography'

interface FooterLinkProps {
  'data-testid': string
  href: string
}

// TODO: Move to common typography based link component in future
export const FooterLink: React.FC<FooterLinkProps> = (props) => (
  <Typography component="span">
    <a
      data-testid={props['data-testid']}
      href={props.href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {props.children}
    </a>
  </Typography>
)
