import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledFooterContainer = styled.div`
  padding: 8px;

  border-top: 1px solid ${({ theme }) => theme.colors.base200};
`

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    padding: 8px;
    top: 4px;
    right: 4px;

    .ant-modal-close-x {
      display: flex;
      justify-content: center;

      width: 24px;
      height: 24px;
    }
  }

  .ant-modal-header {
    padding: 4px;

    border: none;

    .ant-modal-title {
      display: flex;

      border: 1px solid ${({ theme }) => theme.colors.base200};

      background: linear-gradient(180deg, #dcdcdc 0%, #f5f5f5 100%);
      border-radius: 4px;
    }
  }

  .ant-modal-body {
    padding: 4px;
  }
`
