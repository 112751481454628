import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { StandardPerson } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

const DoctorCaseCard: React.FC<{ data: CaseTableData }> = (props) => {
  const { caseId, patient, thumbnailUrl } = props.data.case
  const [isLoaded, setIsLoaded] = useState(false)
  const fullName = `${patient.firstName} ${patient.lastName}`
  const intl = useIntl()
  const onLoad = () => setIsLoaded(true)

  return (
    <Box padding="0 4px" justify="flex-start">
      <CaseAvatar marginRight="8px" width="auto" minWidth="42px" height="40px">
        <img
          style={{ display: isLoaded ? 'block' : 'none' }}
          src={thumbnailUrl}
          alt={intl.formatMessage({ id: 'cases.interface.avatar.alt' })}
          onLoad={onLoad}
        />
        <StandardPerson
          style={{ display: isLoaded ? 'none' : 'block' }}
          aria-label={intl.formatMessage({ id: 'cases.interface.avatar.alt' })}
          width="16px"
        />
      </CaseAvatar>
      <CaseInfo display="inline-flex" flexDirection="column" align="flex-start">
        <Typography component="span">{caseId}</Typography>
        <CaseCardPatientFullName component="span" whiteSpace="nowrap">
          {fullName}
        </CaseCardPatientFullName>
      </CaseInfo>
    </Box>
  )
}

const CaseCardPatientFullName = styled(Typography)`
  overflow-x: hidden;

  width: 100%;

  text-overflow: ellipsis;

  &::-webkit-scrollbar {
    appearance: none;
  }
`

const CaseAvatar = styled(Box)`
  background: ${({ theme }) => theme.colors.base300};

  border-radius: 3px;

  img {
    width: 42px;
    height: 42px;
    object-fit: cover;
  }
`

const CaseInfo = styled(Box)`
  overflow: hidden;
`

export { DoctorCaseCard }
