import styled from 'styled-components'

import { Box } from '../SDS/common/Box'
//Probably will be removed from here, since its some common logic, and could be used as the part of the box
interface RowProps {
  backgroundColor?: string
}
//TODO: create some SDS table rows/columns
const Row = styled(Box)<RowProps>`
  justify-content: flex-start;

  background-color: ${(props) => (props ? props.backgroundColor : 'white')};
`

export { Row }
