import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { InputLabel } from 'app/components/ui/Input/InputLabel'

import { AddressInput } from './AddressInput'

const Address2 = () => (
  <StaffFormItemVertical
    colon={false}
    hasFeedback
    label={<InputLabel labelId="addresses.addressForm.address2" isOptional />}
    data-testid="AddAddress-Address2"
  >
    <AddressInput name="address2" />
  </StaffFormItemVertical>
)

export { Address2 }
