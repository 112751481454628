import { Radio } from 'antd'
import styled from 'styled-components'

const OrmcoRadioGroup = styled(Radio.Group)`
  &&& {
    &.ant-radio-group {
      display: flex;
      flex-direction: column;
    }
  }
`

export { OrmcoRadioGroup }
