import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { LanguageDropdown } from 'app/components/routes/BasicPage/Header/NavigationBar/LanguageDropdown/LanguageDropdown'
import { termsSelectors } from 'app/components/routes/BasicPage/TermsAndConditionsModal/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

import { FooterLink } from './FooterLink'

const OrmcoLink = () => (
  <FooterLink data-testid="Footer-OrmcoLink" href="https://ormco.com">
    <FormattedMessage id="footer.ormcoLinkTitle" />
  </FooterLink>
)

const TermsOfUseLink = () => {
  const { link } = useSelector(termsSelectors.getTermsState())

  return (
    <FooterLink data-testid="Footer-TermsOfUseLink" href={link}>
      <FormattedMessage id="footer.termsofUseLinkTitle" />
    </FooterLink>
  )
}

const PrivacyLink = () => (
  <FooterLink data-testid="Footer-PrivacyLink" href="https://envistaco.com/en/privacy-policy">
    <FormattedMessage id="footer.privacyLinkTitle" />
  </FooterLink>
)

const Version = () => {
  const version = useSelector(appSelectors.getVersion())

  return (
    <Typography data-testid="Footer-Version" component="span" opacity="0">
      <FormattedMessage id="footer.version" /> : {version}
    </Typography>
  )
}

export const Footer = () => {
  const copyrightYear = useSelector(appSelectors.getCopyrightYear())
  const isImpersonated = useSelector(appSelectors.isImpersonated())
  const locales = useSelector(appSelectors.getLocales())
  const hasChoiceOfLanguages = locales.length > 1

  return (
    <Box as="footer" padding="18px" maxHeight="52px" justify="space-between" data-testid="Footer">
      <Typography data-testid="Footer-Copyright" component="span" color="base900">
        <FormattedMessage
          id="footer.message"
          values={{
            copyrightYear,
            ormcoLink: <OrmcoLink />,
          }}
        />
      </Typography>
      <Version />
      <MultipleLinksContainer
        justify="space-around"
        width="auto"
        data-testid="Footer-RightContainer"
      >
        {!isImpersonated && !hasChoiceOfLanguages && <Box width="148px" />}
        <TermsOfUseLink />
        <PrivacyLink />
        {!isImpersonated && <LanguageDropdown data-testid="Footer-LanguageDropdown" />}
      </MultipleLinksContainer>
    </Box>
  )
}

const MultipleLinksContainer = styled(Box)`
  a {
    margin: 0 6px;
  }
`
