import { FC, ReactNode } from 'react'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface AuxTeethNumbersProps {
  caption: ReactNode
  type: string
  icon: JSX.Element
}

const AuxiliaryTypeSummary: FC<AuxTeethNumbersProps> = ({ caption, icon, children, type }) => (
  <Box minWidth="377px" maxWidth="538px" flexDirection="column" align="flex-start">
    <Caption
      data-testid={`AuxTeethNumbers-Caption-${type}`}
      component="h3"
      variant="medium"
      color="base900"
    >
      {caption}
    </Caption>

    <AuxiliaryTypeSummaryContainer marginTop="4px" padding="5px" align="flex-start">
      <Box width="auto" padding="4px 6px" data-testid={`AuxTeethNumbers-AuxIcon-${type}`}>
        {icon}
      </Box>
      <Box flexWrap="wrap" justify="flex-start" data-testid={`AuxTeethNumbers-NumbersBox-${type}`}>
        {children}
      </Box>
    </AuxiliaryTypeSummaryContainer>
  </Box>
)

const Caption = styled(Typography)`
  text-transform: capitalize;
`

const AuxiliaryTypeSummaryContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.base200};

  background: ${({ theme }) => theme.colors.white};

  border-radius: 2px;
`

export { AuxiliaryTypeSummary }
