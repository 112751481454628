import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { parseDoctorQueryParams } from 'app/components/routes/BasicPage/BasicPage'
import { DoctorCaseListQueryUrlParams } from 'app/components/routes/CaseList/CaseList'
import { DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { OrmcoTextArea } from 'app/components/ui/Form/OrmcoTextArea'
import { SdsEyeOutlined, SdsPaperClipOutlined, SdsNoteOutlined } from 'app/components/ui/Icons/sds'
import { Box } from 'app/components/ui/SDS/common/Box'
import { OverflowTooltip } from 'app/components/ui/SDS/common/OverflowTooltip'
import { TextArea, TextAreaProps as TextAreaPropsType } from 'app/components/ui/SDS/common/TextArea'
import { Tooltip } from 'app/components/ui/SDS/common/Tooltip'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { HoldReasonCodes } from 'app/core/domain/Case'
import { SessionStorageKeysMap } from 'app/core/domain/SessionStorageKeysMap'
import { useDidMount } from 'app/core/react/CustomHooks'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { HoldDetailsProps } from './HoldReasonPopover'
import {
  HoldReasonComment,
  HoldReasonPhotosArea,
  PhotoName,
  PhotoNameLabel,
  PhotoItem,
  LinkButton,
  LinkButtonText,
  OnHoldButtons,
} from './styles'

const COMMENT_CHARACTER_LIMIT = 300
const COMMENT_ROWS_NUMBER = 3

interface PopoverContentProps extends HoldDetailsProps {
  togglePopoverVisible: () => void
}

const PopoverContent: React.FC<PopoverContentProps> = (props) => {
  const {
    caseId,
    orderId,
    holdDetails: { reasonCode, comment, files, currentRecordEnabled },
    togglePopoverVisible,
  } = props

  const dispatch = useDispatch()
  const [isCurrentPatientRecords, setIsCurrentPatientRecords] = useState(false)
  const [doctorComment, setDoctorComment] = useState('')
  const locale = useSelector(appSelectors.getLocale())
  const isLinkToPdfVisible = locale && !['zh', 'ja', 'ru'].includes(locale.split('_')[0])
  const intl = useIntl()
  const location = useLocation()
  const { search }: DoctorCaseListQueryUrlParams = parseDoctorQueryParams(location)
  const editBtnText = intl.formatMessage({ id: 'cases.list.doctor.onHoldPopover.editBtnText' })
  const linkToPdfText = intl.formatMessage({ id: 'cases.list.doctor.onHoldPopover.linkToPdfText' })
  const enterComment = intl.formatMessage({ id: 'cases.list.doctor.onHoldPopover.enterComment' })
  const viewFileTooltipText = intl.formatMessage({
    id: 'cases.list.doctor.onHoldPopover.viewFileTooltipText',
  })
  const reasonCodeTitle = intl.formatMessage(
    { id: 'cases.list.doctor.onHoldPopover.holdReasonCodes' },
    { holdReasonCode: HoldReasonCodes[reasonCode] },
  )

  const isScanBestPracticesPdfUrlLoading = useSelector(
    caseListSelectors.isScanBestPracticesPdfUrlLoading(),
  )
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const isUseExistingScansEnabled = featurePermissions?.isUseExistingScansEnabled

  const patientDetailsPagePhotosScansTabLink =
    SiteMap.patiensDetails(orderId) + '&tab=#ImagesLoadTab'

  useDidMount(() => {
    const isCurrentPatientRecordsValue = sessionStorage.getItem(
      `${SessionStorageKeysMap.isCurrentPatientRecords}-${caseId}`,
    )
    const doctorCommentValue = sessionStorage.getItem(
      `${SessionStorageKeysMap.doctorComment}-${caseId}`,
    )

    setIsCurrentPatientRecords(isCurrentPatientRecordsValue === 'true')
    setDoctorComment(doctorCommentValue ?? '')
  })

  const handleEyeImgClick = (uid: string, fileName: string) => () => {
    dispatch(caseListActions.getHoldCaseImageUrlRequestStarted({ fileName }))
    dispatch(caseListActions.getHoldCaseImageUrl({ caseId, uid }))
  }

  const handleLinkToPdfClick = () => {
    if (!isScanBestPracticesPdfUrlLoading) {
      dispatch(caseListActions.getScanBestPracticesPdfUrlRequestStarted())
      dispatch(caseListActions.getScanBestPracticesPdfUrl())
    }
  }

  const handleCheckbox = (event: CheckboxChangeEvent) => {
    const { checked } = event.target

    setIsCurrentPatientRecords(checked)
    sessionStorage.setItem(
      `${SessionStorageKeysMap.isCurrentPatientRecords}-${caseId}`,
      checked.toString(),
    )
    if (!checked) {
      setDoctorComment('')
      sessionStorage.setItem(`${SessionStorageKeysMap.doctorComment}-${caseId}`, '')
    }
  }

  const handleTextChange = (value: string) => {
    setDoctorComment(value)
    dispatch(caseListActions.updateDoctorComment({ caseId, doctorComment }))
  }

  const handleRemoveHold = () => {
    togglePopoverVisible()
    dispatch(caseListActions.toggleHoldRemovalLoader(true))
    dispatch(
      caseListActions.removeCaseFromHoldRequested({
        caseId,
        doctorComment,
        isCurrentPatientRecords,
        tab: DoctorCaseTabs.actionRequired,
        search,
      }),
    )
  }

  const tooltipOverlayInnerStyle = { marginBottom: '-8px' }

  const isSDS = useSelector(featureFlagSelectors.isSDS())
  const textAreaProps: TextAreaPropsType = {
    showCount: isSDS,
    onChange: (event) => handleTextChange(event.target.value),
    rows: COMMENT_ROWS_NUMBER,
    maxLength: COMMENT_CHARACTER_LIMIT,
    placeholder: enterComment,
    'data-testid': 'PopoverContent-OrmcoTextArea',
    value: doctorComment,
  }

  return (
    <Box width="484px" display="block">
      <div>{reasonCodeTitle}</div>
      {comment && (
        <OverflowTooltip
          placement="top"
          overlayInnerStyle={tooltipOverlayInnerStyle}
          title={comment}
        >
          <HoldReasonComment>{comment}</HoldReasonComment>
        </OverflowTooltip>
      )}
      {!!files.length && (
        <HoldReasonPhotosArea>
          {files.map(({ uid, name }) => (
            <PhotoItem key={uid}>
              <SdsPaperClipOutlined width="15px" color="base500" />
              <PhotoName>
                <OverflowTooltip
                  placement="top"
                  overlayInnerStyle={tooltipOverlayInnerStyle}
                  title={name}
                >
                  <PhotoNameLabel>{name}</PhotoNameLabel>
                </OverflowTooltip>
              </PhotoName>
              <Tooltip
                placement="top"
                overlayInnerStyle={tooltipOverlayInnerStyle}
                title={viewFileTooltipText}
              >
                <SdsEyeOutlined
                  onClick={handleEyeImgClick(uid, name)}
                  cursor="pointer"
                  width="15px"
                  color="primary500"
                />
              </Tooltip>
            </PhotoItem>
          ))}
        </HoldReasonPhotosArea>
      )}
      <div>
        <OnHoldButtons
          data-testid="edit-photo-link-btn"
          type="primary"
          href={patientDetailsPagePhotosScansTabLink}
        >
          <SdsNoteOutlined color="white" width="15px" height="15px" />
          <span>{editBtnText}</span>
        </OnHoldButtons>
      </div>
      {currentRecordEnabled && isUseExistingScansEnabled && (
        <Box marginTop="16px">
          <OrmcoCheckbox
            onChange={handleCheckbox}
            data-testid="PopoverContent-OrmcoCheckbox"
            checked={isCurrentPatientRecords}
          >
            <Typography component="span">
              <FormattedMessage
                id="cases.list.doctor.onHoldPopover.useCurrentRecords"
                values={{
                  b: (chunks: string) => (
                    <Typography component="span" textFontWeight="600">
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </OrmcoCheckbox>
        </Box>
      )}
      {currentRecordEnabled && isCurrentPatientRecords && isUseExistingScansEnabled && (
        <Box marginTop="8px" display="block">
          {isSDS ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextArea {...textAreaProps} />
          ) : (
            <>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <OrmcoTextArea {...textAreaProps} />
              <Typography component="span" color="base400" data-testid="PopoverContent-Typography">
                {doctorComment.length}/{COMMENT_CHARACTER_LIMIT}
              </Typography>
            </>
          )}
        </Box>
      )}
      {isLinkToPdfVisible && (
        <Box marginTop="8px" justify="flex-start">
          <LinkButton
            data-testid="scan-best-practices-link-btn"
            type="link"
            onClick={handleLinkToPdfClick}
          >
            <OverflowTooltip
              placement="top"
              overlayInnerStyle={tooltipOverlayInnerStyle}
              title={linkToPdfText}
            >
              <LinkButtonText>{linkToPdfText}</LinkButtonText>
            </OverflowTooltip>
          </LinkButton>
        </Box>
      )}
      {currentRecordEnabled && isCurrentPatientRecords && isUseExistingScansEnabled && (
        <div>
          <OnHoldButtons
            data-testid="PopoverContent-OnHoldButtons"
            type="primary"
            onClick={handleRemoveHold}
          >
            <FormattedMessage id="cases.list.doctor.onHoldPopover.confirm" />
          </OnHoldButtons>
        </div>
      )}
    </Box>
  )
}

export { PopoverContent }
