import { Divider } from 'antd'
import styled from 'styled-components'

interface StyledDividerProps {
  margin?: string
}

export const StyledDivider = styled(Divider)<StyledDividerProps>`
  margin: ${({ margin = '0px' }) => margin};
`
