import { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Col, Row } from 'antd'
import { HorizontalLine } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/HorizontalLine'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext, ThemeType, ContextThemeProps } from 'app/components/ui/Theme/ThemeContext'
import styled from 'styled-components'

import { ActiveAuxiliarySelector } from './ActiveAuxiliary/ActiveAuxiliarySelector'
import { ActivitySelection } from './ActiveAuxiliary/ActivitySelection'
import { AlignerAuxiliariesSummary } from './Summary/AlignerAuxiliariesSummary'
import { AuxClickableTeeth } from './TeethPanel/AuxClickableTeeth'
import { useAvailableAlignersAuxiliaries } from './TeethPanel/useAvailableAlignersAuxiliaries'

const TeethSection = () => {
  const [activeSelect, setActiveSelect] = useState<keyof typeof ActivitySelection>(
    ActivitySelection.dontMoveTeeth,
  )

  const availableAuxiliaries = useAvailableAlignersAuxiliaries()

  const theme = useContext(ThemeContext)

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.teethSection.label" />
      }
      hasFeedback
    >
      <TeethInstructions align="middle">
        <FormattedMessage id="caseWizard.casePrescription.teethSection.instructions" />
      </TeethInstructions>
      <Row>
        <TeethSelectionContainer span={16} contextTheme={theme} data-testid="TeethSelection">
          <Box marginTop="28px" data-testid="TeethSelection-ClickableTeeth">
            <AuxClickableTeeth
              auxiliaries={availableAuxiliaries}
              activeSelect={activeSelect}
              data-testid="TeethSelection-Aux"
            />
          </Box>
          <Row>
            <Box marginTop="14px" justify="space-between">
              <Typography data-testid="TeethSelection-Right" component="label">
                <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.right" />
              </Typography>
              <HorizontalLine />
              <Typography data-testid="TeethSelection-Left" component="label">
                <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.left" />
              </Typography>
            </Box>
          </Row>
        </TeethSelectionContainer>
        <AlignerAuxiliariesSummary auxiliaries={availableAuxiliaries} />
      </Row>
      <TeethSelectorContainer justify="start">
        <Col span={3}>
          <ActiveAuxiliarySelector
            auxiliaries={availableAuxiliaries}
            selected={activeSelect}
            onSelect={setActiveSelect}
            data-testid="TeethSelection-ActivitySelector"
          />
        </Col>
        <IconIntructions>
          <Typography component="label">
            <FormattedMessage id="caseWizard.casePrescription.teethSection.iconInstructions" />
          </Typography>
        </IconIntructions>
      </TeethSelectorContainer>
    </OrmcoFormItemVertical>
  )
}
const TeethSelectorContainer = styled(Row)`
  padding-top: 13px;
`
const TeethInstructions = styled(Row)`
  height: 40px;
`
const IconIntructions = styled(Col)`
  display: flex;
  align-items: center;
`

const TeethSelectionContainer = styled(Col)<ContextThemeProps>`
  &&& {
    padding: 25px;

    background-color: ${({ theme, contextTheme }) =>
      contextTheme === ThemeType.BOOTSTRAP ? theme.colors.base100 : theme.colors.primary10};
    border-radius: 4px;
  }
`

export { TeethSection }
