import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { QuestionCircleOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { basicPageActions } from 'app/components/routes/BasicPage/logic'
import { postLogoutMessage } from 'app/core/auth/AuthenticationBroadcast'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

import { HorizontalMenu } from '../HorizontalMenu'
import {
  MenuProps as HorizontalMenuProps,
  MenuDropdownItemProps,
} from '../HorizontalMenu/interfaces'

interface HeaderNavigationProps {
  'data-testid': string
}

export const HeaderNavigation = ({ 'data-testid': dataTestId }: HeaderNavigationProps) => {
  const dispatch = useDispatch()
  const fullName = useSelector(appSelectors.getFullName())
  const location = useLocation()
  const isDoctor = useSelector(appSelectors.isDoctor())
  const isStaff = useSelector(appSelectors.isStaff())
  const myTeamLinkClickCallBack = useCallback(() => {
    dispatch(basicPageActions.myTeamLinkClicked())
  }, [dispatch])

  const handleLogout = () => {
    sessionStorage.clear()
    postLogoutMessage()
  }

  const patientsMenuOptions: MenuDropdownItemProps['options'] = [
    {
      label: <FormattedMessage id="header.navigation.doctors.support" />,
      href: SiteMap.DOCTORS.support(),
      key: `${dataTestId}-support`,
      'data-testid': `${dataTestId}-support`,
    },
    {
      label: <FormattedMessage id="header.navigation.doctors.downloadApprover" />,
      href: SiteMap.DOCTORS.downloadApprover(),
      key: `${dataTestId}-downloadApprover`,
      'data-testid': `${dataTestId}-downloadApprover`,
    },
    {
      label: <FormattedMessage id="header.navigation.doctors.education" />,
      href: SiteMap.DOCTORS.education(),
      key: `${dataTestId}-education`,
      'data-testid': `${dataTestId}-education`,
    },
  ]

  const doctorMenuOptions: MenuDropdownItemProps['options'] = isDoctor
    ? [
        {
          label: <FormattedMessage id="header.navigation.doctors.practice" />,
          onClick: () => myTeamLinkClickCallBack(),
          href: '/myPractice',
          key: `${dataTestId}-practice`,
          isLocalRoute: true,
          'data-testid': `${dataTestId}-practice`,
        },
        {
          label: <FormattedMessage id="header.navigation.doctors.clinicalPreferences" />,
          href: SiteMap.DOCTORS.clinicalPreferences(),
          key: `${dataTestId}-clinicalPreferences`,
          'data-testid': `${dataTestId}-clinicalPreferences`,
        },
      ]
    : []

  const userMenuOptions: MenuDropdownItemProps['options'] = [
    {
      label: <FormattedMessage id="header.navigation.doctors.preferences" />,
      href: SiteMap.DOCTORS.profile(),
      'data-testid': `${dataTestId}-preferences`,
      key: `${dataTestId}-preferences`,
    },
    {
      label: <FormattedMessage id="header.navigation.doctors.profile" />,
      href: '/myProfile',
      isLocalRoute: true,
      key: `${dataTestId}-profile`,
      'data-testid': `${dataTestId}-profile`,
    },
    ...doctorMenuOptions,
    {
      label: <FormattedMessage id="header.navigation.doctors.logout" />,
      onClick: () => handleLogout(),
      href: SiteMap.logout(),
      key: `${dataTestId}-logout`,
      'data-testid': `${dataTestId}-logout`,
    },
  ]

  const getIsActiveDropdown = (items: MenuDropdownItemProps['options']) =>
    items.some((item) => location.pathname.indexOf(item.href) !== -1)

  const menuItems: HorizontalMenuProps['items'] = [
    {
      label: <FormattedMessage id="header.navigation.patients" />,
      key: 'patients',
      href: '/homePage',
      isLocalRoute: true,
      isActive: location.pathname.indexOf('/homePage') !== -1,
      icon: <SolutionOutlined />,
    },
    {
      label: <FormattedMessage id="header.navigation.help" />,
      key: `${dataTestId}-help`,
      icon: <QuestionCircleOutlined />,
      isActive: getIsActiveDropdown(patientsMenuOptions),
      options: patientsMenuOptions,
      'data-testid': `${dataTestId}-help`,
    },
    {
      label: fullName,
      icon: <UserOutlined />,
      key: `${dataTestId}-User`,
      isActive: getIsActiveDropdown(userMenuOptions),
      options: userMenuOptions,
      'data-testid': `${dataTestId}-User`,
    },
  ]

  if (isDoctor || isStaff) {
    return <HorizontalMenu items={menuItems} isDark dropdownMenuTheme="light" />
  }

  return (
    <div data-testid={`${dataTestId}-NoNavigationMessage`}>
      No suitable Navigation Bar found for user
    </div>
  )
}
