import { DefaultTheme } from 'styled-components'

import { rgbaHexUtil } from './utils'

export const sdsTheme: DefaultTheme = {
  colors: {
    black: '#000000',
    blackTransparent70: rgbaHexUtil('#000000', 0.7),
    blackTransparent60: rgbaHexUtil('#000000', 0.6),
    blackTransparent50: rgbaHexUtil('#000000', 0.5),
    blackTransparent30: rgbaHexUtil('#000000', 0.3),
    blackTransparent20: rgbaHexUtil('#000000', 0.2),
    blackTransparent10: rgbaHexUtil('#000000', 0.1),
    blackTransparent5: rgbaHexUtil('#000000', 0.05),

    base900: '#1A1A1A',
    base800: '#333333',
    base700: '#4D4D4D',
    base600: '#666666',
    base500: '#808080',
    base400: '#999999',
    base300: '#B3B3B3',
    base200: '#CCCCCC',
    base100: '#E6E6E6',
    base10: '#F4F5F5',

    white: '#FFFFFF',
    whiteTransparent70: rgbaHexUtil('#FFFFFF', 0.7),
    whiteTransparent60: rgbaHexUtil('#FFFFFF', 0.6),
    whiteTransparent50: rgbaHexUtil('#FFFFFF', 0.5),
    whiteTransparent30: rgbaHexUtil('#FFFFFF', 0.3),
    whiteTransparent20: rgbaHexUtil('#FFFFFF', 0.2),
    whiteTransparent10: rgbaHexUtil('#FFFFFF', 0.1),
    whiteTransparent5: rgbaHexUtil('#FFFFFF', 0.05),

    primary900: '#044070',
    primary800: '#064F8A',
    primary700: '#065CA2',
    primary600: '#086CBD',
    primary500: '#097AD6',
    primary400: '#2294F0',
    primary300: '#3DA8FF',
    primary200: '#8ACBFF',
    primary100: '#D6EDFF',
    primary10: '#F0F8FF',
    primaryTransparent70: rgbaHexUtil('#097AD6', 0.7),
    primaryTransparent60: rgbaHexUtil('#097AD6', 0.6),
    primaryTransparent50: rgbaHexUtil('#097AD6', 0.5),
    primaryTransparent40: rgbaHexUtil('#097AD6', 0.4),
    primaryTransparent30: rgbaHexUtil('#097AD6', 0.3),
    primaryTransparent20: rgbaHexUtil('#097AD6', 0.2),
    primaryTransparent10: rgbaHexUtil('#097AD6', 0.1),
    primaryTransparent5: rgbaHexUtil('#097AD6', 0.05),

    secondary600: '#348194',
    secondary500: '#3D97AD',
    secondary400: '#46ADC7',
    secondary300: '#4EC3E0',
    secondary200: '#57DAFA',
    secondary100: '#8CE9FF',
    secondary10: '#D9F8FF',
    secondaryTransparent70: rgbaHexUtil('#4EC3E0', 0.7),
    secondaryTransparent60: rgbaHexUtil('#4EC3E0', 0.6),
    secondaryTransparent50: rgbaHexUtil('#4EC3E0', 0.5),
    secondaryTransparent30: rgbaHexUtil('#4EC3E0', 0.3),
    secondaryTransparent20: rgbaHexUtil('#4EC3E0', 0.2),
    secondaryTransparent10: rgbaHexUtil('#4EC3E0', 0.1),
    secondaryTransparent5: rgbaHexUtil('#4EC3E0', 0.05),

    success600: '#32803E',
    success500: '#3C994A',
    success400: '#45B156',
    success300: '#50CC62',
    success200: '#96FFA6',
    success100: '#C9FFD1',
    success10: '#E6FFE9',
    successTransparent70: rgbaHexUtil('#50CC62', 0.7),
    successTransparent60: rgbaHexUtil('#50CC62', 0.6),
    successTransparent50: rgbaHexUtil('#50CC62', 0.5),
    successTransparent30: rgbaHexUtil('#50CC62', 0.3),
    successTransparent20: rgbaHexUtil('#50CC62', 0.2),
    successTransparent10: rgbaHexUtil('#50CC62', 0.1),
    successTransparent5: rgbaHexUtil('#50CC62', 0.05),

    warning600: '#C79542',
    warning500: '#E0A84A',
    warning400: '#F9BB53',
    warning300: '#FFC96E',
    warning200: '#FFDCA1',
    warning100: '#FFEFD4',
    warning10: '#FFF8ED',
    warningTransparent70: rgbaHexUtil('#F9BB53', 0.7),
    warningTransparent60: rgbaHexUtil('#F9BB53', 0.6),
    warningTransparent50: rgbaHexUtil('#F9BB53', 0.5),
    warningTransparent30: rgbaHexUtil('#F9BB53', 0.3),
    warningTransparent20: rgbaHexUtil('#F9BB53', 0.2),
    warningTransparent10: rgbaHexUtil('#F9BB53', 0.1),
    warningTransparent5: rgbaHexUtil('#F9BB53', 0.05),

    danger600: '#942B2B',
    danger500: '#C73A3A',
    danger400: '#E04242',
    danger300: '#FF6363',
    danger200: '#FF9696',
    danger100: '#FFC9C9',
    danger10: '#FFE3E3',
    dangerTransparent70: rgbaHexUtil('#E04242', 0.7),
    dangerTransparent60: rgbaHexUtil('#E04242', 0.6),
    dangerTransparent50: rgbaHexUtil('#E04242', 0.5),
    dangerTransparent30: rgbaHexUtil('#E04242', 0.3),
    dangerTransparent20: rgbaHexUtil('#E04242', 0.2),
    dangerTransparent10: rgbaHexUtil('#E04242', 0.1),
    dangerTransparent5: rgbaHexUtil('#E04242', 0.05),

    neutral600: '#C26C2B',
    neutral500: '#DC7A30',
    neutral400: '#F68937',
    neutral300: '#FF9C52',
    neutral200: '#FFC89E',
    neutral100: '#FFE5D1',
    neutral10: '#FFF4EB',
    neutralTransparent70: rgbaHexUtil('#F68937', 0.7),
    neutralTransparent60: rgbaHexUtil('#F68937', 0.6),
    neutralTransparent50: rgbaHexUtil('#F68937', 0.5),
    neutralTransparent30: rgbaHexUtil('#F68937', 0.3),
    neutralTransparent20: rgbaHexUtil('#F68937', 0.2),
    neutralTransparent10: rgbaHexUtil('#F68937', 0.1),
    neutralTransparent5: rgbaHexUtil('#F68937', 0.05),

    neutralSecondary600: '#5E008A',
    neutralSecondary500: '#8000BD',
    neutralSecondary400: '#A300F0',
    neutralSecondary300: '#BA29FF',
    neutralSecondary200: '#D375FF',
    neutralSecondary00: '#E3A8FF',
    neutralSecondary10: '#F4DBFF',
    neutralSecondaryTransparent70: rgbaHexUtil('#A300F0', 0.7),
    neutralSecondaryTransparent60: rgbaHexUtil('#A300F0', 0.6),
    neutralSecondaryTransparent50: rgbaHexUtil('#A300F0', 0.5),
    neutralSecondaryTransparent30: rgbaHexUtil('#A300F0', 0.3),
    neutralSecondaryTransparent20: rgbaHexUtil('#A300F0', 0.2),
    neutralSecondaryTransparent10: rgbaHexUtil('#A300F0', 0.1),
    neutralSecondaryTransparent5: rgbaHexUtil('#A300F0', 0.05),

    blackTitelGradient: 'linear-gradient(180deg, #E5E5E5 0%, #F5F5F5 100%)',
    darkBlackGradient: 'linear-gradient(180deg, #EFEFEF 0%, #B4B4B4 100%)',
    blackGradient: 'linear-gradient(180deg, #F5F5F5 0%, #CCCCCC 100%)',
    mediumBlackGradient: 'linear-gradient(180deg, #F5F5F5 0%, #E5E5E5 100%)',
    lightBlackGradient: 'linear-gradient(180deg, #F5F5F5 0%, #EFEFEF 100%)',

    darkBlueGradient: 'linear-gradient(180deg, #337AB7 0%, #00467F 100%)',
    blueGradient: 'linear-gradient(180deg, #337AB7 0%, #065CA2 100%)',
    mediumBlueGradient: 'linear-gradient(180deg, #3891EA 0%, #337AB7 100%)',
    lightBlueGradient: 'linear-gradient(180deg, #E2F0FC 0%, #98BFE1 100%, #D7EDFF 100%)',

    darkGreenGradient: 'linear-gradient(180deg, #45B156 0%, #32803E 100%)',
    greenGradient: 'linear-gradient(180deg, #50CC62 0%, #3C994A 100%)',
    mediumGreenGradient: 'linear-gradient(180deg, #96FFA6 0%, #45B156 100%)',
    lightGreenGradient: 'linear-gradient(180deg, #E5FFE9 0%, #C9FFD1 100%)',

    darkRedGradient: 'linear-gradient(180deg, #DA1E28 0%, #761C19 100%)',
    redGradient: 'linear-gradient(180deg, #D9534F 0%, #AC2925 100%)',
    mediumRedGradient: 'linear-gradient(180deg, #EC8B89 0%, #DA1E28 100%)',
    lightRedGradient: 'linear-gradient(180deg, #F2DEDE 0%, #E4B9C0 100%)',
  },

  fontVariants: {
    large: {
      fontSize: '18px',
      lineHeight: '28px',
    },
    medium: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    default: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    small: {
      fontSize: '12px',
      lineHeight: '16px',
    },
    'extra-small': {
      fontSize: '10px',
      lineHeight: '14px',
    },
    h1: {
      fontSize: '44px',
      lineHeight: '56px',
    },
    h2: {
      fontSize: '36px',
      lineHeight: '48px',
    },
    h3: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    h5: {
      fontSize: '20px',
      lineHeight: '24px',
    },
    h6: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },

  zIndex: {
    layer1: 1000,
    layer2: 2000,
    layer3: 3000,
    footer: 4000,
    header: 5000,
    dropdown: 8500,
    headerDropdowns: 9000,
    notification: 9001,
    overlay: 10000,
    modal: 9500,
  },
}
