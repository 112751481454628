import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { FullNote } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/FullNote'
import {
  doctorNoteActions,
  doctorNotesSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

const DoctorNote: React.FC<{ data: CaseTableData }> = (props) => {
  const intl = useIntl()
  const addButtonTitle = intl.formatMessage({ id: 'cases.list.doctor.notes.addNote' })
  const { notes, caseId } = props.data.case
  const dispatch = useDispatch()

  const editingCaseId = useSelector(doctorNotesSelectors.getEditingCaseId())
  const isCurrentDoctorNoteEditing = editingCaseId === caseId

  const hoveringCaseId = useSelector(doctorNotesSelectors.getHoveringCaseId())
  const isShown = isCurrentDoctorNoteEditing || hoveringCaseId === caseId
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  const setIsShownCallback = useCallback(() => {
    if (notes) {
      dispatch(doctorNoteActions.doctorNoteHovered({ caseId }))
    }
  }, [caseId, dispatch, notes])
  const noteClickCallback = useCallback(
    () => (e: React.MouseEvent) => {
      e.stopPropagation()
      if (!featurePermissions?.disableOfficeNotes) {
        dispatch(doctorNoteActions.editNoteButtonClicked({ caseId }))
      }
    },
    [caseId, dispatch, featurePermissions],
  )

  return (
    <NoteCardContainer
      display="block"
      boxSizing="border-box"
      align="normal"
      height="100%"
      onMouseEnter={() => setIsShownCallback()}
      onMouseLeave={() => !isCurrentDoctorNoteEditing && setIsShownCallback()}
      onClick={noteClickCallback()}
      data-testid={`NoteCardContainer-${caseId}`}
    >
      <NoteContainer
        padding="11px 10px"
        align="flex-start"
        justify="flex-start"
        boxSizing="border-box"
        height="100%"
      >
        {notes ||
          (!featurePermissions?.disableOfficeNotes && (
            <ActionButton
              title={addButtonTitle}
              data-testid={`NoteCardContainer-AddNoteButton-${caseId}`}
              height="18px"
            >
              <Typography textOverflow="ellipsis" color="base700">
                <FormattedMessage id="cases.list.doctor.notes.addNote" />
              </Typography>
            </ActionButton>
          ))}
      </NoteContainer>
      {isShown && (
        <FullNote
          isCurrentDoctorNoteEditing={isCurrentDoctorNoteEditing}
          onClick={() => noteClickCallback()}
          notes={notes}
          data-testid={`NoteCardContainer-FullNote-${caseId}`}
        />
      )}
    </NoteCardContainer>
  )
}

const NoteContainer = styled(Box)`
  overflow-y: hidden;

  border: 1px solid transparent;

  text-overflow: ellipsis;
`

const NoteCardContainer = styled(Box)`
  position: relative;
`

export { DoctorNote }
