import React from 'react'

import { Box } from 'app/components/ui/SDS/common/Box'
import styled, { ThemeColors } from 'styled-components'

interface HorizontalLineProps {
  color?: keyof ThemeColors
}

const HorizontalLine = ({ color = 'black' }: HorizontalLineProps) => (
  <Box margin="0 1em" flexDirection="row">
    <HorizontalLineBorder color={color} />
  </Box>
)

const HorizontalLineBorder = styled.div<HorizontalLineProps>`
  width: 100%;

  border-top: 1px solid ${({ theme, color = 'black' }) => theme.colors[color]};
`

export { HorizontalLine }
