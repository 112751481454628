import React from 'react'
import { useSelector } from 'react-redux'

import { appSelectors } from 'app/logic/app/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const PracticeName: React.FC = () => {
  const { practiceName } = useSelector(appSelectors.getEmployeePersonalDetails())

  return (
    <ReadonlyValue
      data-testid="MyProfile-PracticeName"
      labelId="practice.name"
      value={practiceName}
    />
  )
}

export { PracticeName }
