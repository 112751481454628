import { TeethImageType } from 'app/core/domain/TeethImageType'
import { ReactComponent as Upper1_16Icon } from 'app/resources/img/icons/teeth/1-16.svg'
import { ReactComponent as Lower17_32Icon } from 'app/resources/img/icons/teeth/17-32.svg'
import { ReactComponent as Lower18_31Icon } from 'app/resources/img/icons/teeth/18-31.svg'
import { ReactComponent as Lower19_30Icon } from 'app/resources/img/icons/teeth/19-30.svg'
import { ReactComponent as Upper2_15Icon } from 'app/resources/img/icons/teeth/2-15.svg'
import { ReactComponent as Lower20_29Icon } from 'app/resources/img/icons/teeth/20-29.svg'
import { ReactComponent as Lower21_28Icon } from 'app/resources/img/icons/teeth/21-28.svg'
import { ReactComponent as Lower22_27Icon } from 'app/resources/img/icons/teeth/22-27.svg'
import { ReactComponent as Lower23_26Icon } from 'app/resources/img/icons/teeth/23-26.svg'
import { ReactComponent as Lower24_25Icon } from 'app/resources/img/icons/teeth/24-25.svg'
import { ReactComponent as Upper3_14Icon } from 'app/resources/img/icons/teeth/3-14.svg'
import { ReactComponent as Upper4_13Icon } from 'app/resources/img/icons/teeth/4-13.svg'
import { ReactComponent as Upper5_12Icon } from 'app/resources/img/icons/teeth/5-12.svg'
import { ReactComponent as Upper6_11Icon } from 'app/resources/img/icons/teeth/6-11.svg'
import { ReactComponent as Upper7_10Icon } from 'app/resources/img/icons/teeth/7-10.svg'
import { ReactComponent as Upper8_9Icon } from 'app/resources/img/icons/teeth/8-9.svg'
import styled, { css } from 'styled-components'

interface TeethProps {
  width?: string
  mirror?: boolean
  type?: keyof typeof TeethImageType
}

const teethStyle = css<TeethProps>`
  display: block;

  #base {
    stroke: ${({ type }) => type === TeethImageType.DISABLED && '#F4F4F4'};
  }

  #extracted {
    stroke: ${({ type }) => type === TeethImageType.EXTRACTED && '#FA4D56'};
  }

  #hook {
    fill: ${({ type }) =>
      (type === TeethImageType.CLASSII_HOOK || type === TeethImageType.CLASSIII_HOOK) && '#000000'};
  }

  width: 100%;
`

const getIsLowerHookStyle = (isLower?: boolean) => css<TeethProps>`
  #hookstyle {
    transform: ${({ type }) => {
      if (type !== TeethImageType.CLASSIII_HOOK) {
        return null
      }

      return isLower ? 'scale(1, -1)' : 'scale(-1, 1) scale(-1, 1)'
    }};
  }
`

const Upper1_16 = styled(Upper1_16Icon)<TeethProps>`
  max-width: ${({ width = '31px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-30.5px, -14px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper2_15 = styled(Upper2_15Icon)<TeethProps>`
  max-width: ${({ width = '31px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-30.5px, -14px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper3_14 = styled(Upper3_14Icon)<TeethProps>`
  max-width: ${({ width = '31px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-30.529px, 0px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper4_13 = styled(Upper4_13Icon)<TeethProps>`
  max-width: ${({ width = '22px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-19.7751px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper5_12 = styled(Upper5_12Icon)<TeethProps>`
  max-width: ${({ width = '23px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-21.991675px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper6_11 = styled(Upper6_11Icon)<TeethProps>`
  max-width: ${({ width = '24px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-22.5974px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper7_10 = styled(Upper7_10Icon)<TeethProps>`
  max-width: ${({ width = '22px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-21.314675px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Upper8_9 = styled(Upper8_9Icon)<TeethProps>`
  max-width: ${({ width = '33px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'scale(-1, 1) translate(-32.139064px, 1.000000px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle()}
`

const Lower17_32 = styled(Lower17_32Icon)<TeethProps>`
  max-width: ${({ width = '33px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.593118px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower18_31 = styled(Lower18_31Icon)<TeethProps>`
  max-width: ${({ width = '33px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.593118px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower19_30 = styled(Lower19_30Icon)<TeethProps>`
  max-width: ${({ width = '33px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.115203px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower20_29 = styled(Lower20_29Icon)<TeethProps>`
  max-width: ${({ width = '22px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.115203px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower21_28 = styled(Lower21_28Icon)<TeethProps>`
  max-width: ${({ width = '22px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.46867px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower22_27 = styled(Lower22_27Icon)<TeethProps>`
  max-width: ${({ width = '17px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.130519px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower23_26 = styled(Lower23_26Icon)<TeethProps>`
  max-width: ${({ width = '22px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.041906px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

const Lower24_25 = styled(Lower24_25Icon)<TeethProps>`
  max-width: ${({ width = '23px' }) => width};

  #reverse {
    transform: ${({ mirror }) => mirror && 'translate(1.719551px, 1px)'};
  }

  ${teethStyle}
  ${getIsLowerHookStyle(true)}
`

export {
  Upper1_16,
  Upper2_15,
  Upper3_14,
  Upper4_13,
  Upper5_12,
  Upper6_11,
  Upper7_10,
  Upper8_9,
  Lower17_32,
  Lower18_31,
  Lower19_30,
  Lower20_29,
  Lower21_28,
  Lower22_27,
  Lower23_26,
  Lower24_25,
}
