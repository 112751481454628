import {
  caseSubmittedModalActions,
  caseSubmittedModalSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { getCaseDraftUpdateUrl } from 'app/core/net/urls'
import { ALIGNER_WEB_URL } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { of } from 'rxjs'
import { filter, ignoreElements, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import URIJS from 'urijs'

const parseParamsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([caseSubmittedModalActions.caseSubmittedModalMounted])),
    switchMap(() => {
      const { printCaseId, isSecondary } = parseQuery<{
        printCaseId: string
        isSecondary?: string
      }>(window.location.search)
      let caseId = null
      let isOpen = false
      let secondary = false

      if (printCaseId) {
        caseId = printCaseId
        isOpen = true
      }
      if (isSecondary === 'true') {
        secondary = true
      }

      const uri = new URIJS(window.location.href)

      uri.removeSearch('printCaseId').removeSearch('isSecondary')
      window.history.pushState(null, '', uri.valueOf())

      return of(
        caseSubmittedModalActions.urlParamsParsed({
          caseId,
          isSecondary: secondary,
          isOpen,
        }),
      )
    }),
  )

const goToPrintPage = (caseId: string, isSecondary: boolean) => () =>
  window.open(
    `${ALIGNER_WEB_URL}/${
      isSecondary ? 'secondaryPrintCaseSubmissionForm' : 'printCaseSubmissionForm'
    }?caseId=${caseId}`,
    `${isSecondary ? 'SecondaryPrescription' : 'PrimaryPrescription'}`,
    'noopener,scrollbars=yes,resizable=yes',
  )

const goToPrintPageEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseSubmittedModalActions.goToPrintPageClicked])),
    tap(() => {
      const isSecondary = caseSubmittedModalSelectors.isSecondary()(state$.value)
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)
      const username = appSelectors.getUsername()(state$.value)

      if (caseId) {
        axios
          .get(`/api/v1/doctors/${username}/cases/${caseId}`)
          .then(goToPrintPage(caseId, isSecondary))
      }
    }),
    ignoreElements(),
  )

const saveAndGoToPrintPageEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseSubmittedModalActions.saveAndGoToPrintPageEpic])),
    filter(() => caseWizardSelectors.getDraftId()(state$.value) !== undefined),
    tap(() => {
      const isSecondary = caseSubmittedModalSelectors.isSecondary()(state$.value)
      const draft = caseWizardSelectors.getDraftData()(state$.value)
      const username = appSelectors.getUsername()(state$.value)
      const id = caseWizardSelectors.getDraftId()(state$.value)
      const url = getCaseDraftUpdateUrl(id!, username, draft.patientDetails.treatmentType)

      axios.put(url, draft).then(goToPrintPage(id!, isSecondary))
    }),
    ignoreElements(),
  )

const caseSubmittedEpic = combineEpics(parseParamsEpic, goToPrintPageEpic, saveAndGoToPrintPageEpic)

export { caseSubmittedEpic }
