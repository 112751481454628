import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

const MyProfileDescription: React.FC = () => (
  <Box marginTop="60px" marginRight="auto" display="block" width="512px" height="32px">
    <Typography
      data-testid="MyProfile-Title"
      component="h1"
      variant="h2"
      textFontWeight="600"
      letterSpacing="0.25px"
    >
      <FormattedMessage id="preferences.title" />
    </Typography>
  </Box>
)

export { MyProfileDescription }
