import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { useDidMount } from 'app/core/react/CustomHooks'

interface ScanPdfModalProps {
  isOpen: boolean
  onClose: () => void
  scanPdfUrl?: string
  selectedScanType?: string
  isDirectTransferPdf?: boolean
}

const ScanPdfModal: React.FC<ScanPdfModalProps> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { scanPdfUrl, selectedScanType, isDirectTransferPdf } = props
  const scanGuidePdfUrl = useSelector(editScansSelectors.getScanPdfUrl())
  const directTransferPdfUrl = useSelector(editScansSelectors.getDirectTransferPdfUrl())
  const getScanPdfUrl = isDirectTransferPdf ? directTransferPdfUrl : scanGuidePdfUrl
  const hasError = getScanPdfUrl?.status === ResponseStatus.BAD_REQUEST
  const pdfFileNameArray =
    selectedScanType && getScanPdfUrl && getScanPdfUrl.payload
      ? getScanPdfUrl.payload.split(`${selectedScanType}/`)
      : ''

  const fileName =
    pdfFileNameArray && pdfFileNameArray.length > 1
      ? decodeURIComponent(pdfFileNameArray[1].split('?')[0])
      : ''

  useDidMount(() => {
    if (isDirectTransferPdf) {
      dispatch(editScansActions.getDirectTransferPdfRequested({ scanPdfUrl }))
    } else {
      dispatch(editScansActions.getScanPdfRequested({ scanPdfUrl }))
    }

    return () => {
      if (isDirectTransferPdf) {
        dispatch(editScansActions.getDirectTransferPdfUnmounted())
      } else {
        dispatch(editScansActions.getScanPdfUnmounted())
      }
    }
  })

  return (
    <OrmcoModal
      isOpen={props.isOpen}
      data-testid="ScanPdfModal-OrmcoModal"
      additionalStyles={{ content: { width: '900px' } }}
    >
      <OrmcoModalHeader flexDirection="row">
        {fileName && <OrmcoModalHeaderText variant="h5" headerId="scans.modal.title" />}

        <Button type="text" onClick={props.onClose} data-testid="ScanPdfModal-Close">
          <Cancel width="14px" />
        </Button>
      </OrmcoModalHeader>
      <OrmcoModalBody>
        {!hasError ? (
          <iframe
            title={intl.formatMessage({ id: 'scans.modal.title' })}
            src={getScanPdfUrl?.payload}
            width="100%"
            height="600px"
            frameBorder="0"
            data-testid="Modal-Scans-DocumentIframe"
          />
        ) : (
          <Typography>
            <FormattedMessage id={getScanPdfUrl?.message} />
          </Typography>
        )}
      </OrmcoModalBody>
    </OrmcoModal>
  )
}

export { ScanPdfModal }
