import { Badge } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/interfaces/Badge'
import {
  SapphireI,
  SapphireII,
  SapphireIII,
  SapphirePlus,
  SapphireElite,
  USPerksInsider,
  USPerksVIP,
  USPerksElite,
  Pinnacle,
} from 'app/components/ui/Icons/common'

const iconMap = {
  [Badge.SAPPHIRE_I]: SapphireI,
  [Badge.SAPPHIRE_II]: SapphireII,
  [Badge.SAPPHIRE_III]: SapphireIII,
  [Badge.SAPPHIRE_PLUS]: SapphirePlus,
  [Badge.SAPPHIRE_ELITE]: SapphireElite,
  [Badge.INSIDER]: USPerksInsider,
  [Badge.VIP]: USPerksVIP,
  [Badge.ELITE]: USPerksElite,
  [Badge.PINNACLE]: Pinnacle,
} as const

export { iconMap }
