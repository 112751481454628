import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { LightBlueButton } from 'app/components/ui/Buttons'
import { OrmcoInput } from 'app/components/ui/Form/OrmcoInput'
import { ArrowRight, ArrowLeft } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import styled, { useTheme } from 'styled-components'

import { Typography } from './SDS/common/Typography'

interface PaginationProps {
  showingAmount: number
  totalAmount: number
  pageNum: number
  setPageNum: (pageNum: number) => void
  'data-testid': string
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { showingAmount, totalAmount, pageNum, setPageNum, 'data-testid': testId } = props
  const theme = useTheme()
  const maxPage = Math.ceil(totalAmount / showingAmount) - 1

  const goPrevPage = useCallback(() => {
    if (pageNum > 0) {
      setPageNum(pageNum - 1)
    }
  }, [pageNum, setPageNum])

  const goNextPage = useCallback(() => {
    if (pageNum < maxPage) {
      setPageNum(pageNum + 1)
    }
  }, [pageNum, setPageNum, maxPage])

  if (!totalAmount) {
    return null
  }

  return (
    <Box marginTop="16px" data-testid={testId} justify="space-between">
      <Typography data-testid={`${testId}-PaginationDesc`} component="label">
        <FormattedMessage
          id="3shape.modal.pagination"
          values={{
            showing: showingAmount < totalAmount ? showingAmount : totalAmount,
            total: totalAmount,
          }}
        />
      </Typography>

      <Box justify="flex-end">
        <LightBlueButton
          as="button"
          onClick={goPrevPage}
          disabled={pageNum <= 0}
          data-testid={`${testId}-ButtonLeft`}
        >
          <ArrowLeft width="10px" fill={theme.colors.primary800} />
        </LightBlueButton>

        <Box margin="0 4px" width="80px">
          <PaginationInput data-testid={`${testId}-CurrentPage`} value={pageNum + 1} readOnly />
        </Box>

        <LightBlueButton
          as="button"
          onClick={goNextPage}
          disabled={pageNum >= maxPage}
          data-testid={`${testId}-ButtonRight`}
        >
          <ArrowRight width="10" fill={theme.colors.primary800} />
        </LightBlueButton>
      </Box>
    </Box>
  )
}

export { Pagination }

const PaginationInput = styled(OrmcoInput)`
  text-align: center;
`
