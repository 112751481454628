import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { truGenXRModalActions } from './logic'

const saveTruGenPopupsState: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(truGenXRModalActions.trugenPopupsDontShowUpdateRequested)),
    switchMap((action) => {
      const username = appSelectors.getUsername()(state$.value)
      const popupsDisplayState = action.payload

      return from(
        axios.put(
          `/api/v1/doctors/${username}/showPrimaryBrandingPopups?selection=${popupsDisplayState}`,
        ),
      ).pipe(
        switchMap((res) => {
          if (res?.data) {
            return of(truGenXRModalActions.trugenPopupsDontShowUpdateFinished())
          } else {
            return of(truGenXRModalActions.trugenPopupsDontShowUpdateFailed())
          }
        }),
        catchError(() => of(truGenXRModalActions.trugenPopupsDontShowUpdateFailed())),
      )
    }),
  )

const getTruGenXRGifEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([truGenXRModalActions.primaryTruGenXRGifRequested])),
    switchMap(() =>
      from(
        axios
          .get('/api/v1/webapi/staticS3Images/trugenXR')
          .then((res) => truGenXRModalActions.primaryTruGenXRGifReceived(res.data))
          .catch(() => truGenXRModalActions.primaryTruGenXRGifFailed()),
      ),
    ),
  )

const truGenXRSelectModalEpic = combineEpics(getTruGenXRGifEpic, saveTruGenPopupsState)

export { truGenXRSelectModalEpic }
