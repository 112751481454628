import { useFormContext } from 'react-hook-form'

import { Arch, getArchTeethNumbers, Universal } from 'app/core/domain/ArchTools'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { addOrRemoveFromArray } from 'app/core/lang/ArrayUtils'
import { useEffectSomeDepsChange } from 'app/core/react/CustomHooks'

import { ActivitySelection } from './ActiveAuxiliary/ActivitySelection'
import { useAvailableAlignersAuxiliaries } from './TeethPanel/useAvailableAlignersAuxiliaries'

export const useDefaultTeethSectionValues = () => {
  const form = useFormContext<CaseDraft>()

  const {
    primaryCasePreferences: { treatArches },
  } = form.getValues()

  const availableAuxiliaries = useAvailableAlignersAuxiliaries()

  useEffectSomeDepsChange(() => {
    const removeTeethFromAux = (
      teeth: Array<Universal>,
      selection: ActivitySelection,
      arches?: TreatArches,
    ) => {
      let selectedArchTeethNumbers: Array<Universal> = []

      if (arches === TreatArches.LOWER_ONLY) {
        selectedArchTeethNumbers = getArchTeethNumbers(Arch.LOWER)
      }
      if (arches === TreatArches.UPPER_ONLY) {
        selectedArchTeethNumbers = getArchTeethNumbers(Arch.UPPER)
      }

      const teethToRemove: Array<Universal> = []

      if (teeth) {
        teeth.forEach((n) => {
          if (!selectedArchTeethNumbers.includes(n)) {
            teethToRemove.push(n)
          }
        })

        const res = addOrRemoveFromArray(teethToRemove, teeth)

        form.setValue(`primaryCasePreferences.${selection}`, res)
      }
    }

    if (treatArches !== TreatArches.BOTH) {
      availableAuxiliaries.forEach(({ arr, type }) => removeTeethFromAux(arr, type, treatArches))
    }
  }, [treatArches])
}
