import React from 'react'

import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { Box } from 'app/components/ui/SDS/common/Box'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'

interface AddressSummaryIconContainerProps {
  titleId: string
  children: React.ReactNode
}

const AddressSummaryIconContainer: React.FC<AddressSummaryIconContainerProps> = (props) => {
  return (
    <OrmcoTooltip
      title={<TooltipText data-testid="AddressSummary-TooltipContent" textId={props.titleId} />}
      placement="top"
      overlayInnerStyle={{
        padding: '11px',
        textAlign: 'justify',
      }}
    >
      <Box data-testid="AddressSummary-TooltipIcon" padding="0 4px" width="max-content">
        {props.children}
      </Box>
    </OrmcoTooltip>
  )
}

export { AddressSummaryIconContainer }
