import React, { useEffect, useState, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Divider } from 'antd'
import { AddressDetails } from 'app/components/routes/MyProfile/AddressDetails/AddressDetails'
import { ChangePasswordModal } from 'app/components/routes/MyProfile/ChangePasswordModal/ChangePasswordModal'
import {
  changePasswordModalActions,
  changePasswordModalSelectors,
} from 'app/components/routes/MyProfile/ChangePasswordModal/logic'
import { ContactDetails } from 'app/components/routes/MyProfile/ContactDetails/ContactDetails'
import { ErrorModal } from 'app/components/routes/MyProfile/ErrorModal'
import { MyProfileDescription } from 'app/components/routes/MyProfile/MyProfileDescription'
import { PersonalDetails } from 'app/components/routes/MyProfile/PersonalDetails/PersonalDetails'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Notification } from 'app/components/ui/notification/Notification'
import { NotificationPosition } from 'app/components/ui/notification/NotificationPosition'
import { notificationActions, notificationSelectors } from 'app/components/ui/notification/logic'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { EmployeeProfileFields } from 'app/core/domain/EmployeeProfile'
import { NotificationType } from 'app/core/domain/NotificationType'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { useDidMount } from 'app/core/react/CustomHooks'
import { appActions, appSelectors } from 'app/logic/app/logic'
import scrollIntoView from 'scroll-into-view-if-needed'
import styled from 'styled-components'

const DEFAULT_ERROR_MODAL_STATE = {
  isOpen: false,
  headerText: '',
  bodyText: '',
}

const MyProfile: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const location = useLocation()
  const isOpen = useSelector(notificationSelectors.isOpen())
  const passwordUpdateResult = useSelector(changePasswordModalSelectors.getPasswordUpdateResult())
  const profileDataReceived = useSelector(appSelectors.isProfileDataReceived())
  const profileUpdateResult = useSelector(appSelectors.getEmployeeUpdateResult())
  const { firstName, middleName, lastName } = useSelector(appSelectors.getEmployeePersonalDetails())
  const { phone, email, receiveEmail, websiteUrl } = useSelector(
    appSelectors.getEmployeeContactDetails(),
  )
  const [errorModal, setErrorModal] = useState(DEFAULT_ERROR_MODAL_STATE)

  const form = useForm<EmployeeProfileFields>({
    mode: 'onChange',
  })
  const addressSection = useRef<HTMLDivElement>(null)

  useDidMount(() => {
    const { scrollTo } = parseQuery<{ scrollTo?: string }>(location.search)

    if (addressSection.current !== null && scrollTo === 'address') {
      scrollIntoView(addressSection.current, { behavior: 'smooth' })
    } else {
      window.scrollTo(0, 0)
    }
  })

  useDidMount(() => {
    if (!profileDataReceived) {
      dispatch(appActions.myProfileMounted())
    }

    form.reset()
    const { notification } = parseQuery<{ notification?: string }>(location.search)

    if (notification === 'deleteAddressSuccess') {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'delete.address.success',
          type: NotificationType.error,
        }),
      )
      if (addressSection.current !== null) {
        scrollIntoView(addressSection.current, { behavior: 'smooth' })
      }
    }
    return () => {
      dispatch(notificationActions.notificationUnMounted())
    }
  })

  useEffect(() => {
    if (passwordUpdateResult && passwordUpdateResult.status === ResponseStatus.OK) {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: passwordUpdateResult.message,
          type: NotificationType.info,
        }),
      )
      dispatch(changePasswordModalActions.passwordDetailsUnmounted())
    }

    if (!form.formState.isDirty && profileDataReceived) {
      form.setValue('firstName', firstName)
      form.setValue('middleName', middleName)
      form.setValue('lastName', lastName)
      form.setValue('phone', phone)
      form.setValue('email', email)
      form.setValue('receiveEmail', receiveEmail)
      form.setValue('websiteUrl', websiteUrl)
    }
  }, [
    dispatch,
    passwordUpdateResult,
    form,
    firstName,
    middleName,
    lastName,
    phone,
    email,
    receiveEmail,
    profileDataReceived,
    websiteUrl,
  ])

  useEffect(() => {
    if (profileUpdateResult && profileUpdateResult.status !== ResponseStatus.OK) {
      setErrorModal({
        isOpen: true,
        headerText: intl.formatMessage({
          id: 'profile.update.error.title',
        }),
        bodyText: intl.formatMessage({
          id: 'profile.update.error.message',
        }),
      })

      dispatch(appActions.saveEmployeeDetailsUnmounted())
    }
  }, [dispatch, intl, profileUpdateResult])

  return (
    <MyProfileContainer
      paddingBottom="60px"
      flexDirection="column"
      className="MyProfileClass"
      data-testid="MyProfile"
    >
      {isOpen && <Notification notificationPosition={NotificationPosition.FIXED} />}
      <MyProfileForm name="myProfileForm" data-testid="MyProfile-Form">
        <FormProvider {...form}>
          <MyProfileDescription />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <PersonalDetails />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <ContactDetails />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <div ref={addressSection}>
            <AddressDetails />
          </div>
        </FormProvider>
        <ChangePasswordModal />
      </MyProfileForm>

      <ErrorModal
        isOpen={errorModal.isOpen}
        onClose={() => {
          setErrorModal(DEFAULT_ERROR_MODAL_STATE)
          setTimeout(() => window.location.reload(), 0)
        }}
        headerText={errorModal.headerText}
        bodyText={errorModal.bodyText}
      />
    </MyProfileContainer>
  )
}

const MyProfileContainer = styled(Box)`
  .ant-alert-info {
    .ant-alert-message {
      color: ${({ theme }) => theme.colors.primary800};
    }

    .ant-alert-close-icon {
      top: 8px;

      .anticon-close {
        color: ${({ theme }) => theme.colors.primary800};
      }
    }
  }
`

const MyProfileForm = styled.form`
  display: block;

  box-sizing: border-box;
  max-width: 850px;
`

const DividerContainer = styled.div`
  margin-top: 10px;
`

export { MyProfile }
