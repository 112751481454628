import { PropsWithChildren } from 'react'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Nullable } from 'app/core/types/utils'
import styled from 'styled-components'

import { Box } from '../SDS/common/Box'
import { BoxProps } from '../SDS/common/Box/interfaces'
import { Typography } from '../SDS/common/Typography'

interface ValidationContainerProps extends BoxProps {
  'data-testid': string
  messagePadding?: string
  error?: Nullable<FieldError>
}

interface ErrorContainerProps {
  hasError: boolean
}

export const ValidationFieldContainer = ({
  flexDirection = 'column',
  justify = 'flex-start',
  align = 'flex-start',
  messagePadding,
  error,
  children,
  ['data-testid']: testId,
  ...boxProps
}: PropsWithChildren<ValidationContainerProps>) => {
  const hasError = Boolean(error && error.message)

  return (
    <StyledErrorContainer
      flexDirection={flexDirection}
      justify={justify}
      align={align}
      hasError={hasError}
      data-testid={testId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      {children}
      {hasError && (
        <Typography
          marginTop="8px"
          padding={messagePadding}
          data-testid={`${testId}-ErrorMessage`}
          variant="small"
          color="danger500"
        >
          <FormattedMessage id={error!.message} />
        </Typography>
      )}
    </StyledErrorContainer>
  )
}

const StyledErrorContainer = styled(Box)<ErrorContainerProps>`
  background: ${({ theme, hasError, backgroundColor }) => {
    if (hasError) {
      return theme.colors.danger10
    }

    if (backgroundColor) {
      return theme.colors.base100
    }

    return 'none'
  }};

  border-radius: 0 0 4px 4px;
`
