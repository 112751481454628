import React from 'react'
import { useDispatch } from 'react-redux'

import { caseListActions } from 'app/components/routes/CaseList/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { SdsDeliveryFilled } from 'app/components/ui/Icons/sds'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case } from 'app/core/domain/Case'

const TrackButton: React.FC<{ caseData: Case }> = ({ caseData, children }) => {
  const dispatch = useDispatch()
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(caseListActions.trackCaseButtonClicked({ case: caseData }))
  }

  return (
    <ActionButton data-testid={`TrackButton-${caseData.caseId}`} onClick={onClick} height="18px">
      <SdsDeliveryFilled width="12px" color="success300" />
      <Typography textOverflow="ellipsis" color="base700">
        {children}
      </Typography>
    </ActionButton>
  )
}

export { TrackButton }
