import { getStyledConfig } from 'app/core/react/getStyledConfig'
import styled from 'styled-components'

interface ActionButtonProps {
  height?: string
  padding?: string
}

const ActionButton = styled.button.withConfig<ActionButtonProps>(getStyledConfig('padding'))`
  padding: ${({ padding }) => padding ?? '0 4px 0px 4px'};

  cursor: pointer;

  overflow: hidden;
  flex-direction: row;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 5px;

  max-width: 142px;
  height: ${({ height }) => height ?? '21px'};
  border: 0;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.base700};
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.base10} 0%, ${theme.colors.base200} 100%)`};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  :hover {
    background: ${({ theme }) => theme.colors.white};
  }
  :active {
    background: ${({ theme }) => theme.colors.base200};
  }
  a {
    white-space: nowrap;
    text-decoration: none;

    color: ${({ theme }) => theme.colors.base700};
    :focus {
      text-decoration: none;
    }
  }

  & + & {
    margin-left: 4px;
  }
`

export { ActionButton }
