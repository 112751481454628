import React from 'react'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Select } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Doctor } from 'app/core/domain/Doctor'
import styled from 'styled-components'

interface StaffSelectProps {
  userList: Array<Doctor>
  defaultValue?: string[]
  disabled?: boolean
  'data-testid': string
}

export const StaffSelect: React.FC<StaffSelectProps> = (props) => {
  const intl = useIntl()
  const { Option } = Select

  const options = props.userList.map((user: Doctor) => (
    <Option key={user.username} value={user.username}>
      {user.name}
    </Option>
  ))

  return (
    <Controller
      render={({ field }) => (
        <SelectContainer>
          <Select
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            // @ts-ignore
            filterOption={(input, option) =>
              option !== undefined
                ? option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
            dropdownRender={(menu) => menu}
            mode="multiple"
            notFoundContent={
              <FormattedMessage id="staff.staffDetails.associatedDoctors.no.results" />
            }
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            placeholder={intl.formatMessage({
              id: 'staff.staffDetails.associatedDoctors.placeholder',
            })}
            data-testid={props['data-testid']}
          >
            {options}
          </Select>
        </SelectContainer>
      )}
      name="canManageCasesOf"
    />
  )
}

const SelectContainer = styled(Box)`
  &&& {
    font-size: 13px;
    font-weight: 500;

    &.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
      margin: 0;
      padding: 0;

      width: 0;
      border: 0;

      font-size: 0;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
    &.ant-select-selector {
      border-color: ${({ theme }) => theme.colors.primary800};

      border-radius: 4px;
    }

    &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 41px;
    }

    &.ant-select-open {
      z-index: ${({ theme }) => theme.zIndex.dropdown};
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid ${({ theme }) => theme.colors.primary600};

      border-radius: 4px;
    }

    &.ant-select-selection-item {
      margin: 3px;
      padding: 1px;

      height: 28px;
    }

    .ant-select-selection-item-remove {
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-select-item {
      letter-spacing: 0.1px;

      font-size: 13px;
      line-height: 20px;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      font-weight: 500;
    }

    .anticon-check {
      padding-top: 3px;

      width: 16px;
      height: 16px;

      font-size: 10px;

      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary800};
      border-radius: 8px;
    }

    .ant-select-item-empty {
      text-align: center;

      font-weight: 500;

      color: ${({ theme }) => theme.colors.base700};
    }

    .ant-select-selector {
      height: 100%;
      min-height: 41px;
    }
  }
`
