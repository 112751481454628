import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

export interface AmazonStaticImagePayload {
  method: string
  url?: string
}

interface IntegratedHooksModalState {
  isIntegratedHooksPopupOpen: boolean
  integratedHooksImageURL: Nullable<string>
}

const INITIAL_STATE = {
  isIntegratedHooksPopupOpen: false,
  integratedHooksImageURL: null,
}

const integratedHooksModalActions = {
  integratedHooksModalUnmounted: createAction('@INTEGRATED_HOOKS_MODAL/MODAL_UNMOUNTED')(),
  toggleIntegratedHooksModal: createAction('@INTEGRATED_HOOKS_MODAL/TOGGLE')<boolean>(),

  integratedHooksModalImageRequested: createAction('@INTEGRATED_HOOKS_MODAL/IMAGE_REQUESTED')(),
  integratedHooksModalImageRequestFinished: createAction(
    '@INTEGRATED_HOOKS_MODAL/IMAGE_REQUEST_FINISHED',
  )<AmazonStaticImagePayload>(),
  integratedHooksModalImageRequestFailed: createAction(
    '@INTEGRATED_HOOKS_MODAL/IMAGE_REQUEST_FAILED',
  )(),

  integratedHooksModalDontShowUpdateRequested: createAction(
    '@INTEGRATED_HOOKS_MODAL/DONT_SHOW_UPDATE_REQUESTED',
  )<boolean>(),
  integratedHooksModalDontShowUpdateFinished: createAction(
    '@INTEGRATED_HOOKS_MODAL/DONT_SHOW_UPDATE_FINISHED',
  )(),
  integratedHooksModalDontShowUpdateFailed: createAction(
    '@INTEGRATED_HOOKS_MODAL/DONT_SHOW_UPDATE_FAILED',
  )(),
}

type IntegratedHooksModalActions = ActionType<typeof integratedHooksModalActions>
const integratedHooksModalReducer = createReducer<
  IntegratedHooksModalState,
  IntegratedHooksModalActions
>(INITIAL_STATE)
  .handleAction([integratedHooksModalActions.toggleIntegratedHooksModal], (state, action) => ({
    ...state,
    isIntegratedHooksPopupOpen: action.payload,
  }))
  .handleAction(
    [integratedHooksModalActions.integratedHooksModalImageRequestFinished],
    (state, action) => ({
      ...state,
      integratedHooksImageURL: action.payload?.url ? action.payload.url : null,
    }),
  )
  .handleAction([integratedHooksModalActions.integratedHooksModalUnmounted], (state) => ({
    ...state,
    integratedHooksImageURL: null,
  }))

const integratedHooksModalSelectors = {
  getIntegratedHooksModalState: () => (state: RootState) => ({
    isOpen: state.integratedHooksModal.isIntegratedHooksPopupOpen,
    imageUrl: state.integratedHooksModal.integratedHooksImageURL,
  }),
}

export {
  IntegratedHooksModalState,
  IntegratedHooksModalActions,
  integratedHooksModalActions,
  integratedHooksModalSelectors,
  integratedHooksModalReducer,
}
