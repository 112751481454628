import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import { NavigationContent } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationContent/NavigationContent'
import {
  StyledNavigationBackgroundMixin,
  StyledNavigationMixin,
} from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/StyledNavigationMixin'
import { Box } from 'app/components/ui/SDS/common/Box'
import _ from 'lodash'
import styled from 'styled-components'

interface NavigationDropdownProps {
  icon?: JSX.Element
  titleTextId: string
  rawTitle?: boolean
  'data-testid': string
}

const getHrefsFromItems = (items: React.ReactNode[]): string[] =>
  items.map((item: any) => item.props && item.props.href).filter((href) => href) as string[]

const NavigationDropdown: React.FC<NavigationDropdownProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const items = getHrefsFromItems(React.Children.toArray(props.children))
  const isActive = isOpen || _.some(items, (ref) => ref === window.location.pathname)

  return (
    <StyledNavigationDropdown
      isActive={isActive}
      onClick={() => setIsOpen(!isOpen)}
      width="auto"
      maxHeight="54px"
      justify="flex-start"
      data-testid={props['data-testid']}
    >
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        <NavigationContent
          data-testid={props['data-testid']}
          icon={props.icon}
          textId={props.titleTextId}
          rawValue={props.rawTitle}
          carret
        />
        {isOpen && (
          <DropdownItemsContainer padding="4px 0" display="block" width="auto" minWidth="160px">
            {props.children}
          </DropdownItemsContainer>
        )}
      </OutsideClickHandler>
    </StyledNavigationDropdown>
  )
}

const StyledNavigationDropdown = styled(Box)`
  position: relative;

  ${StyledNavigationMixin};
  ${StyledNavigationBackgroundMixin};
`

const DropdownItemsContainer = styled(Box)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.headerDropdowns};

  border: 1px solid ${({ theme }) => theme.colors.primary800};

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

export { NavigationDropdown }
