import React from 'react'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

const DoctorNameCard: React.FC<{ data: CaseTableData }> = (props) => {
  const { doctor } = props.data.case

  return (
    <DoctorNameCardContainer
      title={doctor.name}
      justify="flex-start"
      maxWidth="122px"
      paddingLeft="10px"
    >
      <Typography data-testid="Case-DoctorName" component="span" whiteSpace="nowrap">
        {doctor.name}
      </Typography>
    </DoctorNameCardContainer>
  )
}

const DoctorNameCardContainer = styled(Box)`
  overflow: hidden;
`

export { DoctorNameCard }
