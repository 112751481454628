import React from 'react'

import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/es/tooltip'

type OrmcoTooltipProps = TooltipProps & {
  optional?: boolean
  id?: string
  'data-testid'?: string
}

const OrmcoTooltip: React.FC<OrmcoTooltipProps> = (props) => {
  const defaultAndMergedProps = {
    ...props,
    overlayStyle: props.overlayStyle || { maxWidth: 420 },
    placement: props.placement || 'topLeft',
    color: props.color || 'rgba(0,24,43,0.9)',
    trigger: props.trigger || 'hover',
  }

  return <Tooltip {...defaultAndMergedProps}>{props.children}</Tooltip>
}

export { OrmcoTooltipProps, OrmcoTooltip }
