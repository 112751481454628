import { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import { getConverterToTeethSystem, Universal } from 'app/core/domain/ArchTools'
import { appSelectors } from 'app/logic/app/logic'

const TeethNums: FC<{
  'data-testid': string
  arr?: Array<Universal>
  divider?: ReactNode
}> = ({ arr, divider, 'data-testid': dataTestId }) => {
  const toothSystem = useSelector(appSelectors.getToothNumberingSystem())
  const convert = getConverterToTeethSystem(toothSystem)

  return (
    <>
      {arr && arr.length > 0 ? (
        arr.map((num, index) => {
          const isNotLastItem = index < arr.length - 1

          return (
            <Typography
              data-testid={`${dataTestId}-teethNumber--${num}`}
              key={`teethNumber--${num}`}
              component="span"
              color="base900"
              padding="0 3px"
            >
              {convert(num)}
              {isNotLastItem ? divider : ' '}
            </Typography>
          )
        })
      ) : (
        <Typography color="base900" textFontWeight="600" padding="0 3px">
          <FormattedMessage id="caseWizard.casePrescription.auxiliaries.none" />
        </Typography>
      )}
    </>
  )
}

export { TeethNums }
