import React, { useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

import { staffListActions, staffListSelectors } from './logic'

interface TableBodyProps {
  length: number
}

const StaffTableBody: React.FC<TableBodyProps> = (props) => {
  const { length } = props
  const dispatch = useDispatch()
  const hasMore = useSelector(staffListSelectors.hasMoreStaffList())
  const isLoading = useSelector(staffListSelectors.isStaffListLoading())
  const isSearch = useSelector(staffListSelectors.isSearchFilterUsed())
  const height = '80vh'
  const isSearchResult = isSearch ? (
    <FormattedMessage id="cases.list.empty" />
  ) : (
    <FormattedMessage id="staff.teamManagement.empty.placeholder" />
  )
  const IsLoadingResult = isLoading ? (
    <Loader />
  ) : (
    <Typography color="base600">{isSearchResult}</Typography>
  )
  const loadMoreCallback = useCallback(() => {
    dispatch(staffListActions.loadMoreStaffReached())
  }, [dispatch])

  return (
    <TableRowsContainer display="block">
      {length ? (
        <InfiniteScroll
          dataLength={length}
          next={loadMoreCallback}
          hasMore={hasMore}
          loader={<TableLoader />}
          height={height}
          style={{
            maxHeight: '75vh',
            minHeight: '65vh',
            overflow: 'overlay',
          }}
          hasChildren
        >
          {props.children}
        </InfiniteScroll>
      ) : (
        <Box height="65vh">{IsLoadingResult}</Box>
      )}
    </TableRowsContainer>
  )
}

const TableRowsContainer = styled(Box)`
  overflow: auto;
`

const TableLoader: React.FC = () => (
  <Box>
    <Loader />
  </Box>
)

export { StaffTableBody }
