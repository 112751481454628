import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface StaffDeleteModalState {
  isOpen: boolean
}

const INITIAL_STATE: StaffDeleteModalState = {
  isOpen: false,
}

const staffDeleteModalActions = {
  staffDeleteModalMounted: createAction('@STAFF_DELETE_MODAL/STAFF_DELETE_MODAL_MOUNTED')(),
  staffDeletedModalTriggered: createAction('@STAFF_DELETE_MODAL/STAFF_DELETE_MODAL_TRIGGERED')(),

  closeModalClicked: createAction('@STAFF_DELETE_MODAL/CLOSE_MODAL_CLICKED')(),
}

type StaffDeleteModalActions = ActionType<typeof staffDeleteModalActions>

const staffDeleteModalReducer = createReducer<StaffDeleteModalState, StaffDeleteModalActions>(
  INITIAL_STATE,
)
  .handleAction(staffDeleteModalActions.staffDeletedModalTriggered, (state) => ({
    ...state,
    isOpen: true,
  }))
  .handleAction(
    [staffDeleteModalActions.closeModalClicked, staffDeleteModalActions.staffDeleteModalMounted],
    (state) => ({
      ...state,
      isOpen: false,
    }),
  )

const staffDeleteModalSelectors = {
  isOpen: () => (state: RootState) => state.staffDeleteModal.isOpen,
}

export {
  StaffDeleteModalState,
  StaffDeleteModalActions,
  staffDeleteModalActions,
  staffDeleteModalReducer,
  staffDeleteModalSelectors,
}
