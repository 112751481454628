enum MidlineActionPrimary {
  'MAINTAIN' = 'MAINTAIN',
  'CORRECT' = 'CORRECT',
}

enum DirectionPrimary {
  'UPPER_TO_LOWER' = 'UPPER_TO_LOWER',
  'LOWER_TO_UPPER' = 'LOWER_TO_UPPER',
  'MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE' = 'MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE',
}

enum MidlineDistance {
  'RIGHT_ONE_TO_TWO_MM' = 'RIGHT_ONE_TO_TWO_MM',
  'LEFT_ONE_TO_TWO_MM' = 'LEFT_ONE_TO_TWO_MM',
  'RIGHT_TWO_PLUS_MM' = 'RIGHT_TWO_PLUS_MM',
  'LEFT_TWO_PLUS_MM' = 'LEFT_TWO_PLUS_MM',
  'MAINTAIN' = 'MAINTAIN',
}

interface MidlineV5Preferences {
  distancePrimaryLower?: MidlineDistance
  distancePrimaryUpper?: MidlineDistance
  moveSpecificAmounts?: boolean
}

interface MidlinePreferencesPrimary extends MidlineV5Preferences {
  actionPrimary: MidlineActionPrimary
  directionPrimary: DirectionPrimary
  goodQualityPhotoProvided?: boolean
}

export { MidlinePreferencesPrimary, MidlineDistance, DirectionPrimary, MidlineActionPrimary }
