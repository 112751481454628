interface GetCloudFrontScansPropValueProps {
  selectedScanType: string
  cloudFrontScans: Record<string, string>
  propName: string
}

const SCANNER_PROPERTY_INDEX = 1

export const getCloudFrontScansPropValue = ({
  selectedScanType,
  cloudFrontScans,
  propName,
}: GetCloudFrontScansPropValueProps) => {
  const cloudFrontScansPropArray =
    selectedScanType && selectedScanType.length
      ? Object.entries(cloudFrontScans).find(([key]) => key === propName + selectedScanType)
      : []

  return cloudFrontScansPropArray && cloudFrontScansPropArray.length
    ? cloudFrontScansPropArray[SCANNER_PROPERTY_INDEX]
    : ''
}
