import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { AssociatedUsersTooltip } from 'app/components/routes/Staff/AssociatedUsersTooltip'
import { StaffSelect } from 'app/components/routes/Staff/StaffSelect'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import styled from 'styled-components'

import { doctorDetailsSelectors, doctorDetailsActions } from './logic'
const AssociatedStaff = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const label = intl.formatMessage({ id: 'staff.doctorDetails.associatedStaff.label' })
  const { setValue } = useFormContext<PersonalInformation>()
  const activeStaff = useSelector(doctorDetailsSelectors.getActiveStaff())
  const allActiveStaffSelected = useSelector(doctorDetailsSelectors.getAllActiveStaffSelected())

  const changeAllStaffSelection = useCallback(() => {
    const manageStaff = !allActiveStaffSelected ? activeStaff.map((user) => user.username) : []

    setValue('canManageCasesOf', manageStaff)
    dispatch(doctorDetailsActions.associateAllStaffFlagChanged())
  }, [activeStaff, allActiveStaffSelected, dispatch, setValue])

  return (
    <StaffFormItemVertical
      colon={false}
      label={
        <Box as="span" display="inline-flex" justify="flex-start">
          <Typography component="label" textFontWeight="600">
            {label}
          </Typography>
          <AssociatedUsersTooltip />
        </Box>
      }
      marginBottom="0"
      hasFeedback
    >
      <Box flexDirection="column">
        <StaffSelect
          userList={activeStaff}
          disabled={allActiveStaffSelected}
          data-testid="DoctorDetails-AssociatedStaffSelect"
        />
        <AssociateAllStaffCheckbox
          margin="5px 0 0"
          justify="flex-start"
          data-testid="DoctorDetails-AssociateAllStaffCheckbox"
        >
          <OrmcoCheckbox onChange={changeAllStaffSelection} checked={allActiveStaffSelected}>
            <Typography component="label">
              <FormattedMessage id="staff.doctorDetails.allStaff.label" />
            </Typography>
          </OrmcoCheckbox>
        </AssociateAllStaffCheckbox>
      </Box>
    </StaffFormItemVertical>
  )
}

const AssociateAllStaffCheckbox = styled(Box)`
  .ant-checkbox-wrapper {
    margin-right: 5px;
  }

  .ant-checkbox-inner {
    opacity: 0.6;

    border: 2px solid ${({ theme }) => theme.colors.primary800};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary800};
  }
`

export { AssociatedStaff }
