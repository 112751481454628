import { Universal } from 'app/core/domain/ArchTools'
import styled from 'styled-components'

import { AuxComponent } from './AuxComponent'
import { auxiliariesStylesMap } from './auxiliariesStylesMap'

interface AlignerAuxComponentProps extends Pick<AuxComponent<string>, 'type' | 'Icon'> {
  auxIndex: number
  auxCount: number
  toothNumber: Universal
}

export const AlignerAuxComponent = (props: AlignerAuxComponentProps) => {
  const { type, toothNumber, auxCount, auxIndex, Icon } = props

  const { top, left, right, size } = auxiliariesStylesMap[toothNumber][auxCount][auxIndex]

  return (
    <IconContainer
      width={`${size}px`}
      top={top.toString(10) ? `${top.toString(10)}%` : '0'}
      left={left ? `${left}%` : undefined}
      right={right ? `${right}%` : undefined}
      data-type={type}
    >
      <Icon width={`${size}`} />
    </IconContainer>
  )
}

const IconContainer = styled.div<{ top?: string; left?: string; right?: string; width: string }>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};

  width: ${(props) => props.width};
`
