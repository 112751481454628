import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { CheckboxInput } from 'app/components/routes/MyProfile/CheckboxInput'
import { Box } from 'app/components/ui/SDS/common/Box'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

import { AddressFormLabel } from './AddressFormLabel'

const DefaultShippingAddress = () => {
  const intl = useIntl()
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  return (
    <CheckboxContainer
      marginTop="4px"
      marginBottom="12px"
      justify="flex-start"
      data-testid="AddAddress-DefaultShippingAddress"
    >
      <Controller
        name="isShipTo"
        render={({ field }) => (
          <CheckboxInput
            name={field.name}
            onChange={field.onChange}
            checked={field.value}
            disabled={featurePermissions?.disableUserProfile ?? false}
          />
        )}
      />
      <AddressFormLabel
        text={intl.formatMessage({ id: 'addresses.addressForm.setDefaultShippingAddress' })}
      />
    </CheckboxContainer>
  )
}

export { DefaultShippingAddress }

const CheckboxContainer = styled(Box)`
  .ant-checkbox-wrapper {
    margin-right: 5px;
  }

  .ant-checkbox-inner {
    border: 2px solid ${({ theme }) => theme.colors.primary800};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary800};

    ::after {
      left: 17.5%;
    }
  }
`
