import { useCallback, useMemo, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { DicomUploadStatus } from 'app/components/routes/Dicom/DicomUploadStatus'
import { PrimaryButton } from 'app/components/ui/Buttons'
import { Error } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { MultipartDicomUploader } from './MultipartDicomUploader'
import { uploadDicomActions, uploadDicomSelectors } from './logic'

interface UploadDicomParams {
  id: string
}

const UploadDicom = () => {
  const intl = useIntl()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [showUploader, setShowUploader] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const dispatch = useDispatch()
  const { id } = useParams<UploadDicomParams>()
  const selectedFile = useSelector(uploadDicomSelectors.getDicomSelectedFile())
  const errorFile = useSelector(uploadDicomSelectors.getDicomErrorFile())
  const temporaryCredentials = useSelector(uploadDicomSelectors.getDicomTempCredentials())

  const selectedFileName = useMemo(() => {
    if (selectedFile) {
      return selectedFile.name
    }

    return intl.formatMessage({ id: 'caseWizard.dicom.noFileChosen' })
  }, [intl, selectedFile])
  const multipartDicomUploader = useMemo(
    () =>
      new MultipartDicomUploader({
        credentials: temporaryCredentials,
        onProgress: setUploadProgress,
        onSuccess: () =>
          dispatch(
            uploadDicomActions.saveDicomFileRecordRequested({
              caseId: id,
              fileName: selectedFile?.name ?? `${id}.zip`,
            }),
          ),
        onError: () => setUploadError(true),
      }),
    [dispatch, id, temporaryCredentials, selectedFile],
  )

  const uploadFiles = useCallback(() => {
    if (!selectedFile) {
      return
    }

    setShowUploader(true)
    multipartDicomUploader.uploadFile(selectedFile)
  }, [multipartDicomUploader, selectedFile])

  const updateDicomFileUploadStatus = useCallback(() => {
    if (localStorage.getItem(`dicom-${id}`) !== DicomUploadStatus.UPLOAD_COMPLETE) {
      localStorage.removeItem(`dicom-${id}`)
    }
  }, [id])

  const fileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const regex = new RegExp(/\.zip$/i)
      const file = event.target.files?.[0]

      if (file) {
        if (regex.test(file.name)) {
          dispatch(uploadDicomActions.saveDicomZipFile(file))
          dispatch(uploadDicomActions.saveErrorFileName(''))
        } else {
          dispatch(uploadDicomActions.saveDicomZipFile(undefined))
          dispatch(uploadDicomActions.saveErrorFileName(file.name))
        }
      }
    },
    [dispatch],
  )

  useDidMount(() => {
    localStorage.setItem(`dicom-${id}`, DicomUploadStatus.UPLOAD_INPROGRESS)
    dispatch(uploadDicomActions.getTempCredentialsRequest({ caseId: id }))
    window.addEventListener('beforeunload', updateDicomFileUploadStatus)

    return () => {
      window.removeEventListener('beforeunload', updateDicomFileUploadStatus)
    }
  })

  return (
    <PageBackground data-testid="UploadDicom-PageBackground">
      <UploadDicomHeader data-testid="UploadDicom-UploadDicomHeader">
        <FormattedMessage id="caseWizard.dicom.upload" />
      </UploadDicomHeader>
      <UploadDicomBody data-testid="UploadDicom-UploadDicomBody">
        {showUploader ? (
          <>
            <DicomUploadTitle data-testid="UploadDicom-DicomUploadTitle">
              <FormattedMessage id="caseWizard.dicom.selectFileHeader" values={{ caseId: id }} />
            </DicomUploadTitle>
            <FileProgressDiv>
              <CurrentUploadProgress
                currentWidth={`${uploadProgress.toString()}%`}
                data-testid="UploadDicom-CurrentUploadProgress"
              />
            </FileProgressDiv>
            <div data-testid="UploadDicom-SelectedFileLabel">
              <FormattedMessage id="caseWizard.dicom.selectedFileLabel" />
              {selectedFile?.name}
            </div>
            {uploadError && (
              <UploadDicomContentStrong>
                <UploadDicomError data-testid="UploadDicom-UploadDicomError">
                  <div data-testid="UploadDicom-UploadDicomErrorHead">
                    <ErrorContainer>
                      <Error width="12px" />
                    </ErrorContainer>
                    <strong>
                      <FormattedMessage id="caseWizard.dicom.error" />
                    </strong>
                  </div>
                  <div data-testid="UploadDicom-UploadDicomErrorMessage">
                    <FormattedMessage id="caseWizard.dicom.upload.errorMessage" />
                  </div>
                </UploadDicomError>
              </UploadDicomContentStrong>
            )}
          </>
        ) : (
          <>
            <DicomUploadTitle data-testid="UploadDicom-DicomUploadTitle">
              <FormattedMessage id="caseWizard.dicom.uploadFileHeader" />
            </DicomUploadTitle>
            <FileInputContainer>
              <FileInput
                type="file"
                data-testid="UploadDicom-FileInput"
                onChange={fileChange}
                accept=".zip"
              />
              <FileLabel>{selectedFileName}</FileLabel>
            </FileInputContainer>
            <UploadDicomContentStrong data-testid="UploadDicom-FileFormat">
              <FormattedMessage id="caseWizard.dicom.fileFormat" />
            </UploadDicomContentStrong>
            {errorFile && (
              <UploadDicomContentStrong>
                <UploadDicomError data-testid="UploadDicom-UploadDicomError">
                  <Box justify="flex-start" data-testid="UploadDicom-UploadDicomErrorHead">
                    <ErrorContainer>
                      <Error width="12px" />
                    </ErrorContainer>
                    <strong>
                      <FormattedMessage id="caseWizard.dicom.error" />
                    </strong>
                  </Box>
                  <div data-testid="UploadDicom-UploadDicomErrorMessage">
                    <FormattedMessage id="caseWizard.dicom.errorMessage" />
                  </div>
                </UploadDicomError>
              </UploadDicomContentStrong>
            )}
            <SubmitButton
              as="button"
              data-testid="UploadDicom-SubmitButton"
              onClick={uploadFiles}
              disabled={!selectedFile}
            >
              <FormattedMessage id="upload.popup.submit" />
            </SubmitButton>
          </>
        )}
      </UploadDicomBody>
    </PageBackground>
  )
}

export { UploadDicom }

const PageBackground = styled.div`
  padding-bottom: 32%;

  background-color: ${({ theme }) => theme.colors.white};
`

const DicomUploadTitle = styled.div`
  font-weight: 600;
`

const UploadDicomHeader = styled.div`
  padding-top: 4%;
  padding-left: 5%;

  font-size: 19px;
  font-weight: 600;
`

const UploadDicomContentStrong = styled.div`
  margin-top: 3%;
`

const UploadDicomError = styled.div`
  margin-right: 2%;
  padding: 5%;

  border-color: ${({ theme }) => theme.colors.danger100};

  color: #a94442;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.danger10} 0, ${theme.colors.danger100} 100%)`};
  border-radius: 4px;
`

const ErrorContainer = styled.div`
  margin-right: 5px;
  margin-bottom: 3px;
`

const UploadDicomBody = styled.div`
  margin-top: 7%;
  margin-left: 15%;
`

const SubmitButton = styled(PrimaryButton)`
  margin-top: 3%;
`

const FileProgressDiv = styled.div`
  margin-top: 5%;

  overflow: hidden;

  width: 75%;
  height: 5px;
  border: 1px solid ${({ theme }) => theme.colors.black};

  background-color: ${({ theme }) => theme.colors.base300};
`

const CurrentUploadProgress = styled.div<{ currentWidth: string }>`
  width: ${({ currentWidth }) => currentWidth};
  height: 4px;

  background-color: ${({ theme }) => theme.colors.primary600};
`

const FileInput = styled.input`
  color: transparent;
`

const FileInputContainer = styled.div`
  margin-top: 3%;

  display: flex;
`

const FileLabel = styled.div`
  width: 250%;
`
