enum Badge {
  'SAPPHIRE_I' = 'SAPPHIRE_I',
  'SAPPHIRE_II' = 'SAPPHIRE_II',
  'SAPPHIRE_III' = 'SAPPHIRE_III',
  'SAPPHIRE_PLUS' = 'SAPPHIRE_PLUS',
  'SAPPHIRE_ELITE' = 'SAPPHIRE_ELITE',
  'INSIDER' = 'INSIDER',
  'VIP' = 'VIP',
  'ELITE' = 'ELITE',
  'PINNACLE' = 'PINNACLE',
}

export { Badge }
