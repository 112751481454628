import React, { useEffect, useMemo, useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { Tabs } from 'antd'
import { ScanViewer } from 'app/components/routes/CaseWizard/ScanViewer'
import { Cancel } from 'app/components/ui/Icons/common'
import { Loader } from 'app/components/ui/Loader'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ScanViewUrl } from 'app/core/domain/ScanViewUrl'
import styled from 'styled-components'

interface TruGenModalProps {
  isOpen: boolean
  onClose: () => void
  urls: ScanViewUrl[] | null
}

interface ScanViewProps {
  url: string
}

const ScanViewModal: React.FC<TruGenModalProps> = (props) => {
  const { isOpen, onClose, urls } = props

  return (
    <OrmcoModal isOpen={isOpen} data-testid="ScanViewModal-OrmcoModal" onRequestClose={onClose}>
      <OrmcoModalHeader flexDirection="row" data-testid="ScanViewModal-OrmcoModalHeader">
        <OrmcoModalHeaderText variant="h5" headerId="scan.view.title" />
        <Button type="text" onClick={onClose} data-testid="ScanViewModal-CloseButton">
          <Cancel />
        </Button>
      </OrmcoModalHeader>
      <OrmcoModalBody>
        {(() => {
          if (!urls) {
            return (
              <Typography>
                <FormattedMessage id="scan.view.loading" />
              </Typography>
            )
          }

          if (!urls.length) {
            return (
              <Typography>
                <FormattedMessage id="scan.view.notReady" />
              </Typography>
            )
          }

          return (
            <Tabs>
              {urls.map(({ url }, index) => (
                <Tabs.TabPane key={index} tab={`STL-${index + 1}`}>
                  <ScanView url={url} />
                </Tabs.TabPane>
              ))}
            </Tabs>
          )
        })()}
      </OrmcoModalBody>
    </OrmcoModal>
  )
}

const ScanView: React.FC<ScanViewProps> = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef<HTMLDivElement | null>(null)
  const viewer = useMemo(() => new ScanViewer(), [])

  useEffect(() => {
    if (ref.current) {
      viewer.init(url, ref.current, () => setIsLoading(false))
      viewer.animate()
    }
  }, [url, viewer])

  useEffect(
    () => () => {
      viewer.clear()
    },
    [viewer],
  )

  return (
    <ScanViewContainer ref={ref} data-testid="ScanViewModal-ScanViewContainer">
      {isLoading && <ScanViewLoader />}

      <InstructionContainer>
        <FormattedMessage
          id="scan.view.instructions"
          values={{
            breakingLine: <br />,
          }}
        />
      </InstructionContainer>
    </ScanViewContainer>
  )
}

const ScanViewContainer = styled.div`
  position: relative;

  overflow: hidden;

  height: 374px;

  border: 1px solid ${({ theme }) => theme.colors.black};
`

const InstructionContainer = styled.div`
  padding: 1%;
  position: absolute;
  bottom: 8px;
  left: 8px;

  opacity: 0.5;

  display: block;

  font-family: Arial, sans-serif;

  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 5px;
`

const ScanViewLoader = styled(Loader)`
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);

  width: 32px;
  height: 32px;
`

export { ScanViewModal }
