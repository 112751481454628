import React from 'react'
import { useSelector } from 'react-redux'

import { appSelectors } from 'app/logic/app/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const OfficePhone: React.FC = () => {
  const { officePhone } = useSelector(appSelectors.getEmployeePersonalDetails())

  return (
    <ReadonlyValue
      data-testid="MyProfile-OfficePhone"
      labelId="myProfile.officePhone"
      value={officePhone}
    />
  )
}

export { OfficePhone }
