import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  unarchiveModalActions,
  unarchiveModalSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { InfoButton } from 'app/components/ui/Buttons'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

const UnarchiveModal: React.FC = () => {
  const isOpen = useSelector(unarchiveModalSelectors.getOpenState())
  const dispatch = useDispatch()
  const close = useCallback(() => {
    dispatch(unarchiveModalActions.closeButtonClicked())
  }, [dispatch])
  const unarchive = useCallback(() => {
    dispatch(unarchiveModalActions.unarchiveButtonClicked())
  }, [dispatch])

  return (
    <OrmcoModal
      isOpen={isOpen}
      additionalStyles={{ content: { width: '390px' } }}
      data-testid="UnarchiveModal"
    >
      <UnarchiveModalHeader data-testid="UnarchiveModal-Header" flexDirection="row">
        <OrmcoModalHeaderText variant="large" headerId="unarchiveModal.header" />
        <Button type="text" onClick={close} data-testid="UnarchiveModal-Close">
          <Cancel width="16px" />
        </Button>
      </UnarchiveModalHeader>
      <Typography data-testid="UnarchiveModal-Body" variant="medium" color="base900" padding="20px">
        <FormattedMessage id="unarchiveModal.body" />
      </Typography>
      <UnarchiveModalFooter data-testid="UnarchiveModal-Footer">
        <InfoButton onClick={close} data-testid="UnarchiveModal-ButtonNo">
          <GlyphIcon type={IconType.REMOVE_SIGN} />
          <Padder width="5px" />
          <FormattedMessage id="unarchiveModal.no" />
        </InfoButton>
        <Padder width="10px" />
        <InfoButton onClick={unarchive} data-testid="UnarchiveModal-ButtonYes">
          <GlyphIcon type={IconType.OK_SIGN} />
          <Padder width="5px" />
          <FormattedMessage id="unarchiveModal.yes" />
        </InfoButton>
      </UnarchiveModalFooter>
    </OrmcoModal>
  )
}

const UnarchiveModalHeader = styled(OrmcoModalHeader)`
  padding: 16px;

  height: 55px;

  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.base300} 0, ${theme.colors.base100} 100%)`};
  border-radius: 6px;
`

const UnarchiveModalFooter = styled(OrmcoModalFooter)`
  justify-content: flex-end;

  height: 60px;
`

export { UnarchiveModal }
