import React from 'react'
import Modal from 'react-modal'

import { head, merge } from 'lodash'
import { useTheme } from 'styled-components'

type OrmcoModalProps = Omit<ReactModal.Props, 'style' | 'testId'> & {
  additionalStyles?: ReactModal.Styles
  'data-testid'?: string
}

const OrmcoModal: React.FC<OrmcoModalProps> = (props) => {
  const body = head(document.getElementsByTagName('body'))
  const theme = useTheme()
  const defaultModalStyles = {
    content: {
      top: '30px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%,0)',
      width: '598px',
      backgroundColor: theme.colors.white,
      zIndex: theme.zIndex.modal,
      padding: '0',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      border: 'none',
    },
    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: theme.zIndex.overlay,
      overflowY: 'auto',
    },
  }
  const styles = merge({}, defaultModalStyles, props.additionalStyles)

  return (
    <Modal
      style={styles}
      appElement={body}
      testId={props['data-testid']}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
    >
      {props.children}
    </Modal>
  )
}

export { OrmcoModal }
