const emailValidatorUtil = (emailFieldValue?: string) => {
  const EMAIL_REGEXP = /^[^:;,\\[\]<>()\s@]+@[^\s@]+\.\w+$/

  if (emailFieldValue) {
    const splitEmails = emailFieldValue.split(/;\s?/)

    for (let emailToValidate of splitEmails) {
      if (!EMAIL_REGEXP.test(emailToValidate)) {
        return false
      }
    }

    return true
  }
  return false
}

export { emailValidatorUtil }
