import {
  unarchiveModalActions,
  unarchiveModalSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { filter, switchMap, catchError } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const makeUnarchiveRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([unarchiveModalActions.unarchiveButtonClicked])),
    switchMap(() => {
      const caseId = unarchiveModalSelectors.getCaseId()(state$.value)

      return from(axios.put(`/api/v1/cases/${caseId}/archive`, 'false')).pipe(
        switchMap(() => of(unarchiveModalActions.unarchiveRequestReceived())),
        catchError(() => of(unarchiveModalActions.unarchiveRequestFailed())),
      )
    }),
  )

const unarchiveModalEpic = combineEpics(makeUnarchiveRequestEpic)

export { unarchiveModalEpic }
