import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { NavigationDropdown } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationDropdown/NavigationDropdown'
import { RouterDropdownItem } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationDropdown/RouterDropdownItem'
import { NavigationLink } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationLink/NavigationLink'
import { basicPageActions } from 'app/components/routes/BasicPage/logic'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { HelpFilled, PatientText } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { postLogoutMessage } from 'app/core/auth/AuthenticationBroadcast'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

import { DropdownItem } from '../NavigationItems/NavigationDropdown/DropdownItem'

interface DoctorNavigationBarProps {
  'data-testid': string
}

const DoctorNavigationBar: React.FC<DoctorNavigationBarProps> = (props) => {
  const dispatch = useDispatch()
  const fullName = useSelector(appSelectors.getFullName())
  const isDoctor = useSelector(appSelectors.isDoctor())

  const myTeamLinkClickCallBack = useCallback(() => {
    dispatch(basicPageActions.myTeamLinkClicked())
  }, [dispatch])

  const handleLogout = () => {
    sessionStorage.clear()
    postLogoutMessage()
  }

  return (
    <>
      <NavigationLink
        to={SiteMap.homePage()}
        icon={<PatientText />}
        data-testid={`${props['data-testid']}-patients`}
        linkTextId="header.navigation.patients"
      />
      <NavigationDropdown
        icon={<HelpFilled />}
        titleTextId="header.navigation.help"
        data-testid={`${props['data-testid']}-Help`}
      >
        <DropdownItem
          href={SiteMap.DOCTORS.support()}
          data-testid={`${props['data-testid']}-support`}
        >
          <FormattedMessage id="header.navigation.doctors.support" />
        </DropdownItem>
        <DropdownItem
          href={SiteMap.DOCTORS.downloadApprover()}
          data-testid={`${props['data-testid']}-downloadApprover`}
        >
          <FormattedMessage id="header.navigation.doctors.downloadApprover" />
        </DropdownItem>
        <DropdownItem
          href={SiteMap.DOCTORS.education()}
          data-testid={`${props['data-testid']}-education`}
        >
          <FormattedMessage id="header.navigation.doctors.education" />
        </DropdownItem>
      </NavigationDropdown>

      <NavigationDropdown
        icon={<GlyphIcon type={IconType.USER} />}
        titleTextId={fullName}
        data-testid={`${props['data-testid']}-User`}
        rawTitle
      >
        <DropdownItem
          href={SiteMap.DOCTORS.profile()}
          data-testid={`${props['data-testid']}-preferences`}
        >
          <FormattedMessage id="header.navigation.doctors.preferences" />
        </DropdownItem>
        <RouterDropdownItem link="/myProfile" data-testid={`${props['data-testid']}-profile`}>
          <FormattedMessage id="header.navigation.doctors.profile" />
        </RouterDropdownItem>
        {isDoctor && (
          <Box display="block">
            <RouterDropdownItem
              link="/myPractice"
              onClick={myTeamLinkClickCallBack}
              data-testid={`${props['data-testid']}-practice`}
            >
              <FormattedMessage id="header.navigation.doctors.practice" />
            </RouterDropdownItem>
            <DropdownItem
              href={SiteMap.DOCTORS.clinicalPreferences()}
              data-testid={`${props['data-testid']}-clinicalPreferences`}
            >
              <FormattedMessage id="header.navigation.doctors.clinicalPreferences" />
            </DropdownItem>
          </Box>
        )}
        <DropdownItem
          href={SiteMap.logout()}
          onClick={handleLogout}
          data-testid={`${props['data-testid']}-logout`}
        >
          <FormattedMessage id="header.navigation.doctors.logout" />
        </DropdownItem>
      </NavigationDropdown>
    </>
  )
}

export { DoctorNavigationBar }
