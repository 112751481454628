enum CaseField {
  'AGE' = 'AGE',
  'PENDING' = 'PENDING',
  'SUBMITTED_DATE' = 'SUBMITTED_DATE',
  'NOTES' = 'NOTES',
  'SHIP_DATE' = 'SHIP_DATE',
  'STATUS' = 'STATUS',
  'CREATION_DATE' = 'CREATION_DATE',
  'ORDER_TYPE' = 'ORDER_TYPE',
  'DOCTOR' = 'DOCTOR',
  'COUNTRY' = 'COUNTRY',
  'OPERATOR' = 'OPERATOR',
  'NAME' = 'NAME',
}

export { CaseField }
