import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Divider } from 'app/components/ui/Divider'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { ValidationFieldContainer } from 'app/components/ui/Form/ValidationFieldContainer'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { get } from 'lodash'

const MIDLINE_GOOD_PHOTOS_PROPERTY_KEY =
  'primaryCasePreferences.midlinePreferencesPrimary.goodQualityPhotoProvided'

export const MidlineUpperAndLowerSection = () => {
  const form = useFormContext<CaseDraft>()
  const goodQualityPhotoProvided = form.getValues(MIDLINE_GOOD_PHOTOS_PROPERTY_KEY)
  const goodQualityPhotosRegister = form.register(MIDLINE_GOOD_PHOTOS_PROPERTY_KEY, {
    onChange: (event: CheckboxChangeEvent) => {
      form.setValue(MIDLINE_GOOD_PHOTOS_PROPERTY_KEY, event.target.checked)
      form.clearErrors(MIDLINE_GOOD_PHOTOS_PROPERTY_KEY)
    },
  })

  const error = get(form.formState.errors, MIDLINE_GOOD_PHOTOS_PROPERTY_KEY)

  return (
    <>
      <Divider margin="0 4px" />
      <ValidationFieldContainer
        padding="8px"
        data-testid="MidlineUpperAndLowerSectionSection"
        error={error}
      >
        <OrmcoCheckbox
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...goodQualityPhotosRegister}
          checked={Boolean(goodQualityPhotoProvided)}
          data-testid="MidlineUpperAndLowerSectionSection-GoodQuality"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.midline.goodQualityPhotosCheckbox" />
          </Typography>
        </OrmcoCheckbox>
      </ValidationFieldContainer>
    </>
  )
}
