import styled, { css } from 'styled-components'

export const SdsTooltipGlobalStyle = css`
  body {
    .sds-tooltip {
      &.ant-tooltip-placement-top {
        &,
        &Right,
        &Left {
          padding-bottom: 8px;
          .ant-tooltip-arrow {
            bottom: 3px;
          }
        }
      }
      &.ant-tooltip-placement-right {
        &,
        &Top,
        &Bottom {
          padding-left: 8px;
          .ant-tooltip-arrow {
            left: 3px;
          }
        }
      }
      &.ant-tooltip-placement-bottom {
        &,
        &Right,
        &Left {
          padding-top: 8px;
          .ant-tooltip-arrow {
            top: 3px;
          }
        }
      }
      &.ant-tooltip-placement-left {
        &,
        &Top,
        &Bottom {
          padding-right: 8px;
          .ant-tooltip-arrow {
            right: 3px;
          }
        }
      }
    }
  }
`

export const SdsToolTipChildContainer = styled.div`
  cursor: pointer;
`
