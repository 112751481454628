import { FC, SVGProps } from 'react'

import styled, { css, ThemeColors } from 'styled-components'

export interface IconsProps {
  width?: string
  color?: keyof ThemeColors | 'inherit' | 'currentColor'
}

const iconStyle = css`
  display: block;

  width: 100%;
  height: auto;
`

export const createSVGIcon = (Icon: FC<SVGProps<SVGSVGElement>>, initWidth: string) => styled(
  Icon,
)<IconsProps>`
  min-width: ${({ width = initWidth }) => width};
  max-width: ${({ width = initWidth }) => width};

  fill: ${({ color = 'currentColor', theme }) => {
    if (color === 'inherit' || color === 'currentColor') {
      return color
    }

    return theme.colors[color]
  }};

  ${iconStyle}
`
