enum CaseStatus {
  'SAVED' = 'SAVED',
  'NEW' = 'NEW',
  'IMPRESSION_RECEIVED' = 'IMPRESSION_RECEIVED',
  'IMPRESSION_RECEIVED_OLDDATA' = 'IMPRESSION_RECEIVED_OLDDATA',
  'ACCEPTED_BY_SCANNER' = 'ACCEPTED_BY_SCANNER',
  'COMPLETED_BY_SCANNER' = 'COMPLETED_BY_SCANNER',
  'ACCEPTED_BY_ANATOMY' = 'ACCEPTED_BY_ANATOMY',
  'COMPLETED_BY_ANATOMY' = 'COMPLETED_BY_ANATOMY',
  'ACCEPTED_BY_DESIGN' = 'ACCEPTED_BY_DESIGN',
  'COMPLETED_BY_DESIGN' = 'COMPLETED_BY_DESIGN',
  'PENDING_APPROVAL' = 'PENDING_APPROVAL',
  'CHANGES_RETURNED' = 'CHANGES_RETURNED',
  'APPROVED_MANUFACTURING' = 'APPROVED_MANUFACTURING',
  'COMPLETED_BY_DESIGN_QC' = 'COMPLETED_BY_DESIGN_QC',
  'ACCEPTED_BY_MANUFACTURING' = 'ACCEPTED_BY_MANUFACTURING',
  'COMPLETED_BY_MANUFACTURING' = 'COMPLETED_BY_MANUFACTURING',
  'PARTIALLY_SHIPPED' = 'PARTIALLY_SHIPPED',
  'ACCEPTED_BY_SHIPPING' = 'ACCEPTED_BY_SHIPPING',
  'SHIPPED' = 'SHIPPED',
  'REORDERED_BY_DOCTOR' = 'REORDERED_BY_DOCTOR',
  'NEXT_CLEARGUIDE_SET_REQUESTED' = 'NEXT_CLEARGUIDE_SET_REQUESTED',
  'APPROVED_BY_ADMIN' = 'APPROVED_BY_ADMIN',
  'ACCEPTED_BY_MANUFACTURING_FOR_ALIGNER' = 'ACCEPTED_BY_MANUFACTURING_FOR_ALIGNER',
  'ACCEPTED_BY_DESIGN_QC_FOR_REORDER' = 'ACCEPTED_BY_DESIGN_QC_FOR_REORDER',
  'INFORMATION_REQUIRED' = 'INFORMATION_REQUIRED',
  'IMPRESSION_NOT_RECEIVED' = 'IMPRESSION_NOT_RECEIVED',
}

enum OrderStatus {
  'UNSUBMITTED' = 'UNSUBMITTED',
  'DESIGN_IN_PROGRESS' = 'DESIGN_IN_PROGRESS',
  'AWAITING_APPROVAL' = 'AWAITING_APPROVAL',
  'IN_PRODUCTION' = 'IN_PRODUCTION',
  'SHIPPED_PARTIALLY' = 'SHIPPED_PARTIALLY',
  'SHIPPED' = 'SHIPPED',
  'CASE_REORDERED' = 'CASE_REORDERED',
}

export { CaseStatus, OrderStatus }
