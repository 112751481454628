import { addressEpic } from 'app/components/routes/Address/epic'
import { integratedHooksModalEpic } from 'app/components/routes/BasicPage/IntegratedHooksModal/epic'
import { termsEpic } from 'app/components/routes/BasicPage/TermsAndConditionsModal/epic'
import { caseFilterActionsEpic } from 'app/components/routes/CaseList/DoctorCaseList/CaseFilter/epic'
import { casePerksActionsEpic } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/epic'
import { caseSubmittedEpic } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/epic'
import { unarchiveModalEpic } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/epic'
import { doctorNoteActionsEpic } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/epic'
import { casesListEpic } from 'app/components/routes/CaseList/epic'
import { caseWizardEpic } from 'app/components/routes/CaseWizard/epic'
import { truGenXRSelectModalEpic } from 'app/components/routes/CaseWizard/steps/CasePrescription/TruGenXRSelectModal/epic'
import { editScansEpic } from 'app/components/routes/CaseWizard/steps/EditScans/epic'
import { uploadDicomEpic } from 'app/components/routes/Dicom/epic'
import { changePwdEpic } from 'app/components/routes/MyProfile/ChangePasswordModal/epic'
import { doctorDetailsEpic } from 'app/components/routes/Staff/DoctorDetails/epic'
import { staffCreationEpic } from 'app/components/routes/Staff/StaffDetails/epic'
import { staffTableEpic } from 'app/components/routes/Staff/StaffTable/epic'
import { appEpic } from 'app/logic/app/epic'
import { combineEpics } from 'redux-observable'

const rootEpic = combineEpics(
  appEpic,
  casesListEpic,
  termsEpic,
  unarchiveModalEpic,
  doctorNoteActionsEpic,
  casePerksActionsEpic,
  caseSubmittedEpic,
  caseWizardEpic,
  caseFilterActionsEpic,
  staffCreationEpic,
  staffTableEpic,
  doctorDetailsEpic,
  addressEpic,
  changePwdEpic,
  editScansEpic,
  uploadDicomEpic,
  integratedHooksModalEpic,
  truGenXRSelectModalEpic,
)

export { rootEpic }
