import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Row } from 'app/components/ui/Table/Row'
import { UserRole } from 'app/core/domain/UserRole'
import styled from 'styled-components'

import { StaffTableBody } from './StaffTableBody'
import { staffListActions, staffListSelectors } from './logic'

export const StaffTable = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const staffInfo = useSelector(staffListSelectors.getStaffInfo())

  const handleUserClick = useCallback(
    (username: string, userRole: UserRole) => {
      dispatch(staffListActions.userInformationClicked(username))
      if (userRole === UserRole.ROLE_NORMAL) {
        history.push(`/doctorDetails?username=${username}`)
      } else {
        history.push(`/createUpdateStaff?staffUsername=${username}`)
      }
    },
    [dispatch, history],
  )

  return (
    <Box display="block" minHeight="75vh" data-testid="StaffTable">
      <HeaderRow justify="flex-start">
        <Box padding="7px 16px" justify="flex-start" flexGrow="1" data-testid="StaffTable-Name">
          <Typography component="span" textFontWeight="600" isUppercase>
            <FormattedMessage id="staff.teamManagement.header.name" />
          </Typography>
        </Box>
        <Box padding="7px 16px" justify="flex-start" flexGrow="1" data-testid="StaffTable-Role">
          <Typography component="span" textFontWeight="600" isUppercase>
            <FormattedMessage id="staff.teamManagement.header.role" />
          </Typography>
        </Box>
        <Box padding="7px 16px" justify="flex-start" flexGrow="1" data-testid="StaffTable-Email">
          <Typography component="span" textFontWeight="600" isUppercase>
            <FormattedMessage id="staff.teamManagement.header.emailAddress" />
          </Typography>
        </Box>
      </HeaderRow>
      <StaffTableBody length={staffInfo.length} data-testid="StaffTable-Body">
        {staffInfo.map((user) => {
          const { username, name, email, userRole } = user

          return (
            <TableRow
              key={`${name}-${username}`}
              onClick={() => handleUserClick(username, userRole)}
            >
              <Box padding="12px 16px" justify="flex-start" flexGrow="1">
                <Typography component="span" textFontWeight="600" wordBreak="break-word">
                  {name}
                </Typography>
              </Box>
              <Box padding="12px 16px" justify="flex-start" flexGrow="1">
                <Typography component="span" textFontWeight="600" wordBreak="break-word">
                  {userRole === UserRole.ROLE_NORMAL ? (
                    <FormattedMessage id="staff.teamManagement.role.doctor" />
                  ) : (
                    <FormattedMessage id="staff.teamManagement.role.staff" />
                  )}
                </Typography>
              </Box>
              <Box padding="12px 16px" justify="flex-start" flexGrow="1">
                <Typography component="span" textFontWeight="600" wordBreak="break-word">
                  {email}
                </Typography>
              </Box>
            </TableRow>
          )
        })}
      </StaffTableBody>
    </Box>
  )
}

const HeaderRow = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primary10};
`

const TableRow = styled(Row)`
  border-right: 0.5px solid ${({ theme }) => theme.colors.base200};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.base200};

  :hover {
    cursor: pointer;

    background-color: ${({ theme }) => theme.colors.base300};
  }
`
