import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Alert } from 'antd'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { NotificationPosition } from './NotificationPosition'
import { notificationActions, notificationSelectors } from './logic'

interface NotificationProps {
  'data-testid'?: string
  notificationPosition?: NotificationPosition
}

const Notification: React.FC<NotificationProps> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { messageId, type, displayDuration } = useSelector(
    notificationSelectors.getNotificationInfo(),
  )
  const defaultPresenceTime = 5000
  const presenceTime = displayDuration === undefined ? defaultPresenceTime : displayDuration
  const message = intl.formatMessage({ id: messageId })
  const ref = useRef<HTMLDivElement | null>(null)

  useDidMount(() => {
    setTimeout(() => {
      dispatch(notificationActions.notificationUnMounted())
    }, presenceTime)
  })

  useDidMount(() => {
    if (ref.current?.parentElement) {
      ref.current.style.width = `${ref.current.parentElement.offsetWidth}px`
    }
  })

  return (
    <NotificationMsgContainer notificationPosition={props.notificationPosition} ref={ref}>
      <NotificationMsg type={type} message={message} data-testid={props['data-testid']} />
    </NotificationMsgContainer>
  )
}

const NotificationMsgContainer = styled.div<Pick<NotificationProps, 'notificationPosition'>>`
  position: ${(props) => props.notificationPosition};
  top: 0;

  z-index: ${({ theme }) => theme.zIndex.notification};
`

const NotificationMsg = styled(Alert)`
  &&& {
    &.ant-alert-no-icon {
      padding: 5px;
    }

    &.ant-alert.ant-alert-no-icon .ant-alert-close-icon {
      top: 7px;
    }

    &.ant-alert-error .ant-alert-message {
      color: ${({ theme }) => theme.colors.danger400};
    }

    width: 100%;
    height: 28px;
    border: 0;

    text-align: center;
    letter-spacing: 0.1px;

    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }
`

export { Notification }
