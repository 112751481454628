import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Form } from 'antd'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { OrmcoRadioGroup } from 'app/components/ui/Form/OrmcoRadioGroup'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { AffectedArche } from 'app/core/domain/AffectedArches'
import { CaseDraft } from 'app/core/domain/CaseDraft'

interface ArchesToTreatProps {
  defaultArch?: AffectedArche
  formatMessageKey?: string
  selectedArch?: AffectedArche
  'data-testid': string
}

const PROPERTY_KEY = 'primaryCasePreferences.overbitePrimary.biteLowerUpper'

const BiteLowerUpper: React.FC<ArchesToTreatProps> = (props) => {
  const form = useFormContext<CaseDraft>()

  const { defaultArch, formatMessageKey } = props

  const formatMessageValue = formatMessageKey || 'casePrescription.overbite'

  const defaultArchToTreat = defaultArch || AffectedArche.BOTH
  const propertyValue = form.getValues(PROPERTY_KEY)
  const testId = props['data-testid']

  const changeRadio = (res: AffectedArche) => {
    form.setValue(PROPERTY_KEY, res)
  }

  return (
    <Form>
      <OrmcoFormItemVertical hasFeedback name={PROPERTY_KEY} data-testid={testId}>
        <OrmcoRadioGroup
          name={PROPERTY_KEY}
          defaultValue={defaultArchToTreat}
          onChange={(selected) => changeRadio(selected.target.value)}
        >
          <OrmcoRadio
            value={AffectedArche.BOTH}
            checked={propertyValue === AffectedArche.BOTH}
            data-testid={`${testId}-Both`}
          >
            <Typography component="span">
              <FormattedMessage
                id={`caseWizard.${formatMessageValue}.affectedArche`}
                values={{ affectedArche: AffectedArche.BOTH }}
              />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            value={AffectedArche.UPPER}
            checked={propertyValue === AffectedArche.UPPER}
            data-testid={`${testId}-Upper`}
          >
            <Typography component="span">
              <FormattedMessage
                id={`caseWizard.${formatMessageValue}.affectedArche`}
                values={{ affectedArche: AffectedArche.UPPER }}
              />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            value={AffectedArche.LOWER}
            checked={propertyValue === AffectedArche.LOWER}
            data-testid={`${testId}-Lower`}
          >
            <Typography component="span">
              <FormattedMessage
                id={`caseWizard.${formatMessageValue}.affectedArche`}
                values={{ affectedArche: AffectedArche.LOWER }}
              />
            </Typography>
          </OrmcoRadio>
        </OrmcoRadioGroup>
      </OrmcoFormItemVertical>
    </Form>
  )
}

export { BiteLowerUpper }
