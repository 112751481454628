import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { SelectedScanner } from 'app/components/routes/CaseWizard/SelectedScanner'
import { ScanType } from 'app/components/routes/CaseWizard/steps/EditScans/ScanType'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { Button } from 'app/components/ui/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { SHOW_MESSAGE_DURATION } from 'app/core/constants/global'
import styled from 'styled-components'

interface ThreeShapeButtonsProps {
  handleCloseScanViewer: () => void
  onScanViewerOpen: () => void
  onPreloaderModalOpen: () => void
  onPreloaderModalClose: () => void
}

const ScanActionsButtons: React.FC<ThreeShapeButtonsProps> = (props) => {
  const { handleCloseScanViewer, onScanViewerOpen, onPreloaderModalOpen, onPreloaderModalClose } =
    props
  const intl = useIntl()
  const dispatch = useDispatch()
  const filesUploading = useSelector(editScansSelectors.getFilesUploading())
  const lastDeleteTimestamp = useSelector(editScansSelectors.getLastDeleteTimestamp())
  const selectedScanner = useSelector(editScansSelectors.getSelectedScanner())
  const fileUploadedScanner = useSelector(editScansSelectors.getFileUploadedScanner())
  const displayScanActionsButtons = useSelector(editScansSelectors.getDisplayScanActionButtons())
  const scanViewUrls = useSelector(editScansSelectors.getScanViewUrls())
  const isLoading = useSelector(editScansSelectors.getisLoading())

  const [isDeleteMsgDisplay, setIsDeleteMsgDisplay] = useState(false)
  const [isUploadMsgDisplay, setIsUploadMsgDisplay] = useState(false)

  const deleteSuccessMessage = useMemo(
    () =>
      selectedScanner?.name === ScanType.THREESHAPESCANS
        ? intl.formatMessage({ id: '3shape.notification.deleteSuccess' })
        : intl.formatMessage({ id: 'upload.popup.deleteSuccessfully' }),
    [intl, selectedScanner],
  )
  const uploadSuccessMessage = useMemo(
    () =>
      selectedScanner?.name === ScanType.THREESHAPESCANS
        ? intl.formatMessage({ id: '3shape.notification.uploadSuccess' })
        : intl.formatMessage({ id: 'upload.popup.uploadSuccessfully' }),
    [intl, selectedScanner],
  )

  const areScanViewUrlsReady = Boolean(scanViewUrls?.length)
  const displayUploadMessage =
    isUploadMsgDisplay && !filesUploading && selectedScanner?.name === fileUploadedScanner?.name

  const handleDeleteScansButtonClick = useCallback(() => {
    dispatch(
      editScansActions.scanDeleteRequested({
        hideButtonsOnEnd: true,
        updateTimestamp: true,
      }),
    )
    handleCloseScanViewer()
    setIsUploadMsgDisplay(false)
  }, [dispatch, handleCloseScanViewer])

  const handleViewScanButtonClick = useCallback(() => {
    if (!areScanViewUrlsReady) {
      onPreloaderModalOpen()
      return
    }

    onScanViewerOpen()
  }, [onScanViewerOpen, onPreloaderModalOpen, areScanViewUrlsReady])

  useEffect(() => {
    if (Date.now() - Number(lastDeleteTimestamp) < SHOW_MESSAGE_DURATION) {
      setIsDeleteMsgDisplay(true)
    }

    setTimeout(() => {
      setIsDeleteMsgDisplay(false)
    }, SHOW_MESSAGE_DURATION)

    return () => setIsDeleteMsgDisplay(false)
  }, [lastDeleteTimestamp])

  useEffect(() => {
    const isUpdated = displayScanActionsButtons && areScanViewUrlsReady && !filesUploading

    if (isUpdated) {
      setIsUploadMsgDisplay(true)

      if (selectedScanner?.name === fileUploadedScanner?.name) {
        dispatch(editScansActions.saveFileUploadedScanner(selectedScanner as SelectedScanner))
      }

      setTimeout(() => {
        setIsUploadMsgDisplay(false)
      }, SHOW_MESSAGE_DURATION)
    }
  }, [
    dispatch,
    displayScanActionsButtons,
    fileUploadedScanner,
    areScanViewUrlsReady,
    selectedScanner,
    filesUploading,
  ])

  useEffect(() => {
    if (isLoading) {
      onPreloaderModalOpen()
    } else {
      onPreloaderModalClose()
    }
  }, [onPreloaderModalOpen, onPreloaderModalClose, isLoading])

  return (
    <>
      {isDeleteMsgDisplay && (
        <Typography data-testid="Scans-DeleteMessage" margin="4px 0 0" color="base700">
          {deleteSuccessMessage}
        </Typography>
      )}

      {displayUploadMessage && (
        <Typography data-testid="Scans-UploadMessage" margin="4px 0 0" color="base700">
          {uploadSuccessMessage}
        </Typography>
      )}

      {displayScanActionsButtons && (
        <EditScansDiv margin="12px 0 0">
          <Button
            title={intl.formatMessage({ id: 'selectScan.actions.view' })}
            data-testid="Scans-BlueButton"
            onClick={handleViewScanButtonClick}
          >
            <FormattedMessage id="selectScan.actions.view" />
          </Button>

          <Button
            variant="danger"
            title={intl.formatMessage({ id: 'selectScan.actions.remove' })}
            data-testid="Scans-RedButton"
            onClick={handleDeleteScansButtonClick}
          >
            <FormattedMessage id="selectScan.actions.remove" />
          </Button>
        </EditScansDiv>
      )}
    </>
  )
}

const EditScansDiv = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin};

  display: flex;
  align-items: center;

  button + button {
    margin-left: 4px;
  }
`

export { ScanActionsButtons }
