import {
  Upper1_16,
  Upper2_15,
  Upper3_14,
  Upper4_13,
  Upper5_12,
  Upper6_11,
  Upper8_9,
  Upper7_10,
  Lower18_31,
  Lower19_30,
  Lower20_29,
  Lower21_28,
  Lower22_27,
  Lower23_26,
  Lower24_25,
  Lower17_32,
} from 'app/components/ui/Icons/teeth'

export const ALIGNERS_TEETH_MATRIX = {
  1: Upper1_16,
  2: Upper2_15,
  3: Upper3_14,
  4: Upper4_13,
  5: Upper5_12,
  6: Upper6_11,
  7: Upper7_10,
  8: Upper8_9,

  9: Upper8_9,
  10: Upper7_10,
  11: Upper6_11,
  12: Upper5_12,
  13: Upper4_13,
  14: Upper3_14,
  15: Upper2_15,
  16: Upper1_16,

  17: Lower17_32,
  18: Lower18_31,
  19: Lower19_30,
  20: Lower20_29,
  21: Lower21_28,
  22: Lower22_27,
  23: Lower23_26,
  24: Lower24_25,

  25: Lower24_25,
  26: Lower23_26,
  27: Lower22_27,
  28: Lower21_28,
  29: Lower20_29,
  30: Lower19_30,
  31: Lower18_31,
  32: Lower17_32,
}
