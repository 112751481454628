import { ThemeType } from 'app/components/ui/Theme/ThemeContext'
import styled from 'styled-components'

interface OrmcoModalHeaderProps {
  height?: string
  flexDirection?: string
  contextTheme?: ThemeType
}

const OrmcoModalHeader = styled.div<OrmcoModalHeaderProps>`
  padding: 15px;

  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
  justify-content: space-between;
  align-items: center;

  height: ${(props) => (props.height ? props.height : 'unset')};

  border-bottom: 1px solid ${({ theme }) => theme.colors.base300};
`

export { OrmcoModalHeader }
