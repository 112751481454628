import React from 'react'
import { useSelector } from 'react-redux'

import { AdminNavigationBar } from 'app/components/routes/BasicPage/Header/NavigationBar/AdminNavigationBar/AdminNavigationBar'
import { DoctorNavigationBar } from 'app/components/routes/BasicPage/Header/NavigationBar/DoctorNavigationBar/DoctorNavigationBar'
import { OperatorNavigationBar } from 'app/components/routes/BasicPage/Header/NavigationBar/OperatorNavigationBar/OperatorNavigationBar'
import { appSelectors } from 'app/logic/app/logic'

interface NavigationBarProps {
  'data-testid': string
}

const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const isAdmin = useSelector(appSelectors.isAdmin())
  const isOperator = useSelector(appSelectors.isOperator())
  const isDoctor = useSelector(appSelectors.isDoctor())
  const isStaff = useSelector(appSelectors.isStaff())

  if (isAdmin) {
    return <AdminNavigationBar data-testid={`${props['data-testid']}-Admin`} />
  }
  if (isOperator) {
    return <OperatorNavigationBar data-testid={`${props['data-testid']}-Operator`} />
  }
  if (isDoctor) {
    return <DoctorNavigationBar data-testid={`${props['data-testid']}-Doctor`} />
  }
  if (isStaff) {
    return <DoctorNavigationBar data-testid={`${props['data-testid']}-Staff`} />
  }
  return (
    <div data-testid={`${props['data-testid']}-NoNavigationMessage`}>
      No suitable Navigation Bar found for user
    </div>
  )
}

export { NavigationBar }
