import React, { useCallback, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Space } from 'antd'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { Features } from 'app/components/routes/CaseWizard/steps/CasePrescription/ClinicalPreferences/Features'
import { FinalToothPosition } from 'app/components/routes/CaseWizard/steps/CasePrescription/ClinicalPreferences/FinalToothPosition'
import { Staging } from 'app/components/routes/CaseWizard/steps/CasePrescription/ClinicalPreferences/Staging'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext, ThemeType } from 'app/components/ui/Theme/ThemeContext'
import { TreatmentType } from 'app/core/domain/PrimaryCasePreferences'
import styled from 'styled-components'

const ClinicalPreferences = () => {
  const dispatch = useDispatch()
  const isOpened = useSelector(caseWizardSelectors.getModalState())
  const treatmentType = useSelector(caseWizardSelectors.getTreatmentType())
  const {
    patientDetails: { doctorName },
  } = useSelector(caseWizardSelectors.getDraftData())

  const closeModal = useCallback(() => {
    dispatch(caseWizardActions.clinicalPreferencesModalToggled(false))
  }, [dispatch])
  const theme = useContext(ThemeContext)

  return (
    <OrmcoModal
      isOpen={isOpened}
      onRequestClose={closeModal}
      additionalStyles={{
        content: { width: '736px' },
        overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
      }}
    >
      <ClinicalPreferencesModalHeader
        height="unset"
        flexDirection="row"
        contextTheme={theme}
        data-testid="ClinicalPreferencesModalHeader-Header"
      >
        <Typography
          data-testid="ClinicalPreferencesModalHeader-Header-Text"
          component="h2"
          variant="h4"
          textFontWeight="600"
          letterSpacing="0.25px"
        >
          {doctorName}
          <FormattedMessage id="clinicalPreferences.header" />
        </Typography>

        <Button
          type="link"
          onClick={closeModal}
          data-testid="ClinicalPreferencesModalHeader-CloseButton"
        >
          <Cancel />
        </Button>
      </ClinicalPreferencesModalHeader>
      <OrmcoModalBody>
        <Box display="block">
          <Space direction="vertical" size={30}>
            <FinalToothPosition />
            {treatmentType !== TreatmentType.IDB && (
              <>
                <Staging />
                <Features />
              </>
            )}
          </Space>
        </Box>
      </OrmcoModalBody>
    </OrmcoModal>
  )
}

const ClinicalPreferencesModalHeader = styled(OrmcoModalHeader)`
  padding: 24px;

  background-color: ${({ theme, contextTheme }) =>
    contextTheme === ThemeType.BOOTSTRAP ? theme.colors.base100 : theme.colors.primary10};
`

export { ClinicalPreferences }
