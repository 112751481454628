import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router'

import { RadioChangeEvent } from 'antd'
import { ScanViewModal } from 'app/components/routes/CaseWizard/ScanViewModal'
import { caseWizardActions } from 'app/components/routes/CaseWizard/logic'
import { DisplayScanType } from 'app/components/routes/CaseWizard/steps/EditScans/DisplayScanType'
import { Scan } from 'app/components/routes/CaseWizard/steps/EditScans/Scan'
import { ScanActionsButtons } from 'app/components/routes/CaseWizard/steps/EditScans/ScanActionsButtons'
import { ScanPreloaderModal } from 'app/components/routes/CaseWizard/steps/EditScans/ScanPreloaderModal'
import { ScanType } from 'app/components/routes/CaseWizard/steps/EditScans/ScanType'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import { CaseStatus } from 'app/core/domain/CaseStatus'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { ScanTooltip } from './ScanTooltip'
import { ScannerInfoBox } from './ScannerInfoBox'

const Scans = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const allScanTypes = useSelector(editScansSelectors.getAllScanTypes())
  const scanViewUrls = useSelector(editScansSelectors.getScanViewUrls())
  const selectedScanner = useSelector(editScansSelectors.getSelectedScanner())
  const selectedCaseStatus = useSelector(editScansSelectors.getCaseStatus())
  const areScansConfigured = useSelector(editScansSelectors.getAllScanTypesAreConfigured())
  const isSubmittedCase = selectedCaseStatus !== CaseStatus.SAVED
  const isScanListAvailable = allScanTypes && allScanTypes.length > 0
  const scansChangesConfirmed = useSelector(editScansSelectors.getScansChangesConfirmed())

  const [isScanViewerOpen, setIsScanViewerOpen] = useState(false)
  const [isPreloaderModalOpen, setPreloaderModalOpen] = useState(false)

  const handleOpenScanViewer = () => setIsScanViewerOpen(true)
  const handleCloseScanViewer = () => setIsScanViewerOpen(false)
  const handleOpenPreloaderModal = () => setPreloaderModalOpen(true)
  const handleClosePreloaderModal = () => setPreloaderModalOpen(false)

  useEffect(() => {
    if (!selectedScanner && isScanListAvailable && !isSubmittedCase) {
      const firstScan = allScanTypes[0]

      dispatch(
        editScansActions.saveScannerRequested({
          ...firstScan,
          scanOrderId: '',
        }),
      )
    }
  }, [allScanTypes, dispatch, isScanListAvailable, isSubmittedCase, selectedScanner])

  const handleChangeRadio = useCallback(
    (event: RadioChangeEvent) => {
      const selectedScan = event.target.value as Scan
      const isScanDeleted =
        !isSubmittedCase ||
        (isSubmittedCase &&
          !selectedScan.uploadScan &&
          selectedScan.name !== ScanType.THREESHAPESCANS)

      if (isSubmittedCase && !scansChangesConfirmed) {
        dispatch(editScansActions.updateScansChangesConfirmed(true))
      }
      if (
        (selectedScan.uploadScan || selectedScan.name === ScanType.THREESHAPESCANS) &&
        isSubmittedCase
      ) {
        dispatch(
          editScansActions.saveScannerReceived({
            name: selectedScan.name,
            description: selectedScan.description,
            uploadScan: selectedScan.uploadScan,
            scanUploaded: false,
            scanOrderId: '',
          }),
        )
      } else {
        dispatch(
          editScansActions.saveScannerRequested({
            ...selectedScan,
            scanOrderId: '',
          }),
        )
        if (isSubmittedCase && !selectedScan.uploadScan) {
          dispatch(
            editScansActions.saveFileUploadedScanner({
              ...selectedScan,
              scanOrderId: '',
            }),
          )
        }
      }
      if (isScanDeleted) {
        dispatch(
          editScansActions.scanDeleteRequested({
            hideButtonsOnStart: true,
            hideButtonsOnEnd: false,
            updateTimestamp: false,
          }),
        )
      }
      dispatch(editScansActions.getDirectTransferScansFlagRequested(null))
      dispatch(caseWizardActions.removeErrorMessage())
    },
    [dispatch, scansChangesConfirmed, isSubmittedCase],
  )

  useDidMount(() => {
    dispatch(editScansActions.threeShapeAccountsRequested())
    dispatch(editScansActions.getScanListRequest())
    dispatch(editScansActions.getDirectTransferScansFlagRequested(null))
  })

  return (
    <>
      <Prompt
        when={scansChangesConfirmed}
        message={intl.formatMessage({ id: 'scans.popup.confirm' })}
      />
      <OrmcoFormItemVerticalFlex
        hasFeedback
        label={<OrmcoFormItemVerticalHeader headerTextId="editScans.box.label" />}
        data-testid="Scans-OrmcoFormItemVerticalFlex"
        marginBottom="0"
      >
        <EditScansDiv>
          <ScanTypeLabel>
            <Typography component="label" textFontWeight="600">
              <FormattedMessage id="scanner.scanType.label" />
            </Typography>
          </ScanTypeLabel>
          <div>
            {!areScansConfigured && (
              <Typography
                data-testid="Scans-ConfigurationMessageWarning"
                component="span"
                color="danger300"
                textFontWeight="600"
              >
                <FormattedMessage id="scanner.scansAreNotConfigured" />
              </Typography>
            )}
            {isScanListAvailable &&
              allScanTypes.map((scan) => {
                const isScanSelected = selectedScanner?.name === scan.name
                const displayScanType =
                  isScanSelected && (scan.name === ScanType.THREESHAPESCANS || scan.uploadScan)
                const showScanOrderId = isScanSelected && scan.showScanOrderId
                const showDirectTransfer = isScanSelected && scan.showDirectTransfer

                return (
                  <Vertical key={scan.name} data-testid={`Scans-Vertical-${scan.name}`}>
                    <BlockedOrmcoRadio
                      value={scan}
                      onChange={handleChangeRadio}
                      checked={isScanSelected}
                    >
                      <ScanTooltip scan={scan} isSelected={isScanSelected} />

                      {displayScanType && <DisplayScanType selectedScan={scan} />}

                      {isScanSelected && (
                        <ScanActionsButtons
                          handleCloseScanViewer={handleCloseScanViewer}
                          onScanViewerOpen={handleOpenScanViewer}
                          onPreloaderModalOpen={handleOpenPreloaderModal}
                          onPreloaderModalClose={handleClosePreloaderModal}
                        />
                      )}
                      {(showScanOrderId || showDirectTransfer) && (
                        <ScannerInfoBox
                          scannerDescription={scan.description}
                          scanOrderId={selectedScanner?.scanOrderId}
                          data-testid="Scans-ScannerInfoBox"
                          showScanOrderId={showScanOrderId}
                          showDirectTransfer={showDirectTransfer}
                        />
                      )}
                    </BlockedOrmcoRadio>
                  </Vertical>
                )
              })}
          </div>
        </EditScansDiv>

        <ScanViewModal
          isOpen={isScanViewerOpen}
          onClose={handleCloseScanViewer}
          urls={scanViewUrls}
        />

        <ScanPreloaderModal
          isOpen={isPreloaderModalOpen && !scanViewUrls?.length}
          setIsOpen={setPreloaderModalOpen}
        />
      </OrmcoFormItemVerticalFlex>
    </>
  )
}

const OrmcoFormItemVerticalFlex = styled(OrmcoFormItemVertical)`
  &&& {
    &.ant-form-item-control-input-content {
      display: flex !important;
    }
  }
`

const BlockedOrmcoRadio = styled(OrmcoRadio)`
  padding-bottom: 6.5px;

  width: max-content;
  svg {
    display: inline-block;
  }

  &.ant-radio-wrapper {
    display: block;
  }

  &.ant-radio-wrapper::after {
    display: none;
  }
`

const ScanTypeLabel = styled.div`
  padding-left: 40px;

  display: flex;
  align-items: center;

  width: 18%;
`

const EditScansDiv = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin};

  display: flex;

  button + button {
    margin-left: 4px;
  }
`

export { Scans }
