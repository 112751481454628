import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Entry } from 'app/components/routes/CaseWizard/CaseWizard.style'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { Section } from 'app/components/routes/CaseWizard/steps/CasePrescription/ClinicalPreferences/FinalToothPosition'
import { Typography } from 'app/components/ui/SDS/common/Typography'

import { ClinicalPreferencesCaption } from './ClinicalPreferencesCaption'
import { ClinicalPreferencesPropertyCaption } from './ClinicalPreferencesPropertyCaption'
import { ClinicalPreferencesPropertyValue } from './ClinicalPreferencesPropertyValue'
import { FeaturesCutoutPlacements } from './FeaturesCutoutPlacements'

const Features = () => {
  const clinicalPreferences = useSelector(caseWizardSelectors.getClinicalPreferences())

  return (
    <Section>
      <Entry>
        <ClinicalPreferencesCaption
          data-testid="Features-Caption"
          captionTextId="clinicalPreferences.features"
        />
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-DelayStage"
          captionTextId="clinicalPreferences.features.delayStage"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.features.delayStageAttachment === 'YES' && (
            <>
              <FormattedMessage id="clinicalPreferences.yes" />
              <FormattedMessage id="clinicalPreferences.features.startStage" />
              {clinicalPreferences.features.startStage}
            </>
          )}

          {clinicalPreferences?.features.delayStageAttachment === 'NO' && (
            <FormattedMessage id="clinicalPreferences.no" />
          )}
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-ExtrusiveMovements"
          captionTextId="clinicalPreferences.features.extrusiveMovements.caption"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.features.extrusiveMovements"
            values={{ extrusiveMovements: clinicalPreferences?.features.extrusiveMovements }}
          />
          {clinicalPreferences?.features.extrusiveMovements === 'OTHER' &&
            clinicalPreferences.features.extrusiveMovementsOther}
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-RotationalMovements"
          captionTextId="clinicalPreferences.features.rotationalMovements.caption"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.features.rotationalMovements"
            values={{ rotationalMovements: clinicalPreferences?.features.rotationalMovements }}
          />
          {clinicalPreferences?.features.rotationalMovements === 'OTHER' &&
            clinicalPreferences.features.rotationalMovementsOther}
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-ToothWithElasticHook"
          captionTextId="clinicalPreferences.features.toothWithElasticHook"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.features.toothElasticHookRetention"
            values={{
              toothElasticHookRetention: clinicalPreferences?.features.toothElasticHookRetention,
            }}
          />
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-ToothWithButtonCutout"
          captionTextId="clinicalPreferences.features.toothWithButtonCutout"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.features.toothButtonCutoutRetention"
            values={{
              toothButtonCutoutRetention: clinicalPreferences?.features.toothButtonCutoutRetention,
            }}
          />
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-ConflictAttachHooksCutout"
          captionTextId="clinicalPreferences.features.conflictAttachHooksCutout"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.features.conflictBetAttachButtonHook"
            values={{
              conflictBetAttachButtonHook:
                clinicalPreferences?.features.conflictBetAttachButtonHook,
            }}
          />
          {clinicalPreferences?.features.conflictBetAttachButtonHook ===
            'MOVE_HOOK_CUTOUT_ADJACENT_TOOTH' && (
            <Typography color="danger500" textFontWeight="500">
              <FormattedMessage id="clinicalPreferences.features.moveToAttachWarning" />
            </Typography>
          )}
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <FeaturesCutoutPlacements cutoutType="CLASSII" />
      <FeaturesCutoutPlacements cutoutType="CLASSIII" />
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-PosteriorPontics"
          captionTextId="clinicalPreferences.features.posterior.pontics"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.features.posteriorPontics"
            values={{ posteriorPontics: clinicalPreferences?.features.posteriorPontics }}
          />
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Features-PropertyCaption-AdditionalComments"
          captionTextId="clinicalPreferences.features.additionalComntFeature"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.features?.additionalComments ? (
            clinicalPreferences.features.additionalComments
          ) : (
            <FormattedMessage id="clinicalPreferences.none" />
          )}
        </ClinicalPreferencesPropertyValue>
      </Entry>
    </Section>
  )
}

export { Features }
