import styled, { css } from 'styled-components'

interface CasePerksDashboardContainerProps {
  isSDS: boolean
}

const SDSStylesMixin = css`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.base300}`};

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 1px 6px rgba(0, 0, 0, 0.08);
`

const LegacyStylesMixin = css`
  border: ${({ theme }) => `1px solid ${theme.colors.base700}`};

  background-color: ${({ theme }) => theme.colors.base10};
  box-shadow: 2px 2px 3px 0 rgba(0, 70, 127, 0.12);
`

export const CasePerksDashboardContainer = styled.div<CasePerksDashboardContainerProps>`
  width: 100%;

  ${({ isSDS }) => (isSDS ? SDSStylesMixin : LegacyStylesMixin)}
`
