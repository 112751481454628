import { useCallback, useEffect } from 'react'

export const AUTHENTICATED_KEY = 'isAuthenticated'

export const postLogoutMessage = () => localStorage.setItem(AUTHENTICATED_KEY, 'false')

export const useLogoutBroadcast = (onLogout: () => void) => {
  const handleStorageEvent = useCallback(() => {
    if (localStorage.getItem(AUTHENTICATED_KEY) !== 'true') onLogout()
  }, [onLogout])

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [handleStorageEvent])
}
