namespace OperatingSystem {
  export function isWindows() {
    return navigator.userAgent.indexOf('Win') !== -1
  }
  export function isMac() {
    return navigator.userAgent.indexOf('Mac') !== -1
  }
}

export { OperatingSystem }
