import { useSelector } from 'react-redux'

import { featureFlagSelectors } from 'app/logic/features/logic'

import { Address1 } from './Address1'
import { Address2 } from './Address2'
import { AddressTag } from './AddressTag'
import { City } from './City'
import { Country } from './Country'
import { DefaultBillingAddress } from './DefaultBillingAddress'
import { DefaultShippingAddress } from './DefaultShippingAddress'
import { OfficeAddress } from './OfficeAddress'
import { PhoneNumber } from './PhoneNumber'
import { State } from './State'
import { Zip } from './Zip'

const AddAddressFormFields = () => {
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  return (
    <>
      <AddressTag />
      <Address1 />
      <Address2 />
      <City />
      <Country />
      <State />
      <Zip />
      {featurePermissions?.doctorLocator && <PhoneNumber />}
      <DefaultBillingAddress />
      <DefaultShippingAddress />
      {featurePermissions?.doctorLocator && <OfficeAddress />}
    </>
  )
}

export { AddAddressFormFields }
