import { S3UploadCredentials } from 'app/core/domain/S3UploadCredentials'
import AWS from 'aws-sdk'

const FILE_PART_SIZE = 5 * 1024 * 1024

interface MultipartDicomUploaderOptions {
  credentials: S3UploadCredentials
  onProgress: (progress: number) => void
  onSuccess: () => void
  onError: (err: AWS.AWSError) => void
}

class MultipartDicomUploader {
  private readonly credentials: MultipartDicomUploaderOptions['credentials']

  private readonly onProgress: MultipartDicomUploaderOptions['onProgress']

  private readonly onSuccess: MultipartDicomUploaderOptions['onSuccess']

  private readonly onError: MultipartDicomUploaderOptions['onError']

  private readonly multipartUploadParams: AWS.S3.Types.CreateMultipartUploadRequest

  private completedParts: AWS.S3.Types.CompletedPart[]

  private bucket!: AWS.S3

  private numPartsLeft!: number

  private selectedFile!: File

  constructor(options: MultipartDicomUploaderOptions) {
    this.credentials = options.credentials
    this.onProgress = options.onProgress
    this.onError = options.onError
    this.onSuccess = options.onSuccess

    this.multipartUploadParams = {
      Bucket: this.credentials.bucketName,
      Key: this.credentials.path,
      ServerSideEncryption: 'aws:kms',
      SSEKMSKeyId: this.credentials.encryptionKey,
    }

    this.completedParts = []

    AWS.config.update({
      accessKeyId: this.credentials.creds.accessKeyId,
      secretAccessKey: this.credentials.creds.secretAccessKey,
      sessionToken: this.credentials.creds.sessionToken,
    })
  }

  public uploadFile(selectedFile: File) {
    let partNum = 0

    this.bucket = new AWS.S3({
      endpoint: this.credentials.bucketEndpoint,
      maxRetries: 10,
      retryDelayOptions: { base: 500 },
    })

    this.bucket.createMultipartUpload(
      this.multipartUploadParams,
      (createPartError, createPartData) => {
        if (createPartError) {
          this.onError(createPartError)
          return
        }

        const { UploadId } = createPartData

        if (!UploadId) {
          return
        }

        this.selectedFile = selectedFile
        this.numPartsLeft = Math.ceil(this.selectedFile.size / FILE_PART_SIZE)

        for (
          let rangeStart = 0;
          rangeStart < this.selectedFile.size;
          rangeStart += FILE_PART_SIZE
        ) {
          partNum += 1
          const end = Math.min(rangeStart + FILE_PART_SIZE, this.selectedFile.size)
          const partParams = {
            Body: this.selectedFile.slice(rangeStart, end, this.selectedFile.type),
            Key: this.credentials.path,
            Bucket: this.credentials.bucketName,
            PartNumber: partNum,
            UploadId,
          }

          this.uploadPart(partParams)
        }
      },
    )
  }

  private uploadPart(partParams: AWS.S3.Types.UploadPartRequest) {
    this.bucket.uploadPart(partParams, (multiErr, mData) => {
      if (multiErr) {
        this.onError(multiErr)
        return
      }

      this.completedParts[partParams.PartNumber - 1] = {
        ETag: mData.ETag,
        PartNumber: Number(partParams.PartNumber),
      }

      this.numPartsLeft -= 1
      this.onProgress(
        100 - (this.numPartsLeft * 100) / Math.ceil(this.selectedFile.size / FILE_PART_SIZE),
      )

      if (this.numPartsLeft > 0) {
        return
      }

      const doneParams = {
        Key: partParams.Key,
        Bucket: partParams.Bucket,
        MultipartUpload: { Parts: this.completedParts },
        UploadId: partParams.UploadId,
      }

      this.completeMultipartUpload(doneParams)
    })
  }

  private completeMultipartUpload(doneParams: AWS.S3.Types.CompleteMultipartUploadRequest) {
    this.bucket.completeMultipartUpload(doneParams, (err) => {
      if (err) {
        this.onError(err)
      } else {
        this.onSuccess()
      }
    })
  }
}

export { MultipartDicomUploader }
