import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { StaffInput as ScanOrderIdInput } from 'app/components/ui/Form/StaffInput'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

import { ScanOrderType } from './ScanOrderType'
import { editScansActions } from './logic'

interface ScanOrderIdProps {
  scannerDescription: string | undefined
}

const ScanOrderId = ({ scannerDescription }: ScanOrderIdProps) => {
  const dispatch = useDispatch()
  const form = useFormContext<ScanOrderType>()

  const updateScanOrderId = () => {
    dispatch(editScansActions.updateScanOrderIdRequested(form.getValues('scanOrderId') ?? ''))
  }

  const handleScanOrderIdChange = (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget
      const orderId = value.trim()

      dispatch(editScansActions.scanOrderIdChanged(orderId))
      dispatch(editScansActions.getDirectTransferScansFlagRequested(orderId))
  }

  return (
    <Box data-testid="ScanOrderId-div" marginRight="auto" width="max-content" align="flex-start">
      <LabelContainer component="label" textFontWeight="600">
        {scannerDescription} <FormattedMessage id="scanner.orderId" />:
      </LabelContainer>
      <Controller
        name="scanOrderId"
        render={({ field }) => (
          <ScanOrderIdInput
            name={field.name}
            onChange={handleScanOrderIdChange}
            value={field.value}
            onBlur={updateScanOrderId}
          />
        )}
      />
    </Box>
  )
}

const LabelContainer = styled(Typography)`
  margin-top: 2%;

  width: 275px;
`

export { ScanOrderId }
