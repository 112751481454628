import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { caseSubmittedModalActions } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import { PATIENT_PRESCRIPTION_INDEX } from 'app/components/routes/CaseWizard/hybrid/HybridCaseWizardProgress'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { ScanType } from 'app/components/routes/CaseWizard/steps/EditScans/ScanType'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { Button } from 'app/components/ui/Button'
import { LightestInfoButton } from 'app/components/ui/Buttons'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { SiteMap } from 'app/core/react/SiteMap'
import styled from 'styled-components'

import { getV6ApRelationsErrors } from '../validators/getV6ApRelationsErrors'
import { getV6MidlineSelectionErrors } from '../validators/getV6MidlineSelectionErrors'

interface HybridWizardButtonProps {
  isSubmittedCase?: boolean
  hidePrintPrescription?: boolean
  id?: string
  step?: string
}

const PRESCRIPTION_STEP = '3'
const SCANS_STEP = '2'

const HybridWizardButtons: React.FC<HybridWizardButtonProps> = (props) => {
  const { id, step, isSubmittedCase } = props
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())

  const dispatch = useDispatch()
  const history = useHistory()
  const form = useFormContext<CaseDraft>()

  const draft = useSelector(caseWizardSelectors.getDraftData())
  const selectedScanner = useSelector(editScansSelectors.getSelectedScanner())
  const displayScanActionsButtons = useSelector(editScansSelectors.getDisplayScanActionButtons())
  const fileUploadedScanner = useSelector(editScansSelectors.getFileUploadedScanner())
  const formState = form.getValues()

  const disableConfirmAndNextButton = useMemo(() => {
    const checkForUploadScan =
      step === SCANS_STEP &&
      (selectedScanner?.uploadScan || selectedScanner?.name === ScanType.THREESHAPESCANS)
    const scannerChanged = selectedScanner?.name !== fileUploadedScanner?.name

    //TODO: need to update dependencies, since for now isValid state doesn't work properly, so error data was used instead
    if (step !== PRESCRIPTION_STEP && Object.keys(form.formState.errors).length > 0) {
      return true
    }

    if (!checkForUploadScan) {
      return false
    }

    if (!isSubmittedCase) {
      return !displayScanActionsButtons
    }

    return !displayScanActionsButtons && scannerChanged
  }, [
    form.formState,
    displayScanActionsButtons,
    fileUploadedScanner,
    isSubmittedCase,
    selectedScanner,
    step,
  ])

  const afterSubmit = (close: boolean) => {
    if (isV6Form && !close && step && Number(step) === PATIENT_PRESCRIPTION_INDEX) {
      const relationshipErrors = getV6ApRelationsErrors(
        formState.primaryCasePreferences.apRelationshipPrimary,
        formState.primaryCasePreferences.treatArches,
      )
      const midlineSelectionErrors = getV6MidlineSelectionErrors(
        formState.primaryCasePreferences.midlinePreferencesPrimary,
      )

      const errors = [...relationshipErrors, ...midlineSelectionErrors]

      if (errors.length) {
        errors.forEach((error) => form.setError(error.field, { message: error.message }))
      }

      if (form.formState.errors?.primaryCasePreferences) {
        return
      }
    }

    if (!id) {
      dispatch(caseWizardActions.createCaseButtonClicked(draft.patientDetails))
      return
    }

    dispatch(caseWizardActions.updateCaseDraftRequested(draft))

    if (!close) {
      dispatch(caseWizardActions.nextButtonClicked())
      if (step) {
        const parseStep = parseInt(step, 10)

        if (parseStep !== PATIENT_PRESCRIPTION_INDEX) {
          dispatch(caseWizardActions.instantCaseStepChosen(parseStep + 1))
          history.push(`/caseWizard?id=${id}&step=${parseStep + 1}`)
        }
      }
    } else {
      history.push('/homePage')
    }
  }

  const saveAndPrintBehaviour = useCallback(() => {
    dispatch(caseSubmittedModalActions.saveAndGoToPrintPageEpic())
  }, [dispatch])

  const onCancel = useCallback(() => {
    dispatch(caseWizardActions.openCancelDialogButtonClicked(true))
  }, [dispatch])

  const onClose = useCallback(() => {
    if (id) {
      dispatch(editScansActions.updateScansChangesConfirmed(false))
      window.location.href = `${SiteMap.patiensDetails(id)}#ImagesLoadTab`
    }
  }, [id, dispatch])

  const onConfirm = useCallback(() => {
    if (id) {
      dispatch(editScansActions.updateScansChangesConfirmed(false))
      dispatch(editScansActions.updateCaseStatusRequested({ caseId: id }))
    }
  }, [dispatch, id])

  return (
    <WizardButtonsContainer
      margin="0"
      padding="8px"
      justify="space-between"
      boxSizing="border-box"
      data-testid="HybridWizardButton-WizardButtonsContainer"
    >
      {isSubmittedCase ? (
        <>
          <Box width="auto" justify="space-between" marginLeft="auto">
            <ConfirmButton
              as="button"
              disabled={disableConfirmAndNextButton}
              onClick={onConfirm}
              data-testid="HybridWizardButtons-ConfirmButton"
            >
              <FormattedMessage id="footer.confirm" />
            </ConfirmButton>
            <Padder width="10px" />
            <Button
              disabled={disableConfirmAndNextButton}
              onClick={onClose}
              data-testid="HybridWizardButtons-BlueButton"
            >
              <FormattedMessage id="footer.close" />
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box flexBasis="300px" width="auto" justify="space-between">
            <Button variant="danger" onClick={onCancel} data-testid="WizardButtons-ButtonCancel">
              <FormattedMessage id="caseWizard.navigation.cancel" />
            </Button>
          </Box>
          <Box justify="space-between" width="auto">
            <Button
              variant="white"
              onClick={() => afterSubmit(true)}
              data-testid="HybridWizardButtons-GreyButton"
            >
              <FormattedMessage id="caseWizard.navigation.close" />
            </Button>
            <Padder width="10px" />
            {!props.hidePrintPrescription && (
              <Button
                disabled={id === undefined}
                onClick={saveAndPrintBehaviour}
                data-testid="WizardButtons-ButtonPrint"
              >
                <FormattedMessage id="caseWizard.casePrescription.printPrescription" />
              </Button>
            )}
            <Padder width="10px" />
            <Button
              disabled={disableConfirmAndNextButton}
              onClick={() => afterSubmit(false)}
              data-testid="HybridWizardButtons-BlueButtonNext"
            >
              <FormattedMessage id="caseWizard.navigation.next" />
            </Button>
          </Box>
        </>
      )}
    </WizardButtonsContainer>
  )
}

const WizardButtonsContainer = styled(Box)`
  position: sticky;
  bottom: 0;

  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.base300};

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1.5px 1.5px 1.5px 0.5px ${({ theme }) => theme.colors.base200};
`

const ConfirmButton = styled(LightestInfoButton)`
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`

export { HybridWizardButtons }
