import { createGlobalStyle, css } from 'styled-components'

import { SdsTooltipGlobalStyle } from './components/ui/SDS/common/Tooltip/tooltip.styles'

const dropdownMenuTitleContentMixin = (isDark: boolean) => css`
  .ant-dropdown-menu-title-content {
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      background: ${({ theme }) => theme.colors[isDark ? 'primary600' : 'primary10']};
    }

    ${({ theme }) =>
      isDark ? `color: ${theme.colors.white};` : `&:hover { color: ${theme.colors.primary500}; }`}
  }
`

export const GlobalStyle = createGlobalStyle`
  ${SdsTooltipGlobalStyle}
  body {
    .ant-dropdown {
      z-index: 10000;
      
      .ant-dropdown-menu, .ant-dropdown-menu-item {
        padding: 0;
        
        line-height: 18px;
      }
      
      .ant-dropdown-menu-item:hover {
        background: transparent;
      }

      ${dropdownMenuTitleContentMixin(false)}
      
      .ant-dropdown-menu-item-selected {
        background-color: ${({ theme }) => theme.colors.primary100};
        &.ant-dropdown-menu-title-content {
          color: ${({ theme }) => theme.colors.primary500};
        }
      }
      
      .sds-dropdown-menu-dark {
        background-color: ${({ theme }) => theme.colors.primary700};
        ${dropdownMenuTitleContentMixin(true)}
        .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
          background-color: ${({ theme }) => theme.colors.primary500};
        }
      }
    }

    .ant-select-dropdown.select-legacy-dropdown {
      border: 1px solid ${({ theme }) => theme.colors.base300};
    
      border-radius: 4px;

      .ant-select-item-option-content {
        font-size: 13px;
        font-weight: 400;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: ${({ theme }) => theme.colors.base10};
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color: ${({ theme }) => theme.colors.primary10};
      }
    }
  }
`
