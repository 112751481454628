import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'

import { staffCreationSelectors } from './logic'

const StaffUserName: React.FC = () => {
  const intl = useIntl()
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalInformation>()
  const isStaffUpdateFlow = useSelector(staffCreationSelectors.isStaffUpdateFlow())

  return (
    <>
      <StaffFormItemVertical
        colon={false}
        label={<InputLabel labelId="staff.staffDetails.staffUserName.label" />}
        hasFeedback
        validateStatus={getValidationStatus('username', errors)}
        help={<OrmcoFormErrorMessage errors={errors} name="username" />}
        data-testid="StaffDetails-StaffUserName"
      >
        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <StaffInput
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              readOnly={isStaffUpdateFlow}
            />
          )}
          rules={{
            validate: (data) => {
              if (!data) {
                return intl.formatMessage({ id: 'staff.staffDetails.staffUserName.required' })
              }
              const upperCasePattern = /[A-Z]/
              const someLetterIsUppercase = data.match(upperCasePattern)
              const validUserNamePattern = /^[a-z0-9-]{1,50}$/
              const allLettersAreAlphanumeric = data.match(validUserNamePattern)

              if (someLetterIsUppercase) {
                return intl.formatMessage({ id: 'staff.staffDetails.staffUserName.inLowerCase' })
              }

              if (!allLettersAreAlphanumeric) {
                return intl.formatMessage({ id: 'staff.staffDetails.staffUserName.letter' })
              }
              return true
            },
          }}
        />
      </StaffFormItemVertical>
    </>
  )
}

export { StaffUserName }
