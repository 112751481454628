import React, { useContext } from 'react'
import { Panel } from 'react-bootstrap'

import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { ThemeContext, ThemeType } from 'app/components/ui/Theme/ThemeContext'
import PanelBody from 'react-bootstrap/lib/PanelBody'
import PanelHeading from 'react-bootstrap/lib/PanelHeading'
import styled from 'styled-components'

interface OrmcoFormItemVerticalProps extends FormItemProps {
  'data-testid'?: string
  marginBottom?: string
}

const OrmcoFormItemVertical: React.FC<OrmcoFormItemVerticalProps> = (props) => {
  const { label, marginBottom, help, children, validateStatus } = props
  const theme = useContext(ThemeContext)

  return (
    <>
      {theme === ThemeType.ANTD ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <OrmcoFormItemVerticalItem {...props} colon={!!props.colon} label={label}>
          {children}
        </OrmcoFormItemVerticalItem>
      ) : (
        <PanelMargined data-testid={props['data-testid']} marginBottom={marginBottom}>
          <PanelHeading data-testid={`${props['data-testid']}-PanelHeading`}>{label}</PanelHeading>
          <PanelBody data-testid={`${props['data-testid']}-PanelBody`}>
            <FormItem validateStatus={validateStatus} help={help}>
              {children}
            </FormItem>
          </PanelBody>
        </PanelMargined>
      )}
    </>
  )
}

const FormItem = styled(Form.Item)<FormItemProps<any>>`
  &&& {
    margin-bottom: 0;

    .ant-input-textarea-show-count::after {
      letter-spacing: 0.2px;

      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      line-height: 16px;

      color: ${({ theme, validateStatus }) =>
        validateStatus === 'error' ? theme.colors.danger400 : theme.colors.base700};
    }

    .ant-form-item-explain {
      position: absolute;
      bottom: -8px;

      font-size: 11px;

      color: ${({ theme }) => theme.colors.danger400};
    }
  }
`

const OrmcoFormItemVerticalItem = styled(Form.Item)`
  &&& {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-control {
      width: 100%;
    }
  }
`

interface PanelMarginedProps {
  marginBottom?: string
}

const PanelMargined = styled(Panel).withConfig<PanelMarginedProps>({
  shouldForwardProp: (propName) => propName !== 'marginBottom',
})`
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '1em'};
`

export { OrmcoFormItemVertical }
