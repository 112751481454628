import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface SubmitRequestCompletePayload {
  caseId: Nullable<string>
  notes: string
}

interface DoctorNotesState {
  editingCaseId: Nullable<string>
  hoveringCaseId: Nullable<string>
}

const INITIAL_STATE: DoctorNotesState = {
  editingCaseId: null,
  hoveringCaseId: null,
}

const doctorNoteActions = {
  doctorNoteHovered: createAction('@DOCTOR_NOTE/DOCTOR_NOTE_HOVERED')<{ caseId: string }>(),
  editNoteButtonClicked: createAction('@DOCTOR_NOTE/EDIT_NOTE_BUTTON_CLICKED')<{
    caseId: string
  }>(),
  cancelButtonClicked: createAction('@DOCTOR_NOTE/CANCEL_BUTTON_CLICKED')(),
  submitButtonClicked: createAction('@DOCTOR_NOTE/SUBMIT_BUTTON_CLICKED')<{ text: string }>(),
  submitRequestComplete: createAction(
    '@DOCTOR_NOTE/SUBMIT_REQUEST_COMPLETE',
  )<SubmitRequestCompletePayload>(),
  submitRequestFailed: createAction('@DOCTOR_NOTE/SUBMIT_REQUEST_FAILED')<{ message: string }>(),
}

type DoctorNoteActions = ActionType<typeof doctorNoteActions>

const doctorNoteReducer = createReducer<DoctorNotesState, DoctorNoteActions>(INITIAL_STATE)
  .handleAction(doctorNoteActions.editNoteButtonClicked, (state, action) => ({
    ...state,
    editingCaseId: action.payload.caseId,
  }))
  .handleAction(doctorNoteActions.doctorNoteHovered, (state, action) => ({
    ...state,
    hoveringCaseId: state.hoveringCaseId === action.payload.caseId ? null : action.payload.caseId,
  }))
  .handleAction(
    [
      doctorNoteActions.submitRequestComplete,
      doctorNoteActions.submitRequestFailed,
      doctorNoteActions.cancelButtonClicked,
    ],
    (state) => ({
      ...state,
      editingCaseId: null,
      hoveringCaseId: null,
    }),
  )

const doctorNotesSelectors = {
  getEditingCaseId: () => (state: RootState) => state.doctorNote.editingCaseId,
  getHoveringCaseId: () => (state: RootState) => state.doctorNote.hoveringCaseId,
}

export {
  SubmitRequestCompletePayload,
  DoctorNotesState,
  DoctorNoteActions,
  doctorNoteActions,
  doctorNoteReducer,
  doctorNotesSelectors,
}
