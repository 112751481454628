import React from 'react'
import { useSelector } from 'react-redux'

import { appSelectors } from 'app/logic/app/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const CustomerId: React.FC = () => {
  const { customerId } = useSelector(appSelectors.getEmployeePersonalDetails())

  return (
    <ReadonlyValue data-testid="MyProfile-CustomerId" labelId="customer.id" value={customerId} />
  )
}

export { CustomerId }
