import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { LabeledTooltip } from 'app/components/ui/Tooltip/LabeledTooltip'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { featureFlagSelectors } from 'app/logic/features/logic'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'

interface CommonSettingsProps {
  testId: string
  onChange: (value: ApRelationshipPrimary) => void
}

const CommonSettings = ({ testId, onChange }: CommonSettingsProps) => {
  const form = useFormContext<CaseDraft>()
  const apRelationshipPrimary = form.getValues(PROPERTY_KEY)

  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const cutoutAndElasticsMessageId = `caseWizard.casePrescription.anteriorPosteriorRelationship.${
    featurePermissions?.integratedHooks ? 'useAlignerElastics' : 'useAlignerCutouts'
  }`

  const setVirtualSimulation = (selected: CheckboxChangeEvent) => {
    onChange({
      ...apRelationshipPrimary,
      virtualSimulation: selected.target.checked,
    })

    form.clearErrors()
  }

  const setAlignerCutouts = (selected: CheckboxChangeEvent) => {
    onChange({
      ...apRelationshipPrimary,
      alignerCutouts: selected.target.checked,
    })
    form.clearErrors()
  }

  return (
    <>
      <Box padding="8px" width="auto" justify="flex-start">
        <OrmcoCheckbox
          onChange={setVirtualSimulation}
          checked={apRelationshipPrimary?.virtualSimulation === true}
          data-testid={`AnteriorPosteriorRelationship-${testId}-VirtualSimulation`}
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.useVirtualSimulation" />
          </Typography>
        </OrmcoCheckbox>
      </Box>
      <Box padding="8px" width="auto" justify="flex-start">
        <OrmcoCheckbox
          onChange={setAlignerCutouts}
          checked={apRelationshipPrimary && apRelationshipPrimary.alignerCutouts === true}
          data-testid={`AnteriorPosteriorRelationship-${testId}-AlignerElastics`}
        >
          <Typography component="span">
            <FormattedMessage id={cutoutAndElasticsMessageId} />
          </Typography>
        </OrmcoCheckbox>
        {featurePermissions?.integratedHooks && (
          <LabeledTooltip
            data-testid={`AnteriorPosteriorRelationship-${testId}-AlignerCutouts-Tooltip`}
            title={
              <TooltipText
                data-testid="CommonAlignerSettings-TooltipContent"
                textId="caseWizard.casePrescription.anteriorPosteriorRelationship.useAlignerCutouts.tooltip"
              />
            }
            overlayInnerStyle={{
              textAlign: 'justify',
              padding: '11px',
            }}
          >
            <HelpIcon data-testid="CommonAlignerSettings-TooltipIcon" />
          </LabeledTooltip>
        )}
      </Box>
    </>
  )
}

export { CommonSettings }
