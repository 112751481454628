import React, { useState, useEffect, useRef } from 'react'

import { Tooltip, TooltipProps } from 'app/components/ui/SDS/common/Tooltip'

export const OverflowTooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
  const [isTargetComponentOverflow, setIsTargetComponentOverflow] = useState(false)
  const targetComponentRef = useRef<HTMLElement>(null)

  if (!React.isValidElement(React.Children.only(children))) {
    throw new Error('children have to be a single element with text content')
  }

  useEffect(() => {
    const targetHtmlElement: HTMLElement | null = targetComponentRef.current

    setTimeout(() => {
      if (targetHtmlElement) {
        setIsTargetComponentOverflow(targetHtmlElement.scrollWidth > targetHtmlElement.offsetWidth)
      }
    }, 0)
  })

  const targetComponent = React.Children.map(children, (child: any) =>
    React.cloneElement(child, { ref: targetComponentRef }),
  )

  return isTargetComponentOverflow ? (
    <Tooltip {...rest}>{targetComponent}</Tooltip>
  ) : (
    <>{targetComponent}</>
  )
}
