import React from 'react'

import { ColumnSetting } from 'app/components/ui/ColumnSettings'
import { Column } from 'app/components/ui/Table/Column'
import styled from 'styled-components'

interface TableColumnsProps<T> {
  columnSettings: ColumnSetting<T>[]
  data: T
  'data-testid'?: string
}

const TableColumns = <T extends any>(props: TableColumnsProps<T>) => {
  const { columnSettings, data, 'data-testid': testId } = props

  return (
    <>
      {columnSettings.map((column) => {
        const Component: React.FC<{ data: T }> = column.contentComponent

        return (
          <TableBodyColumn
            key={column.header}
            width={column.width}
            data-testid={`${testId}-${column.header}`}
          >
            <Component data={data} />
          </TableBodyColumn>
        )
      })}
    </>
  )
}

const TableBodyColumn = styled(Column)`
  justify-content: center;
  align-content: center;

  height: 100%;

  border-left: 0.5px solid ${({ theme }) => theme.colors.base300};
`

export { TableColumns }
