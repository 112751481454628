import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { InfoButton } from 'app/components/ui/Buttons'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

interface ImpersonationInfoProps {
  'data-testid': string
}

const ImpersonationInfo: React.FC<ImpersonationInfoProps> = (props) => {
  const impersonator = useSelector(appSelectors.getImpersonator())
  const username = useSelector(appSelectors.getUsername())

  const handleBackToAdmin = () => {
    sessionStorage.clear()
  }

  return (
    <ImpersonationInfoContainer
      marginLeft="24px"
      padding="2px 5px"
      height="40px"
      minWidth="270px"
      data-testid={props['data-testid']}
    >
      <Box flexDirection="column" align="flex-start">
        <Typography
          data-testid={`${props['data-testid']}-Title`}
          component="span"
          variant="extra-small"
          textFontWeight="600"
          color="white"
        >
          <FormattedMessage id="impersonation.title" />
        </Typography>
        <Typography
          data-testid={`${props['data-testid']}-Impersonator`}
          component="span"
          variant="extra-small"
          textFontWeight="600"
          color="white"
        >
          <FormattedMessage
            id="impersonation.info"
            values={{
              impersonator,
              username,
            }}
          />
        </Typography>
      </Box>
      <ClearImpersonationButton href={SiteMap.clearImpersonation()} onClick={handleBackToAdmin}>
        <FormattedMessage id="impersonation.clear" values={{ impersonator }} />
      </ClearImpersonationButton>
    </ImpersonationInfoContainer>
  )
}

const ImpersonationInfoContainer = styled(Box)`
  overflow: hidden;

  background: orange;
`

const ClearImpersonationButton = styled(InfoButton)`
  margin: 10px;
  padding: 3px;

  user-select: none;

  height: auto;

  white-space: nowrap;
  text-align: center;

  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;

  color: ${({ theme }) => theme.colors.white};
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 0;
  touch-action: manipulation;
  vertical-align: middle;
`

export { ImpersonationInfo }
