import { Button } from 'antd'
import styled, { css } from 'styled-components'

//TODO: Move it to polymorphic blueButton
const InfoButton = styled.a`
  padding: 0 12px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.primary700};

  text-decoration: none;

  font-size: 13px;
  font-weight: 600;

  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) =>
    `${theme.colors.primary800}
       linear-gradient(to bottom, ${theme.colors.primary600} 0, ${theme.colors.primary700} 100%) repeat-x`};
  border-radius: 4px;

  transition: none;

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary800};

    color: ${({ theme }) => theme.colors.white};

    background-color: ${({ theme }) => theme.colors.primary700};
  }

  &:active,
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &[disabled] {
    filter: grayscale(1);
  }
`

const LightInfoButton = styled(InfoButton)`
  border-color: ${({ theme }) => theme.colors.primary400};

  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.primary300} 0, ${theme.colors.primary400} 100%)`};
`
const LightestInfoButton = styled(InfoButton)`
  color: ${({ theme }) => theme.colors.primary800};
  background: ${({ theme }) => theme.colors.primary100};
`

const RedButton = styled(InfoButton)`
  padding: 0 12px;

  justify-content: center;

  min-width: 108px;
  height: 40px;
  border-color: ${({ theme }) => theme.colors.danger300};

  letter-spacing: 0.1px;

  font-weight: 600;
  line-height: 18px;

  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.danger300} 0, ${theme.colors.danger400} 100%)`};

  &:hover {
    border-color: ${({ theme }) => theme.colors.danger500};

    color: ${({ theme }) => theme.colors.base100};
    background: ${({ theme }) => theme.colors.danger400};
  }
`

interface PrimaryButtonProps {
  disabled?: boolean
  padding?: string
}

const primaryButtonDisabledMixin = (props: PrimaryButtonProps) =>
  props.disabled &&
  css`
    color: ${({ theme }) => theme.colors.base300};
    background: ${({ theme }) => theme.colors.primary200};
  `

const PrimaryButton = styled(InfoButton)<PrimaryButtonProps>`
  padding: ${(props) => (props.padding ? props.padding : '0 24px')};

  height: 40px;

  background: ${({ theme }) => theme.colors.primary800};

  ${primaryButtonDisabledMixin};
`

const LightBlueButton = styled(InfoButton)`
  text-align: center;
  letter-spacing: 0.1px;

  font-weight: 500;

  color: ${({ theme }) => theme.colors.primary800};
  background: ${({ theme }) => theme.colors.primary10};

  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary800};

    color: ${({ theme }) => theme.colors.primary800};
  }

  &[disabled] {
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
`

interface IconButtonProps {
  width?: string
  height?: string
  fontSize?: string
}

const IconButton = styled(InfoButton)<IconButtonProps>`
  padding: 0;

  width: ${(props) => props.width ?? 'auto'};
  height: ${(props) => props.height ?? 'auto'};
  border: none;

  font-size: ${(props) => props.fontSize ?? '24px'};

  color: ${({ theme }) => theme.colors.black};
  background: transparent;
  fill: ${({ theme }) => theme.colors.black};

  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }
`

const LightBlueButtonWithBorder = styled(Button)`
  &&& {
    padding-top: 0;
    padding-bottom: 0;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    min-width: 108px;
    max-width: fit-content;
    height: 40px;
    border-color: transparent;

    text-align: center;
    letter-spacing: 0.1px;

    font-size: 13px;
    font-weight: 600;
    line-height: 18px;

    color: ${({ theme }) => theme.colors.primary800};
    background-color: ${({ theme }) => theme.colors.primary10};
    border-radius: 4px;

    transition: none;

    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.primary800};
    }

    &&:disabled {
      border-color: transparent;

      background-color: ${({ theme }) => theme.colors.primary10};
    }

    svg {
      fill: currentColor;
    }
  }
`

export {
  RedButton,
  IconButton,
  InfoButton,
  LightInfoButton,
  LightestInfoButton,
  LightBlueButton,
  PrimaryButton,
  LightBlueButtonWithBorder,
}
