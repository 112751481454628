import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CasePerksProgress } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/components/CasePerksProgress'
import { useCasePerksProgressData } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/hooks'
import { iconMap } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/interfaces/iconMap'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ensure } from 'app/core/utils/ensure'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { ThemeColors } from 'styled-components'

import { StatusContainer } from './styles'

export const CasePerks = () => {
  const { casePerksGradations, badge, dates, testId } = ensure(useCasePerksProgressData())
  const Icon = badge && iconMap[badge]
  const isSDS = useSelector(featureFlagSelectors.isSDS())
  const textColor: keyof ThemeColors = isSDS ? 'base600' : 'base900'

  return (
    <Box data-testid="CasePerks" width="auto" height="82px" justify="flex-start" align="normal">
      <StatusContainer isSDS={isSDS} flexBasis="23%">
        <Box margin="0 10px" maxHeight="32px">
          {Icon && <Icon data-testid={`CasePerks-${badge}Badge`} />}
        </Box>

        <Box flexDirection="column" align="flex-start" minWidth="10em">
          <Typography
            data-testid="CasePerks-PartnerPerks"
            component="span"
            color={textColor}
            letterSpacing="-0.3px"
          >
            <FormattedMessage id="perks.partnerPerks" />
          </Typography>
          <Typography
            data-testid="CasePerks-CurrentLevel"
            component="span"
            color={textColor}
            textFontWeight="600"
          >
            <FormattedMessage id="perks.currentLevel" />
          </Typography>
          <Typography
            data-testid="CasePerks-Badge"
            component="span"
            color={textColor}
            letterSpacing="-0.3px"
          >
            <FormattedMessage id="perks.badge" values={{ badge }} />
          </Typography>
        </Box>
      </StatusContainer>

      <CasePerksProgress dates={dates} gradations={casePerksGradations} data-testid={testId} />
    </Box>
  )
}
