import { Panel, Well } from 'react-bootstrap'

import styled from 'styled-components'

const CasePrescriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  box-sizing: border-box;
  width: 100%;
`

const CaseName = styled(Well)`
  margin: 10px 10px 0 10px;
  padding: 15px;

  min-height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.base200};

  background: ${({ theme }) =>
    `${theme.colors.base100}
     linear-gradient(to bottom, ${theme.colors.base100} 0, ${theme.colors.base10} 100%) repeat-x`};
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
`

const FormContainer = styled(Panel)`
  margin: 22px 0;

  width: 97%;
`

const Entry = styled.div`
  padding: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.base200};
`

export { CasePrescriptionForm, CaseName, Entry, FormContainer }
