import { isNil } from '../isNil'

type PrimitiveValues = null | undefined | string | number | boolean | symbol | bigint
type IsTuple<T extends ReadonlyArray<any>> = number extends T['length'] ? false : true
type TupleKeys<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>
type PathImpl<K extends string | number, V> = V extends PrimitiveValues
  ? `${K}`
  : `${K}` | `${K}.${Path<V>}`

export type Path<T> = T extends ReadonlyArray<infer V>
  ? IsTuple<T> extends true
    ? {
        [K in TupleKeys<T>]-?: PathImpl<K & string, T[K]>
      }[TupleKeys<T>]
    : PathImpl<number, V>
  : {
      [K in keyof T]-?: PathImpl<K & string, T[K]>
    }[keyof T]

export const deepFindByPath = <T extends Record<string, any>>(obj: T, path: Path<T>) => {
  if (Object.keys(obj).length === 0) {
    return { found: false }
  }

  const splittenPath = path.split('.')
  let resultObj = obj

  for (const level of splittenPath) {
    if (isNil(resultObj[level])) {
      return { found: false }
    }

    resultObj = resultObj[level]
  }

  return { found: true, value: resultObj }
}
