import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { InputLabel } from 'app/components/ui/Input/InputLabel'

import { AddressInput } from './AddressInput'

const Zip = () => (
  <StaffFormItemVertical
    colon={false}
    label={<InputLabel labelId="addresses.addressForm.zip" isOptional />}
    hasFeedback
    data-testid="AddAddress-Zip"
  >
    <AddressInput name="zipCode" />
  </StaffFormItemVertical>
)

export { Zip }
