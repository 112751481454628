enum DoctorCaseTabs {
  'actionRequired' = 'actionRequired',
  'withOrmco' = 'withOrmco',
  'shipped' = 'shipped',
  'archived' = 'archived',
}

interface DoctorCaseCount {
  [DoctorCaseTabs.actionRequired]: number
  [DoctorCaseTabs.withOrmco]: number
  [DoctorCaseTabs.shipped]: number
  [DoctorCaseTabs.archived]: number
}

export { DoctorCaseCount, DoctorCaseTabs }
