import React from 'react'
import { useIntl } from 'react-intl'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

const OrderCard: React.FC<{ data: CaseTableData }> = (props) => {
  const intl = useIntl()

  const { order, productTier } = props.data.case
  const ordersEntries = order && (
    <span key={order.name}>
      {order.name}
      {order.stage > 0 && order.stage}
    </span>
  )
  const productName = intl.formatMessage({ id: 'cases.list.productTier' }, { productTier })

  return (
    <Box padding="10px" flexDirection="column" align="flex-start">
      <Typography component="span">{ordersEntries}</Typography>

      {productTier && <Typography component="span">{productName}</Typography>}
    </Box>
  )
}

export { OrderCard }
