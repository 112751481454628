import { FormattedMessage } from 'react-intl'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

interface VerticalReadonlyValueProps {
  labelTextId: string
  value?: string
}

export const VerticalReadonlyValue = (props: VerticalReadonlyValueProps) => {
  return (
    <Box margin="0 0 16px" flexDirection="column" align="flex-start">
      <Typography component="label" textFontWeight="600" letterSpacing="0.1px" margin="0 0 5px">
        <FormattedMessage id={props.labelTextId} />
      </Typography>
      <Typography
        component="span"
        textFontWeight="600"
        color="base800"
        letterSpacing="0.1px"
        margin="0 0 5px"
      >
        {props.value}
      </Typography>
    </Box>
  )
}
