import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { OverjetPrimary } from 'app/core/domain/PrimaryCasePreferences'

const PROPERTY_KEY = 'primaryCasePreferences.overjetPrimary'

const Overjet = () => {
  const form = useFormContext<CaseDraft>()
  const overjet = form.getValues(PROPERTY_KEY) ?? OverjetPrimary.MAINTAIN
  const changeRadio = useCallback(
    (res: OverjetPrimary) => {
      form.setValue(PROPERTY_KEY, res)
    },
    [form],
  )

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.improveOverjet.label" />
      }
      hasFeedback
      data-testid="Overjet"
    >
      <Vertical>
        <OrmcoRadio
          onChange={() => changeRadio(OverjetPrimary.MAINTAIN)}
          checked={overjet === OverjetPrimary.MAINTAIN}
          data-testid="Overjet-Maintain"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.improveOverjet.false" />
          </Typography>
        </OrmcoRadio>
        <OrmcoRadio
          onChange={() => changeRadio(OverjetPrimary.IMPROVE)}
          checked={overjet === OverjetPrimary.IMPROVE}
          data-testid="Overjet-Improve"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.improveOverjet.true" />
          </Typography>
        </OrmcoRadio>
      </Vertical>
    </OrmcoFormItemVertical>
  )
}

export { Overjet }
