import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { SelectorDropdownIcon } from 'app/components/ui/Select/SelectorDropdownIcon'
import styled from 'styled-components'

import { caseFilterActions, caseFilterSelectors } from '../logic'

interface PatientFilterNameProps {
  'data-testid': string
}

const PatientFilterName: React.FC<PatientFilterNameProps> = (props) => {
  const dispatch = useDispatch()
  const caseFilterName = useSelector(caseFilterSelectors.getCaseFilterName())
  const caseFilterUsers = useSelector(caseFilterSelectors.getCaseFilterUsers())

  const caseFilterClick = () => {
    if (caseFilterUsers !== undefined && caseFilterUsers.length > 0) {
      dispatch(caseFilterActions.caseFilterClicked())
    }
  }

  return (
    <PatientFilterNameContainer
      padding="0 8px"
      width="152px"
      height="32px"
      justify="flex-start"
      onClick={caseFilterClick}
      data-testid={props['data-testid']}
    >
      <PatientFilterNameText component="span" color="base900" whiteSpace="nowrap">
        <FormattedMessage id={caseFilterName} />
      </PatientFilterNameText>
      <SelectorDropdownIcon />
    </PatientFilterNameContainer>
  )
}

export { PatientFilterName }

const PatientFilterNameContainer = styled(Box)`
  cursor: pointer;

  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 4px;
`

const PatientFilterNameText = styled(Typography)`
  overflow: hidden;

  width: 100%;
  height: 20px;

  text-overflow: ellipsis;
`
