export const V6FormValues = [
  {
    inputKey: 'aPrelationshipDistalization',
    optionsKey: 'aPrelationshipDistalizationOptions',
  },
  {
    inputKey: 'posteriorIPR',
    optionsKey: 'posteriorIPROptions',
  },
  {
    inputKey: 'mesialization',
    optionsKey: 'mesializationOptions',
  },
] as const

export const standardApRelationshipsValues = {
  alignerCutouts: false,
  virtualSimulation: false,
  posteriorIPR: false,
  IPR3_6: false,
}

export const standardApRelationshipsV6Values = {
  ...standardApRelationshipsValues,
  mesialization: false,
  aPrelationshipDistalization: false,
  aPrelationshipDistalizationOptions: null,
  mesializationOptions: null,
  posteriorIPROptions: null,
}
