import { Input } from 'antd'
import styled, { css, ThemeColors } from 'styled-components'

const borderColor = (color: keyof ThemeColors) => css`
  border-color: ${({ theme }) => theme.colors[color]};
`

export const TextArea = styled(Input.TextArea)`
  .ant-input {
    ${borderColor('base200')}
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    box-shadow: none;
    ${borderColor('primary500')}
    &[disabled] {
      ${borderColor('base200')}
    }
  }

  &.sds-form-control-error {
    .ant-input,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input:hover {
      ${borderColor('danger400')}
    }
  }

  &.ant-input-textarea-show-count::after {
    margin-top: 4px;

    word-spacing: -4px;

    font-size: 14px;
    line-height: 18px;
  }
`
