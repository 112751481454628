import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

interface FullNoteLinkProps {
  notes?: string
  'data-testid'?: string
}

const FullNoteLink: React.FC<FullNoteLinkProps> = (props) => {
  const { notes } = props
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const showEditNoteLink = notes && !featurePermissions?.disableOfficeNotes

  return (
    <Box flexDirection="column" align="flex-start" data-testid={props['data-testid']}>
      <Typography textOverflow="ellipsis">{notes}</Typography>
      {showEditNoteLink && (
        <EditNoteLink>
          <FormattedMessage id="cases.list.doctor.notes.editNote" />
        </EditNoteLink>
      )}
    </Box>
  )
}

const EditNoteLink = styled.a`
  align-self: flex-end;

  text-decoration: underline;
`

export { FullNoteLink }
