import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import { AffectedArche } from 'app/core/domain/AffectedArches'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import {
  IntAnteriorTeeth,
  OverbiteActionPrimary,
  OverbitePrimary,
} from 'app/core/domain/OverbitePrimary'
import { OverbiteType } from 'app/core/domain/OverbiteType'

import { CorrectDeepBite } from './CorrectDeepBite'
import { CorrectOpenBite } from './CorrectOpenBite'

const PROPERTY_KEY = 'primaryCasePreferences.overbitePrimary'

const Overbite = () => {
  const form = useFormContext<CaseDraft>()

  const overbitePrimary = form.getValues(PROPERTY_KEY)
  const changeRadio = useCallback(
    (res: OverbitePrimary) => {
      form.setValue(PROPERTY_KEY, res)
    },
    [form],
  )

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.overbiteAction.label" />
      }
      hasFeedback
      data-testid="Overbite"
    >
      <Vertical>
        <OrmcoRadio
          value={undefined}
          checked={
            overbitePrimary === undefined ||
            overbitePrimary.overbiteActionPrimary === OverbiteActionPrimary.MAINTAIN
          }
          onChange={() =>
            changeRadio({
              overbiteActionPrimary: OverbiteActionPrimary.MAINTAIN,
            })
          }
          data-testid="Overbite-Maintain"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.overbiteAction.maintain" />
          </Typography>
        </OrmcoRadio>
        <OrmcoRadio
          value={OverbiteType.DEEP}
          onChange={() =>
            changeRadio({
              overbiteActionPrimary: OverbiteActionPrimary.CORRECT_DEEP_BITE,
              intAnteriorTeeth: IntAnteriorTeeth.INT_ANT_TEETH_ONLY,
              biteLowerUpper: AffectedArche.BOTH,
            })
          }
          checked={
            overbitePrimary !== undefined &&
            overbitePrimary.overbiteActionPrimary === OverbiteActionPrimary.CORRECT_DEEP_BITE
          }
          data-testid="Overbite-CorrectDeepBite"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.overbiteAction.DEEP" />
          </Typography>
        </OrmcoRadio>
        <OrmcoRadio
          value={OverbiteType.OPEN}
          onChange={() =>
            changeRadio({
              overbiteActionPrimary: OverbiteActionPrimary.CORRECT_OPEN_BITE,
              extAnteriorTeeth: true,
              biteLowerUpper: AffectedArche.BOTH,
            })
          }
          checked={
            overbitePrimary !== undefined &&
            overbitePrimary.overbiteActionPrimary === OverbiteActionPrimary.CORRECT_OPEN_BITE
          }
          data-testid="Overbite-CorrectOpenBite"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.overbiteAction.OPEN" />
          </Typography>
        </OrmcoRadio>
      </Vertical>
      {overbitePrimary &&
        overbitePrimary.overbiteActionPrimary === OverbiteActionPrimary.CORRECT_OPEN_BITE && (
          <CorrectOpenBite />
        )}
      {overbitePrimary &&
        overbitePrimary.overbiteActionPrimary === OverbiteActionPrimary.CORRECT_DEEP_BITE && (
          <CorrectDeepBite />
        )}
    </OrmcoFormItemVertical>
  )
}

export { Overbite }
