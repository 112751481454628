import { TermsResponse } from 'app/core/domain/Http/TermsResponse'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface TermsState {
  accepted: boolean
  version: string
  link: string
  hasError: boolean
}

const INITIAL_STATE: TermsState = {
  accepted: true,
  link: '',
  version: '',
  hasError: false,
}

const termsActions = {
  termsRequestFinished: createAction('@APP/TERMS_REQUEST_FINISHED')<TermsResponse>(),
  termsRequestFailed: createAction('@APP/TERMS_REQUEST_FAILED')<string>(),
  decisionMade: createAction('@APP/DECISION_MADE')<boolean>(),
}

type TermsActions = ActionType<typeof termsActions>
const termsSelectors = {
  getTermsState: () => (state: RootState) => state.terms,
}

const termsReducer = createReducer<TermsState, TermsActions>(INITIAL_STATE)
  .handleAction([termsActions.termsRequestFinished], (state, action) => ({
    ...state,
    accepted: action.payload.accepted,
    link: action.payload.link,
    version: action.payload.version,
  }))
  .handleAction([termsActions.decisionMade], (state, action) => ({
    ...state,
    accepted: action.payload,
  }))
  .handleAction([termsActions.termsRequestFailed], (state) => ({
    ...state,
    hasError: true,
    accepted: false,
  }))

export { TermsState, TermsActions, termsActions, termsSelectors, termsReducer }
