import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Box } from '../SDS/common/Box'
import { Typography } from '../SDS/common/Typography'

interface InputLabelProps {
  labelId: string
  isOptional?: boolean
}

const InputLabel: React.FC<InputLabelProps> = (props) => {
  return (
    <Box justify="space-between" width="100%">
      <Typography component="label" textFontWeight="600">
        <FormattedMessage id={props.labelId} />
      </Typography>

      {props.isOptional && (
        <Typography
          component="label"
          variant="small"
          textFontWeight="600"
          color="base900"
          letterSpacing="0.2px"
          margin="0 -9px 0 0"
          isUppercase
        >
          <FormattedMessage id="addresses.addressForm.optional" />
        </Typography>
      )}
    </Box>
  )
}

export { InputLabel }
