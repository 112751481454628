import { SelectProps } from 'antd/es/select'
import { OrmcoSelect } from 'app/components/ui/Form/OrmcoSelect'
import { ContextThemeProps, ThemeType } from 'app/components/ui/Theme/ThemeContext'
import styled from 'styled-components'

export const StyledSelect = styled(OrmcoSelect)<SelectProps<any> & ContextThemeProps>`
  &&& {
    width: 300px;

    &.ant-select-open {
      z-index: ${({ theme: styledTheme }) => styledTheme.zIndex.dropdown};
    }

    .ant-select-selection-item {
      padding-left: 20px;
    }

    .ant-select-selector {
      background-color: ${({ theme: styledTheme, contextTheme }) =>
        contextTheme === ThemeType.BOOTSTRAP
          ? styledTheme.colors.base100
          : styledTheme.colors.primary10};
    }
  }
`
