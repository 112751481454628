import React, { useRef } from 'react'

import { Select as AntdSelect, RefSelectProps } from 'antd'
import { Nullable } from 'app/core/types/utils'

import { getBottomMessageRenderer } from './helper'
import { SelectComposition, SelectProps } from './interfaces'
import { SelectContainer } from './styles'

const forwardRefSelect = React.forwardRef<RefSelectProps, SelectProps>(
  (
    {
      className,
      width,
      prefixIcon,
      label,
      rightLabel,
      helpMessage,
      error,
      size,
      disabled,
      isDark,
      children,
      'data-testid': dataTestId,
      ...restProps
    },
    forwardedRef,
  ) => {
    const optionsRef = useRef<Nullable<HTMLDivElement>>(null)
    const bottomMessage = getBottomMessageRenderer({
      helpMessage,
      error,
      dataTestId,
    })
    const getOptionsContainer = (node: HTMLElement) => optionsRef.current ?? node

    return (
      <SelectContainer
        className={className}
        $width={width}
        isDisabled={disabled}
        size={size}
        hasPrefixIcon={Boolean(prefixIcon)}
        isDark={isDark}
        data-testid={`${dataTestId}-container`}
      >
        <div className="sds-select__label-container">
          {label && (
            <span className="sds-select__label" data-testid={`${dataTestId}-label`}>
              {label}
            </span>
          )}
          {rightLabel && (
            <span className="sds-select__right-label" data-testid={`${dataTestId}-rightLabel`}>
              {rightLabel}
            </span>
          )}
        </div>
        <div className="sds-select__content">
          {prefixIcon && (
            <span className="sds-select__prefix-icon" aria-hidden="true">
              {prefixIcon}
            </span>
          )}
          <AntdSelect
            getPopupContainer={getOptionsContainer}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...restProps}
            ref={forwardedRef}
            size={size}
            status={error && 'error'}
            data-testid={dataTestId}
            disabled={disabled}
          >
            {children}
          </AntdSelect>
          <div ref={optionsRef} className="sds-select__options" />
        </div>
        {bottomMessage}
      </SelectContainer>
    )
  },
)

export const Select = {
  ...forwardRefSelect,
  Option: AntdSelect.Option,
  OptGroup: AntdSelect.OptGroup,
} as SelectComposition
