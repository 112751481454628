import styled from 'styled-components'

import {
  borderBottomMixin,
  NavigationMenuItemSpanSvgMixin,
} from '../HeaderNavigationDropdown/styles'

import { StyledNavigationLinkProps } from './interfaces'

export const StyledNavigationLink = styled.a<StyledNavigationLinkProps>`
  margin: 0 12px;

  display: inline-flex;
  align-items: center;

  height: 100%;
  ${borderBottomMixin}

  & svg {
    margin: 0 8px 0 0;

    width: 16px;
    height: auto;
  }

  ${NavigationMenuItemSpanSvgMixin}

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    span,
    svg {
      color: ${({ theme, isDark }) => theme.colors[isDark ? 'white' : 'primary500']};
    }
  }
`
