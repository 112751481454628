import { TreatArches } from './PrimaryCasePreferences'
import { ToothNumberingSystem } from './ToothNumberingSystem'

enum Arch {
  'UPPER' = 'UPPER',
  'LOWER' = 'LOWER',
}

type Palmer = string
type Universal = number
type FDI = number

interface TeethConversionMap {
  [key: number]: {
    [ToothNumberingSystem.PALMER]: string
    [ToothNumberingSystem.FDI]: number
  }
}

const BITE_RAMPS_TEETH_DISABLED = [1, 2, 3, 16, 15, 14, 17, 18, 19, 32, 31, 30]

const teethConversionMap: TeethConversionMap = {
  1: { [ToothNumberingSystem.PALMER]: 'UR8', [ToothNumberingSystem.FDI]: 18 },
  2: { [ToothNumberingSystem.PALMER]: 'UR7', [ToothNumberingSystem.FDI]: 17 },
  3: { [ToothNumberingSystem.PALMER]: 'UR6', [ToothNumberingSystem.FDI]: 16 },
  4: { [ToothNumberingSystem.PALMER]: 'UR5', [ToothNumberingSystem.FDI]: 15 },
  5: { [ToothNumberingSystem.PALMER]: 'UR4', [ToothNumberingSystem.FDI]: 14 },
  6: { [ToothNumberingSystem.PALMER]: 'UR3', [ToothNumberingSystem.FDI]: 13 },
  7: { [ToothNumberingSystem.PALMER]: 'UR2', [ToothNumberingSystem.FDI]: 12 },
  8: { [ToothNumberingSystem.PALMER]: 'UR1', [ToothNumberingSystem.FDI]: 11 },

  9: { [ToothNumberingSystem.PALMER]: 'UL1', [ToothNumberingSystem.FDI]: 21 },
  10: { [ToothNumberingSystem.PALMER]: 'UL2', [ToothNumberingSystem.FDI]: 22 },
  11: { [ToothNumberingSystem.PALMER]: 'UL3', [ToothNumberingSystem.FDI]: 23 },
  12: { [ToothNumberingSystem.PALMER]: 'UL4', [ToothNumberingSystem.FDI]: 24 },
  13: { [ToothNumberingSystem.PALMER]: 'UL5', [ToothNumberingSystem.FDI]: 25 },
  14: { [ToothNumberingSystem.PALMER]: 'UL6', [ToothNumberingSystem.FDI]: 26 },
  15: { [ToothNumberingSystem.PALMER]: 'UL7', [ToothNumberingSystem.FDI]: 27 },
  16: { [ToothNumberingSystem.PALMER]: 'UL8', [ToothNumberingSystem.FDI]: 28 },

  17: { [ToothNumberingSystem.PALMER]: 'LL8', [ToothNumberingSystem.FDI]: 38 },
  18: { [ToothNumberingSystem.PALMER]: 'LL7', [ToothNumberingSystem.FDI]: 37 },
  19: { [ToothNumberingSystem.PALMER]: 'LL6', [ToothNumberingSystem.FDI]: 36 },
  20: { [ToothNumberingSystem.PALMER]: 'LL5', [ToothNumberingSystem.FDI]: 35 },
  21: { [ToothNumberingSystem.PALMER]: 'LL4', [ToothNumberingSystem.FDI]: 34 },
  22: { [ToothNumberingSystem.PALMER]: 'LL3', [ToothNumberingSystem.FDI]: 33 },
  23: { [ToothNumberingSystem.PALMER]: 'LL2', [ToothNumberingSystem.FDI]: 32 },
  24: { [ToothNumberingSystem.PALMER]: 'LL1', [ToothNumberingSystem.FDI]: 31 },

  25: { [ToothNumberingSystem.PALMER]: 'LR1', [ToothNumberingSystem.FDI]: 41 },
  26: { [ToothNumberingSystem.PALMER]: 'LR2', [ToothNumberingSystem.FDI]: 42 },
  27: { [ToothNumberingSystem.PALMER]: 'LR3', [ToothNumberingSystem.FDI]: 43 },
  28: { [ToothNumberingSystem.PALMER]: 'LR4', [ToothNumberingSystem.FDI]: 44 },
  29: { [ToothNumberingSystem.PALMER]: 'LR5', [ToothNumberingSystem.FDI]: 45 },
  30: { [ToothNumberingSystem.PALMER]: 'LR6', [ToothNumberingSystem.FDI]: 46 },
  31: { [ToothNumberingSystem.PALMER]: 'LR7', [ToothNumberingSystem.FDI]: 47 },
  32: { [ToothNumberingSystem.PALMER]: 'LR8', [ToothNumberingSystem.FDI]: 48 },
}

const getArchTeethNumbers = (arch: Arch): Array<Universal> => {
  const teethMap = {
    [Arch.UPPER]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    [Arch.LOWER]: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
  }

  return teethMap[arch]
}
const toPalmer = (teethNumber: Universal): Palmer =>
  teethConversionMap[teethNumber][ToothNumberingSystem.PALMER]
const toFDI = (teethNumber: Universal): FDI =>
  teethConversionMap[teethNumber][ToothNumberingSystem.FDI]
const getConverterToTeethSystem = (toothNumberingSystem: ToothNumberingSystem) => {
  const key = toothNumberingSystem || ToothNumberingSystem.UNIVERSAL
  const map = {
    [ToothNumberingSystem.UNIVERSAL]: (n: Universal) => n,
    [ToothNumberingSystem.FDI]: toFDI,
    [ToothNumberingSystem.PALMER]: toPalmer,
  }

  return map[key]
}

const getArchForTreatArches = (treatArches: TreatArches) => {
  if (treatArches === TreatArches.LOWER_ONLY) {
    return Arch.LOWER
  }
  if (treatArches === TreatArches.UPPER_ONLY) {
    return Arch.UPPER
  }
  return undefined
}

export {
  Arch,
  Palmer,
  FDI,
  Universal,
  toPalmer,
  toFDI,
  getArchTeethNumbers,
  getConverterToTeethSystem,
  getArchForTreatArches,
  BITE_RAMPS_TEETH_DISABLED,
}
