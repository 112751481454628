import React from 'react'

import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { ProgressStriped } from 'app/components/ui/ProgressStriped'
import { Button } from 'app/components/ui/SDS/common/Button'

interface ThreeShapePreloaderModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ScanPreloaderModal: React.FC<ThreeShapePreloaderModalProps> = (props) => (
  <OrmcoModal
    isOpen={props.isOpen}
    data-testid="ThreeShapeModal-OrmcoModal"
    onRequestClose={() => props.setIsOpen(false)}
    additionalStyles={{
      content: {
        width: '300px',
      },
    }}
  >
    <OrmcoModalHeader flexDirection="row" data-testid="ThreeShapeModal-OrmcoModalHeader">
      <OrmcoModalHeaderText variant="h5" headerId="loading.wait" />
      <Button
        type="text"
        onClick={() => props.setIsOpen(false)}
        data-testid="ThreeShapeModal-CloseButton"
      >
        <Cancel />
      </Button>
    </OrmcoModalHeader>
    <OrmcoModalBody>
      <ProgressStriped />
    </OrmcoModalBody>
  </OrmcoModal>
)

export { ScanPreloaderModal }
