import { EmployeeProfile } from 'app/core/domain/EmployeeProfile'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { SystemDictionaries } from 'app/core/domain/Http/SystemDictionaries'
import { MarketingMessage } from 'app/core/domain/MarketingMessage'
import { Region } from 'app/core/domain/Region'
import { RegionSupportContact } from 'app/core/domain/SupportContacts'
import { ToothNumberingSystem } from 'app/core/domain/ToothNumberingSystem'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { UserProfile } from 'app/core/domain/UserProfile'
import { UserRole } from 'app/core/domain/UserRole'
import { UserSettings } from 'app/core/domain/UserSettings'
import { WebContent } from 'app/core/domain/WebContent'
import { ensure } from 'app/core/lang/TypeUtils'
import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { head, includes, set } from 'lodash'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

//TODO: this state contains a lot, should divided to smaller pieces

interface AppState {
  userProfile?: UserProfile
  version: string
  supportContacts?: RegionSupportContact
  copyrightYear: number
  marketingMessage: MarketingMessage
  region?: Region
  locale: string
  locales: Array<string>
  toothNumberingSystem: ToothNumberingSystem
  employeeProfileDetails: EmployeeProfile
  webContent?: WebContent
  settingsReceived: boolean
  timeZoneIdentifier: Nullable<string>
  showIntegratedHooksPopup: boolean
}

const INITIAL_STATE: AppState = {
  timeZoneIdentifier: null,
  userProfile: undefined,
  version: EnvironmentParams.VERSION,
  copyrightYear: -1,
  marketingMessage: {
    message: '',
    isVisible: false,
  },
  locale: '',
  locales: ['en_EU'],
  settingsReceived: false,
  toothNumberingSystem: ToothNumberingSystem.UNIVERSAL,
  employeeProfileDetails: {
    employeePersonalDetails: {
      firstName: '',
      middleName: '',
      lastName: '',
      customerId: '',
      practiceName: '',
      loginName: '',
    },
    employeeContactDetails: {
      phone: '',
      email: '',
      receiveEmail: false,
      websiteUrl: '',
    },
    practiceAddressDetails: [],
    practiceCountry: '',
    profileDataReceived: false,
    permissions: undefined,
  },
  showIntegratedHooksPopup: true,
}

const appActions = {
  profileInitialized: createAction('@APP/PROFILE_INITIALIZED')<UserProfile>(),
  systemInfoRequestFinished: createAction(
    '@APP/SYSTEM_INFO_REQUEST_FINISHED',
  )<SystemDictionaries>(),
  marketingMessageRead: createAction('@APP/MARKETING_MESSAGE_READ')(),
  marketingMessageReceived: createAction('@APP/MARKETING_MESSAGE_RECEIVED')<{
    marketingMessage: MarketingMessage
  }>(),
  localeChanged: createAction('@APP/LOCALE_CHANGED')<{ locale: string }>(),
  userSettingsReceived: createAction('@APP/USER_SETTINGS_RECEIVED')<UserSettings>(),
  userSettingsReceivedForOperator: createAction('@APP/USER_SETTINGS_RECEIVED_FOR_OPERATOR')(),
  termsPdfLinkRequested: createAction('@APP/TERMS_PDF_LINK_REQUESTED')(),
  employeeDetailsRequestFinished: createAction(
    '@APP/EMPLOYEE_DETAILS_REQUEST_FINISHED',
  )<EmployeeProfile>(),
  employeeDetailsRequestFailed: createAction('@APP/EMPLOYEE_DETAILS_REQUEST_FAILED')<{
    message: string
  }>(),
  employeeDetailsUpdated: createAction('@APP/EMPLOYEE_DETAILS_UPDATED')(),
  saveEmployeeDetails: createAction('@APP/SAVE_EMPLOYEE_DETAILS')<{ part?: any; key: string }>(),
  saveEmployeeDetailsFailed: createAction('@APP/SAVE_EMPLOYEE_DETAILS_FAILED')<{
    message: string
  }>(),
  saveEmployeeDetailsFinished: createAction('@APP/SAVE_EMPLOYEE_DETAILS_FINISHED')(),
  saveEmployeeDetailsUnmounted: createAction('@APP/SAVE_EMPLOYEE_DETAILS_UNMOUNTED')(),
  myProfileMounted: createAction('@APP/MY_PROFILE_PAGE_MOUNTED')(),

  // TODO: Since the data depends on the current user, it's worth removing the data from the store on fail.
  webContentRequested: createAction('@APP/WEB_CONTENT_REQUESTED')(),
  webContentReceived: createAction('@APP/WEB_CONTENT_RECEIVED')<WebContent>(),
  webContentFailed: createAction('@APP/WEB_CONTENT_FAILED')(),
}

type AppActions = ActionType<typeof appActions>
const appReducer = createReducer<AppState, AppActions>(INITIAL_STATE)
  .handleAction([appActions.userSettingsReceived], (state, action) => {
    const { locale, locales } = action.payload.languagesInfo

    return {
      ...state,
      locale,
      locales,
      region: action.payload.region,
      toothNumberingSystem: action.payload.toothNumberingSystem,
      timeZoneIdentifier: action.payload.timeZoneIdentifier,
      settingsReceived: true,
      showIntegratedHooksPopup: action.payload.showIntegratedHooksPopup,
    }
  })
  .handleAction([appActions.profileInitialized], (state, action) => ({
    ...state,
    userProfile: action.payload,
  }))
  .handleAction([appActions.systemInfoRequestFinished], (state, action) => ({
    ...state,
    supportContacts: action.payload.supportContacts,
    version: action.payload.version,
    copyrightYear: action.payload.copyrightYear,
  }))
  .handleAction(appActions.marketingMessageRead, (state) => ({
    ...state,
    marketingMessage: {
      ...state.marketingMessage,
      isVisible: false,
    },
  }))
  .handleAction(appActions.localeChanged, (state, action) => ({
    ...state,
    locale: action.payload.locale,
  }))
  .handleAction(appActions.marketingMessageReceived, (state, action) => ({
    ...state,
    marketingMessage: action.payload.marketingMessage,
  }))
  .handleAction(appActions.employeeDetailsRequestFinished, (state, action) => ({
    ...state,
    employeeProfileDetails: { ...action.payload, profileDataReceived: true },
  }))
  .handleAction(appActions.saveEmployeeDetails, (state, action) => {
    const profileData = { ...state.employeeProfileDetails }

    set(profileData, action.payload.key, action.payload.part)
    return { ...state, ...{ employeeProfileDetails: profileData } }
  })
  .handleAction(appActions.saveEmployeeDetailsFinished, (state) => ({
    ...state,
    employeeProfileDetails: {
      ...state.employeeProfileDetails,
      updateResult: {
        status: ResponseStatus.OK,
      },
    },
  }))
  .handleAction(appActions.saveEmployeeDetailsFailed, (state) => ({
    ...state,
    employeeProfileDetails: {
      ...state.employeeProfileDetails,
      updateResult: {
        status: ResponseStatus.BAD_REQUEST,
        message: 'profile.update.error.title',
      },
    },
  }))
  .handleAction(appActions.saveEmployeeDetailsUnmounted, (state) => ({
    ...state,
    employeeProfileDetails: {
      ...state.employeeProfileDetails,
      updateResult: undefined,
    },
  }))
  .handleAction(appActions.myProfileMounted, (state) => ({
    ...state,
    employeeProfileDetails: { ...state.employeeProfileDetails, profileDataReceived: false },
  }))
  .handleAction([appActions.webContentReceived], (state, action) => ({
    ...state,
    webContent: action.payload,
  }))

const appSelectors = {
  getInitializedProfile:
    () =>
    (state: RootState): UserProfile =>
      ensure(state.app.userProfile, 'userProfile'),
  getVersion: () => (state: RootState) => state.app.version,
  getFullName: () => (state: RootState) => {
    const { firstName, lastName, middleName } = appSelectors.getInitializedProfile()(state)

    return `${firstName} ${middleName} ${lastName}`
  },
  getUsername: () => (state: RootState) => appSelectors.getInitializedProfile()(state).username,
  hasRole: (role: UserRole) => (state: RootState) =>
    includes(appSelectors.getInitializedProfile()(state).roles, role),
  isDoctor: () => (state: RootState) => appSelectors.hasRole(UserRole.ROLE_NORMAL)(state),
  isStaff: () => (state: RootState) => appSelectors.hasRole(UserRole.ROLE_STAFF)(state),
  isAdmin: () => (state: RootState) => appSelectors.hasRole(UserRole.ROLE_USER_ADMIN)(state),
  isOperator: () => (state: RootState) => appSelectors.hasRole(UserRole.ROLE_OPERATOR)(state),
  isImpersonated: () => (state: RootState) =>
    Boolean(appSelectors.getInitializedProfile()(state).impersonator),
  getImpersonator: () => (state: RootState) =>
    appSelectors.getInitializedProfile()(state).impersonator,
  getUserRole: () => (state: RootState) => head(appSelectors.getInitializedProfile()(state).roles),
  getLanguage: () => (state: RootState) =>
    head((appSelectors.getLocale()(state) || '').split('_')) || '',
  getLocales: () => (state: RootState) => state.app.locales,
  getLocale: () => (state: RootState) => {
    const supportedLocales = state.app.locales

    if (state.app.settingsReceived) {
      return supportedLocales.find(
        (locale) => locale === state.app.locale || head(locale.split('_')) === state.app.locale,
      )
        ? state.app.locale
        : head(supportedLocales)
    }
    return ''
  },
  getSupportContacts: () => (state: RootState) =>
    ensure(state.app.supportContacts, 'supportContacts'),
  getMarketingMessage: () => (state: RootState) => state.app.marketingMessage,
  getCopyrightYear: () => (state: RootState) => state.app.copyrightYear,
  getUserRegion: () => (state: RootState) => state.app.region,
  getToothNumberingSystem: () => (state: RootState) => state.app.toothNumberingSystem,
  getPracticeCountry: () => (state: RootState) => state.app.employeeProfileDetails.practiceCountry,
  getPracticeAddresses: () => (state: RootState) =>
    state.app.employeeProfileDetails.practiceAddressDetails,
  getEmployeePersonalDetails: () => (state: RootState) =>
    state.app.employeeProfileDetails.employeePersonalDetails,
  getEmployeeContactDetails: () => (state: RootState) =>
    state.app.employeeProfileDetails.employeeContactDetails,
  getEmployeeProfileDetails: () => (state: RootState) => state.app.employeeProfileDetails,
  getEmployeeUpdateResult: () => (state: RootState) =>
    state.app.employeeProfileDetails.updateResult,
  isProfileDataReceived: () => (state: RootState) =>
    state.app.employeeProfileDetails.profileDataReceived,
  getWebContent: () => (state: RootState) => state.app.webContent,
  getWebContentByLanguage: (language: string) => (state: RootState) =>
    state.app.webContent?.[language] ?? state.app.webContent?.en ?? {},
  getTimeZoneIdentifier: () => (state: RootState) => state.app.timeZoneIdentifier,
  getShowIntegratedHooksPopup: () => (state: RootState) => state.app.showIntegratedHooksPopup,
}

export { AppState, AppActions, appActions, appSelectors, appReducer }
