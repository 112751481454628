import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { InputLabel } from 'app/components/ui/Input/InputLabel'

import { AddressInput } from './AddressInput'

const State = () => (
  <StaffFormItemVertical
    colon={false}
    label={<InputLabel labelId="addresses.addressForm.state" isOptional />}
    data-testid="AddAddress-State"
  >
    <AddressInput name="stateProvince" />
  </StaffFormItemVertical>
)

export { State }
