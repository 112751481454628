import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  CreditCard,
  Shipping,
  OfficeAddress as OfficeAddressIcon,
} from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Address } from 'app/core/domain/Address'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { addressSelectors } from '../logic'

import { AddressSummaryIconContainer } from './AddressSummaryIconContainer'

const AddressSummaryIcons: React.FC = () => {
  const addressResult = useSelector(addressSelectors.getAddress())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const form = useFormContext<Address>()
  const formValues = form.watch()
  const isBillTo = formValues.isBillTo ?? addressResult.isBillTo
  const isShipTo = formValues.isShipTo ?? addressResult.isShipTo
  const isOffice = formValues.isOffice ?? addressResult.isOffice

  return (
    <Box marginLeft="16px" width="auto">
      {isBillTo && (
        <AddressSummaryIconContainer titleId="defaultBillingAddress.tooltip.message">
          <CreditCard />
        </AddressSummaryIconContainer>
      )}
      {isShipTo && (
        <AddressSummaryIconContainer titleId="defaultShippingAddress.tooltip.message">
          <Shipping />
        </AddressSummaryIconContainer>
      )}
      {isOffice && featurePermissions?.doctorLocator && (
        <AddressSummaryIconContainer titleId="defaultOfficeAddress.tooltip.message">
          <OfficeAddressIcon />
        </AddressSummaryIconContainer>
      )}
    </Box>
  )
}

export { AddressSummaryIcons }
