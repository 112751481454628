import { Radio } from 'antd'
import styled from 'styled-components'

interface OrmcoRadioProps {
  margin?: string
}

const OrmcoRadio = styled(Radio)<OrmcoRadioProps>`
  margin: ${(props) => props.margin};
  padding-top: 7.5px;
  padding-bottom: 7.5px;

  &:hover {
    .ant-radio-inner {
      border-color: ${({ disabled, theme }) =>
        disabled ? theme.colors.base500 : theme.colors.primary800};
    }
    .ant-radio-inner::after {
      background-color: ${({ disabled, theme }) =>
        disabled ? theme.colors.base500 : theme.colors.primary800};
    }

    .ant-radio-checked::after {
      border-color: ${({ theme }) => theme.colors.primary800};
    }
  }

  .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.primary800};
  }

  .ant-radio-inner::after {
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.base500 : theme.colors.primary800};
  }

  .ant-radio-disabled + span {
    color: ${({ theme }) => theme.colors.black};
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.primary800};
  }

  .ant-radio-checked::after {
    border-color: ${({ theme }) => theme.colors.primary800};
  }

  .ant-radio + * {
    padding-right: 0;
  }
`

export { OrmcoRadio }
