import React, { ReactElement } from 'react'

import styled from 'styled-components'

import { OrmcoTooltip, OrmcoTooltipProps } from '../Form/OrmcoTooltip'
import { Typography } from '../SDS/common/Typography'

type LabeledTooltipProps = OrmcoTooltipProps & {
  text?: ReactElement | string
  disabled?: boolean
}

export const LabeledTooltip: React.FC<LabeledTooltipProps> = (props) => {
  const { text, ...rest } = props

  if (rest.disabled) {
    return <Typography component="span">{text}</Typography>
  }

  return (
    <LabeledTooltipContainer>
      <Typography component="span">{text}</Typography>
      <OrmcoTooltip {...rest} />
    </LabeledTooltipContainer>
  )
}

const LabeledTooltipContainer = styled.span`
  display: inline-flex;
`
