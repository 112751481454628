import styled from 'styled-components'

interface ColumnProps {
  width: string
  sorting?: boolean
}

const Column = styled.div<ColumnProps>`
  cursor: ${(props) => (props.sorting === false ? 'default' : 'pointer')};

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
`

export { Column }
