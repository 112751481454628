import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { staffDeleteModalActions } from 'app/components/routes/Staff/StaffDeleteModal/logic'
import {
  staffCreationActions,
  staffCreationSelectors,
} from 'app/components/routes/Staff/StaffDetails/logic'
import { RedButton, InfoButton, LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import { ResultStatus } from 'app/core/domain/ResultStatus'
import styled from 'styled-components'

const StaffCreationButtons: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const form = useFormContext<PersonalInformation>()
  const { setError } = form
  const personalInformation = useSelector(staffCreationSelectors.getFormPersonalInfo())
  const usernameAvailable = useSelector(staffCreationSelectors.isUsernameAvailable())
  const lastStaffUpdateResult = useSelector(staffCreationSelectors.getLastStaffUpdateResult())
  const isStaffUpdateFlow = useSelector(staffCreationSelectors.isStaffUpdateFlow())
  const isDeleteButtonClick = useSelector(staffCreationSelectors.getDeleteButtonClickFlag())
  const lastStaffDeleteResult = useSelector(staffCreationSelectors.getLastStaffDeleteResult())
  const isStaffUpdateButtonVisible = useSelector(
    staffCreationSelectors.isStaffUpdateButtonVisible(),
  )

  const onSubmit = useCallback(
    (personalInfo: PersonalInformation) => {
      if (isStaffUpdateFlow) {
        dispatch(staffCreationActions.updateStaffButtonClicked(personalInfo))
        window.scroll(0, 0)
      } else {
        dispatch(staffCreationActions.createStaffButtonClicked(personalInfo))
      }
    },
    [dispatch, isStaffUpdateFlow],
  )

  const onDelete = useCallback(() => {
    dispatch(staffDeleteModalActions.staffDeletedModalTriggered())
  }, [dispatch])

  useEffect(() => {
    const isLastStaffUpdateSuccessful = lastStaffUpdateResult?.status === ResultStatus.OK
    const isStaffCreationSuccess = !isStaffUpdateFlow && isLastStaffUpdateSuccessful
    const isStaffUpdateSuccess = isStaffUpdateFlow && isLastStaffUpdateSuccessful

    const isStaffDeletionSuccess = lastStaffDeleteResult?.status === ResultStatus.OK

    const isUsernameInvalidOnSubmitStaffCreation = !isStaffUpdateFlow && usernameAvailable === false

    if (isStaffCreationSuccess) {
      history.push('/myPractice?notification=createSuccess')
    }

    if (isStaffUpdateSuccess) {
      history.push('/myPractice?notification=updateSuccess')
    }

    if (isStaffDeletionSuccess) {
      history.push('/myPractice?notification=deleteSuccess')
    }

    if (isUsernameInvalidOnSubmitStaffCreation) {
      setError('username', {
        type: 'validate',
        message: intl.formatMessage({ id: 'staff.staffDetails.staffUserName.notAvailable' }),
      })
    }
  }, [
    isStaffUpdateFlow,
    lastStaffUpdateResult,
    history,
    usernameAvailable,
    dispatch,
    personalInformation,
    setError,
    intl,
    isDeleteButtonClick,
    lastStaffDeleteResult,
  ])

  const createButton = isStaffUpdateButtonVisible ? (
    <CreateButton
      onClick={form.handleSubmit(onSubmit)}
      data-testid="StaffCreationButtons-CreateButton"
    >
      <FormattedMessage id="staff.update" />
    </CreateButton>
  ) : (
    <CreateButton as={Link} data-testid="StaffCreationButtons-Close" to="/myPractice">
      <FormattedMessage id="staff.staffDetails.close" />
    </CreateButton>
  )

  return (
    <StaffCreationButtonsContainer padding="12px" justify="space-between" boxSizing="border-box">
      {isStaffUpdateFlow ? (
        <>
          <Box
            flexBasis="300px"
            width="auto"
            justify="flex-start"
            onClick={form.handleSubmit(onDelete)}
          >
            <RedButton data-testid="StaffCreationButtons-DeleteButton">
              <FormattedMessage id="staff.delete" />
            </RedButton>
            <Padder />
            {isStaffUpdateButtonVisible && (
              <LightBlueButtonWithBorder
                as={Link}
                data-testid="StaffCreationButtons-Cancel"
                to="/myPractice"
              >
                <FormattedMessage id="staff.creation.cancel" />
              </LightBlueButtonWithBorder>
            )}
          </Box>
          <Box width="auto">{createButton}</Box>
        </>
      ) : (
        <>
          <LightBlueButtonWithBorder
            as={Link}
            data-testid="StaffCreationButtons-Cancel"
            to="/myPractice"
          >
            <Box>
              <FormattedMessage id="staff.creation.cancel" />
            </Box>
          </LightBlueButtonWithBorder>
          <CreateButton
            onClick={form.handleSubmit(onSubmit)}
            data-testid="StaffCreationButtons-Create"
          >
            <FormattedMessage id="staff.create" />
          </CreateButton>
        </>
      )}
    </StaffCreationButtonsContainer>
  )
}

const StaffCreationButtonsContainer = styled(Box)`
  position: sticky;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.layer1};

  border-top: 1px solid ${({ theme }) => theme.colors.base300};

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1.5px 1.5px 1.5px 0.5px ${({ theme }) => theme.colors.base200};
`

const CreateButton = styled(InfoButton)`
  padding: 0;

  justify-content: center;

  min-width: 136px;
  height: 40px;

  letter-spacing: 0.1px;

  font-weight: 600;
  line-height: 18px;
`

export { StaffCreationButtons, StaffCreationButtonsContainer }
