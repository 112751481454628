import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { ValidateStatus } from 'antd/lib/form/FormItem'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoTextArea } from 'app/components/ui/Form/OrmcoTextArea'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { useDidMount } from 'app/core/react/CustomHooks'
import { appSelectors } from 'app/logic/app/logic'
import { get } from 'lodash'

const PROPERTY_KEY = 'primaryCasePreferences.additionalInstruction'
const MAX_LENGTH = 1500

const TreatmentGoals = () => {
  const form = useFormContext<CaseDraft>()
  const intl = useIntl()
  const region = useSelector(appSelectors.getUserRegion())
  const draftData = useSelector(caseWizardSelectors.getDraftData())
  const placeholder =
    region !== 'CHINA'
      ? intl.formatMessage({ id: 'caseWizard.casePrescription.additionalInstruction.placeholder' })
      : intl.formatMessage({
          id: 'caseWizard.casePrescription.additionalInstruction.placeholdercn',
        })

  const errorMessage = intl.formatMessage({
    id: 'caseWizard.casePrescription.additionalInstruction.error.characterLimitReached',
  })
  let validation: ValidateStatus = ''

  if (get(form.formState.errors, PROPERTY_KEY)) {
    validation = 'error'
  }

  useDidMount(() => {
    form.register('primaryCasePreferences.additionalInstruction')
    form.setValue(
      'primaryCasePreferences.additionalInstruction',
      draftData.primaryCasePreferences?.additionalInstruction,
    )
  })

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.additionalInstruction.label" />
      }
      hasFeedback
      validateStatus={validation}
      help={validation !== '' && errorMessage}
      data-testid="TreatmentGoals"
    >
      <Controller
        control={form.control}
        name={PROPERTY_KEY}
        render={({ field }) => (
          <OrmcoTextArea
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            maxLength={MAX_LENGTH}
            showCount
            rows={6}
            placeholder={placeholder}
            data-testid="TreatmentGoals-AdditionalInstruction"
          />
        )}
        rules={{
          validate: {
            checkForMax: (input) => (input ? input.length < MAX_LENGTH : true),
          },
        }}
      />
    </OrmcoFormItemVertical>
  )
}

export { TreatmentGoals }
