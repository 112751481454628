import { useFormContext } from 'react-hook-form'

import { CaseDraft } from 'app/core/domain/CaseDraft'
import { ProductType } from 'app/core/domain/ProductType'
import { useEffectSomeDepsChange } from 'app/core/react/CustomHooks'

import {
  getUntreatedTubeKeys,
  LOWER_FIRST_MOLAR_TUBE_KEY,
  LOWER_SECOND_MOLAR_TUBE_KEY,
  UPPER_FIRST_MOLAR_TUBE_KEY,
  UPPER_SECOND_MOLAR_TUBE_KEY,
} from './molarTubeKeys'

export const useDefaultMolarTubes = () => {
  const form = useFormContext<CaseDraft>()

  const {
    productInfo,
    primaryCasePreferences: { treatArches },
  } = form.getValues()

  useEffectSomeDepsChange(() => {
    if (productInfo?.id !== ProductType.IDB) {
      form.setValue(LOWER_FIRST_MOLAR_TUBE_KEY, undefined)
      form.setValue(LOWER_SECOND_MOLAR_TUBE_KEY, undefined)
      form.setValue(UPPER_FIRST_MOLAR_TUBE_KEY, undefined)
      form.setValue(UPPER_SECOND_MOLAR_TUBE_KEY, undefined)
      return
    }

    const untreatedKey = getUntreatedTubeKeys(treatArches)

    if (!untreatedKey) {
      return
    }

    form.setValue(untreatedKey.firstMolarKey as keyof CaseDraft, undefined)
    form.setValue(untreatedKey.secondMolarKey as keyof CaseDraft, undefined)
  }, [productInfo, treatArches])
}
