import { caseSubmittedModalSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { ScanType } from 'app/components/routes/CaseWizard/steps/EditScans/ScanType'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { FILE_STATUS_MAX_PERCENT } from 'app/core/constants/global'
import { CaseStatus } from 'app/core/domain/CaseStatus'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { HoldReason } from 'app/core/domain/HoldReason'
import { SessionStorageKeysMap } from 'app/core/domain/SessionStorageKeysMap'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { concatMap, forkJoin, from, of } from 'rxjs'
import { catchError, debounceTime, filter, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const DEBOUNCE_TIME = 3000

const stlFileUploadUrl: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.uploadSTLFileRequested])),
    mergeMap((action) => {
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)
      const selectedScan = editScansSelectors.getSelectedScanner()(state$.value)

      if (!selectedScan) {
        throw new Error('no scans available')
      }

      return from(
        axios.get(`/api/v1/cases/${caseId}/getStlFileUploadUrl`, {
          params: {
            scanType: selectedScan.name,
            version: action.payload.fileVersion,
          },
        }),
      ).pipe(
        mergeMap(({ data }) => {
          if (!data.url) {
            return of(
              editScansActions.uploadSTLFileFailed({
                message: `url is not defined. url: ${data.url}`,
              }),
            )
          }

          const s3UploadUrl = data.url
          const config = {
            headers: data.headers,
            onUploadProgress: action.payload.onProgress,
          }
          const content = action.payload.file

          return from(axios.put(s3UploadUrl, content, config)).pipe(
            mergeMap(() =>
              of(
                editScansActions.uploadSTLFileSuccess({
                  onUploadEnd: action.payload.onUploadEnd,
                  fileName: data.filename,
                }),
              ),
            ),
            catchError((err) => of(editScansActions.uploadSTLFileFailed({ message: err.error }))),
          )
        }),
        catchError((err) => of(editScansActions.uploadSTLFileFailed({ message: err.error }))),
      )
    }),
  )

const updateScanEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.uploadSTLFileSuccess])),
    switchMap((action) => {
      const { fileName, onUploadEnd } = action.payload
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)
      const selectedScan = editScansSelectors.getSelectedScanner()(state$.value)
      const progressList = editScansSelectors.getFilesUploadProgressList()(state$.value)
      const uploadProgressTotal =
        progressList.length > 0
          ? progressList.reduce((a, b) => a + b, 0) / progressList.length
          : null

      if (!selectedScan) {
        throw new Error('no scans available')
      }

      const requestPayload = {
        impressionType: selectedScan.name,
        scanOrderId: selectedScan.scanOrderId,
        scanFilesList: [
          {
            name: fileName,
            isDeleted: false,
          },
        ],
      }

      if (uploadProgressTotal === FILE_STATUS_MAX_PERCENT) {
        onUploadEnd()
      }

      return from(axios.put(`/api/v1/cases/update/scan/${caseId}`, requestPayload)).pipe(
        switchMap(() =>
          of(
            editScansActions.scanViewUrlsRequested(),
            editScansActions.saveFileUploadedScanner(selectedScan),
          ),
        ),
        catchError((err) => of(editScansActions.uploadSTLFileFailed({ message: err.error }))),
      )
    }),
  )

const scanListsRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.getScanListRequest])),
    concatMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)

      return forkJoin([
        axios.get(`/api/v1/doctors/${username}/scanners`, {
          params: { caseId },
        }),
        axios.get(`${EnvironmentParams.CONTENT_MANAGEMENT_URL}/scanners.json`, {
          params: {
            timestamp: new Date().getTime(),
          },
        }),
      ])
    }),
    switchMap(([availableScansResponse, scansConfigResponse]) => {
      const availableScans = availableScansResponse.data.scannerInfoList
      const scansConfig = scansConfigResponse.data

      return of(
        editScansActions.getScanListReceived({
          scans: availableScans,
          cloudFrontScansConfig: scansConfig,
        }),
        editScansActions.scanViewUrlsRequested(),
      )
    }),
    catchError((err) => of(editScansActions.getScanListRequestFailed({ message: err.error }))),
  )

const getSelectedScanner: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.getSelectedScannerRequest])),
    switchMap((action) => {
      const username = appSelectors.getUsername()(state$.value)
      const { id, selectedStep } = action.payload

      return from(axios.get(`/api/v1/doctors/${username}/cases/caseData/${id}`)).pipe(
        switchMap((res) => {
          const { data } = res
          const isStatusSaved = data.caseStatus === CaseStatus.SAVED
          const isStatusNew = data.caseStatus === CaseStatus.NEW
          const externalHold = data.onHold && data.holdReason === HoldReason.EXTERNAL
          const isValidCase =
            selectedStep === 2 ? isStatusSaved || (isStatusNew && externalHold) : isStatusSaved

          if (!isValidCase) {
            window.location.href = SiteMap.homePage()
          }

          data.isValidCase = isValidCase
          return of(
            editScansActions.getSelectedScannerReceived(data),
            caseWizardActions.caseIdRequested({ id }),
          )
        }),
        catchError((err) => of(editScansActions.getSelectedScannerFailed({ message: err.error }))),
      )
    }),
  )

const getScansPdf: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.getScanPdfRequested])),
    switchMap((action) => {
      const { scanPdfUrl } = action.payload
      const selectedScan = editScansSelectors.getSelectedScanner()(state$.value)

      if (!selectedScan) {
        throw new Error('no scans available')
      }

      return from(axios.get<string>(`/api/v1/webapi${scanPdfUrl}/${selectedScan.name}`)).pipe(
        switchMap((res) => of(editScansActions.getScanPdfReceived(res.data))),
        catchError((res) => of(editScansActions.getScanPdfFailed(res))),
      )
    }),
  )

const getDirectTransferPdfEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.getDirectTransferPdfRequested])),
    switchMap((action) => {
      const { scanPdfUrl } = action.payload
      const selectedScan = editScansSelectors.getSelectedScanner()(state$.value)

      if (!selectedScan) {
        throw new Error('no scans available')
      }

      return from(axios.get<string>(`/api/v1/webapi${scanPdfUrl}/${selectedScan.name}`)).pipe(
        switchMap((res) => of(editScansActions.getDirectTransferPdfReceived(res.data))),
        catchError((res) => of(editScansActions.getDirectTransferPdfFailed(res))),
      )
    }),
  )

const threeShapeAccountsEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.threeShapeAccountsRequested])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(axios.get(`/api/v1/tShape/${username}/tShapeScannerList`)).pipe(
        switchMap((res) => of(editScansActions.threeShapeAccountsReceived(res.data))),
        catchError((err) => of(editScansActions.threeShapeAccountsFailed({ message: err.error }))),
      )
    }),
  )

const saveScannerEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.saveScannerRequested])),
    switchMap((action) => {
      const id = caseWizardSelectors.getDraftId()(state$.value)
      const { payload } = action
      const requestData = {
        impressionType: payload.name,
        scanOrderId: payload.scanOrderId,
        scanFilesList: null,
      }

      return from(
        axios
          .put(`/api/v1/cases/update/scan/${id}`, requestData)
          .then((res) =>
            res.status === 200
              ? editScansActions.saveScannerReceived({
                  name: payload.name,
                  description: payload.description,
                  uploadScan: payload.uploadScan,
                  scanUploaded: true,
                })
              : editScansActions.saveScannerFailed(),
          )
          .catch((err) => caseWizardActions.requestFailed(err.message)),
      )
    }),
  )

const threeShapeCaseListEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.threeShapeCaseListRequested])),
    switchMap((action) => {
      const { payload } = action
      const username = appSelectors.getUsername()(state$.value)
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)

      return from(
        axios.get(`/api/v1/tShape/${username}/tShapeCaseList/${caseId}`, {
          params: {
            pageNo: payload.pageNum,
            threeShapeAccountId: payload.accountId,
          },
        }),
      ).pipe(
        switchMap((res) => of(editScansActions.threeShapeCaseListReceived(res.data))),
        catchError((err) => of(editScansActions.threeShapeCaseListFailed({ message: err.error }))),
      )
    }),
  )

const threeShapeScanSelectEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.threeShapeScanSelectRequested])),
    switchMap((action) => {
      const username = appSelectors.getUsername()(state$.value)
      const selectedScan = editScansSelectors.getSelectedScanner()(state$.value)

      if (!selectedScan) {
        throw new Error('no scans available')
      }

      return from(
        axios.get(`/api/v1/tShape/${username}/uploadTShapeScan`, {
          params: action.payload,
        }),
      ).pipe(
        switchMap((res) =>
          of(
            editScansActions.threeShapeScanSelectReceived(res.data),
            editScansActions.saveFileUploadedScanner(selectedScan),
          ),
        ),
        catchError((err) =>
          of(editScansActions.threeShapeScanSelectFailed({ message: err.error })),
        ),
      )
    }),
  )

const threeShapePairingCheckEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.threeShapePairingCheckRequested])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(axios.get(`/api/v1/tShape/${username}/check3ShapePairing`)).pipe(
        switchMap((res) => of(editScansActions.threeShapePairingCheckReceived(res.data))),
        catchError(() => of(editScansActions.threeShapePairingCheckReceived({ pairing: 'fail' }))),
      )
    }),
  )

const scanViewUrlsRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.scanViewUrlsRequested])),
    switchMap(() => {
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)
      const username = appSelectors.getUsername()(state$.value)
      const selectedScanner = editScansSelectors.getSelectedScanner()(state$.value)
      const fileUploadedScanner = editScansSelectors.getFileUploadedScanner()(state$.value)
      const scannerUpdated = selectedScanner?.name === fileUploadedScanner?.name
      const displayThreeshapeButtons =
        scannerUpdated && selectedScanner?.name === ScanType.THREESHAPESCANS

      return from(
        axios.get(`/api/v1/cases/${username}/scans`, {
          params: { caseId },
        }),
      ).pipe(
        switchMap(({ data }) => {
          const responseData = Array.isArray(data) ? data : []

          return displayThreeshapeButtons && Array.isArray(data) && data?.length
            ? of(editScansActions.scanViewUrlsReceived(data))
            : of(editScansActions.scanViewUrlsReceived(responseData))
        }),
        catchError((err) => of(editScansActions.scanViewUrlsFailed({ message: err.error }))),
      )
    }),
  )

const scanDeleteRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.scanDeleteRequested])),
    switchMap(({ payload }) => {
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)

      return from(axios.delete(`/api/v1/cases/${caseId}/scans`)).pipe(
        switchMap(() =>
          of(
            editScansActions.scanDeleteSuccess({
              hideButtonsOnEnd: payload.hideButtonsOnEnd,
              updateTimestamp: payload.updateTimestamp,
            }),
            editScansActions.scanViewUrlsRequested(),
          ),
        ),
        catchError((err) => of(editScansActions.scanDeleteFailed({ message: err.error }))),
      )
    }),
  )

const updateCaseStatusEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.updateCaseStatusRequested])),
    switchMap((action) => {
      const { caseId } = action.payload
      const username = appSelectors.getUsername()(state$.value)

      return from(
        axios.put(`/api/v1/doctors/${username}/cases/${caseId}/unhold`, {
          doctorComment: null,
          isCurrentPatientRecords: null,
        }),
      ).pipe(
        switchMap(() => {
          sessionStorage.removeItem(`${SessionStorageKeysMap.doctorComment}-${caseId}`)
          sessionStorage.removeItem(`${SessionStorageKeysMap.isCurrentPatientRecords}-${caseId}`)
          window.location.href = `${SiteMap.patiensDetails(caseId)}#ImagesLoadTab`
          return of(editScansActions.updateCaseStatusReceived())
        }),
        catchError(() => of(editScansActions.updateCaseStatusFailed())),
      )
    }),
  )

const updateScanOrderIdEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.updateScanOrderIdRequested])),
    switchMap((action) => {
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)

      return from(axios.put(`/api/v1/cases/${caseId}/updateScanOrderId`, action.payload)).pipe(
        switchMap(() =>
          of(
            editScansActions.updateScanOrderIdSucceeded(),
            editScansActions.getDirectTransferScansFlagRequested(null),
          ),
        ),
        catchError(() => of(editScansActions.updateScanOrderIdFailed())),
      )
    }),
  )

const getDirectTransferScansFlagEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.getDirectTransferScansFlagRequested])),
    debounceTime(DEBOUNCE_TIME),
    switchMap(({ payload }) => {
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)
      const selectedScanner = editScansSelectors.getSelectedScanner()(state$.value)
      const orderId = payload ?? selectedScanner?.scanOrderId ?? ''

      return from(
        axios
          .get(`/api/v1/cases/getDirectTransferScansFlag/${caseId}`, {
            params: {
              orderId,
              scannerName: selectedScanner?.name ?? '',
            },
          })
          .then((res) => editScansActions.getDirectTransferScansFlagReceived(res.data))
          .catch(() => editScansActions.getDirectTransferScansFlagFailed()),
      )
    }),
  )

const editScansEpic = combineEpics(
  scanListsRequestEpic,
  updateCaseStatusEpic,
  getSelectedScanner,
  getScansPdf,
  getDirectTransferPdfEpic,
  stlFileUploadUrl,
  updateScanEpic,
  threeShapeAccountsEpic,
  threeShapeCaseListEpic,
  threeShapeScanSelectEpic,
  threeShapePairingCheckEpic,
  scanViewUrlsRequestEpic,
  scanDeleteRequestEpic,
  saveScannerEpic,
  updateScanOrderIdEpic,
  getDirectTransferScansFlagEpic,
)

export { editScansEpic }
