import { useFormContext } from 'react-hook-form'

import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'

export const useIsArchDisabled = (arch: TreatArches) => {
  const form = useFormContext<CaseDraft>()

  const {
    primaryCasePreferences: { treatArches },
  } = form.getValues()

  return treatArches !== TreatArches.BOTH && treatArches !== arch
}
