import { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { DicomTooltip } from 'app/components/routes/CaseWizard/steps/EditScans/DicomTooltip'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { DicomUploadStatus } from 'app/components/routes/Dicom/DicomUploadStatus'
import { uploadDicomActions, uploadDicomSelectors } from 'app/components/routes/Dicom/logic'
import { Button } from 'app/components/ui/Button'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

const DicomSection = () => {
  const intl = useIntl()
  const location = useLocation()
  const dispatch = useDispatch()
  const { id }: { id?: string } = parseQuery<{ id?: string }>(location.search)
  const dicomFileUploadStatus = useSelector(uploadDicomSelectors.getDicomFileUploadStatus())
  const dicomAvailable = useSelector(editScansSelectors.getDicomAvailableStatus())
  const dicomButtonText = dicomAvailable ? 'dicom.updateButton.label' : 'dicom.importButton.label'
  const dicomUploadInProgress = dicomFileUploadStatus === DicomUploadStatus.UPLOAD_INPROGRESS
  const cbctToolTipMessage = intl.formatMessage(
    { id: 'dicom.cbctTooltip.line' },
    {
      linkToSupport: (
        <a href={`${EnvironmentParams.ALIGNER_WEB_URL}/support`}>
          <FormattedMessage id="dicom.clickHere" />
        </a>
      ),
    },
  )

  useDidMount(() => {
    dispatch(
      uploadDicomActions.setDicomFileUploadStatusReceived(
        localStorage.getItem(`dicom-${id}`) || '',
      ),
    )
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  })

  const handleStorageChange = useCallback(() => {
    const currentLocalStorageValue = localStorage.getItem(`dicom-${id}`) || ''

    dispatch(uploadDicomActions.setDicomFileUploadStatusReceived(currentLocalStorageValue))
    if (currentLocalStorageValue === DicomUploadStatus.UPLOAD_COMPLETE) {
      dispatch(editScansActions.updateDicomUploadStatus(true))
      localStorage.removeItem(`dicom-${id}`)
    }
  }, [dispatch, id])

  const handleRemoveDicomButtonClick = useCallback(() => {
    dispatch(editScansActions.deleteDicomRequested())
  }, [dispatch])

  const openImportDicomWindow = useCallback(() => {
    window.open(
      `/ui/uploadDicom/${id}`,
      '_blank',
      'location=yes,height=400,width=520,scrollbars=yes,status=yes',
    )
  }, [id])

  return (
    <OrmcoFormItemVertical
      hasFeedback
      label={<OrmcoFormItemVerticalHeader headerTextId="dicom.box.label" />}
      data-testid="DicomSection-OrmcoFormItemVertical"
      marginBottom="0px"
    >
      <DicomSectionText data-testid="DicomSection-DicomSectionText">
        <Typography>{cbctToolTipMessage}</Typography>
      </DicomSectionText>

      <ImportDicomBox>
        <Button
          title={intl.formatMessage({ id: dicomButtonText })}
          data-testid="DicomSection-UploadDicomButton"
          disabled={dicomUploadInProgress}
          onClick={openImportDicomWindow}
        >
          <FormattedMessage id={dicomButtonText} />
        </Button>
        <DicomTooltip />
        {dicomAvailable && (
          <Box marginLeft="12px" width="auto">
            <Button
              variant="danger"
              disabled={dicomUploadInProgress}
              title={intl.formatMessage({ id: 'dicom.removeButton.label' })}
              data-testid="DicomSection-RemoveDicomButton"
              onClick={handleRemoveDicomButtonClick}
            >
              <FormattedMessage id="dicom.removeButton.label" />
            </Button>
          </Box>
        )}
      </ImportDicomBox>
      <DicomSectionNote data-testid="DicomSection-DicomSectionNote">
        <Typography>
          <Typography
            data-testid="DicomSection-DicomSectionStrong"
            component="span"
            textFontWeight="600"
          >
            <FormattedMessage id="dicom.section.noteLabel" />
          </Typography>{' '}
          <FormattedMessage id="dicom.section.noteDescription" />
        </Typography>
      </DicomSectionNote>
    </OrmcoFormItemVertical>
  )
}

export { DicomSection }

const DicomSectionText = styled.div`
  margin-left: 18%;

  width: 64%;

  white-space: pre-line;
`

const ImportDicomBox = styled.div`
  margin-top: 2%;
  margin-left: 18%;

  display: flex;
  align-items: center;
`

const DicomSectionNote = styled.div`
  margin-top: 2%;
  margin-left: 18%;

  width: 64%;
`
