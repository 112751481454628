import { Box } from 'app/components/ui/SDS/common/Box'
import styled, { css } from 'styled-components'

import { StyledNavigationDropdownProps } from './interfaces'

export const borderBottomMixin = css<StyledNavigationDropdownProps>`
  border-bottom: 2px solid
    ${({ theme, isActive, isDark }) => {
      if (isActive) {
        return theme.colors[isDark ? 'white' : 'primary500']
      }
      return 'transparent'
    }};
`

export const NavigationMenuItemSpanSvgMixin = css<StyledNavigationDropdownProps>`
  & span,
  & svg {
    color: ${({ theme, isActive, isDark }) => {
      if (isActive) {
        return theme.colors[isDark ? 'white' : 'primary500']
      }
      return theme.colors[isDark ? 'primary300' : 'base900']
    }};
  }
`

export const StyledNavigationDropdown = styled(Box)<StyledNavigationDropdownProps>`
  margin: 0 12px;
  position: relative;

  cursor: pointer;
  //TODO: move all the svg icon from ant.d to the application
  .dropdown-content {
    overflow: hidden;

    ${borderBottomMixin}

    .mainIcon, .carpetIcon {
      line-height: 0;
    }

    .mainIcon svg {
      width: 16px;
      height: auto;
    }

    .carpetIcon svg {
      width: 10px;
      height: auto;
    }

    &.ant-dropdown-open {
      .carpetIcon svg {
        transform: rotate(180deg);
      }
    }

    ${NavigationMenuItemSpanSvgMixin}

    &.ant-dropdown-open,
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      span,
      svg {
        color: ${({ theme, isDark }) => theme.colors[isDark ? 'white' : 'primary500']};
      }
    }
  }
`
