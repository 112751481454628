import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Divider, RadioChangeEvent } from 'antd'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import {
  DirectionPrimary,
  MidlineDistance,
  MidlinePreferencesPrimary,
} from 'app/core/domain/MidlinePreferencesPrimary'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { ensure } from 'app/core/utils/ensure'
import { useTheme } from 'styled-components'

import { MidlineUpperAndLowerSection } from '../MidlineUpperAndLowerSection'

import { StyledSelect } from './styles'

const MIDLINE_PROPERTY_KEY = 'primaryCasePreferences.midlinePreferencesPrimary'
const TREAT_ARCHES_PROPERTY_KEY = 'primaryCasePreferences.treatArches'

const MidlineCorrectRadioButtons = () => {
  const form = useFormContext<CaseDraft>()
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())
  const midlinePreferences = ensure(form.getValues(MIDLINE_PROPERTY_KEY))
  const treatArches = form.getValues(TREAT_ARCHES_PROPERTY_KEY) as TreatArches

  const radioButtonsToRender = [
    {
      value: DirectionPrimary.UPPER_TO_LOWER,
      translation: 'caseWizard.casePrescription.midline.matchUpperToLower',
      checkRequirement: isV6Form
        ? midlinePreferences.directionPrimary === DirectionPrimary.UPPER_TO_LOWER
        : (midlinePreferences.directionPrimary === DirectionPrimary.UPPER_TO_LOWER &&
            treatArches !== TreatArches.LOWER_ONLY) ||
          treatArches === TreatArches.UPPER_ONLY,
      disabled: treatArches === TreatArches.LOWER_ONLY,
      testId: 'MidlineCorrectSection-UpperToLower',
    },
    {
      value: DirectionPrimary.LOWER_TO_UPPER,
      translation: 'caseWizard.casePrescription.midline.matchLowerToUpper',
      checkRequirement: isV6Form
        ? midlinePreferences.directionPrimary === DirectionPrimary.LOWER_TO_UPPER
        : (midlinePreferences.directionPrimary === DirectionPrimary.LOWER_TO_UPPER &&
            treatArches !== TreatArches.UPPER_ONLY) ||
          treatArches === TreatArches.LOWER_ONLY,
      disabled: treatArches === TreatArches.UPPER_ONLY,
      testId: 'MidlineCorrectSection-LowerToUpper',
    },
  ]

  if (isV6Form) {
    radioButtonsToRender.push({
      value: DirectionPrimary.MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE,
      translation: 'caseWizard.casePrescription.midline.matchUpperLowerFacial',
      checkRequirement:
        midlinePreferences.directionPrimary ===
        DirectionPrimary.MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE,
      disabled: false,
      testId: 'MidlineCorrectSection-MatchLowerToUpperFacial',
    })
  }

  const midlineCorrectRadioFormRegister = form.register(
    `${MIDLINE_PROPERTY_KEY}.directionPrimary`,
    {
      onChange: (event: RadioChangeEvent) => {
        const value = {
          ...midlinePreferences,
          directionPrimary: event.target.value,
          distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
        }

        form.setValue(`${MIDLINE_PROPERTY_KEY}`, value)
        form.clearErrors(MIDLINE_PROPERTY_KEY)
      },
    },
  )

  return (
    <Box justify="flex-start" align="flex-start" flexDirection="column">
      {radioButtonsToRender.map((radioItem) => (
        <OrmcoRadio
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...midlineCorrectRadioFormRegister}
          key={radioItem.value}
          value={radioItem.value}
          checked={radioItem.checkRequirement}
          disabled={radioItem.disabled}
          data-testid={radioItem.testId}
        >
          <Typography component="span">
            <FormattedMessage id={radioItem.translation} />
          </Typography>
        </OrmcoRadio>
      ))}
    </Box>
  )
}

export const MidlineCorrectSection = () => {
  const theme = useTheme()
  const form = useFormContext<CaseDraft>()
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())
  const midlinePreferences = ensure(form.getValues(MIDLINE_PROPERTY_KEY))
  const treatArches = form.getValues(TREAT_ARCHES_PROPERTY_KEY) as TreatArches

  const changeCorrectOption = (res: MidlinePreferencesPrimary) => {
    form.setValue(MIDLINE_PROPERTY_KEY, res)
  }

  return (
    <ThemedBox padding="8px" data-testid="MidlineCorrectSection">
      <MidlineCorrectRadioButtons />
      {isV6Form &&
        midlinePreferences?.directionPrimary ===
          DirectionPrimary.MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE && (
          <MidlineUpperAndLowerSection />
        )}
      {!isV6Form && (
        <>
          <Divider />
          <Box justify="flex-start">
            <OrmcoCheckbox
              onChange={(selected) => {
                if (selected.target.checked) {
                  changeCorrectOption({
                    ...midlinePreferences,
                    ...{
                      moveSpecificAmounts: selected.target.checked,
                    },
                  })
                } else {
                  changeCorrectOption({
                    ...midlinePreferences,
                    ...{
                      distancePrimaryLower: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                      distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                      moveSpecificAmounts: selected.target.checked,
                    },
                  })
                }
              }}
              checked={midlinePreferences?.moveSpecificAmounts === true}
              data-testid="Midline-MoveSpecificAmounts"
            >
              <Typography component="span">
                <FormattedMessage id="caseWizard.casePrescription.midline.moveSpecAmmount" />
              </Typography>
            </OrmcoCheckbox>
          </Box>
          <Vertical>
            <Typography
              component="label"
              variant="small"
              textFontWeight="600"
              color="base900"
              padding="15px 0 3px 15px"
              data-testid="Midline-Heading-Upper"
            >
              <FormattedMessage id="caseWizard.casePrescription.midline.upper" />
            </Typography>
            <StyledSelect
              defaultValue={midlinePreferences.distancePrimaryUpper}
              value={
                treatArches === TreatArches.LOWER_ONLY
                  ? MidlineDistance.RIGHT_ONE_TO_TWO_MM
                  : midlinePreferences.distancePrimaryUpper
              }
              className="selectDistance"
              disabled={
                treatArches === TreatArches.LOWER_ONLY ||
                midlinePreferences?.moveSpecificAmounts === false
              }
              style={{ width: 300 }}
              onChange={(selected: any) =>
                changeCorrectOption({
                  ...midlinePreferences,
                  ...{
                    distancePrimaryUpper: selected,
                  },
                })
              }
              data-testid="Midline-Select-Upper"
            >
              <StyledSelect.Option value={MidlineDistance.RIGHT_ONE_TO_TWO_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.right12mm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.LEFT_ONE_TO_TWO_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.left12mm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.RIGHT_TWO_PLUS_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.right2pmm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.LEFT_TWO_PLUS_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.left2pmm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.MAINTAIN}>
                <FormattedMessage id="caseWizard.casePrescription.midline.maintain" />
              </StyledSelect.Option>
            </StyledSelect>
          </Vertical>

          <Vertical>
            <Typography
              component="label"
              variant="small"
              textFontWeight="600"
              color="base900"
              padding="15px 0 3px 15px"
              data-testid="Midline-Heading-Lower"
            >
              <FormattedMessage id="caseWizard.casePrescription.midline.lower" />
            </Typography>

            <StyledSelect
              disabled={
                treatArches === TreatArches.UPPER_ONLY ||
                midlinePreferences?.moveSpecificAmounts === false
              }
              defaultValue={midlinePreferences.distancePrimaryLower}
              value={
                treatArches === TreatArches.UPPER_ONLY
                  ? MidlineDistance.RIGHT_ONE_TO_TWO_MM
                  : midlinePreferences.distancePrimaryLower
              }
              style={{
                width: 300,
                backgroundColor: theme.colors.primary10,
              }}
              onChange={(selected: any) =>
                changeCorrectOption({
                  ...midlinePreferences,
                  ...{
                    distancePrimaryLower: selected,
                  },
                })
              }
              data-testid="Midline-Select-Lower"
            >
              <StyledSelect.Option value={MidlineDistance.RIGHT_ONE_TO_TWO_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.right12mm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.LEFT_ONE_TO_TWO_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.left12mm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.RIGHT_TWO_PLUS_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.right2pmm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.LEFT_TWO_PLUS_MM}>
                <FormattedMessage id="caseWizard.casePrescription.midline.left2pmm" />
              </StyledSelect.Option>
              <StyledSelect.Option value={MidlineDistance.MAINTAIN}>
                <FormattedMessage id="caseWizard.casePrescription.midline.maintain" />
              </StyledSelect.Option>
            </StyledSelect>
          </Vertical>
        </>
      )}
    </ThemedBox>
  )
}
