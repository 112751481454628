import { Universal } from 'app/core/domain/ArchTools'
import { CutoutType } from 'app/core/domain/CutoutType'
import { Nullable } from 'app/core/types/utils'

export type IntegratedHooksOrientationType = 'reverseY' | 'reverseX' | 'reverseXY'

export interface GetIntegratedHooksOrientationProps {
  toothNumber: Universal
  cutoutType: CutoutType
}

export const getIntegratedHooksOrientation = ({
  toothNumber,
  cutoutType,
}: GetIntegratedHooksOrientationProps): Nullable<IntegratedHooksOrientationType> => {
  const LeftCenterIncisorNumber = 8
  const RightThirdMolarNumber = 16
  const LowerRightCenterIncisorNumber = 24
  const isClassII = cutoutType === CutoutType.CLASSII

  const shouldClassIIReverseHorizontally = isClassII && toothNumber <= LeftCenterIncisorNumber
  const shouldClassIIReverseVertically =
    isClassII && toothNumber > RightThirdMolarNumber && toothNumber > LowerRightCenterIncisorNumber
  const shouldClassIIReverseBothAxis =
    isClassII && toothNumber > RightThirdMolarNumber && toothNumber <= LowerRightCenterIncisorNumber

  const shouldClassIIIReverseHorizontally =
    !isClassII && toothNumber > LeftCenterIncisorNumber && toothNumber <= RightThirdMolarNumber
  const shouldClassIIIReverseBothAxis = !isClassII && toothNumber > LowerRightCenterIncisorNumber
  const shouldClassIIIReverseVertically =
    !isClassII &&
    toothNumber > RightThirdMolarNumber &&
    toothNumber <= LowerRightCenterIncisorNumber

  if (shouldClassIIReverseHorizontally || shouldClassIIIReverseHorizontally) {
    return 'reverseX'
  }

  if (shouldClassIIReverseVertically || shouldClassIIIReverseVertically) {
    return 'reverseY'
  }

  if (shouldClassIIReverseBothAxis || shouldClassIIIReverseBothAxis) {
    return 'reverseXY'
  }

  return null
}
