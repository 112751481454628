import { Typography } from 'app/components/ui/SDS/common/Typography'

interface AddressFormLabelProps {
  text?: string
}

export const AddressFormLabel = (props: AddressFormLabelProps) => (
  <Typography component="label" textFontWeight="600" letterSpacing="0.1px" wordBreak="break-word">
    {props.text}
  </Typography>
)
