import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { CreditCard, Shipping, OfficeAddress } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Row } from 'app/components/ui/Table/Row'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'
import { Address } from 'app/core/domain/Address'
import { PracticeAddressDetails } from 'app/core/domain/EmployeeProfile'
import { appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled, { useTheme } from 'styled-components'

const AddressTable: React.FC = () => {
  const history = useHistory()
  const currentUser = useSelector(appSelectors.getUsername())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const theme = useTheme()

  const practiceAddressesList = useSelector(appSelectors.getPracticeAddresses())
  const maxAddressLength = 50
  const onGet = useCallback(
    (_event: React.MouseEvent, addressData: Address) => {
      history.push(`/editAddress/${currentUser}/${addressData.id}`)
    },
    [history, currentUser],
  )

  return (
    <Box flexDirection="column" align="flex-start" data-testid="AddressDetails-AddressTable">
      <HeaderRow padding="7px 0" justify="flex-start">
        <Box
          paddingLeft="28px"
          justify="flex-start"
          width="200px"
          data-testid="AddressDetails-AddressTable-Tag"
        >
          <Typography component="span" textFontWeight="600" isUppercase>
            <FormattedMessage id="addressTable.header.tag" />
          </Typography>
        </Box>
        <Box
          paddingLeft="28px"
          justify="flex-start"
          width="200px"
          data-testid="AddressDetails-AddressTable-Address"
        >
          <Typography component="span" textFontWeight="600" isUppercase>
            <FormattedMessage id="addressTable.header.address" />
          </Typography>
        </Box>
      </HeaderRow>
      <TableRowsContainer>
        <Box display="block" data-testid="AddressDetails-AddressTable-Body">
          {practiceAddressesList.map((address: PracticeAddressDetails, index) => {
            const { addressTag, addressSummary, isBillTo, isShipTo, isOffice } = address

            return (
              <TableRow minHeight="46px" key={index}>
                <Box
                  paddingLeft="28px"
                  width="200px"
                  justify="flex-start"
                  onClick={(event) => {
                    onGet(event, address)
                  }}
                  data-testid={`addressDetails-addressTag-${addressTag}`}
                >
                  <Typography component="span" textFontWeight="600" textOverflow="ellipsis">
                    {addressTag}
                  </Typography>
                </Box>
                <Box
                  paddingLeft="28px"
                  width="500px"
                  justify="flex-start"
                  onClick={(event) => {
                    onGet(event, address)
                  }}
                >
                  <Typography component="span" textFontWeight="600" textOverflow="ellipsis">
                    {addressSummary !== undefined && addressSummary?.length > maxAddressLength
                      ? addressSummary?.substring(0, maxAddressLength).concat(' ..')
                      : addressSummary}
                  </Typography>
                </Box>
                <Box marginLeft="auto" padding="0 24px" width="auto">
                  {isBillTo && (
                    <OrmcoTooltip
                      title={<FormattedMessage id="defaultBillingAddress.tooltip.message" />}
                      placement="top"
                      overlayInnerStyle={{
                        color: theme.colors.white,
                        fontSize: '11px',
                        padding: '11px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box padding="0 4px" width="auto">
                        <CreditCard />
                      </Box>
                    </OrmcoTooltip>
                  )}
                  {isShipTo && (
                    <OrmcoTooltip
                      title={
                        <Typography variant="small" color="white">
                          <FormattedMessage id="defaultShippingAddress.tooltip.message" />
                        </Typography>
                      }
                      placement="top"
                      overlayInnerStyle={{
                        padding: '11px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box padding="0 4px" width="auto">
                        <Shipping />
                      </Box>
                    </OrmcoTooltip>
                  )}
                  {isOffice && featurePermissions?.doctorLocator && (
                    <OrmcoTooltip
                      title={
                        <TooltipText
                          data-testid="AddressTable-TooltipContent"
                          textId="defaultOfficeAddress.tooltip.message"
                        />
                      }
                      placement="top"
                      overlayInnerStyle={{
                        padding: '11px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box padding="0 4px" width="auto">
                        <OfficeAddress data-testid="AddressTable-TooltipIcon" />
                      </Box>
                    </OrmcoTooltip>
                  )}
                </Box>
              </TableRow>
            )
          })}
        </Box>
      </TableRowsContainer>
    </Box>
  )
}

const HeaderRow = styled(Box)`
  background-color: ${({ theme: styledTheme }) => styledTheme.colors.primary10};
`

const TableRowsContainer = styled(Box)`
  overflow: auto;

  max-height: 460px;
`

const TableRow = styled(Row)`
  border: 0.5px solid ${({ theme: styledTheme }) => styledTheme.colors.base200};
  border-top: none;

  :hover {
    cursor: pointer;

    background-color: ${({ theme: styledTheme }) => styledTheme.colors.base300};
  }
`

export { AddressTable }
