import { basicPageActions } from 'app/components/routes/BasicPage/logic'
import { CaseListParams } from 'app/core/domain/Http/CaseListParams'
import { StaffListResponse } from 'app/core/domain/Http/StaffListResponse'
import { User } from 'app/core/domain/User'
import { UserRole } from 'app/core/domain/UserRole'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

import { StaffListQueryUrlParams } from './TeamManagementTable'

interface MyPracticeFilter {
  role: UserRole
  checked: boolean
}

enum ListType {
  'SEARCH' = 'SEARCH',
  'TAB' = 'TAB',
}

const defaultUserFilter = [
  { role: UserRole.ROLE_NORMAL, checked: true },
  { role: UserRole.ROLE_STAFF, checked: true },
]

interface StaffListState {
  staffTableDetails: User[]
  userFilter: MyPracticeFilter[]
  isStaffListLoading: boolean
  hasMore: boolean
  staffListRequestParams: CaseListParams
  showUserFilter: boolean
  userFiltersUsed: boolean
  listType: ListType
  isSearchFilterUsed: boolean
  username?: string
}
const defaultListElementHeight = 62
const defaultStaffListParams = {
  start: 0,
  size: Math.round(window.innerHeight / defaultListElementHeight),
}
const INITIAL_STATE: StaffListState = {
  staffTableDetails: [],
  userFilter: [...defaultUserFilter],
  isStaffListLoading: false,
  hasMore: true,
  staffListRequestParams: { ...defaultStaffListParams },
  showUserFilter: false,
  userFiltersUsed: false,
  listType: ListType.TAB,
  isSearchFilterUsed: false,
  username: undefined,
}
const paginationStartIndex = 0
const paginationSize = 10

const staffListActions = {
  staffListMounted: createAction('@STAFF_TABLE/STAFF_DETAILS_MOUNTED')<StaffListQueryUrlParams>(),
  staffDetailsRequestFinished: createAction(
    '@STAFF_TABLE/STAFF_DETAILS_REQUEST_FINISHED',
  )<StaffListResponse>(),
  staffDetailsRequestFailed: createAction('@STAFF_TABLE/STAFF_DETAILS_REQUEST_FAILED')<{
    message: string
  }>(),
  staffFilterCheckboxClicked: createAction('@STAFF_TABLE/STAFF_USERS_CHECKBOX_CLICKED')<
    MyPracticeFilter[]
  >(),
  loadMoreStaffReached: createAction('@STAFF_TABLE/LOAD_MORE_STAFF_REACHED')(),

  searchDoctorInputChanged: createAction('@STAFF_TABLE/SEARCH_DOCTOR_INPUT_CHANGED')<string>(),
  clearSearchButtonClicked: createAction('@STAFF_TABLE/SEARCH_CLEAR_REQUESTED')(),
  userInformationClicked: createAction('@STAFF_TABLE/USER_INFORMATION_CLICKED')<string>(),
}

type StaffListActions = ActionType<
  typeof staffListActions | Pick<typeof basicPageActions, 'myTeamLinkClicked'>
>
const staffListReducer = createReducer<StaffListState, StaffListActions>(INITIAL_STATE)
  .handleAction(staffListActions.staffListMounted, (state, action) => {
    let listType: ListType | undefined

    if (!!action.payload?.search) {
      listType = ListType.SEARCH
    }

    return {
      ...state,
      isStaffListLoading: true,
      staffTableDetails: [],
      listType: listType === ListType.SEARCH ? ListType.SEARCH : ListType.TAB,
      staffListRequestParams: {
        ...defaultStaffListParams,
        ...(listType === ListType.SEARCH ? { search: action.payload.search } : {}),
      },
      username: undefined,
    }
  })
  .handleAction(staffListActions.staffDetailsRequestFinished, (state, action) => ({
    ...state,
    staffTableDetails: state.staffTableDetails.concat(action.payload.users),
    hasMore: action.payload.users.length > 0,
    isStaffListLoading: false,
    showUserFilter:
      state.userFiltersUsed === true
        ? true
        : action.payload.users.some((user) => user.userRole === UserRole.ROLE_STAFF),
  }))
  .handleAction(staffListActions.staffFilterCheckboxClicked, (state, action) => ({
    ...state,
    isStaffListLoading: true,
    userFilter: action.payload,
    userFiltersUsed: true,
    staffTableDetails: [],
    staffListRequestParams: { ...state.staffListRequestParams, ...defaultStaffListParams },
  }))
  .handleAction(staffListActions.loadMoreStaffReached, (state) => ({
    ...state,
    isStaffListLoading: true,
    staffListRequestParams: {
      ...state.staffListRequestParams,
      start:
        (state.staffListRequestParams.start || paginationStartIndex) +
        (state.staffListRequestParams.size || paginationSize),
    },
  }))
  .handleAction(staffListActions.searchDoctorInputChanged, (state, action?) => ({
    ...state,
    staffListRequestParams: { ...defaultStaffListParams, search: action.payload },
    listType: ListType.SEARCH,
    staffTableDetails: [],
    userFiltersUsed:
      state.userFiltersUsed === true
        ? true
        : state.staffTableDetails.some((user) => user.userRole === UserRole.ROLE_STAFF),
    isStaffListLoading: true,
    isSearchFilterUsed: true,
  }))
  .handleAction(
    [staffListActions.clearSearchButtonClicked, basicPageActions.myTeamLinkClicked],
    (state) => ({
      ...state,
      staffListRequestParams: { ...defaultStaffListParams },
      listType: ListType.TAB,
      staffTableDetails: [],
      isStaffListLoading: true,
      isSearchFilterUsed: false,
    }),
  )
  .handleAction(staffListActions.userInformationClicked, (state, action) => ({
    ...state,
    username: action.payload,
  }))

const staffListSelectors = {
  getStaffInfo: () => (state: RootState) =>
    state.staffTable.userFiltersUsed === true &&
    !state.staffTable.userFilter.some((user) => user.checked === true)
      ? []
      : state.staffTable.staffTableDetails,
  getUserFilter: () => (state: RootState) => state.staffTable.userFilter,
  isStaffListLoading: () => (state: RootState) => state.staffTable.isStaffListLoading,
  hasMoreStaffList: () => (state: RootState) => state.staffTable.hasMore,
  showUserFilter: () => (state: RootState) =>
    state.staffTable.userFiltersUsed === true
      ? true
      : state.staffTable.staffTableDetails.some((user) => user.userRole === UserRole.ROLE_STAFF),
  getStaffListParams:
    () =>
    (state: RootState): CaseListParams =>
      state.staffTable.staffListRequestParams,
  getSearchText: () => (state: RootState) => state.staffTable.staffListRequestParams.search,
  getListType:
    () =>
    (state: RootState): ListType =>
      state.staffTable.listType,
  isSearchFilterUsed: () => (state: RootState) => state.staffTable.isSearchFilterUsed,
  getUsername: () => (state: RootState) => state.staffTable.username,
}

export {
  MyPracticeFilter,
  defaultUserFilter,
  StaffListState,
  staffListActions,
  staffListReducer,
  staffListSelectors,
  StaffListActions,
  ListType,
}
