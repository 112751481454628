import { AffectedArche } from 'app/core/domain/AffectedArches'

import { Intrusion } from './Intrusion'

interface OverbitePrimary {
  biteLowerUpper?: AffectedArche
  chkExtrusionIntrusionPrimaryAnterior?: boolean
  chkExtrusionIntrusionPrimaryPosterior?: boolean
  chkLevelCurveOfSpee?: boolean
  extAntTeethVirtualSimulation?: boolean
  extAnteriorIntPosteriorTeeth?: boolean
  extAnteriorTeeth?: boolean
  extrusionIntrusionAnterior?: Intrusion
  extrusionIntrusionPosterior?: Intrusion
  intAnteriorTeeth?: IntAnteriorTeeth
  overbiteActionPrimary?: OverbiteActionPrimary
}

enum OverbiteActionPrimary {
  'MAINTAIN' = 'MAINTAIN',
  'CORRECT_DEEP_BITE' = 'CORRECT_DEEP_BITE',
  'CORRECT_OPEN_BITE' = 'CORRECT_OPEN_BITE',
  'CORRECT' = 'CORRECT',
}

enum IntAnteriorTeeth {
  'INT_ANT_TEETH_ONLY' = 'INT_ANT_TEETH_ONLY',
  'INT_ANT_EXT_POST_TEETH' = 'INT_ANT_EXT_POST_TEETH',
  'VITRTUAL_SIM_CORRECT' = 'VITRTUAL_SIM_CORRECT',
}

export { OverbitePrimary, OverbiteActionPrimary, IntAnteriorTeeth }
