import { SelectedScanner } from 'app/components/routes/CaseWizard/SelectedScanner'
import { Scan } from 'app/components/routes/CaseWizard/steps/EditScans/Scan'
import { CheckPairing } from 'app/core/domain/CheckPairing'
import { ScanDeletePayload } from 'app/core/domain/ScanDeletePayload'
import { ScanViewUrl } from 'app/core/domain/ScanViewUrl'
import { ThreeShapeAccount } from 'app/core/domain/ThreeShapeAccount'
import { ThreeShapeCaseList, ThreeShapeCaseSelected } from 'app/core/domain/ThreeShapeCaseList'
import { ThreeShapeCaseListRequest } from 'app/core/domain/ThreeShapeCaseListRequest'
import { ResponseStatus, UpdateResult } from 'app/core/domain/UpdateResult'
import { Nullable } from 'app/core/types/utils'
import { appSelectors } from 'app/logic/app/logic'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

import { CaseData } from './CaseData'
import { getCloudFrontScansPropValue } from './utils'

interface CloudFrontScansConfig {
  [language: string]: Record<string, string>
}

interface EditScansState {
  filesUploading: boolean
  filesUploadProgressList: number[]
  allScanTypes: Scan[]
  selectedScanner: Nullable<SelectedScanner>
  fileUploadedScanner: Nullable<SelectedScanner>
  areScansConfigured: boolean
  caseStatus: string
  selectedScanPdf?: UpdateResult<string>
  directTransferPdf?: UpdateResult<string>
  isThreeShapePairingSuccess: boolean
  displayScanActionsButtons: boolean
  threeShapeAccounts: ThreeShapeAccount[] | null
  threeShapeCaseList: ThreeShapeCaseList | null
  threeShapeSelectedScan: ThreeShapeCaseSelected | null
  scanViewUrls: ScanViewUrl[] | null
  onHold: boolean
  dicomAvailable: boolean
  holdReason: Nullable<string>
  lastDeleteTimestamp: Date | null
  scansChangesConfirmed: boolean
  isValidCase: boolean
  cloudFrontScansConfig: Nullable<CloudFrontScansConfig>
  isLoading: boolean
  directTransferScansUploaded: boolean
}

interface TshapeAccountListInfo {
  threeShapeAccountList: ThreeShapeAccount[]
}

interface UploadSTLPayload {
  file: File
  fileVersion: string
  onProgress: (progressEvent: ProgressEvent) => void
  onUploadEnd: () => void
}

interface UploadSTLSuccessPayload {
  fileName: string
  onUploadEnd: UploadSTLPayload['onUploadEnd']
}

interface GetScanListReceivedPayload {
  cloudFrontScansConfig: CloudFrontScansConfig
  scans: Scan[]
}

const INITIAL_STATE: EditScansState = {
  filesUploading: false,
  filesUploadProgressList: [],
  allScanTypes: [],
  selectedScanner: null,
  fileUploadedScanner: null,
  areScansConfigured: true,
  caseStatus: '',
  selectedScanPdf: undefined,
  directTransferPdf: undefined,
  isThreeShapePairingSuccess: false,
  displayScanActionsButtons: false,
  threeShapeAccounts: null,
  threeShapeCaseList: null,
  threeShapeSelectedScan: null,
  scanViewUrls: null,
  onHold: false,
  dicomAvailable: false,
  holdReason: null,
  lastDeleteTimestamp: null,
  scansChangesConfirmed: false,
  isValidCase: false,
  cloudFrontScansConfig: null,
  isLoading: false,
  directTransferScansUploaded: true,
}

const editScansActions = {
  saveScannerRequested: createAction('@EDIT_SCANS/SAVE_SCANNER_REQUESTED')<Scan>(),
  saveScannerReceived: createAction('@EDIT_SCANS/SAVE_SCANNER_UPDATE')<SelectedScanner>(),
  saveScannerFailed: createAction('@EDIT_SCANS/SAVE_SCANNER_FAILED')(),
  saveFileUploadedScanner: createAction(
    '@EDIT_SCANS/SAVE_FILE_UPLOADED_SCANNER_UPDATE',
  )<SelectedScanner>(),
  uploadFileStatus: createAction('@EDIT_SCANS/UPLOAD_FILE_STATUS')<{
    percentCompleted: number
    fileIndex: number
  }>(),
  uploadSTLFileRequested: createAction('@EDIT_SCANS/UPLOAD_STL_FILE_REQUESTED')<UploadSTLPayload>(),
  uploadSTLFileSuccess: createAction(
    '@EDIT_SCANS/UPLOAD_STL_FILE_SUCCESS',
  )<UploadSTLSuccessPayload>(),
  uploadSTLFileFailed: createAction('@EDIT_SCANS/UPLOAD_STL_FILE_FAILED')<{ message: string }>(),
  uploadAllSTLFilesComplete: createAction('@EDIT_SCANS/UPLOAD_ALL_STL_FILES_COMPLETE')(),
  threeShapePairingCheckRequested: createAction('@EDIT_SCANS/3SHAPE_PAIRING_CHECK_REQUESTED')(),
  threeShapePairingCheckReceived: createAction(
    '@EDIT_SCANS/3SHAPE_PAIRING_CHECK_RECEIVED',
  )<CheckPairing>(),
  threeShapeAccountsRequested: createAction('@EDIT_SCANS/3SHAPE_ACCOUNTS_REQUESTED')(),
  threeShapeAccountsReceived: createAction(
    '@EDIT_SCANS/3SHAPE_ACCOUNTS_RECEIVED',
  )<TshapeAccountListInfo>(),
  threeShapeAccountsFailed: createAction('@EDIT_SCANS/3SHAPE_ACCOUNTS_FAILED')<{
    message: string
  }>(),
  threeShapeCaseListRequested: createAction(
    '@EDIT_SCANS/3SHAPE_CASE_LIST_REQUESTED',
  )<ThreeShapeCaseListRequest>(),
  threeShapeCaseListReceived: createAction(
    '@EDIT_SCANS/3SHAPE_CASE_LIST_RECEIVED',
  )<ThreeShapeCaseList>(),
  threeShapeCaseListFailed: createAction('@EDIT_SCANS/3SHAPE_CASE_LIST_FAILED')<{
    message: string
  }>(),
  threeShapeCaseListUnmounted: createAction('@EDIT_SCANS/3SHAPE_CASE_LIST_UNMOUNTED')(),
  threeShapeScanSelectRequested: createAction(
    '@EDIT_SCANS/3SHAPE_SCAN_SELECTED',
  )<ThreeShapeCaseSelected>(),
  threeShapeScanSelectReceived: createAction('@EDIT_SCANS/3SHAPE_SCAN_SELECTED_RESPONSE')<{
    uploadResponse: string
  }>(),
  threeShapeScanSelectFailed: createAction('@EDIT_SCANS/3SHAPE_SCAN_SELECTED_FAIL')<{
    message: string
  }>(),
  getSelectedScannerRequest: createAction('@EDIT_SCANS/GET_SELECTED_SCANNER_REQUEST')<{
    id: string
    selectedStep: number | null
  }>(),
  getSelectedScannerReceived: createAction('@EDIT_SCANS/SELECTED_SCANNER_RECEIVED')<CaseData>(),
  getSelectedScannerFailed: createAction('@EDIT_SCANS/SELECTED_SCANNER_FAILED')<{
    message: string
  }>(),
  getScanListRequest: createAction('@EDIT_SCANS/SCAN_TYPE_LIST_REQUEST')(),
  getScanListReceived: createAction(
    '@EDIT_SCANS/SCAN_TYPE_LIST_RECEIVED',
  )<GetScanListReceivedPayload>(),
  getScanListRequestFailed: createAction('@EDIT_SCANS/SCAN_TYPE_LIST_REQUEST_FAILED')<{
    message: string
  }>(),
  getScanPdfRequested: createAction('@EDIT_SCANS/GET_SCAN_PDF_REQUEST')<{
    scanPdfUrl: string | undefined
  }>(),
  getScanPdfReceived: createAction('@EDIT_SCANS/GET_SCAN_PDF_RECEIVED')<string>(),
  getScanPdfFailed: createAction('@EDIT_SCANS/GET_SCAN_PDF_FAILED')<string>(),
  getScanPdfUnmounted: createAction('@EDIT_SCANS/GET_SCAN_PDF_UNMOUNTED')(),
  getDirectTransferPdfRequested: createAction('@EDIT_SCANS/GET_DIRECT_TRANSFER_PDF_REQUEST')<{
    scanPdfUrl: string | undefined
  }>(),
  getDirectTransferPdfReceived: createAction(
    '@EDIT_SCANS/GET_DIRECT_TRANSFER_PDF_RECEIVED',
  )<string>(),
  getDirectTransferPdfFailed: createAction('@EDIT_SCANS/GET_DIRECT_TRANSFER_PDF_FAILED')<string>(),
  getDirectTransferPdfUnmounted: createAction('@EDIT_SCANS/GET_DIRECT_TRANSFER_PDF_UNMOUNTED')(),
  scanViewUrlsRequested: createAction('@EDIT_SCANS/SCAN_VIEW_URLS_REQUESTED')(),
  scanViewUrlsReceived: createAction('@EDIT_SCANS/SCAN_VIEW_URLS_RECEIVED')<ScanViewUrl[] | null>(),
  scanViewUrlsFailed: createAction('@EDIT_SCANS/SCAN_VIEW_URLS_FAILED')<{ message: string }>(),
  scanDeleteRequested: createAction('@EDIT_SCANS/SCAN_DELETE_REQUESTED')<ScanDeletePayload>(),
  scanDeleteSuccess: createAction('@EDIT_SCANS/SCAN_DELETE_SUCCESS')<ScanDeletePayload>(),
  scanDeleteFailed: createAction('@EDIT_SCANS/SCAN_DELETE_FAILED')<{ message: string }>(),
  deleteDicomRequested: createAction('@EDIT_SCANS/DELETE_DICOM_REQUESTED')(),
  deleteDicomFailed: createAction('@UPLOAD_DICOM/DELETE_DICOM_FAILED')(),
  updateDicomUploadStatus: createAction('@EDIT_SCANS/UPDATE_DICOM_UPLOAD_STATUS')<boolean>(),
  updateCaseStatusRequested: createAction('@EDIT_SCANS/UPDATE_CASE_STATUS_REQUESTED')<{
    caseId: string
  }>(),
  updateCaseStatusFailed: createAction('@EDIT_SCANS/UPDATE_CASE_STATUS_FAILED')(),
  updateCaseStatusReceived: createAction('@EDIT_SCANS/UPDATE_CASE_STATUS_RECEIVED')(),
  updateScansChangesConfirmed: createAction(
    '@EDIT_SCANS/UPDATE_SCANS_CHANGES_CONFIRMED',
  )<boolean>(),
  scanOrderIdChanged: createAction('@EDIT_SCANS/SCAN_ORDER_ID_CHANGED')<string>(),
  updateScanOrderIdRequested: createAction('@EDIT_SCANS/UPDATE_SCAN_ORDER_ID')<string>(),
  updateScanOrderIdSucceeded: createAction('@EDIT_SCANS/UPDATE_SCAN_ORDER_ID_RECEIVED')(),
  updateScanOrderIdFailed: createAction('@EDIT_SCANS/UPDATE_SCAN_ORDER_ID_FAILED')(),
  getDirectTransferScansFlagRequested: createAction(
    '@EDIT_SCANS/GET_DIRECT_TRANSFER_SCANS_FLAG_REQUESTED',
  )<string | null>(),
  getDirectTransferScansFlagReceived: createAction(
    '@EDIT_SCANS/GET_DIRECT_TRANSFER_SCANS_FLAG_RECEIVED',
  )<boolean>(),
  getDirectTransferScansFlagFailed: createAction(
    '@EDIT_SCANS/GET_DIRECT_TRANSFER_SCANS_FLAG_FAILED',
  )(),
}

type EditScansActions = ActionType<typeof editScansActions>

const editScansReducer = createReducer<EditScansState, EditScansActions>(INITIAL_STATE)
  .handleAction(editScansActions.uploadSTLFileRequested, (state) => ({
    ...state,
    filesUploading: true,
    filesUploadProgressList: [],
  }))
  .handleAction(editScansActions.uploadAllSTLFilesComplete, (state) => ({
    ...state,
    filesUploading: false,
    displayScanActionsButtons: true,
    filesUploadProgressList: [],
  }))
  .handleAction(editScansActions.uploadFileStatus, (state, action) => {
    const progress = [...state.filesUploadProgressList]

    progress[action.payload.fileIndex] = action.payload.percentCompleted

    return {
      ...state,
      filesUploadProgressList: progress,
    }
  })
  .handleAction(editScansActions.scanDeleteSuccess, (state, action) => {
    const displayScanActionsButtons = action.payload.hideButtonsOnEnd
      ? false
      : state.displayScanActionsButtons

    return {
      ...state,
      displayScanActionsButtons,
      isLoading: false,
      lastDeleteTimestamp: action.payload.updateTimestamp ? new Date() : null,
    }
  })
  .handleAction([editScansActions.scanDeleteFailed], (state) => ({
    ...state,
    isLoading: false,
  }))
  .handleAction([editScansActions.saveScannerReceived], (state, action) => ({
    ...state,
    selectedScanner: action.payload,
  }))
  .handleAction([editScansActions.saveFileUploadedScanner], (state, action) => ({
    ...state,
    fileUploadedScanner: action.payload,
  }))
  .handleAction([editScansActions.threeShapeAccountsReceived], (state, action) => ({
    ...state,
    threeShapeAccounts: action.payload.threeShapeAccountList,
  }))
  .handleAction([editScansActions.threeShapeAccountsFailed], (state) => ({
    ...state,
    threeShapeAccounts: null,
  }))
  .handleAction([editScansActions.threeShapeCaseListReceived], (state, action) => ({
    ...state,
    threeShapeCaseList: action.payload,
  }))
  .handleAction([editScansActions.threeShapeCaseListUnmounted], (state) => ({
    ...state,
    threeShapeCaseList: null,
  }))
  .handleAction([editScansActions.threeShapeCaseListFailed], (state) => ({
    ...state,
    threeShapeCaseList: null,
  }))
  .handleAction([editScansActions.threeShapeScanSelectRequested], (state, action) => ({
    ...state,
    filesUploading: true,
    filesUploadProgressList: [],
    displayScanActionsButtons: true,
    threeShapeSelectedScan: action.payload,
  }))
  .handleAction([editScansActions.threeShapeScanSelectReceived], (state) => ({
    ...state,
    lastDeleteTimestamp: null,
    scanViewUrls: null,
    filesUploading: false,
  }))
  .handleAction([editScansActions.threeShapeScanSelectFailed], (state) => ({
    ...state,
    scanViewUrls: null,
    filesUploading: false,
  }))
  .handleAction([editScansActions.threeShapePairingCheckReceived], (state, action) => ({
    ...state,
    isThreeShapePairingSuccess: action.payload?.pairing === 'success',
  }))
  .handleAction(editScansActions.getScanListReceived, (state, action) => {
    const { scans, cloudFrontScansConfig } = action.payload

    return {
      ...state,
      cloudFrontScansConfig: cloudFrontScansConfig,
      allScanTypes: scans,
      areScansConfigured: Boolean(scans.length),
    }
  })
  .handleAction(editScansActions.getScanListRequestFailed, (state) => ({
    ...state,
    areScansConfigured: false,
    allScanTypes: [],
  }))
  .handleAction(editScansActions.getSelectedScannerReceived, (state, action) => ({
    ...state,
    caseStatus: action.payload.caseStatus,
    selectedScanner: action.payload.scannerInfo ?? null,
    fileUploadedScanner: action.payload.scannerInfo ?? null,
    onHold: action.payload.onHold,
    dicomAvailable: action.payload.dicomAvailable,
    holdReason:
      action.payload.onHold && action.payload.holdReason ? action.payload.holdReason : null,
    isValidCase: action.payload.isValidCase ?? true,
  }))
  .handleAction(editScansActions.getSelectedScannerFailed, (state) => ({
    ...state,
    caseStatus: '',
    selectedScanner: {},
    fileUploadedScanner: {},
    onHold: false,
    dicomAvailable: false,
    holdReason: null,
  }))
  .handleAction(editScansActions.getScanPdfReceived, (state, action) => ({
    ...state,
    selectedScanPdf: {
      status: ResponseStatus.OK,
      message: 'scans.modal.title',
      payload: action.payload,
    },
  }))
  .handleAction(editScansActions.getScanPdfFailed, (state) => ({
    ...state,
    selectedScanPdf: {
      status: ResponseStatus.BAD_REQUEST,
      message: 'truGen.modal.error',
    },
  }))
  .handleAction(editScansActions.getScanPdfUnmounted, (state) => ({
    ...state,
    selectedScanPdf: undefined,
  }))
  .handleAction(editScansActions.getDirectTransferPdfReceived, (state, action) => ({
    ...state,
    directTransferPdf: {
      status: ResponseStatus.OK,
      message: 'scans.modal.title',
      payload: action.payload,
    },
  }))
  .handleAction(editScansActions.getDirectTransferPdfFailed, (state) => ({
    ...state,
    directTransferPdf: {
      status: ResponseStatus.BAD_REQUEST,
      message: 'truGen.modal.error',
    },
  }))
  .handleAction(editScansActions.getDirectTransferPdfUnmounted, (state) => ({
    ...state,
    directTransferPdf: undefined,
  }))
  .handleAction(editScansActions.scanViewUrlsReceived, (state, action) => {
    const displayScanActionsButtons = (() => {
      if (state.displayScanActionsButtons) {
        return true
      }

      return state.filesUploading ? false : Boolean(action.payload?.length)
    })()

    return {
      ...state,
      scanViewUrls: action.payload,
      displayScanActionsButtons,
    }
  })
  .handleAction(editScansActions.scanViewUrlsFailed, (state) => ({
    ...state,
    scanViewUrls: null,
    displayScanActionsButtons: false,
  }))
  .handleAction(editScansActions.updateDicomUploadStatus, (state, action) => ({
    ...state,
    dicomAvailable: action.payload,
  }))
  .handleAction(editScansActions.updateScansChangesConfirmed, (state, action) => ({
    ...state,
    scansChangesConfirmed: action.payload,
  }))
  .handleAction(editScansActions.scanDeleteRequested, (state, action) => {
    const displayScanActionsButtons = action.payload.hideButtonsOnStart
      ? false
      : state.displayScanActionsButtons

    return {
      ...state,
      isLoading: false,
      filesUploading: false,
      filesUploadProgressList: [],
      scanViewUrls: null,
      displayScanActionsButtons,
    }
  })
  .handleAction(editScansActions.scanOrderIdChanged, (state, action) => ({
    ...state,
    selectedScanner: { ...state.selectedScanner, scanOrderId: action.payload },
  }))
  .handleAction(editScansActions.updateScanOrderIdRequested, (state, action) => ({
    ...state,
    selectedScanner: { ...state.selectedScanner, scanOrderId: action.payload },
  }))
  .handleAction(editScansActions.getDirectTransferScansFlagFailed, (state) => ({
    ...state,
    directTransferScansUploaded: false,
  }))
  .handleAction(editScansActions.getDirectTransferScansFlagReceived, (state, action) => ({
    ...state,
    directTransferScansUploaded: action.payload,
  }))

const editScansSelectors = {
  getAllScanTypes: () => (state: RootState) => state.editScans.allScanTypes,
  getAllScanTypesAreConfigured: () => (state: RootState) => state.editScans.areScansConfigured,
  getSelectedScanner: () => (state: RootState) => state.editScans.selectedScanner,
  getCaseStatus: () => (state: RootState) => state.editScans.caseStatus,
  getScanPdfUrl: () => (state: RootState) => state.editScans.selectedScanPdf,
  getDirectTransferPdfUrl: () => (state: RootState) => state.editScans.directTransferPdf,
  getThreeShapeAccounts: () => (state: RootState) => state.editScans.threeShapeAccounts,
  getThreeShapeCaseList: () => (state: RootState) => state.editScans.threeShapeCaseList,
  getThreeShapePairingSuccess: () => (state: RootState) =>
    state.editScans.isThreeShapePairingSuccess,
  getDisplayScanActionButtons: () => (state: RootState) =>
    state.editScans.displayScanActionsButtons,
  getisLoading: () => (state: RootState) => state.editScans.isLoading,
  getScanViewUrls: () => (state: RootState) => state.editScans.scanViewUrls,
  getOnHoldStatus: () => (state: RootState) => state.editScans.onHold,
  getDicomAvailableStatus: () => (state: RootState) => state.editScans.dicomAvailable,
  getHoldReason: () => (state: RootState) => state.editScans.holdReason,
  getScansChangesConfirmed: () => (state: RootState) => state.editScans.scansChangesConfirmed,
  getLastDeleteTimestamp: () => (state: RootState) => state.editScans.lastDeleteTimestamp,
  getValidCaseValue: () => (state: RootState) => state.editScans.isValidCase,
  getFilesUploading: () => (state: RootState) => state.editScans.filesUploading,
  getFilesUploadProgressList: () => (state: RootState) => state.editScans.filesUploadProgressList,
  getFileUploadedScanner: () => (state: RootState) => state.editScans.fileUploadedScanner,
  getCloudFrontScansForLanguage: () => (state: RootState) => {
    const language = appSelectors.getLanguage()(state)

    return (
      state.editScans.cloudFrontScansConfig?.[language] ??
      state.editScans.cloudFrontScansConfig?.en ??
      {}
    )
  },
  getDirectTransferScansFlag: () => (state: RootState) =>
    state.editScans.directTransferScansUploaded,
  getScansMessagesAndLinks: () => (state: RootState) => {
    const cloudFrontScans = editScansSelectors.getCloudFrontScansForLanguage()(state)
    const selectedScanType = state.editScans.selectedScanner?.name ?? ''

    return {
      directTransferPdfGuideUrl: getCloudFrontScansPropValue({
        selectedScanType,
        cloudFrontScans,
        propName: 'scanner_direct_transfer_link__',
      }),
      directTransferMessageText: getCloudFrontScansPropValue({
        selectedScanType,
        cloudFrontScans,
        propName: 'scanner_direct_transfer_message__',
      }),
      directTransferLoginLabel: getCloudFrontScansPropValue({
        selectedScanType,
        cloudFrontScans,
        propName: 'scanner_direct_transfer_login_label__',
      }),
      directTransferLoginUrl: getCloudFrontScansPropValue({
        selectedScanType,
        cloudFrontScans,
        propName: 'scanner_direct_transfer_login_url__',
      }),
      scanTextToDisplay: getCloudFrontScansPropValue({
        selectedScanType,
        cloudFrontScans,
        propName: 'scanner_description__',
      }),
      scanPdfUrl: getCloudFrontScansPropValue({
        selectedScanType,
        cloudFrontScans,
        propName: 'scanner_pdf_link__',
      }),
    }
  },
} as const

export {
  editScansActions,
  editScansReducer,
  editScansSelectors,
  EditScansActions,
  EditScansState,
  UploadSTLPayload,
  UploadSTLSuccessPayload,
  TshapeAccountListInfo,
  CloudFrontScansConfig,
  GetScanListReceivedPayload,
}
