import styled from 'styled-components'

import { BoxProps } from './interfaces'

export const Box = styled.div<BoxProps>`
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-left: ${({ marginLeft }) => marginLeft};

  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};

  display: ${({ display = 'flex' }) => display};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  flex-grow: ${({ flexGrow }) => flexGrow};
  gap: ${({ gap }) => gap};
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: ${({ align = 'center' }) => align};

  box-sizing: ${({ boxSizing }) => boxSizing};
  width: ${({ width = '100%' }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};

  background-color: ${({ theme, backgroundColor = 'inherit' }) =>
    backgroundColor === 'inherit' ? 'inherit' : theme.colors[backgroundColor]};

  border-radius: ${({ borderRadius }) => borderRadius};
`
