import { Row } from 'antd'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'

import { BracketPreferencesSelector } from './BracketPreferencesSelector'

const BracketPreferences = () => {
  return (
    <OrmcoFormItemVertical
      hasFeedback
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.bracketPreferences.label" />
      }
      data-testid="CasePrescription-Product"
    >
      <Row gutter={16}>
        <BracketPreferencesSelector arch={TreatArches.UPPER_ONLY} />
        <BracketPreferencesSelector arch={TreatArches.LOWER_ONLY} />
      </Row>
    </OrmcoFormItemVertical>
  )
}

export { BracketPreferences }
