import styled, { css } from 'styled-components'

import { SelectContainerProps } from './interfaces'

const selectLayers = {
  icon: 1,
} as const

const smallTextMixin = css`
  font-size: ${({ theme }) => theme.fontVariants.small.fontSize};
  line-height: ${({ theme }) => theme.fontVariants.small.lineHeight};
`

const mediumTextMixin = css`
  font-size: ${({ theme }) => theme.fontVariants.medium.fontSize};
  line-height: ${({ theme }) => theme.fontVariants.medium.lineHeight};
`

const defaultTextMixin = css`
  font-size: ${({ theme }) => theme.fontVariants.default.fontSize};
  line-height: ${({ theme }) => theme.fontVariants.default.lineHeight};
`

const sharedAntStylesMixin = css<SelectContainerProps>`
  .ant-select {
    width: 100%;
  }

  &&& .ant-select-selector {
    padding-right: 12px;
    padding-left: ${({ hasPrefixIcon }) => (hasPrefixIcon ? '36px' : '16px')};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.colors.base200};
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary500};
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary500};

    box-shadow: 0 1px 6px ${({ theme }) => theme.colors.primaryTransparent20};
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: ${({ theme }) => theme.colors.base300};
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: ${({ theme }) => theme.colors.base200};
    background: ${({ theme }) => theme.colors.base10};
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.danger400};

    color: ${({ theme }) => theme.colors.danger400};

    box-shadow: 0 1px 6px ${({ theme }) => theme.colors.dangerTransparent20};
  }

  .sds-select__options {
    & .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      font-weight: 400;

      color: ${({ theme }) => theme.colors.base900};
      background-color: ${({ theme }) => theme.colors.base10};
    }

    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      font-weight: 400;

      color: ${({ theme }) => theme.colors.base900};
      background-color: ${({ theme }) => theme.colors.primary10};
    }

    & .ant-select-item-option-disabled {
      color: ${({ theme }) => theme.colors.base300};
    }
  }
`

const smallStyles = css<SelectContainerProps>`
  .sds-select__prefix-icon {
    left: 8px;

    svg {
      min-width: 12px;
      max-width: 12px;
      height: auto;
    }
  }

  &&& .ant-select-selector {
    padding-right: 8px;
    padding-left: ${({ hasPrefixIcon }) => (hasPrefixIcon ? '30px' : '8px')};
  }
`

const largeStyles = css<SelectContainerProps>`
  .sds-select__label {
    ${mediumTextMixin}
  }

  .sds-select__right-label {
    ${defaultTextMixin}
  }

  .sds-select__error {
    ${defaultTextMixin}
  }

  .sds-select__help-message {
    ${defaultTextMixin}
  }

  .sds-select__prefix-icon {
    left: 16px;

    svg {
      min-width: 14px;
      max-width: 14px;
      height: auto;
    }
  }

  &&&& .ant-select-selection-item {
    font-size: ${({ theme }) => theme.fontVariants.medium.fontSize};
  }

  &&& .ant-select-selector {
    padding-right: 16px;
    padding-left: ${({ hasPrefixIcon }) => (hasPrefixIcon ? '40px' : '16px')};
  }
`

const darkAntStyles = css<SelectContainerProps>`
  & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.colors.primary500};

    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryTransparent40};
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary300};

    background: ${({ theme }) => theme.colors.primaryTransparent60};
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary300};

    background: ${({ theme }) => theme.colors.primaryTransparent60};

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 10px rgba(0, 0, 0, 0.1);
  }

  && .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary500};

    color: ${({ theme }) => theme.colors.primary500};

    background: transparent;
  }

  &&& .ant-select-single.ant-select-open .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary300};

    background: ${({ theme }) => theme.colors.primaryTransparent40};

    .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.primary300};
    }
  }

  .ant-select-arrow {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.primary500 : theme.colors.white};
  }
`

const darkStyles = css<SelectContainerProps>`
  .sds-select__content {
    background: ${({ theme }) => theme.colors.primary700};

    border-radius: 2px;
  }

  .sds-select__label {
    color: ${({ theme }) => theme.colors.base900};
  }

  ${darkAntStyles}
`

export const SelectContainer = styled.div<SelectContainerProps>`
  width: ${({ $width = 'auto' }) => $width};

  .sds-select__label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sds-select__content {
    position: relative;

    display: flex;
    flex-direction: row;
  }

  .sds-select__prefix-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    z-index: ${selectLayers.icon};

    color: ${({ isDisabled, theme }) => isDisabled && theme.colors.base300};

    transform: translateY(-45%);

    svg {
      min-width: 12px;
      max-width: 12px;
      height: auto;
    }
  }

  .sds-select__label {
    font-weight: 400;

    color: ${({ theme }) => theme.colors.base900};

    ${defaultTextMixin}
  }

  .sds-select__right-label {
    font-weight: 400;

    color: ${({ theme }) => theme.colors.base300};

    ${smallTextMixin}
  }

  .sds-select__error-message {
    font-weight: 400;

    color: ${({ theme }) => theme.colors.danger400};

    ${smallTextMixin}
  }

  .sds-select__help-message {
    font-weight: 400;

    color: ${({ theme }) => theme.colors.base300};

    ${smallTextMixin}
  }

  ${sharedAntStylesMixin}

  ${({ size }) => {
    if (size === 'small') {
      return smallStyles
    }

    if (size === 'large') {
      return largeStyles
    }
  }}

  ${({ isDark }) => isDark && darkStyles}
`
