import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'

const StaffLastName: React.FC = () => {
  const intl = useIntl()

  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalInformation>()

  return (
    <>
      <StaffFormItemVertical
        colon={false}
        label={<InputLabel labelId="staff.staffDetails.staffLastName.label" />}
        hasFeedback
        validateStatus={getValidationStatus('lastName', errors)}
        help={<OrmcoFormErrorMessage errors={errors} name="lastName" />}
        data-testid="StaffDetails-StaffLastName"
      >
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <StaffInput
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
            />
          )}
          rules={{
            required: intl.formatMessage({ id: 'staff.staffDetails.staffLastName.required' }),
            pattern: {
              value: /^[^~`@#$!%^&*()><{}.,_+=?;:\\[\]|'"/]*$/,
              message: intl.formatMessage({ id: 'staff.staffDetails.staffLastName.letter' }),
            },
          }}
        />
      </StaffFormItemVertical>
    </>
  )
}

export { StaffLastName }
