import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { EmployeeProfileFields } from 'app/core/domain/EmployeeProfile'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const WebsiteUrl: React.FC = () => {
  const dispatch = useDispatch()
  const {
    formState: { errors },
  } = useFormContext<EmployeeProfileFields>()
  const form = useFormContext<EmployeeProfileFields>()
  const { websiteUrl } = useSelector(appSelectors.getEmployeeContactDetails())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const updateUserProfileData = () => {
    dispatch(
      appActions.saveEmployeeDetails({
        part: form.getValues('websiteUrl'),
        key: 'employeeContactDetails.websiteUrl',
      }),
    )
  }

  return (
    <>
      {featurePermissions?.disableUserProfile ? (
        <ReadonlyValue
          data-testid="WebsiteUrl-ProfileContainer"
          labelId="website.url"
          value={websiteUrl}
        />
      ) : (
        <StaffFormItemVertical
          colon={false}
          label={<InputLabel labelId="website.url" isOptional />}
          validateStatus={getValidationStatus('websiteUrl', errors)}
          hasFeedback
          help={<OrmcoFormErrorMessage errors={errors} name="websiteUrl" />}
          data-testid="WebsiteUrl-StaffFormItemVertical"
        >
          <Controller
            name="websiteUrl"
            render={({ field }) => (
              <StaffInput
                width="100%"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
                onBlur={updateUserProfileData}
              />
            )}
          />
        </StaffFormItemVertical>
      )}
    </>
  )
}

export { WebsiteUrl }
