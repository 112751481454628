function ensure<T>(param?: T, paramName: string = 'param'): T {
  if (param !== null && typeof param !== 'undefined') {
    return param
  }
  throw new Error(`${paramName} is not initialized`)
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U

export { ensure, XOR }
