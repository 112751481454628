import { FC } from 'react'

import { TypographyProps } from './interfaces'
import { StyledTypography } from './typography.styles'

export const Typography: FC<TypographyProps> = ({
  padding = '0',
  margin = '0',
  variant = 'default',
  component = 'p',
  color = 'base900',
  align = 'left',
  textFontWeight = '400',
  title,
  children,
  ...props
}) => (
  <StyledTypography
    as={component}
    title={title}
    padding={padding}
    margin={margin}
    variant={variant}
    color={color}
    align={align}
    textFontWeight={textFontWeight}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </StyledTypography>
)
