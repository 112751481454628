import React, { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { RadioChangeEvent } from 'antd'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import {
  DirectionPrimary,
  MidlineDistance,
  MidlinePreferencesPrimary,
  MidlineActionPrimary,
} from 'app/core/domain/MidlinePreferencesPrimary'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { Nullable } from 'app/core/types/utils'
import { isNil } from 'app/core/utils'

import { MidlineCorrectSection } from './MidlineCorrectSection'

const MIDLINE_PROPERTY_KEY = 'primaryCasePreferences.midlinePreferencesPrimary'

const defaultMidlineRadioConfigItems = [
  {
    name: 'Midline',
    value: MidlineActionPrimary.MAINTAIN,
    translation: 'caseWizard.casePrescription.midline.maintain',
    testId: 'Midline-Maintain',
  },
  {
    name: 'Midline',
    value: MidlineActionPrimary.CORRECT,
    translation: 'caseWizard.casePrescription.midline.correct',
    testId: 'Midline-Correct',
  },
]

const standardMidlineV5Options = {
  distancePrimaryLower: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
  distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
  moveSpecificAmounts: false,
}

export const Midline = () => {
  const form = useFormContext<CaseDraft>()
  const treatArches = form.getValues('primaryCasePreferences.treatArches')
  const midlinePreferences = form.getValues(MIDLINE_PROPERTY_KEY)
  const midlineSectionRef = useRef<Nullable<HTMLDivElement>>(null)
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())
  let radioButtonsToRender = defaultMidlineRadioConfigItems

  const midlineRadioFormRegister = form.register(`${MIDLINE_PROPERTY_KEY}.actionPrimary`, {
    onChange: (event: RadioChangeEvent) => {
      const v5DirectionPrimary =
        treatArches === TreatArches.UPPER_ONLY
          ? DirectionPrimary.UPPER_TO_LOWER
          : DirectionPrimary.LOWER_TO_UPPER

      const directionPrimary = isV6Form
        ? DirectionPrimary.MATCH_UPPER_AND_LOWER_TO_FACIAL_MIDLINE
        : v5DirectionPrimary

      const actionValues: MidlinePreferencesPrimary = {
        actionPrimary: event.target.value,
        directionPrimary,
      }

      const value = isV6Form
        ? { ...actionValues, ...standardMidlineV5Options }
        : { ...actionValues, goodQualityPhotoProvided: false }

      form.setValue(MIDLINE_PROPERTY_KEY, value)
      form.clearErrors(MIDLINE_PROPERTY_KEY)
    },
  })

  useEffect(() => {
    if (
      isV6Form &&
      !isNil(form.formState.errors.primaryCasePreferences?.midlinePreferencesPrimary) &&
      midlineSectionRef.current
    ) {
      midlineSectionRef.current.scrollIntoView()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isV6Form, form.formState.errors.primaryCasePreferences?.midlinePreferencesPrimary])

  if (isNil(midlinePreferences)) {
    return null
  }

  return (
    <div ref={midlineSectionRef}>
      <OrmcoFormItemVertical
        label={
          <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.midline.label" />
        }
        hasFeedback
        data-testid="Midline"
      >
        <Vertical>
          <OrmcoRadio.Group
            name={`${MIDLINE_PROPERTY_KEY}.actionPrimary`}
            defaultValue={midlinePreferences.actionPrimary}
          >
            <Box flexDirection="column" align="flex-start">
              {radioButtonsToRender.map((radioItem) => {
                return (
                  <OrmcoRadio
                    key={radioItem.value}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...midlineRadioFormRegister}
                    value={radioItem.value}
                    data-testid={radioItem.testId}
                  >
                    <Typography component="span">
                      <FormattedMessage id={radioItem.translation} />
                    </Typography>
                  </OrmcoRadio>
                )
              })}
            </Box>
          </OrmcoRadio.Group>
          {midlinePreferences.actionPrimary === MidlineActionPrimary.CORRECT && (
            <MidlineCorrectSection />
          )}
        </Vertical>
      </OrmcoFormItemVertical>
    </div>
  )
}
