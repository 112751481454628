import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button } from 'antd'
import { AddressTable } from 'app/components/routes/MyProfile/AddressDetails/AddressTable'
import { FormCaption } from 'app/components/ui/Form/FormCaption'
import { Box } from 'app/components/ui/SDS/common/Box'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

const AddressDetails: React.FC = () => {
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  return (
    <Box data-testid="AddressDetails" marginTop="10px" display="block">
      <AddressDetailsForm name="addressDetailsForm" data-testid="AddressDetails-Form">
        <Box justify="space-between">
          <FormCaption data-testid="AddressDetails-FormCaption">
            <FormattedMessage id="addresses" />
          </FormCaption>
          {!featurePermissions?.disableUserProfile && (
            <Link to="/addAddress" data-testid="AddressDetails-AddAddress">
              <AddAddressButton>
                <FormattedMessage id="add.address" />
              </AddAddressButton>
            </Link>
          )}
        </Box>
        <AddressTable />
      </AddressDetailsForm>
    </Box>
  )
}

const AddressDetailsForm = styled.form`
  display: block;

  box-sizing: border-box;
`

const AddAddressButton = styled(Button)`
  &&& {
    width: fit-content;
    height: 40px;
    border-color: transparent;

    text-align: center;
    letter-spacing: 0.1px;

    font-size: 13px;
    font-weight: 600;
    line-height: 18px;

    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary800};
    border-radius: 4px;

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary800};
    }
  }
`

export { AddressDetails }
