import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { Button } from 'app/components/ui/SDS/common/Button'
import styled, { useTheme } from 'styled-components'

const SearchInput: React.FC = () => {
  const intl = useIntl()
  const theme = useTheme()
  const valueIsValid = (value?: string) => value && value !== ' '
  const dispatch = useDispatch()
  const clearSearchCallback = useCallback(
    () => dispatch(caseListActions.clearSearchButtonClicked()),
    [dispatch],
  )
  const onSearchCallback = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (valueIsValid(e.currentTarget.value)) {
        dispatch(caseListActions.searchCasesInputChanged(e.currentTarget.value))
      } else {
        dispatch(caseListActions.clearSearchButtonClicked())
      }
    },
    [dispatch],
  )
  const { search: inputText } = useSelector(caseListSelectors.getCaseListParams())

  return (
    <InputWithIcon>
      <InputIcon>
        <GlyphIcon type={IconType.SEARCH} color={theme.colors.primary800} />
      </InputIcon>
      <SearchInputContainer
        onChange={onSearchCallback}
        data-testid="SearchInput"
        type="text"
        placeholder={intl.formatMessage({ id: 'cases.search.placeholder' })}
        value={inputText || ''}
      />
      {inputText ? (
        <CancelBtn type="text" onClick={clearSearchCallback} data-testid="SearchInput-ClearBtn">
          <GlyphIcon type={IconType.REMOVE} />
        </CancelBtn>
      ) : (
        ''
      )}
    </InputWithIcon>
  )
}
const SearchInputContainer = styled.input`
  padding-left: 34px;

  width: 294px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 4px;

  ::placeholder {
    font-size: 13px;

    color: ${({ theme }) => theme.colors.base400};
  }

  :focus {
    outline: 0;

    border-color: ${({ theme }) => theme.colors.primary200};

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  ::-ms-clear {
    display: none;
  }
`

const InputIcon = styled.div`
  position: absolute;
  top: 3px;
  left: 10px;

  & > div::before {
    font-size: 16px;
  }
`

const InputWithIcon = styled.div`
  margin-left: auto;

  position: relative;
`

const CancelBtn = styled(Button)`
  position: absolute;
  right: 9px;

  font-size: 13px;

  color: ${({ theme }) => theme.colors.base700};
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.base700};
  }
`

export { SearchInput }
