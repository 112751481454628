import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react'

import AuthenticationManager from '../core/auth/AuthenticationManager'
import { isNil } from '../core/utils'

import { appSelectors } from './app/logic'
import { RootState } from './rootReducer'

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/v1/',
  prepareHeaders: (headers) => {
    const token = AuthenticationManager.accessToken

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    if (!headers.has('Content-Type')) {
      headers.set('Content-Type', 'application/json')
    }

    return headers
  },
})

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await AuthenticationManager.updateToken()

  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    console.error(
      `Received ${result.error.status} in response. Token expired or invalid. Redirecting to login `,
    )
    await AuthenticationManager.authenticate()

    result = await baseQuery(args, api, extraOptions)
  }

  return result
}

const baseQueryWithUserCredentials: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const username = appSelectors.getUsername()(api.getState() as RootState)

  if (isNil(username)) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No username provided',
      },
    }
  }

  const urlEnd = typeof args === 'string' ? args : args.url
  const adjustedUrl = `doctors/${username}/${urlEnd}`
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }

  return baseQueryWithAuth(adjustedArgs, api, extraOptions)
}

export const api = createApi({
  baseQuery: baseQueryWithUserCredentials,
  tagTypes: ['CasePerks'],
  endpoints: () => ({}),
})
