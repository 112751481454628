import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { AuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AuxComponent'
import { Unmovable, NoAttachments, Biteramps } from 'app/components/ui/Icons/teeth-auxiliaries'
import { CaseDraft } from 'app/core/domain/CaseDraft'

import { ActivitySelection } from '../ActiveAuxiliary/ActivitySelection'

const getAlignerAuxiliaries = (
  dontMoveTeeth: number[],
  dontPlaceTeeth: number[],
  biteRampTeeth: number[],
) => [
  {
    arr: dontMoveTeeth,
    type: ActivitySelection.dontMoveTeeth,
    name: 'dontMove',
    Icon: Unmovable,
  },
  {
    arr: dontPlaceTeeth,
    type: ActivitySelection.dontPlaceTeeth,
    name: 'dontPlace',
    Icon: NoAttachments,
  },
  {
    arr: biteRampTeeth,
    type: ActivitySelection.biteRampTeeth,
    name: 'biteRamp',
    Icon: Biteramps,
  },
]

export const useAvailableAlignersAuxiliaries = () => {
  const form = useFormContext<CaseDraft>()

  const {
    primaryCasePreferences: { dontMoveTeeth, dontPlaceTeeth, biteRampTeeth },
  } = form.getValues()

  const availableAuxiliaries: AuxComponent<ActivitySelection>[] = useMemo(
    () => getAlignerAuxiliaries(dontMoveTeeth, dontPlaceTeeth, biteRampTeeth),
    [dontMoveTeeth, dontPlaceTeeth, biteRampTeeth],
  )

  return availableAuxiliaries
}
