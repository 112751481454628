import { css } from 'styled-components'

const StyledNavigationMixin = css`
  padding: 15px;

  cursor: pointer;

  && svg {
    min-width: 24px;
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.primary600};
  }
`

const StyledNavigationBackgroundMixin = css<{ isActive?: boolean }>`
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.primary600 : 'transparent'};
`

export { StyledNavigationMixin, StyledNavigationBackgroundMixin }
