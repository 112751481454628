import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { AuxComponent } from 'app/components/routes/CaseWizard/form/Teeth/Auxiliaries/AuxComponent'
import { AuxiliaryTypeSummary } from 'app/components/routes/CaseWizard/form/Teeth/Summary/AuxiliaryTypeSummary'
import { TeethNums } from 'app/components/routes/CaseWizard/form/Teeth/Summary/TeethNums'
import { Box } from 'app/components/ui/SDS/common/Box'

interface AuxiliariesSummaryProps {
  auxiliaries: AuxComponent<string>[]
}

export const IdbAuxiliariesSummary: FC<AuxiliariesSummaryProps> = ({ auxiliaries }) => (
  <Box data-testid="TeethNumbersContainer">
    {auxiliaries.map(({ type, name, arr, Icon }) => (
      <Box width="min-content" key={name}>
        <AuxiliaryTypeSummary
          type={name}
          icon={<Icon width="16px" />}
          caption={
            <FormattedMessage
              id="caseWizard.casePrescription.auxiliaries.showTeethNumbers.caption"
              values={{ mode: type }}
            />
          }
        >
          <TeethNums data-testid="casePrescription-teethPlace" arr={arr} divider="," />
        </AuxiliaryTypeSummary>
      </Box>
    ))}
  </Box>
)
