import { useCallback, useContext, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { TreatmentAction } from 'app/components/routes/CaseWizard/steps/CasePrescription/TreatmentAction'
import { Divider } from 'app/components/ui/Divider'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CanineMolar } from 'app/core/domain/CanineMolar'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { Nullable } from 'app/core/types/utils'
import { isNil } from 'app/core/utils'

import { standardApRelationshipsV6Values, standardApRelationshipsValues } from '../constants'

import { APRelationsImpovementSettings } from './APRelationsImpovementSettings/APRelationsImpovementSettings'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'

const AnteriorPosteriorRelationship = () => {
  const apSectionRef = useRef<Nullable<HTMLDivElement>>(null)
  const form = useFormContext<CaseDraft>()
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())
  const defaultFromValue = isV6Form
    ? standardApRelationshipsV6Values
    : standardApRelationshipsValues

  const themeContext = useContext(ThemeContext)
  const apRelationshipPrimary = form.getValues(PROPERTY_KEY)

  const changeRadio = useCallback(
    (res?: ApRelationshipPrimary) => {
      form.setValue(PROPERTY_KEY, res)
      form.clearErrors()
    },
    [form],
  )
  const setMaintain = useCallback(
    () =>
      changeRadio({
        ...defaultFromValue,
        apActionPrimary: TreatmentAction.MAINTAIN,
        canineMolarActionPrimary: CanineMolar.CANINE,
      }),
    [changeRadio, defaultFromValue],
  )

  const setCanineOnly = useCallback(
    () =>
      changeRadio({
        ...defaultFromValue,
        apActionPrimary: TreatmentAction.CORRECT,
        canineMolarActionPrimary: CanineMolar.CANINE,
      }),
    [changeRadio, defaultFromValue],
  )

  const setCanineAndMolar = useCallback(
    () =>
      changeRadio({
        ...defaultFromValue,
        apActionPrimary: TreatmentAction.CORRECT,
        aPrelationshipDistalization: !isV6Form,
        canineMolarActionPrimary: CanineMolar.CANINE_MOLAR,
      }),
    [changeRadio, defaultFromValue, isV6Form],
  )

  useEffect(() => {
    if (
      isV6Form &&
      !isNil(form.formState.errors.primaryCasePreferences?.apRelationshipPrimary) &&
      apSectionRef.current
    ) {
      apSectionRef.current.scrollIntoView()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isV6Form, form.formState.errors.primaryCasePreferences?.apRelationshipPrimary])

  return (
    <div ref={apSectionRef}>
      <OrmcoFormItemVertical
        label={
          <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.anteriorPosteriorRelationship.label" />
        }
        hasFeedback
        data-testid="AnteriorPosteriorRelationship"
      >
        <Vertical>
          <OrmcoRadio
            onChange={setMaintain}
            checked={
              !apRelationshipPrimary ||
              apRelationshipPrimary?.apActionPrimary === TreatmentAction.MAINTAIN
            }
            data-testid="AnteriorPosteriorRelationship-Maintain"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.maintain" />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            onChange={setCanineOnly}
            checked={apRelationshipPrimary?.apActionPrimary === TreatmentAction.CORRECT}
            data-testid="AnteriorPosteriorRelationship-Correct"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.correct" />
            </Typography>
          </OrmcoRadio>

          {apRelationshipPrimary?.apActionPrimary === TreatmentAction.CORRECT && (
            <ThemedBox
              padding="8px"
              contextTheme={themeContext}
              data-testid="AnteriorPosteriorRelationship-Section"
            >
              <Vertical>
                <OrmcoRadio
                  onChange={setCanineOnly}
                  checked={apRelationshipPrimary?.canineMolarActionPrimary === CanineMolar.CANINE}
                  disabled={!apRelationshipPrimary}
                  data-testid="AnteriorPosteriorRelationship-CanineOnly"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.improveBothCanineAndMolar.false" />
                  </Typography>
                </OrmcoRadio>

                <OrmcoRadio
                  onChange={setCanineAndMolar}
                  checked={
                    apRelationshipPrimary?.canineMolarActionPrimary === CanineMolar.CANINE_MOLAR
                  }
                  disabled={!apRelationshipPrimary}
                  data-testid="AnteriorPosteriorRelationship-CanineMolar"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.improveBothCanineAndMolar.true" />
                  </Typography>
                </OrmcoRadio>
              </Vertical>

              <Divider />
              <APRelationsImpovementSettings />
            </ThemedBox>
          )}
        </Vertical>
      </OrmcoFormItemVertical>
    </div>
  )
}

export { AnteriorPosteriorRelationship }
