import { AlignerMaterial } from 'app/core/domain/AlignerMaterial'
import { TreatmentType } from 'app/core/domain/PrimaryCasePreferences'
import { ProductType } from 'app/core/domain/ProductType'

const PATIENT_TREATMENT_TYPE_KEY = 'patientDetails.treatmentType'
const TREATMENT_TYPE_KEY = 'primaryCasePreferences.treatmentType'
const ALIGNER_MATERIAL_KEY = 'primaryCasePreferences.alignerMaterial'

const productDefaultValues: Map<ProductType, { [key: string]: any }> = new Map()

productDefaultValues.set(ProductType.SPARK_10, {
  [ALIGNER_MATERIAL_KEY]: AlignerMaterial.TruGEN_XR,
  [TREATMENT_TYPE_KEY]: TreatmentType.ALIGNER,
  [PATIENT_TREATMENT_TYPE_KEY]: TreatmentType.ALIGNER,
})

productDefaultValues.set(ProductType.SPARK_20, {
  [ALIGNER_MATERIAL_KEY]: AlignerMaterial.TruGEN_XR,
  [TREATMENT_TYPE_KEY]: TreatmentType.ALIGNER,
  [PATIENT_TREATMENT_TYPE_KEY]: TreatmentType.ALIGNER,
})

productDefaultValues.set(ProductType.SPARK_ADVANCED, {
  [ALIGNER_MATERIAL_KEY]: AlignerMaterial.TruGEN,
  [TREATMENT_TYPE_KEY]: TreatmentType.ALIGNER,
  [PATIENT_TREATMENT_TYPE_KEY]: TreatmentType.ALIGNER,
})

productDefaultValues.set(ProductType.IDB, {
  [TREATMENT_TYPE_KEY]: TreatmentType.IDB,
  [PATIENT_TREATMENT_TYPE_KEY]: TreatmentType.IDB,
})

export { productDefaultValues }
