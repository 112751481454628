import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Divider } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

const MyPracticeDescription: React.FC = () => (
  <Box marginRight="auto" display="block" data-testid="MyPracticeDescription">
    <Box justify="flex-start" data-testid="MyPracticeDescription-Title">
      <Typography component="h1" variant="h4" textFontWeight="600" letterSpacing="0.25px">
        <FormattedMessage id="staff.practice.description.title" />
      </Typography>
      <br />
    </Box>
    <Box width="736px" justify="flex-start" paddingBottom="10px">
      <Typography
        margin="8px 0 0"
        data-testid="MyPracticeDescription-Description"
        textFontWeight="600"
        letterSpacing="0.1px"
      >
        <FormattedMessage id="staff.practice.description.line1" /> <br />
        <FormattedMessage id="staff.practice.description.line2" /> <br />
      </Typography>
    </Box>
    <Divider />
  </Box>
)

export { MyPracticeDescription }
