import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { unarchiveModalActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { SdsUnarchiveFilled } from 'app/components/ui/Icons/sds'
import { Tooltip } from 'app/components/ui/SDS/common/Tooltip'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case } from 'app/core/domain/Case'

interface UnarchiveButtonProps {
  caseId: Case['caseId']
  withoutText?: boolean
  showTooltip?: boolean
}

const UnarchiveButton: React.FC<UnarchiveButtonProps> = ({ caseId, withoutText, showTooltip }) => {
  const dispatch = useDispatch()
  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(unarchiveModalActions.openModalButtonClicked({ caseId }))
  }

  const button = (
    <ActionButton data-testid={`UnarchiveButton-${caseId}`} onClick={onClick} height="18px">
      <SdsUnarchiveFilled width="10px" color="secondary300" />
      {!withoutText && (
        <Typography textOverflow="ellipsis" color="base700">
          <FormattedMessage id="cases.list.doctor.button.unarchive" />
        </Typography>
      )}
    </ActionButton>
  )

  return showTooltip ? (
    <Tooltip placement="top" title={<FormattedMessage id="cases.list.doctor.button.unarchive" />}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}

export { UnarchiveButton }
