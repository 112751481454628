import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

interface StatusContainerProps {
  isSDS: boolean
}

export const StatusContainer = styled(Box)<StatusContainerProps>`
  border-right: 1px solid
    ${({ theme, isSDS }) => (isSDS ? theme.colors.base300 : theme.colors.base800)};
`
