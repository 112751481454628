import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  noUsersAssociatedModalActions,
  noUsersAssociatedModalSelectors,
} from 'app/components/routes/Staff/NoUsersAssociatedModal/logic'
import { staffCreationSelectors } from 'app/components/routes/Staff/StaffDetails/logic'
import { InfoButton, LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBodyText } from 'app/components/ui/Modal/OrmcoModalBodyText'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

const NoUsersAssociatedModal: React.FC = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(noUsersAssociatedModalSelectors.isOpen())
  const isNoUserAssociationAccepted = useSelector(
    noUsersAssociatedModalSelectors.isNoUserAssociationAccepted(),
  )
  const isStaffUpdateFlow = useSelector(staffCreationSelectors.isStaffUpdateFlow())

  const close = useCallback(() => {
    dispatch(noUsersAssociatedModalActions.closeModalClicked())
  }, [dispatch])

  const onSubmit = useCallback(() => {
    if (isStaffUpdateFlow) {
      dispatch(noUsersAssociatedModalActions.updateButtonClicked())
    }
    dispatch(noUsersAssociatedModalActions.createStaffMemberButtonClicked())
  }, [dispatch, isStaffUpdateFlow])

  useDidMount(() => {
    dispatch(noUsersAssociatedModalActions.noUsersAssociatedModalMounted())
  })
  return (
    <>
      {!isNoUserAssociationAccepted && (
        <OrmcoModal
          isOpen={isOpen}
          onRequestClose={close}
          additionalStyles={{
            content: {
              top: '40%',
              padding: '13px',
            },
            overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
          }}
          shouldCloseOnOverlayClick={false}
          data-testid="NoUsersAssociatedModal"
        >
          <NoUsersAssociatedModalHeader data-testid="NoUsersAssociatedModal-Heading">
            <OrmcoModalHeaderText headerId="staff.noUsersAssociated.modal.header" />
            <Button type="text" onClick={close} data-testid="NoUsersAssociatedModal-Close">
              <Cancel />
            </Button>
          </NoUsersAssociatedModalHeader>
          <OrmcoModalBodyText
            data-testid="NoUsersAssociatedModal-Body"
            textId="staff.noUsersAssociated.modal.body"
          />
          <NoUsersAssociatedModalFooter data-testid="NoUsersAssociatedModal-Footer">
            <LightBlueButtonWithBorder onClick={close} data-testid="NoUsersAssociatedModal-Cancel">
              <FormattedMessage id="staff.creation.cancel" />
            </LightBlueButtonWithBorder>
            <Padder width="10px" />

            {isStaffUpdateFlow ? (
              <UpdateStaffButton onClick={onSubmit} data-testid="NoUsersAssociatedModal-Update">
                <FormattedMessage id="staff.update" />
              </UpdateStaffButton>
            ) : (
              <CreateStaffButton onClick={onSubmit} data-testid="NoUsersAssociatedModal-Submit">
                <FormattedMessage id="staff.noUsersAssociated.modal.create.staff.member" />
              </CreateStaffButton>
            )}
          </NoUsersAssociatedModalFooter>
        </OrmcoModal>
      )}
    </>
  )
}

export { NoUsersAssociatedModal }

const NoUsersAssociatedModalHeader = styled(OrmcoModalHeader)`
  flex-direction: row;

  width: 560px;
  border-bottom: 0;
`

const NoUsersAssociatedModalFooter = styled(OrmcoModalFooter)`
  margin-left: 19.5em;

  justify-content: flex-end;

  border-top: 0;
`

const CreateStaffButton = styled(InfoButton)`
  justify-content: center;

  width: 264px;
  height: 40px;

  font-weight: 600;
`

const UpdateStaffButton = styled(InfoButton)`
  justify-content: center;

  width: 114px;
  height: 40px;

  font-weight: 600;
`
