import { useSelector } from 'react-redux'

import { SessionStorageKeysMap } from 'app/core/domain/SessionStorageKeysMap'
import { useSessionStorage } from 'app/core/react/useSessionStorage'
import { appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { IntegratedHooksModal } from './IntegratedHooksModal/IntegratedHooksModal'
import { integratedHooksModalSelectors } from './IntegratedHooksModal/logic'
import { TermsAndConditionsModal } from './TermsAndConditionsModal/TermsAndConditionsModal'
import { termsSelectors } from './TermsAndConditionsModal/logic'

export const GlobalModalsProvider = () => {
  const isDoctor = useSelector(appSelectors.isDoctor())
  const showIntegratedHooksPopupFromServer = useSelector(appSelectors.getShowIntegratedHooksPopup())
  const { accepted: isTermsAndConditionsAccepted, version } = useSelector(
    termsSelectors.getTermsState(),
  )
  const { isOpen: isIntegratedHooksModalOpen } = useSelector(
    integratedHooksModalSelectors.getIntegratedHooksModalState(),
  )
  const [sessionIntegratedHooksModalOpen] = useSessionStorage(
    SessionStorageKeysMap.isIntegratedHooksModalOpen,
    isIntegratedHooksModalOpen,
  )

  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  const showIntegratedHooksPopup =
    version &&
    isIntegratedHooksModalOpen &&
    sessionIntegratedHooksModalOpen &&
    showIntegratedHooksPopupFromServer &&
    isDoctor &&
    featurePermissions?.integratedHooks

  if (!isTermsAndConditionsAccepted) {
    return <TermsAndConditionsModal />
  }

  if (showIntegratedHooksPopup) {
    return <IntegratedHooksModal />
  }

  return null
}
