import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'

const StaffFirstName: React.FC = () => {
  const intl = useIntl()
  const {
    control,
    formState: { errors },
  } = useFormContext<PersonalInformation>()

  return (
    <StaffFormItemVertical
      colon={false}
      label={<InputLabel labelId="staff.staffDetails.staffFirstName.label" />}
      className="StaffFormItemVertical"
      hasFeedback
      validateStatus={getValidationStatus('firstName', errors)}
      help={<OrmcoFormErrorMessage errors={errors} name="firstName" />}
      data-testid="StaffDetails-StaffFirstName"
    >
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <StaffInput
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
          />
        )}
        rules={{
          required: intl.formatMessage({ id: 'staff.staffDetails.staffFirstName.required' }),
          pattern: {
            value: /^[^~`@#$!%^&*()><{}.,_+=?;:\\[\]|'"/]*$/,
            message: intl.formatMessage({ id: 'staff.staffDetails.staffFirstName.letter' }),
          },
        }}
      />
    </StaffFormItemVertical>
  )
}

export { StaffFirstName }
