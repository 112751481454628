import { V6FormValues } from 'app/components/routes/CaseWizard/steps/CasePrescription/constants'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CanineMolar } from 'app/core/domain/CanineMolar'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { TreatmentAction } from 'app/core/domain/TreatmentAction'
import { deepFindByPath } from 'app/core/utils'

import { ValidationError } from '../interfaces'

export const getV6ApRelationsErrors = (
  formState: ApRelationshipPrimary | undefined,
  treatArches: TreatArches | undefined,
): ValidationError[] => {
  if (!formState || !treatArches) {
    return []
  }

  if (formState.apActionPrimary !== TreatmentAction.CORRECT) {
    return []
  }

  if (formState.canineMolarActionPrimary !== CanineMolar.CANINE_MOLAR) {
    return []
  }

  let result: Array<ValidationError> = []

  if (
    !deepFindByPath(formState, 'aPrelationshipDistalization').value &&
    !deepFindByPath(formState, 'posteriorIPR').value &&
    !deepFindByPath(formState, 'mesialization').value &&
    !deepFindByPath(formState, 'virtualSimulation').value &&
    !deepFindByPath(formState, 'alignerCutouts').value
  ) {
    result.push({
      field: 'primaryCasePreferences.apRelationshipPrimary',
      message:
        'caseWizard.casePrescription.anteriorPosteriorRelationship.validation.mandatoryFields',
    })
  }

  if (treatArches !== TreatArches.BOTH) {
    return result
  }

  result = V6FormValues.reduce((reducer, item) => {
    const hasInputWithOptions =
      deepFindByPath(formState, item.inputKey).value &&
      deepFindByPath(formState, `${item.optionsKey}`).found

    if (!hasInputWithOptions) {
      return reducer
    }

    const hasOptionsInState =
      deepFindByPath(formState, `${item.optionsKey}.upper`).found &&
      deepFindByPath(formState, `${item.optionsKey}.lower`).found

    if (
      !hasOptionsInState ||
      (!deepFindByPath(formState, `${item.optionsKey}.upper`).value &&
        !deepFindByPath(formState, `${item.optionsKey}.lower`).value)
    ) {
      reducer.push({
        field: `primaryCasePreferences.apRelationshipPrimary.${item.inputKey}`,
        message:
          'caseWizard.casePrescription.anteriorPosteriorRelationship.validation.mandatoryFields',
      })
    }

    return reducer
  }, result)

  return result
}
