import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Col, Select } from 'antd'
import { OrmcoSelect } from 'app/components/ui/Form/OrmcoSelect'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { BracketType, BracketTypeLocalizationKey } from 'app/core/domain/BracketType'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'

import { getBracketPreferencesKey } from './bracketPreferencesKeys'

interface BracketPreferencesSelectorProps {
  arch: TreatArches
}

export const BracketPreferencesSelector = ({ arch }: BracketPreferencesSelectorProps) => {
  const form = useFormContext<CaseDraft>()
  const bracketPreferenceKey = getBracketPreferencesKey(arch) as keyof CaseDraft
  const lowerBracket = form.getValues(bracketPreferenceKey)
  const { Option } = Select

  const handleChangeUpperBracketType = (value: BracketType, key: keyof CaseDraft) => {
    form.setValue(key, value)
  }

  return (
    <Col span={12}>
      <Typography component="label" textFontWeight="600" margin="0 0 16px">
        <FormattedMessage id="caseWizard.casePrescription.arche" values={{ arch }} />
      </Typography>
      <OrmcoSelect
        dropdownStyle={{ zIndex: 10000 }}
        id="ThreeShapeDropDown"
        value={lowerBracket}
        data-testid="ThreeShapeScan-OrmcoSelect"
        onChange={(selectedBracketType) => {
          handleChangeUpperBracketType(selectedBracketType, bracketPreferenceKey)
        }}
        disabled
      >
        <Option value={BracketType.DAMON_ULTIMA_STOCK_5_5}>
          <FormattedMessage
            id="caseWizard.casePrescription.bracketPreferences"
            values={{ bracketPreferences: BracketTypeLocalizationKey.DAMON_ULTIMA_STOCK_5_5 }}
          />
        </Option>
      </OrmcoSelect>
    </Col>
  )
}
