import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { useDidMount } from 'app/core/react/CustomHooks'

import { DirectTransferMessage } from './DirectTransferMessage'
import { BoxMargin } from './DisplayScanType'
import { ScanOrderId } from './ScanOrderId'
import { ScanOrderType } from './ScanOrderType'
import { editScansSelectors } from './logic'

interface ScannerInfoBoxProps {
  scannerDescription?: string
  scanOrderId?: string
  showScanOrderId?: boolean
  showDirectTransfer?: boolean
}

const ScannerInfoBox = (props: ScannerInfoBoxProps) => {
  const isDirectTransferScansUploaded = useSelector(editScansSelectors.getDirectTransferScansFlag())
  const { scannerDescription, scanOrderId, showScanOrderId, showDirectTransfer } = props
  const theme = useContext(ThemeContext)
  const form = useForm<ScanOrderType>({
    mode: 'onChange',
  })

  useDidMount(() => {
    form.register('scanOrderId')

    return () => {
      form.unregister('scanOrderId')
    }
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.setValue('scanOrderId', scanOrderId)
    }
  }, [form, scanOrderId])

  return (
    <BoxMargin contextTheme={theme} padding="1.2em">
      <FormProvider {...form}>
        <Box width="800px" justify="flex-start" flexDirection="column">
          {showScanOrderId && <ScanOrderId scannerDescription={scannerDescription} />}
          {showDirectTransfer && !isDirectTransferScansUploaded && <DirectTransferMessage />}
        </Box>
      </FormProvider>
    </BoxMargin>
  )
}

export { ScannerInfoBox }
