import { Help } from 'app/components/ui/Icons/common'
import { Tooltip } from 'app/components/ui/SDS/common/Tooltip'
import { Typography } from 'app/components/ui/SDS/common/Typography'

import { CasePerksHintMessage } from './CasePerksHintMessage'

interface CasePerksHintProps {
  'data-testid': string
}

export const CasePerksHint = ({ 'data-testid': testId }: CasePerksHintProps) => {
  return (
    <Tooltip
      placement="topRight"
      colorScheme="light"
      title={<CasePerksHintMessage data-testid={`${testId}-Message`} />}
      data-testid={testId}
    >
      <Typography
        data-testid={testId}
        component="span"
        variant="small"
        color="primary700"
        textFontWeight="600"
      >
        <Help width="16px" />
      </Typography>
    </Tooltip>
  )
}
