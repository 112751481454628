import React from 'react'
import { Link } from 'react-router-dom'

import { DropdownItem } from './DropdownItem'

interface RouterDropdownItemProps {
  link: string
  onClick?: React.DOMAttributes<HTMLAnchorElement>['onClick']
}

const RouterDropdownItem: React.FC<RouterDropdownItemProps> = (props) => (
  <Link to={props.link} onClick={props.onClick}>
    <DropdownItem>{props.children}</DropdownItem>
  </Link>
)

export { RouterDropdownItem }
