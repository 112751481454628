import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus, ValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { EmployeeProfileFields } from 'app/core/domain/EmployeeProfile'
import { emailValidatorUtil } from 'app/core/react/EmailValidationUtil'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const Email = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const {
    formState: { errors },
  } = useFormContext<EmployeeProfileFields>()
  const form = useFormContext<EmployeeProfileFields>()
  const { email } = useSelector(appSelectors.getEmployeeContactDetails())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const updateUserProfileData = () => {
    if (getValidationStatus('email', errors) !== ValidationStatus.error) {
      dispatch(
        appActions.saveEmployeeDetails({
          part: form.getValues('email'),
          key: 'employeeContactDetails.email',
        }),
      )
    }
  }

  const emailValidator = (emailFieldValue: string) =>
    !emailValidatorUtil(emailFieldValue) ? intl.formatMessage({ id: 'email.invalid' }) : true

  return (
    <>
      {featurePermissions?.disableUserProfile ? (
        <ReadonlyValue data-testid="Email-ProfileContainer" labelId="email" value={email} />
      ) : (
        <StaffFormItemVertical
          colon={false}
          label={<InputLabel labelId="email" />}
          hasFeedback
          validateStatus={getValidationStatus('email', errors)}
          help={<OrmcoFormErrorMessage errors={errors} name="email" />}
          data-testid="ContactDetails-Email"
        >
          <Controller
            name="email"
            render={({ field }) => (
              <StaffInput
                width="100%"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
                onBlur={updateUserProfileData}
              />
            )}
            rules={{
              validate: { emailValidator },
              required: intl.formatMessage({ id: 'email.required' }),
            }}
          />
        </StaffFormItemVertical>
      )}
    </>
  )
}

export { Email }
