import React from 'react'
import { FormattedNumber } from 'react-intl'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

interface CasePendingCardProps {
  data: CaseTableData
  style?: 'unit' | 'decimal' | 'percent' | 'currency'
}

const CasePendingCard: React.FC<CasePendingCardProps> = ({ data, style = 'unit' }) => {
  const { pendingApprovalDays } = data.case

  if (!pendingApprovalDays && pendingApprovalDays !== 0) {
    return null
  }

  return (
    <Box title={String(pendingApprovalDays)}>
      <Typography data-testid="Case-PendingApproval" component="span">
        <FormattedNumber value={pendingApprovalDays} unit="day" style={style} />
      </Typography>
    </Box>
  )
}

export { CasePendingCard }
