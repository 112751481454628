import { Path, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { ValidationFieldContainer } from 'app/components/ui/Form/ValidationFieldContainer'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { get } from 'lodash'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'
const TREAT_ARCHES = 'primaryCasePreferences.treatArches'

import { CanineMolarCheckboxProps } from './interfaces'

const canineMolarSubOptions = ['upper', 'lower'] as const

export const CanineMolarCheckbox = ({
  optionsKey,
  testId,
  translationKey,
  inputKey,
}: CanineMolarCheckboxProps) => {
  const form = useFormContext<CaseDraft>()
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())
  const apRelationshipPrimary = form.getValues(PROPERTY_KEY) as ApRelationshipPrimary
  const error = get(
    form.formState.errors,
    `primaryCasePreferences.apRelationshipPrimary.${[inputKey]}`,
  )
  const treatArches = form.getValues(TREAT_ARCHES)

  const isSubOptionsEnabled = isV6Form && treatArches === TreatArches.BOTH

  const checkBoxRegisterOptions: RegisterOptions = {
    onChange: (event: CheckboxChangeEvent) => {
      const value: ApRelationshipPrimary = {
        ...apRelationshipPrimary,
        [inputKey]: event.target.checked,
        [optionsKey]:
          event.target.checked && isSubOptionsEnabled ? { upper: false, lower: false } : null,
      }

      form.setValue(PROPERTY_KEY, value)
      form.clearErrors(PROPERTY_KEY)
    },
  }

  const subCheckBoxRegisterOptions: RegisterOptions = {
    onChange: (event: CheckboxChangeEvent) => {
      form.setValue(event.target.name as Path<CaseDraft>, event.target.checked)
      form.clearErrors()
    },
  }

  return (
    <ValidationFieldContainer
      padding="8px"
      messagePadding="0 24px"
      display="block"
      error={error}
      data-testid="CanineMolarCheckbox-error"
    >
      <OrmcoCheckbox
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...form.register(`${PROPERTY_KEY}.${inputKey}`, checkBoxRegisterOptions)}
        checked={Boolean(apRelationshipPrimary[inputKey])}
        data-testid={testId}
      >
        <Typography component="span">
          <FormattedMessage id={translationKey} />
        </Typography>
      </OrmcoCheckbox>
      {isSubOptionsEnabled && apRelationshipPrimary?.[optionsKey] != null && (
        <Box margin="16px 24px 0" justify="flex-start" width="auto">
          {canineMolarSubOptions.map((subOption) => (
            <OrmcoCheckbox
              key={`${optionsKey}-${subOption}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...form.register(
                `${PROPERTY_KEY}.${optionsKey}.${subOption}`,
                subCheckBoxRegisterOptions,
              )}
              checked={Boolean(apRelationshipPrimary[optionsKey]![subOption])}
              data-testid={`${testId}-${subOption}`}
            >
              <Typography component="span">
                <FormattedMessage
                  id={`caseWizard.casePrescription.anteriorPosteriorRelationship.${subOption}`}
                />
              </Typography>
            </OrmcoCheckbox>
          ))}
        </Box>
      )}
    </ValidationFieldContainer>
  )
}
