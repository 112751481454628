import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import { TypographyProps } from 'app/components/ui/SDS/common/Typography/interfaces'
import cn from 'classnames'

import { DropdownItemProps } from './interfaces'
import { DropdownItemContainer } from './styles'

export const DropdownItem = ({
  href,
  onClick,
  isLocalRoute,
  isDark,
  children,
  'data-testid': dataTestId,
}: PropsWithChildren<DropdownItemProps>) => {
  const linkProps = {
    className: 'dropdown-item__header-link',
    'data-testid': dataTestId,
    onClick,
  }
  const typographyProps: TypographyProps = {
    'data-testid': `${dataTestId}-Text`,
    component: 'span',
    textFontWeight: '400',
    whiteSpace: 'nowrap',
    color: 'inherit',
    className: cn(`dropdown-item-text-${isDark ? 'dark' : 'light'}`, {
      'dropdown-item-text': !isLocalRoute,
    }),
  }
  const itemContainer = (
    <DropdownItemContainer>
      <Typography
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...typographyProps}
      >
        {children}
      </Typography>
    </DropdownItemContainer>
  )

  if (isLocalRoute) {
    return (
      <Link
        to={href}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...linkProps}
      >
        {itemContainer}
      </Link>
    )
  }

  return (
    <a
      href={href}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...linkProps}
    >
      {itemContainer}
    </a>
  )
}
