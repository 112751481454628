import { Row } from 'app/components/ui/Table/Row'
import styled from 'styled-components'

const TableRow = styled(Row)`
  height: 58px;
  border-right: 0.5px solid ${({ theme }) => theme.colors.base300};
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.base300};

  :hover {
    cursor: pointer;

    background-color: ${({ theme }) => theme.colors.base200};
  }
`

export { TableRow }
