import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface NoUsersAssociatedModalState {
  isOpen: boolean
  isNoUserAssociationAccepted: boolean
}

const INITIAL_STATE: NoUsersAssociatedModalState = {
  isOpen: false,
  isNoUserAssociationAccepted: false,
}

const noUsersAssociatedModalActions = {
  noUsersAssociatedModalMounted: createAction(
    '@NO_USERS_ASSOCIATED_MODAL/NO_USERS_ASSOCIATED_MODAL_MOUNTED',
  )(),
  noUsersAssociatedModalTriggered: createAction(
    '@NO_USERS_ASSOCIATED_MODAL/NO_USERS_ASSOCIATED_MODAL_TRIGGERED',
  )(),
  closeModalClicked: createAction('@NO_USERS_ASSOCIATED_MODAL/CLOSE_MODAL_CLICKED')(),
  createStaffMemberButtonClicked: createAction(
    '@NO_USERS_ASSOCIATED_MODAL/CREATE_STAFF_MEMBER_BUTTON_CLICKED',
  )(),
  updateButtonClicked: createAction('@NO_USERS_ASSOCIATED_MODAL/UPDATE_BUTTON_CLICKED')(),
}

type NoUsersAssociatedModalActions = ActionType<typeof noUsersAssociatedModalActions>

const noUsersAssociatedModalReducer = createReducer<
  NoUsersAssociatedModalState,
  NoUsersAssociatedModalActions
>(INITIAL_STATE)
  .handleAction(noUsersAssociatedModalActions.noUsersAssociatedModalTriggered, (state) => ({
    ...state,
    isOpen: true,
  }))
  .handleAction(
    [
      noUsersAssociatedModalActions.closeModalClicked,
      noUsersAssociatedModalActions.noUsersAssociatedModalMounted,
    ],
    (state) => ({
      ...state,
      isOpen: false,
      isNoUserAssociationAccepted: false,
    }),
  )
  .handleAction(
    [
      noUsersAssociatedModalActions.createStaffMemberButtonClicked,
      noUsersAssociatedModalActions.updateButtonClicked,
    ],
    (state) => ({
      ...state,
      isNoUserAssociationAccepted: true,
    }),
  )
const noUsersAssociatedModalSelectors = {
  isOpen: () => (state: RootState) => state.noUserAssociatedModal.isOpen,
  isNoUserAssociationAccepted: () => (state: RootState) =>
    state.noUserAssociatedModal.isNoUserAssociationAccepted,
}

export {
  NoUsersAssociatedModalState,
  NoUsersAssociatedModalActions,
  noUsersAssociatedModalActions,
  noUsersAssociatedModalReducer,
  noUsersAssociatedModalSelectors,
}
