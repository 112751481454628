import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Divider } from 'antd'
import { Entry } from 'app/components/routes/CaseWizard/CaseWizard.style'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { Section } from 'app/components/routes/CaseWizard/steps/CasePrescription/ClinicalPreferences/FinalToothPosition'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'

import { ClinicalPreferencesCaption } from './ClinicalPreferencesCaption'
import { ClinicalPreferencesPropertyCaption } from './ClinicalPreferencesPropertyCaption'
import {
  ClinicalPreferencesBoxPropertyValue,
  ClinicalPreferencesPropertyValue,
} from './ClinicalPreferencesPropertyValue'

const Staging = () => {
  const clinicalPreferences = useSelector(caseWizardSelectors.getClinicalPreferences())
  const theme = useContext(ThemeContext)

  return (
    <Section>
      <Entry>
        <ClinicalPreferencesCaption
          data-testid="Staging-Caption"
          captionTextId="clinicalPreferences.staging"
        />
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Staging-PropertyCaption-ActiveAligners"
          captionTextId="clinicalPreferences.staging.activeAligners"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.staging.activeAligners ? (
            <FormattedMessage id="clinicalPreferences.staging.showVelocity" />
          ) : (
            <FormattedMessage id="clinicalPreferences.staging.dontShowVelocity" />
          )}
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Staging-PropertyCaption-PassiveAligners"
          captionTextId="clinicalPreferences.staging.passiveAligners.caption"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.staging.passiveAligners ? (
            <FormattedMessage id="clinicalPreferences.staging.useAdditionalPassive" />
          ) : (
            <FormattedMessage id="clinicalPreferences.staging.useAdditionalPassiveDont" />
          )}
        </ClinicalPreferencesPropertyValue>

        {clinicalPreferences?.staging.passiveAligners &&
          clinicalPreferences?.staging.numberOfAligners && (
            <ThemedBox width="60%" padding="1em" contextTheme={theme}>
              <ClinicalPreferencesPropertyCaption captionTextId="clinicalPreferences.staging.alignersNumber" />
              {clinicalPreferences.staging.numberOfAligners}
              {clinicalPreferences.staging.addPassiveAligners && (
                <>
                  <Divider />
                  <ClinicalPreferencesBoxPropertyValue>
                    <FormattedMessage
                      id="clinicalPreferences.staging.passiveAligners"
                      values={{ passiveAligners: clinicalPreferences.staging.addPassiveAligners }}
                    />
                  </ClinicalPreferencesBoxPropertyValue>
                </>
              )}
            </ThemedBox>
          )}
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Staging-PropertyCaption-VirtualChain"
          captionTextId="clinicalPreferences.staging.virtualCChain"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.staging.virtualCChain3To3 ? (
            <FormattedMessage id="clinicalPreferences.staging.apply" />
          ) : (
            <FormattedMessage id="clinicalPreferences.staging.dontApply" />
          )}
          &nbsp;
          <FormattedMessage id="clinicalPreferences.staging.virtualCChain3To3" />
          <br />
          {clinicalPreferences?.staging.virtualCChain7To7 ? (
            <FormattedMessage id="clinicalPreferences.staging.apply" />
          ) : (
            <FormattedMessage id="clinicalPreferences.staging.dontApply" />
          )}
          &nbsp;
          <FormattedMessage id="clinicalPreferences.staging.virtualCChain7To7" />
        </ClinicalPreferencesPropertyValue>
      </Entry>
      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="Staging-PropertyCaption-AdditionalComments"
          captionTextId="clinicalPreferences.staging.additionalCommentsStaging"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.staging.overCorrectionReq ? (
            clinicalPreferences.staging.overCorrectionReq
          ) : (
            <FormattedMessage id="clinicalPreferences.none" />
          )}
        </ClinicalPreferencesPropertyValue>
      </Entry>
    </Section>
  )
}

export { Staging }
