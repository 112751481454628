import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { OrmcoPasswordInput } from 'app/components/ui/Form/OrmcoInput'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'

const OldPassword: React.FC = () => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <StaffFormItemVertical
      colon={false}
      label={<InputLabel labelId="current.password" />}
      hasFeedback
      validateStatus={getValidationStatus('oldPassword', errors)}
      help={<OrmcoFormErrorMessage errors={errors} name="oldPassword" />}
      data-testid="ChangePasswordModal-OldPassword"
    >
      <Controller
        name="oldPassword"
        render={({ field }) => (
          <OrmcoPasswordInput
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </StaffFormItemVertical>
  )
}

export { OldPassword }
