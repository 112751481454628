import { FormattedDate, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  CasePerksDates,
  CasePerksGradation,
} from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/hooks'
import { useGetCasePerksQuery } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ensure } from 'app/core/utils/ensure'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { ThemeColors } from 'styled-components'

import { CasePerksHint } from '../CasePerksHint'

import { Position } from './interfaces'
import {
  Step,
  StepCaption,
  ProgressBarContainer,
  ProgressBarFilling,
  StandardProgressContainer,
} from './styles'

interface ProgressBarProps {
  gradations: CasePerksGradation[]
  dates: CasePerksDates
  'data-testid': string
}

const POINTS_RANKS_GAP = 70

export const CasePerksProgress = ({
  dates,
  gradations,
  'data-testid': testId,
}: ProgressBarProps) => {
  const { totalPoints: points } = useGetCasePerksQuery(undefined, {
    selectFromResult: ({ data }) => ensure(data),
  })
  const isSDS = useSelector(featureFlagSelectors.isSDS())
  const textColor: keyof ThemeColors = isSDS ? 'base600' : 'base900'
  const start = points < POINTS_RANKS_GAP ? 0 : points - POINTS_RANKS_GAP

  const roundToHalf = Math.round(points * 2) / 2
  const integerPart = Math.floor(roundToHalf)
  const decimalPart = roundToHalf - integerPart
  const scaledValue = points - start

  return (
    <StandardProgressContainer data-testid={testId}>
      <Box marginTop="8px" justify="space-between">
        <Box display="block" padding="8px">
          <Typography component="span" color={textColor} textFontWeight="600">
            <span>{integerPart > 0 && integerPart}</span>
            <span>{decimalPart > 0 && '½'}</span>
            &nbsp;
            <FormattedMessage
              id="perks.progress.shippedCases"
              values={{ count: Math.ceil(roundToHalf) }}
            />
            &nbsp;
          </Typography>
          <Typography component="span" color={textColor}>
            <FormattedMessage id="perks.progress.thisTerm" />
            &nbsp; (
            <FormattedDate value={dates.leftDateThreshold} month="short" />
            &nbsp;-&nbsp;
            <FormattedDate value={dates.rightDateThreshold} month="short" />)
          </Typography>
        </Box>
        <Box margin="0 16px" width="auto">
          <CasePerksHint data-testid={`${testId}-Hint`} />
        </Box>
      </Box>

      <ProgressBarContainer isSDS={isSDS} data-testid={`${testId}-ProgressBar`}>
        <ProgressBarFilling width={`${scaledValue}%`} />
        {scaledValue > 0 && (
          <Step isSDS={isSDS} position={Position.top} width={`${scaledValue - 0.1}%`}>
            |
          </Step>
        )}
        {gradations.map((grad) => {
          const paddingThreshold = 1

          if (grad.value > start + paddingThreshold) {
            return (
              <div key={grad.badge}>
                <Step
                  isSDS={isSDS}
                  position={Position.bottom}
                  width={`${grad.value - start - 0.1}%`}
                >
                  |
                </Step>
                <StepCaption
                  position={Position.bottom}
                  width={`${grad.value - start - 1}%`}
                  component="span"
                  variant="extra-small"
                  color={textColor}
                  textFontWeight="600"
                  whiteSpace="nowrap"
                >
                  <FormattedMessage id="perks.badge" values={{ badge: grad.badge }} />({grad.value}+
                  <FormattedMessage id="perks.progress.cases" />)
                </StepCaption>
              </div>
            )
          }
          return null
        })}
      </ProgressBarContainer>
    </StandardProgressContainer>
  )
}
