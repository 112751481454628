import { FeatureFlag } from 'app/core/domain/FeatureFlag'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

import { featureFlagsSelector } from './featureFlagsSelector'

interface FeatureFlagState {
  featureFlags: FeatureFlag
}

const INITIAL_STATE = {
  featureFlags: {},
}

const featureFlagActions = {
  featureFlagsRequestFinished: createAction(
    '@FEATURE_FLAG/FEATURE_FLAGS_REQUEST_FINISHED',
  )<FeatureFlag>(),
  featureFlagsRequestFailed: createAction('@FEATURE_FLAG/FEATURE_FLAGS_REQUEST_FAILED')(),
}

type FeatureFlagActions = ActionType<typeof featureFlagActions>
const featureFlagReducer = createReducer<FeatureFlagState, FeatureFlagActions>(INITIAL_STATE)
  .handleAction(featureFlagActions.featureFlagsRequestFinished, (state, action) => ({
    ...state,
    featureFlags: { ...action.payload },
  }))
  .handleAction(featureFlagActions.featureFlagsRequestFailed, (state) => ({
    ...state,
    featureFlags: {},
  }))

const featureFlagSelectors = {
  getFeatureFlags: () => (state: RootState) => featureFlagsSelector(state.featureFlag.featureFlags),
  isSDS: () => (state:RootState) => state.featureFlag.featureFlags.SDS ?? false
}

export {
  FeatureFlagState,
  FeatureFlagActions,
  featureFlagActions,
  featureFlagSelectors,
  featureFlagReducer,
}
