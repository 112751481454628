import {
  caseFilterActions,
  caseFilterSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/CaseFilter/logic'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from } from 'rxjs'
import { filter, ignoreElements, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { PatientFilterNames } from './PatientFilter/PatientFilterNames'

const isCaseFilterSaveEnabled = (
  caseFilterName: string,
  isCaseFilterOpen: boolean,
  isImpersonated: boolean,
): boolean =>
  caseFilterName !== PatientFilterNames.NO_PATIENTS && !isCaseFilterOpen && !isImpersonated

const makeCaseFilterGetRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(caseFilterActions.caseFilterMounted)),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)

      return from(
        axios
          .get<Array<string>>(`/api/v1/users/${username}/filteredUsers`)
          .then((res) => caseFilterActions.caseFilterUsersReceived(res.data))
          .catch((err) =>
            caseFilterActions.caseFilterUsersRequestFailed({ message: err.toString() }),
          ),
      )
    }),
  )

const makeCaseFilterSaveRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        caseFilterActions.caseFilterMyPatientsButtonClicked,
        caseFilterActions.caseFilterAllPatientsButtonClicked,
        caseFilterActions.caseFilterClicked,
      ]),
    ),
    filter(() => {
      const caseFilterName = caseFilterSelectors.getCaseFilterName()(state$.value)
      const isCaseFilterOpen = caseFilterSelectors.isCaseFilterOpen()(state$.value) as boolean
      const isImpersonated = appSelectors.isImpersonated()(state$.value)

      return isCaseFilterSaveEnabled(caseFilterName, isCaseFilterOpen, isImpersonated)
    }),
    tap(() => {
      const filteredUsers = caseFilterSelectors.getCaseFilterSelectedUsers()(state$.value).join(',')

      return axios.put(`/api/v1/users/${appSelectors.getUsername()(state$.value)}/filteredUsers`, {
        filteredUsers,
      })
    }),
    ignoreElements(),
  )

const makeCaseFilterUsersRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseFilterActions.caseFilterUsersReceived])),
    switchMap((selectedUsers) => {
      const username = appSelectors.getUsername()(state$.value)
      const isStaff = appSelectors.isStaff()(state$.value)

      let status = null

      if (isStaff) {
        status = 'associated'
      }

      return from(
        axios
          .get(`/api/v1/users/${username}/clinicusers`, {
            params: {
              status,
            },
          })
          .then((res) =>
            caseFilterActions.caseFilterUserListReceived({
              doctors: res.data.users,
              selectedUsers: selectedUsers.payload,
              username,
            }),
          )
          .catch((err) =>
            caseFilterActions.caseFilterUserListRequestFailed({ message: err.toString() }),
          ),
      )
    }),
  )

const caseFilterActionsEpic = combineEpics(
  makeCaseFilterGetRequestEpic,
  makeCaseFilterUsersRequestEpic,
  makeCaseFilterSaveRequestEpic,
)

export { caseFilterActionsEpic }
