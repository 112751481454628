import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Box } from 'app/components/ui/SDS/common/Box'

export const EmptyStep = () => (
  <Box minHeight="80vh" data-testid="EmptyStep">
    <FormattedMessage id="caseWizard.navigation.loading" />
  </Box>
)
