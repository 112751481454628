import styled from 'styled-components'

import { OrmcoModalHeader } from '../OrmcoModalHeader'

export const BrandindModalHeader = styled(OrmcoModalHeader)`
  padding: 24px;

  flex-flow: row;

  border-bottom: none;

  text-align: center;

  background: ${({ theme }) => theme.colors.secondary300};
`
