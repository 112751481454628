import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { FormItemProps } from 'antd/lib/form'
import { addressActions, addressSelectors } from 'app/components/routes/Address/logic'
import { RedButton, InfoButton, LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Address } from 'app/core/domain/Address'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

import { deleteAddressModalActions } from './DeleteAddressModal/logic'

export const AddAddressButtons = (props: FormItemProps & { size: number; id: string }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const form = useFormContext<Address>()
  const addAddressInProgress = useSelector(addressSelectors.getAddressProgressStatus())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const { id, size } = props
  const showDeleteAddressButton = id && !featurePermissions?.disableUserProfile
  const onSubmit = useCallback(
    (address: Address) => {
      if (!addAddressInProgress) {
        dispatch(addressActions.addAddressProgressStatusUpdate())

        if (id) {
          dispatch(
            addressActions.updateAddressAsDefaultRequested({
              address,
              id,
            }),
          )
        } else {
          dispatch(
            addressActions.addAddressRequested({
              address,
              isEdit: false,
            }),
          )
        }
      }
    },
    [dispatch, id, addAddressInProgress],
  )

  const onDelete = useCallback(() => {
    dispatch(deleteAddressModalActions.deleteAddressModalTriggered())
  }, [dispatch])

  let button

  if (id) {
    if (size > 0) {
      button = (
        <CreateButton onClick={form.handleSubmit(onSubmit)} data-testid="AddAddress-CreateButton">
          <div>
            <FormattedMessage id="footer.update" />
          </div>
        </CreateButton>
      )
    } else {
      button = (
        <CreateButton
          onClick={() => history.push('/myProfile')}
          data-testid="AddAddress-CreateButton"
        >
          <div>
            <FormattedMessage id="footer.close" />
          </div>
        </CreateButton>
      )
    }
  } else {
    button = (
      <CreateButton onClick={form.handleSubmit(onSubmit)} data-testid="AddAddress-CreateButton">
        <div>
          <FormattedMessage id="footer.create" />
        </div>
      </CreateButton>
    )
  }
  return (
    <AddressButtonsContainer
      marginBottom="0"
      padding="1em"
      justify="space-between"
      boxSizing="border-box"
    >
      <Box justify="space-between" width="auto">
        {!id && (
          <LightBlueButtonWithBorder
            data-testid="AddAddress-CancelButton"
            onClick={() => history.push('/myProfile')}
          >
            <div>
              <FormattedMessage id="footer.cancel" />
            </div>
          </LightBlueButtonWithBorder>
        )}
        {showDeleteAddressButton && (
          <RedButton data-testid="AddAddress-DeleteAddressButton" onClick={onDelete}>
            <FormattedMessage id="delete.address.modal.button" />
          </RedButton>
        )}
      </Box>
      <ButtonsWrapper justify="space-between" width="auto">
        {id && size > 0 && (
          <LightBlueButtonWithBorder
            data-testid="AddAddress-CancelButton"
            onClick={() => history.push('/myProfile')}
          >
            <div>
              <FormattedMessage id="footer.cancel" />
            </div>
          </LightBlueButtonWithBorder>
        )}{' '}
        {button}
      </ButtonsWrapper>
    </AddressButtonsContainer>
  )
}

const ButtonsWrapper = styled(Box)`
  > * {
    margin-left: 20px;
  }
`

const AddressButtonsContainer = styled(Box)`
  position: sticky;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.layer1};

  border-top: 1px solid ${({ theme }) => theme.colors.base300};

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1.5px 1.5px 1.5px 0.5px ${({ theme }) => theme.colors.base200};
`

const CreateButton = styled(InfoButton)`
  margin-right: 0;
  margin-left: 20px;
  padding: 0;

  justify-content: center;

  min-width: 136px;
  height: 40px;

  letter-spacing: 0.1px;

  font-weight: 600;
  line-height: 18px;
`
