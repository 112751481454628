import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { ImpersonationInfo } from 'app/components/routes/BasicPage/Header/ImpersonationInfo'
import { LanguageDropdown } from 'app/components/routes/BasicPage/Header/NavigationBar/LanguageDropdown/LanguageDropdown'
import { NavigationBar } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationBar'
import { OrmcoDTXLogo } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

const OrmcoLogo = () => (
  <StyledLogoLink to={SiteMap.homePage()} data-testid="Header-OrmcoLogo">
    <OrmcoDTXLogo />
  </StyledLogoLink>
)

const Header = () => {
  return (
    <HeaderContainer as="header" data-testid="Header" justify="space-between">
      <HeaderContainerDetails />
    </HeaderContainer>
  )
}
const HeaderContainerDetails = () => {
  const isImpersonated = useSelector(appSelectors.isImpersonated())

  return (
    <>
      <Box justify="flex-start">
        <OrmcoLogo />
        {isImpersonated && (
          <>
            <ImpersonationInfo data-testid="Header-ImpersonationInfo" />
            <Box marginLeft="24px" width="auto">
              <LanguageDropdown data-testid="Header-LanguageDropdown" />
            </Box>
          </>
        )}
      </Box>
      <Box justify="flex-end">
        <NavigationBar data-testid="Header-NavigationBar" />
      </Box>
    </>
  )
}

interface HeaderContainerProps {
  isFixed?: boolean
}

const HeaderContainer = styled(Box)<HeaderContainerProps>`
  padding-right: 15px;
  padding-left: 15px;
  z-index: ${({ theme }) => theme.zIndex.header};

  box-sizing: border-box;
  height: 53px;

  background-color: ${({ theme }) => theme.colors.primary800};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0;
`

const StyledLogoLink = styled(Link)`
  align-self: center;

  min-width: 152px;
`

export { Header }
