import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Divider } from 'antd'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { AffectedArche } from 'app/core/domain/AffectedArches'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { OverbiteActionPrimary, OverbitePrimary } from 'app/core/domain/OverbitePrimary'

import { BiteLowerUpper } from './BiteLowerUpper'

const PROPERTY_KEY = 'primaryCasePreferences.overbitePrimary'

const CorrectOpenBite = () => {
  const form = useFormContext<CaseDraft>()
  const overbitePrimary = form.getValues(PROPERTY_KEY)
  const theme = useContext(ThemeContext)
  const changeRadio = (res: OverbitePrimary) => {
    form.setValue(PROPERTY_KEY, res)
  }

  return (
    <ThemedBox data-testid="CorrectOpenBite-Section" contextTheme={theme}>
      <OrmcoFormItemVertical hasFeedback data-testid="CorrectOpenBite">
        <Vertical>
          <OrmcoRadio
            checked={overbitePrimary !== undefined && overbitePrimary.extAnteriorTeeth === true}
            onChange={() =>
              changeRadio({
                ...overbitePrimary,
                overbiteActionPrimary: OverbiteActionPrimary.CORRECT_OPEN_BITE,
                extAntTeethVirtualSimulation: false,
                extAnteriorIntPosteriorTeeth: false,
                extAnteriorTeeth: true,
              })
            }
            data-testid="CorrectOpenBite-Anterior"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.overbiteAction.affectedTeeth.ANTERIOR" />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            checked={
              overbitePrimary !== undefined &&
              overbitePrimary.extAnteriorIntPosteriorTeeth === true &&
              overbitePrimary.extAntTeethVirtualSimulation === false
            }
            onChange={() =>
              changeRadio({
                ...overbitePrimary,
                overbiteActionPrimary: OverbiteActionPrimary.CORRECT_OPEN_BITE,
                extAntTeethVirtualSimulation: false,
                extAnteriorIntPosteriorTeeth: true,
                extAnteriorTeeth: false,
              })
            }
            data-testid="CorrectOpenBite-AnteriorAndPosterior"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.overbiteAction.affectedTeeth.ANTERIOR_AND_POSTERIOR" />
            </Typography>
          </OrmcoRadio>

          <OrmcoRadio
            checked={
              overbitePrimary !== undefined &&
              overbitePrimary.extAntTeethVirtualSimulation === true &&
              overbitePrimary.extAnteriorIntPosteriorTeeth === true
            }
            onChange={() =>
              changeRadio({
                ...overbitePrimary,
                overbiteActionPrimary: OverbiteActionPrimary.CORRECT_OPEN_BITE,
                extAntTeethVirtualSimulation: true,
                extAnteriorIntPosteriorTeeth: true,
                extAnteriorTeeth: false,
              })
            }
            data-testid="CorrectOpenBite-AnteriorAndPosteriorWithSimulation"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.overbiteAction.affectedTeeth.ANTERIOR_AND_POSTERIOR_WITH_SIMULATION" />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            checked={
              overbitePrimary !== undefined &&
              overbitePrimary.extAntTeethVirtualSimulation === true &&
              overbitePrimary.extAnteriorIntPosteriorTeeth === false
            }
            onChange={() =>
              changeRadio({
                overbiteActionPrimary: OverbiteActionPrimary.CORRECT_OPEN_BITE,
                extAntTeethVirtualSimulation: true,
                extAnteriorIntPosteriorTeeth: false,
                extAnteriorTeeth: false,
              })
            }
            data-testid="CorrectOpenBite-VirtualSimulation"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.overbiteAction.affectedTeeth.VIRTUAL_SIMULATION" />
            </Typography>
          </OrmcoRadio>
        </Vertical>
      </OrmcoFormItemVertical>
      <Divider />
      <BiteLowerUpper
        selectedArch={overbitePrimary?.biteLowerUpper}
        defaultArch={overbitePrimary?.biteLowerUpper ?? AffectedArche.BOTH}
        formatMessageKey="casePrescription.overbite"
        data-testid="CorrectOpenBite-LowerUpper"
      />
    </ThemedBox>
  )
}

export { CorrectOpenBite }
