import React, { useContext } from 'react'

import { ResolveArchCrowding } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveArchCrowding'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { ThemeContext, ThemeType, ContextThemeProps } from 'app/components/ui/Theme/ThemeContext'
import { Arch } from 'app/core/domain/ArchTools'
import styled from 'styled-components'

const ResolveCrowding = () => {
  const theme = useContext(ThemeContext)

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.resolveCrowdingBy" />
      }
      data-testid="ResolveCrowding"
    >
      <Box justify="space-between">
        <ThemedBox contextTheme={theme}>
          <ResolveArchCrowding arch={Arch.UPPER} />
        </ThemedBox>
        <ThemedBox contextTheme={theme}>
          <ResolveArchCrowding arch={Arch.LOWER} />
        </ThemedBox>
      </Box>
    </OrmcoFormItemVertical>
  )
}

interface BoxProps extends ContextThemeProps {
  width?: string
  padding?: string
}

const ThemedBox = styled.div<BoxProps>`
  padding: ${(props) => (props.padding ? props.padding : '2em')};

  flex-basis: 48%;

  width: ${(props) => (props.width ? props.width : 'unset')};

  background-color: ${({ contextTheme = ThemeType.BOOTSTRAP, theme }) =>
    contextTheme === ThemeType.BOOTSTRAP ? theme.colors.base100 : theme.colors.primary10};
  border-radius: 4px;
`

export { ResolveCrowding, ThemedBox }
