import { FormattedMessage } from 'react-intl'

import { Typography } from 'app/components/ui/SDS/common/Typography'

interface ClinicalPreferencesCaptionProps {
  'data-testid': string
  captionTextId: string
}

export const ClinicalPreferencesCaption = (props: ClinicalPreferencesCaptionProps) => {
  return (
    <Typography
      data-testid={props['data-testid']}
      component="h2"
      variant="large"
      textFontWeight="600"
      color="primary900"
    >
      <FormattedMessage id={props.captionTextId} />
    </Typography>
  )
}
