import styled from 'styled-components'

const ProgressStriped = () => (
  <ProgressStripedContainer>
    <ProgressStripedInner />
  </ProgressStripedContainer>
)

const ProgressStripedContainer = styled.div`
  margin: 0 auto;

  overflow: hidden;

  height: 20px;

  background: ${({ theme }) =>
    `${theme.colors.base100}
     linear-gradient(to bottom, ${theme.colors.base200} 0, ${theme.colors.base100} 100%)
     repeat-x`};
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
`

const ProgressStripedInner = styled.div`
  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.colors.primary300}
    linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    )
    repeat-x;

  background-size: 40px 40px;

  animation: progress-bar-stripes 2s linear infinite;
`

export { ProgressStriped }
