import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { AssociatedDoctors } from 'app/components/routes/Staff/StaffDetails/AssociatedDoctors'
import { StaffEmail } from 'app/components/routes/Staff/StaffDetails/StaffEmail'
import { StaffFirstName } from 'app/components/routes/Staff/StaffDetails/StaffFirstName'
import { StaffLastName } from 'app/components/routes/Staff/StaffDetails/StaffLastName'
import { StaffMiddleName } from 'app/components/routes/Staff/StaffDetails/StaffMiddleName'
import { StaffUserName } from 'app/components/routes/Staff/StaffDetails/StaffUserName'
import { FormCaption } from 'app/components/ui/Form/FormCaption'
import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import image from 'app/resources/img/pictures/addStaff.png'

import { staffCreationSelectors } from './logic'

export const StaffDetails: React.FC = () => {
  const isStaffUpdateFlow = useSelector(staffCreationSelectors.isStaffUpdateFlow())
  const isLoading = useSelector(staffCreationSelectors.isStaffLoading())
  const formHeadingMessageId = isStaffUpdateFlow
    ? 'staff.staffDetails.member.details'
    : 'staff.staffDetails.add.staff.member'

  return (
    <>
      <Box display="block" width="auto">
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            as="form"
            justify="space-around"
            align="flex-start"
            flexDirection="column"
            boxSizing="border-box"
            width="320px"
            name="addStaffForm"
            data-testid="StaffDetails"
          >
            <FormCaption data-testid="StaffDetails-FormCaption">
              <FormattedMessage id={formHeadingMessageId} />
            </FormCaption>
            <StaffFirstName />
            <StaffMiddleName />
            <StaffLastName />
            <AssociatedDoctors />
            <StaffUserName />
            <StaffEmail />
          </Box>
        )}
      </Box>
      <Box marginTop="88px" width="296px" height="470px">
        <img width="100%" height="auto" src={image} alt="Add Staff logo" />
      </Box>
    </>
  )
}
