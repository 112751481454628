import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

export const SelectorDropdownIcon = () => (
  <PatientFilterDropDownIcon data-testid="SelectorDropdownIcon" component="span" color="base600">
    ▼
  </PatientFilterDropDownIcon>
)

const PatientFilterDropDownIcon = styled(Typography)`
  transform: scale(1.5, 0.75);
`
