import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { getValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { Address } from 'app/core/domain/Address'

import { AddressInput } from './AddressInput'

const AddressTag = () => {
  const intl = useIntl()
  const {
    formState: { errors },
  } = useFormContext<Address>()

  return (
    <StaffFormItemVertical
      colon={false}
      label={<InputLabel labelId="addresses.addressForm.tag" />}
      hasFeedback
      validateStatus={getValidationStatus('addressTag', errors)}
      help={<OrmcoFormErrorMessage errors={errors} name="addressTag" />}
      data-testid="AddAddress-AddressTag"
    >
      <AddressInput
        name="addressTag"
        rules={{
          required: intl.formatMessage({ id: 'addresses.addressForm.tag.required' }),
        }}
      />
    </StaffFormItemVertical>
  )
}

export { AddressTag }
