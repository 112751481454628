import { basicPageActions } from 'app/components/routes/BasicPage/logic'
import {
  staffListActions,
  staffListSelectors,
  ListType,
} from 'app/components/routes/Staff/StaffTable/logic'
import { StaffListResponse } from 'app/core/domain/Http/StaffListResponse'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import jstz from 'jstz'
import { combineEpics, Epic } from 'redux-observable'
import { from, merge, of } from 'rxjs'
import { catchError, filter, switchMap, tap, debounceTime } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import URI from 'urijs'

const addUrlToBrowserHistory = (url: string) => {
  if (window.location.href !== url) {
    window.history.pushState(null, '', url.toString())
  }
}
const getClinicUsersEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  merge(
    action$.pipe(
      filter(
        isActionOf([
          staffListActions.staffListMounted,
          staffListActions.staffFilterCheckboxClicked,
          staffListActions.loadMoreStaffReached,
          staffListActions.clearSearchButtonClicked,
          basicPageActions.myTeamLinkClicked,
        ]),
      ),
    ),
    action$.pipe(filter(isActionOf(staffListActions.searchDoctorInputChanged)), debounceTime(500)),
  ).pipe(
    tap(() => {
      let search: string | undefined
      const listType = staffListSelectors.getListType()(state$.value)

      if (listType === ListType.SEARCH) {
        search = staffListSelectors.getStaffListParams()(state$.value).search
      }
      const url = URI(window.location.href)
        .setQuery({
          search,
        })
        .toString()

      addUrlToBrowserHistory(url)
    }),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const userFilters = staffListSelectors.getUserFilter()(state$.value)
      const roles = userFilters
        .filter((user) => user.checked === true)
        .map((user) => user.role)
        .join(',')
      const params = staffListSelectors.getStaffListParams()(state$.value)

      const timezone = jstz.determine()
      const listType = staffListSelectors.getListType()(state$.value)
      const searchParams = {
        browserTimezone: listType === ListType.SEARCH ? timezone.name() : undefined,
        locale: appSelectors.getLocale()(state$.value),
      }

      return from(
        axios.get<StaffListResponse>(`/api/v1/users/${username}/clinicusers`, {
          params: {
            role: roles,
            ...params,
            ...searchParams,
          },
        }),
      ).pipe(
        switchMap((res) => of(staffListActions.staffDetailsRequestFinished(res.data))),
        catchError((err) => of(staffListActions.staffDetailsRequestFailed({ message: err }))),
      )
    }),
  )

const staffTableEpic = combineEpics(getClinicUsersEpic)

export { staffTableEpic }
