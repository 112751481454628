import { casePerksActions } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Perks } from 'app/core/domain/Http/Perks'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const makeCasePerkRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([casePerksActions.casePerksMounted])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const url = `/api/v1/doctors/${username}/perks`

      return from(axios.get<Perks>(url)).pipe(
        switchMap((result) => of(casePerksActions.perksRequestComplete(result.data))),
        catchError((err) => of(casePerksActions.perksRequestFailed({ message: err }))),
      )
    }),
  )

const casePerksActionsEpic = combineEpics(makeCasePerkRequestEpic)

export { casePerksActionsEpic }
