const traverseAndFlatten = (
  currentNode: Record<string, any>,
  target: any,
  flattenedKey?: string,
) => {
  for (let key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      let newKey

      if (flattenedKey === undefined) {
        newKey = key
      } else {
        newKey = `${flattenedKey}.${key}`
      }

      const value = currentNode[key]

      if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey)
      } else {
        target[newKey] = value
      }
    }
  }
}

export const flattenObject = (obj: Record<string, any>) => {
  const result = {}

  traverseAndFlatten(obj, result)
  return result
}
