import { useSelector } from 'react-redux'

import { useGetCasePerksQuery } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Region } from 'app/core/domain/Region'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { CasePerks, ShippedCasePerks } from './components'
import { CasePerksDashboardContainer } from './styles'

export const CasePerksDashboard = () => {
  const { data: casePerks, isError, isFetching, isLoading, error } = useGetCasePerksQuery()
  const isSDS = useSelector(featureFlagSelectors.isSDS())

  if (isFetching || isLoading) {
    return null
  }

  if (isError || error) {
    return <div data-testid="CasePerksDashboard-Error" />
  }

  const showOnlyShippedCasePerks =
    casePerks?.region !== Region.NORTH_AMERICA || casePerks?.showShippedCasesPerks

  return (
    <>
      <CasePerksDashboardContainer isSDS={isSDS} data-testid="CasePerksDashboard-Container">
        {showOnlyShippedCasePerks ? <ShippedCasePerks /> : <CasePerks />}
      </CasePerksDashboardContainer>
    </>
  )
}
