import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { caseFilterSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CaseFilter/logic'
import { MarketingMessage } from 'app/components/routes/CaseList/MarketingMessage'
import { SearchInput } from 'app/components/routes/CaseList/SearchInput'
import { InfoButton } from 'app/components/ui/Buttons'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { Loader } from 'app/components/ui/Loader'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

import { caseListSelectors } from './logic'

const ToolPanel = () => {
  const isStaff = useSelector(appSelectors.isStaff())
  const caseFilterUsers = useSelector(caseFilterSelectors.getCaseFilterUsers())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const hideAddPatientButton =
    (isStaff && !caseFilterUsers?.length) || Boolean(featurePermissions?.disablePatientCreation)
  const showLoader = useSelector(caseListSelectors.getHoldRemovalLoader())

  return (
    <Box
      paddingTop="40px"
      flexDirection="row"
      justify="flex-start"
      align="flex-start"
      height="100px"
      boxSizing="border-box"
    >
      <Padder />
      {!hideAddPatientButton && (
        <AddPatientButton
          href={SiteMap.DOCTORS.addPatientDetails()}
          data-testid="ToolPanel-AddPatientButton"
        >
          <GlyphIcon type={IconType.PLUS} />
          <div>
            <FormattedMessage id="toolPanel.addPatient" />
          </div>
        </AddPatientButton>
      )}
      {featurePermissions?.payMyBillButtonEnabled && (
        <InfoButton
          as="a"
          target="_blank"
          data-testid="ToolPanel-PayMyBill"
          href={featurePermissions?.payMyBillButtonUrl}
        >
          Pay My Bill
        </InfoButton>
      )}
      <MarketingMessage data-testid="ToolPanel-MarketingMessage" />
      {showLoader && <Loader isAbsoluteCenter top="3% !important" />}
      <SearchInput data-testid="ToolPanel-SearchInput" />
    </Box>
  )
}

const AddPatientButton = styled(InfoButton)`
  margin-right: 20px;

  min-width: 104px;
  height: 32px;
`

export { ToolPanel }
