import React from 'react'
import { useSelector } from 'react-redux'

import { appSelectors } from 'app/logic/app/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const LoginName: React.FC = () => {
  const { loginName } = useSelector(appSelectors.getEmployeePersonalDetails())

  return <ReadonlyValue data-testid="MyProfile-LoginName" labelId="login.name" value={loginName} />
}

export { LoginName }
