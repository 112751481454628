import { useSelector } from 'react-redux'

import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { ReadonlyValue } from '../MyProfile/ReadonlyValue'

import { AddressInput } from './AddressInput'
import { addressSelectors } from './logic'

const PhoneNumber = () => {
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const phoneNumber = useSelector(addressSelectors.getAddressPhoneNumber())

  return (
    <>
      {featurePermissions?.disableUserProfile ? (
        <ReadonlyValue
          data-testid="PhoneNumber-ProfileContainer"
          labelId="addresses.addressForm.phone"
          value={phoneNumber}
        />
      ) : (
        <StaffFormItemVertical
          colon={false}
          label={<InputLabel labelId="addresses.addressForm.phone" isOptional />}
          data-testid="PhoneNumber-StaffFormItemVertical"
        >
          <AddressInput name="phoneNumber" />
        </StaffFormItemVertical>
      )}
    </>
  )
}

export { PhoneNumber }
