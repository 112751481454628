import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Doctor } from 'app/core/domain/Doctor'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

import { caseFilterActions, caseFilterSelectors } from '../logic'

interface PatientFilterListProps {
  'data-testid': string
}

const PatientFilterList: React.FC<PatientFilterListProps> = (props) => {
  const dispatch = useDispatch()
  const caseFilterUsers = useSelector(caseFilterSelectors.getCaseFilterUsers())
  const { practiceName } = caseFilterUsers[0]
  const currentUser = useSelector(appSelectors.getUsername())
  const isDoctor = useSelector(appSelectors.isDoctor())
  const testId = props['data-testid']

  const allPatientsButtonClicked = useCallback(() => {
    dispatch(caseFilterActions.caseFilterAllPatientsButtonClicked())
  }, [dispatch])

  const myPatientsButtonClicked = useCallback(() => {
    dispatch(caseFilterActions.caseFilterMyPatientsButtonClicked({ currentUser }))
  }, [currentUser, dispatch])

  const selectCaseFilterUserCallback = (data: Doctor) => {
    const updatedDoctors: Array<Doctor> = caseFilterUsers.map((doctor) =>
      doctor.username === data.username ? { ...doctor, checked: !doctor.checked } : doctor,
    )

    dispatch(caseFilterActions.caseFilterUsersCheckBoxClicked(updatedDoctors))
  }

  return (
    <PatientFilterListContainer
      marginTop="36px"
      padding="15px"
      justify="flex-start"
      display="block"
      width="auto"
      data-testid={testId}
    >
      <Typography
        data-testid={`${testId}-PracticeName`}
        component="span"
        color="base900"
        textFontWeight="600"
        padding="0 0 12px"
      >
        {practiceName}
      </Typography>

      <DoctorNameContainer
        marginBottom="12px"
        display="block"
        width="max-content"
        max-height="63vh"
      >
        {caseFilterUsers.map((user: Doctor) => {
          const { username, checked, name } = user
          const onUserClicked = () => selectCaseFilterUserCallback(user)

          return (
            <DoctorCheckBoxContainer
              margin="4px 0"
              padding="3px 0"
              justify="flex-start"
              key={username}
              onClick={onUserClicked}
              data-testid={`${testId}-User-${username}`}
            >
              <OrmcoCheckbox name={username} checked={checked} />
              <Padder width="10px" />
              <Typography component="label" color="base900">
                <FormattedMessage id="caseFilter.doctorInitial" /> {name}
              </Typography>
            </DoctorCheckBoxContainer>
          )
        })}
      </DoctorNameContainer>

      {!isDoctor && <LineSeparator />}
      <Box justify="flex-start">
        <AllPatientsButton
          onClick={allPatientsButtonClicked}
          data-testid={`${testId}-AllPatientsButton`}
        >
          <FormattedMessage id="caseFilter.allPatients" />
        </AllPatientsButton>
        {isDoctor && (
          <MyCasesButton onClick={myPatientsButtonClicked} data-testid={`${testId}-MyCasesButton`}>
            <FormattedMessage id="caseFilter.myPatients" />
          </MyCasesButton>
        )}
      </Box>
    </PatientFilterListContainer>
  )
}

export { PatientFilterList }

const AllPatientsButton = styled(LightBlueButtonWithBorder)`
  padding: 0;

  justify-content: center;

  min-width: 150px;
  height: 45px;

  font-size: 14px;
`

const MyCasesButton = styled(LightBlueButtonWithBorder)`
  margin-left: 10px;

  justify-content: center;

  min-width: 150px;
  height: 45px;

  font-size: 14px;
`

const DoctorCheckBoxContainer = styled(Box)`
  cursor: pointer;
`

const PatientFilterListContainer = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;

  z-index: ${({ theme }) => theme.zIndex.dropdown};

  min-width: 10em;
  border: 1px solid ${({ theme }) => theme.colors.base300};

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`

const DoctorNameContainer = styled(Box)`
  overflow-y: auto;
`

const LineSeparator = styled.hr`
  margin: 0 -15px 15px -15px;

  border-width: 3px;
`
