import { DefaultTheme } from 'styled-components'

import {
  FillBtnColorsStructure,
  OutlineBtnColorsStructure,
  AdditionalButtonProps,
} from './interfaces'

type GetBtnColorSchemeType<ReturnType> = (
  isDark: AdditionalButtonProps['isDark'],
  theme: DefaultTheme,
) => ReturnType
type GetOutlineBtnColorSchemeType = (theme: DefaultTheme) => OutlineBtnColorsStructure
type GetFillBtnColorSchemeType = (theme: DefaultTheme) => FillBtnColorsStructure

export const getFillBtnColorLightScheme: GetFillBtnColorSchemeType = ({
  colors: {
    primary100,
    primary200,
    primary500,
    primary600,
    primary700,
    success100,
    success300,
    success400,
    success500,
    success600,
    warning100,
    warning400,
    warning500,
    warning600,
    danger100,
    danger200,
    danger400,
    danger500,
    danger600,
    base100,
    base200,
    base300,
  },
}) => ({
  primary: {
    default: {
      backgroundColor: primary500,
    },
    hover: {
      backgroundColor: primary600,
    },
    focus: {
      backgroundColor: primary700,
    },
    disabled: {
      backgroundColor: primary100,
      color: primary200,
    },
  },

  success: {
    default: {
      backgroundColor: success400,
    },
    hover: {
      backgroundColor: success500,
    },
    focus: {
      backgroundColor: success600,
    },
    disabled: {
      backgroundColor: success100,
      color: success300,
    },
  },

  warning: {
    default: {
      backgroundColor: warning400,
    },
    hover: {
      backgroundColor: warning500,
    },
    focus: {
      backgroundColor: warning600,
    },
    disabled: {
      backgroundColor: warning100,
      color: warning400,
    },
  },

  danger: {
    default: {
      backgroundColor: danger400,
    },
    hover: {
      backgroundColor: danger500,
    },
    focus: {
      backgroundColor: danger600,
    },
    disabled: {
      backgroundColor: danger100,
      color: danger200,
    },
  },

  basic: {
    default: {
      backgroundColor: base100,
    },
    hover: {
      backgroundColor: base200,
    },
    focus: {
      backgroundColor: base300,
    },
    disabled: {
      backgroundColor: base100,
      color: base300,
    },
  },
})

export const getFillBtnColorDarkScheme: GetFillBtnColorSchemeType = (theme) => {
  const lightScheme = getFillBtnColorLightScheme(theme)
  const {
    colors: {
      primary600,
      primary800,
      primary900,
      success600,
      success400,
      warning500,
      warning300,
      danger600,
      danger300,
    },
  } = theme

  return {
    primary: {
      ...lightScheme.primary,
      focus: {
        backgroundColor: primary900,
      },
      disabled: {
        backgroundColor: primary800,
        color: primary600,
      },
    },
    success: {
      ...lightScheme.success,
      disabled: {
        backgroundColor: success600,
        color: success400,
      },
    },
    warning: {
      ...lightScheme.warning,
      disabled: {
        backgroundColor: warning500,
        color: warning300,
      },
    },
    danger: {
      ...lightScheme.danger,
      disabled: {
        backgroundColor: danger600,
        color: danger300,
      },
    },
    basic: {
      ...lightScheme.basic,
    },
  }
}

export const getOutlineBtnColorLightScheme: GetOutlineBtnColorSchemeType = ({
  colors: {
    primary100,
    primary200,
    primary300,
    primary500,
    primary600,
    success10,
    success100,
    success200,
    success400,
    success500,
    successTransparent50,
    warning100,
    warning200,
    warning300,
    warning400,
    warning500,
    warning600,
    dangerTransparent50,
    danger10,
    danger100,
    danger200,
    danger400,
    danger500,
    danger600,
    base100,
    base200,
    base600,
    base700,
    base800,
    white,
  },
}) => ({
  primary: {
    default: {
      borderColor: primary300,
      color: primary500,
    },
    hover: {
      backgroundColor: primary100,
      borderColor: primary600,
      color: primary600,
    },
    focus: {
      backgroundColor: primary200,
      borderColor: primary600,
      color: primary600,
    },

    disabled: {
      backgroundColor: white,
      borderColor: primary200,
      color: primary200,
    },
  },
  success: {
    default: {
      borderColor: successTransparent50,
      color: success400,
    },
    hover: {
      backgroundColor: success10,
      borderColor: success500,
      color: success500,
    },
    focus: {
      backgroundColor: success100,
      borderColor: success500,
      color: success500,
    },

    disabled: {
      backgroundColor: white,
      borderColor: success200,
      color: success200,
    },
  },
  warning: {
    default: {
      borderColor: warning300,
      color: warning400,
    },
    hover: {
      backgroundColor: warning100,
      borderColor: warning500,
      color: warning500,
    },
    focus: {
      backgroundColor: warning200,
      borderColor: warning500,
      color: warning600,
    },

    disabled: {
      backgroundColor: white,
      borderColor: warning200,
      color: warning200,
    },
  },
  danger: {
    default: {
      borderColor: dangerTransparent50,
      color: danger400,
    },
    hover: {
      backgroundColor: danger10,
      borderColor: danger400,
      color: danger500,
    },
    focus: {
      backgroundColor: danger100,
      borderColor: danger400,
      color: danger600,
    },

    disabled: {
      backgroundColor: white,
      borderColor: danger200,
      color: danger200,
    },
  },
  basic: {
    default: {
      borderColor: base200,
      color: base800,
    },
    hover: {
      backgroundColor: base100,
      borderColor: base600,
      color: base700,
    },
    focus: {
      backgroundColor: base200,
      borderColor: base600,
      color: base800,
    },

    disabled: {
      backgroundColor: white,
      borderColor: base200,
      color: base200,
    },
  },
})

export const getOutlineBtnColorDarkScheme: GetOutlineBtnColorSchemeType = ({
  colors: {
    primary500,
    primary600,
    primary800,
    primary900,
    success400,
    success500,
    success600,
    warning400,
    warning500,
    warning600,
    danger300,
    danger400,
    danger600,
    blackTransparent10,
    blackTransparent30,
    neutral600,
    white,
  },
}) => {
  const colorScheme = {
    primary: {
      default: {
        borderColor: primary500,
        color: white,
      },
      hover: {
        backgroundColor: blackTransparent10,
        borderColor: primary800,
        color: white,
      },
      focus: {
        backgroundColor: blackTransparent30,
        borderColor: primary900,
        color: white,
      },

      disabled: {
        backgroundColor: '',
        borderColor: primary600,
        color: primary600,
      },
    },
    success: {
      default: {
        borderColor: success400,
        color: success400,
      },
      hover: {
        backgroundColor: blackTransparent10,
        borderColor: success600,
        color: success500,
      },
      focus: {
        backgroundColor: blackTransparent30,
        borderColor: success600,
        color: success500,
      },

      disabled: {
        backgroundColor: '',
        borderColor: success500,
        color: success500,
      },
    },
    warning: {
      default: {
        borderColor: warning400,
        color: warning400,
      },
      hover: {
        backgroundColor: blackTransparent10,
        borderColor: warning600,
        color: warning500,
      },
      focus: {
        backgroundColor: blackTransparent30,
        borderColor: warning600,
        color: warning600,
      },

      disabled: {
        backgroundColor: '',
        borderColor: warning600,
        color: warning600,
      },
    },
    danger: {
      default: {
        borderColor: danger300,
        color: danger300,
      },
      hover: {
        backgroundColor: blackTransparent10,
        borderColor: danger400,
        color: danger300,
      },
      focus: {
        backgroundColor: blackTransparent30,
        borderColor: danger600,
        color: danger400,
      },

      disabled: {
        backgroundColor: '',
        borderColor: neutral600,
        color: neutral600,
      },
    },
  }

  return {
    ...colorScheme,
    // we don't have style for basic button in dark theme in our design system
    basic: colorScheme.primary,
  }
}

export const getFillBtnColorScheme: GetBtnColorSchemeType<FillBtnColorsStructure> = (
  isDark,
  theme,
) => {
  return isDark ? getFillBtnColorDarkScheme(theme) : getFillBtnColorLightScheme(theme)
}

export const getOutlineBtnColorScheme: GetBtnColorSchemeType<OutlineBtnColorsStructure> = (
  isDark,
  theme,
) => {
  return isDark ? getOutlineBtnColorDarkScheme(theme) : getOutlineBtnColorLightScheme(theme)
}
