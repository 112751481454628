import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { MyPracticeDescription } from 'app/components/routes/Staff/MyPracticeDescription'
import {
  StaffListQueryUrlParams,
  TeamManagementTable,
} from 'app/components/routes/Staff/StaffTable/TeamManagementTable'
import { InfoButton } from 'app/components/ui/Buttons'
import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'
import { Notification } from 'app/components/ui/notification/Notification'
import { notificationActions, notificationSelectors } from 'app/components/ui/notification/logic'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { NotificationType } from 'app/core/domain/NotificationType'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

const MyPractice: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { search }: StaffListQueryUrlParams = parseQuery<StaffListQueryUrlParams>(location.search)
  const isOpen = useSelector(notificationSelectors.isOpen())

  useDidMount(() => {
    const { notification } = parseQuery<{ notification?: string }>(location.search)

    if (notification === 'createSuccess') {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'staff.creation.success',
          type: NotificationType.info,
        }),
      )
    } else if (notification === 'deleteSuccess') {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'staff.deletion.success',
          type: NotificationType.error,
        }),
      )
    } else if (notification === 'updateSuccess') {
      dispatch(
        notificationActions.notificationTriggered({
          messageId: 'staff.update.success',
          type: NotificationType.info,
        }),
      )
    }
    return () => {
      dispatch(notificationActions.notificationUnMounted())
    }
  })
  return (
    <MyPracticeContainer paddingBottom="120px" flexDirection="column" className="myPracticeClass">
      {isOpen && <Notification />}
      <Box margin="4em" justify="flex-start" flexDirection="column" width="850px">
        <MyPracticeDescription />
        <Box
          marginTop="20px"
          paddingBottom="20px"
          justify="space-between"
          data-testid="TeamManagement"
        >
          <Box width="auto">
            <Typography
              data-testid="TeamManagement-Title"
              component="h2"
              variant="h4"
              textFontWeight="600"
              letterSpacing="0.25px"
            >
              <FormattedMessage id="staff.teamManagement" />
            </Typography>

            <OrmcoTooltip
              title={
                <TooltipText data-testid="TeamManagement-TooltipContent" textId="staff.help" />
              }
              placement="top"
              overlayInnerStyle={{
                padding: '11px',
                textAlign: 'left',
              }}
              overlayStyle={{ maxWidth: 300 }}
              align={{
                offset: [30, 40],
                targetOffset: [30, 40],
                overflow: {
                  adjustX: true,
                  adjustY: true,
                },
              }}
            >
              <div data-testid="TeamManagement-Help">
                <HelpIconContainer>
                  <HelpIcon data-testid="TeamManagement-TooltipIcon" />
                </HelpIconContainer>
              </div>
            </OrmcoTooltip>
          </Box>

          <InfoButton as={Link} to="/createUpdateStaff" data-testid="TeamManagement-AddStaffButton">
            <FormattedMessage id="staff.addMember" />
          </InfoButton>
        </Box>
        <TeamManagementTable search={search} />
      </Box>
    </MyPracticeContainer>
  )
}

const MyPracticeContainer = styled(Box)`
  .ant-alert-info {
    .ant-alert-message {
      color: ${({ theme: styledTheme }) => styledTheme.colors.primary800} !important;
    }

    .ant-alert-close-icon {
      top: 8px !important;

      .anticon-close {
        color: ${({ theme: styledTheme }) => styledTheme.colors.primary800} !important;
      }
    }
  }
`

const HelpIconContainer = styled.span`
  cursor: pointer;

  max-width: 24px;
  min-height: 24px;
`

export { MyPractice }
