import React, { useCallback, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { ResolveSpacingByAction } from 'app/core/domain/ResolveSpacingByAction'
import { ResolveSpacingByDirection } from 'app/core/domain/ResolveSpacingByDirection'

import { ThemedBox } from './ResolveCrowding/ResolveCrowding'

const PROPERTY_KEY = 'primaryCasePreferences.resolveSpacingBy'

const ResolveSpacingByForm = () => {
  const form = useFormContext<CaseDraft>()
  const { primaryCasePreferences } = useSelector(caseWizardSelectors.getDraftData())
  const { action, direction } =
    form.getValues(PROPERTY_KEY) ?? primaryCasePreferences.resolveSpacingBy
  const theme = useContext(ThemeContext)
  const changeActionRadio = useCallback(
    (res: ResolveSpacingByAction) => {
      form.setValue(PROPERTY_KEY, {
        action: res,
        direction:
          res !== ResolveSpacingByAction.CLOSE_ALL_SPACES
            ? ResolveSpacingByDirection.ANTERIOR
            : direction,
      })
    },
    [direction, form],
  )

  const changeDirectionRadio = useCallback(
    (res: ResolveSpacingByDirection) => {
      form.setValue(PROPERTY_KEY, { action, direction: res })
    },
    [action, form],
  )

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.spacesResolution.label" />
      }
      hasFeedback
      data-testid="ResolveSpacingByForm"
    >
      <Vertical>
        <OrmcoRadio
          onChange={() => changeActionRadio(ResolveSpacingByAction.CLOSE_ALL_SPACES)}
          checked={action === undefined || action === ResolveSpacingByAction.CLOSE_ALL_SPACES}
          data-testid="ResolveSpacingByForm-CloseAllSpaces"
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.spacesResolution"
              values={{ spacesResolution: ResolveSpacingByAction.CLOSE_ALL_SPACES }}
            />
          </Typography>

          {(action === undefined || action === ResolveSpacingByAction.CLOSE_ALL_SPACES) && (
            <ThemedBox contextTheme={theme} data-testid="ResolveSpacingByForm-Section">
              <Vertical>
                <OrmcoRadio
                  onChange={() => changeDirectionRadio(ResolveSpacingByDirection.ANTERIOR)}
                  checked={direction === ResolveSpacingByDirection.ANTERIOR}
                  data-testid="ResolveSpacingByForm-CloseAllSpaces-Anterior"
                >
                  <Typography component="span">
                    <FormattedMessage
                      id="caseWizard.casePrescription.resolveSpacingBy.direction"
                      values={{ direction: ResolveSpacingByDirection.ANTERIOR }}
                    />
                  </Typography>
                </OrmcoRadio>

                <OrmcoRadio
                  onChange={() => changeDirectionRadio(ResolveSpacingByDirection.POSTERIOR)}
                  checked={direction === ResolveSpacingByDirection.POSTERIOR}
                  data-testid="ResolveSpacingByForm-CloseAllSpaces-Posterior"
                >
                  <Typography component="span">
                    <FormattedMessage
                      id="caseWizard.casePrescription.resolveSpacingBy.direction"
                      values={{ direction: ResolveSpacingByDirection.POSTERIOR }}
                    />
                  </Typography>
                </OrmcoRadio>
                <OrmcoRadio
                  onChange={() => changeDirectionRadio(ResolveSpacingByDirection.BOTH)}
                  checked={direction === ResolveSpacingByDirection.BOTH}
                  data-testid="ResolveSpacingByForm-CloseAllSpaces-Both"
                >
                  <Typography component="span">
                    <FormattedMessage
                      id="caseWizard.casePrescription.resolveSpacingBy.direction"
                      values={{ direction: ResolveSpacingByDirection.BOTH }}
                    />
                  </Typography>
                </OrmcoRadio>
              </Vertical>
            </ThemedBox>
          )}
        </OrmcoRadio>
        <OrmcoRadio
          value={ResolveSpacingByAction.EQUAL_AROUND_UPPER}
          onChange={() => changeActionRadio(ResolveSpacingByAction.EQUAL_AROUND_UPPER)}
          checked={action !== undefined && action === ResolveSpacingByAction.EQUAL_AROUND_UPPER}
          data-testid="ResolveSpacingByForm-EqualAroundUpper"
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.spacesResolution"
              values={{ spacesResolution: ResolveSpacingByAction.EQUAL_AROUND_UPPER }}
            />
          </Typography>
        </OrmcoRadio>
        <OrmcoRadio
          onChange={() => changeActionRadio(ResolveSpacingByAction.DISTAL_TO_UPPER_LATERALS)}
          checked={
            action !== undefined && action === ResolveSpacingByAction.DISTAL_TO_UPPER_LATERALS
          }
          data-testid="ResolveSpacingByForm-DistalToUpperLaterals"
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.spacesResolution"
              values={{ spacesResolution: ResolveSpacingByAction.DISTAL_TO_UPPER_LATERALS }}
            />
          </Typography>
        </OrmcoRadio>
        <OrmcoRadio
          onChange={() => changeActionRadio(ResolveSpacingByAction.DISTAL_TO_UPPER_CANINES)}
          checked={
            action !== undefined && action === ResolveSpacingByAction.DISTAL_TO_UPPER_CANINES
          }
          data-testid="ResolveSpacingByForm-DistalToUpperCanines"
        >
          <Typography component="span">
            <FormattedMessage
              id="caseWizard.casePrescription.spacesResolution"
              values={{ spacesResolution: ResolveSpacingByAction.DISTAL_TO_UPPER_CANINES }}
            />
          </Typography>
        </OrmcoRadio>
      </Vertical>
    </OrmcoFormItemVertical>
  )
}

export { ResolveSpacingByForm }
