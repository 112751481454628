import React from 'react'

enum ThemeType {
  'BOOTSTRAP' = 'BOOTSTRAP',
  'ANTD' = 'ANTD',
}

interface ContextThemeProps {
  contextTheme?: keyof typeof ThemeType
}

const ThemeContext = React.createContext(ThemeType.ANTD)

export { ThemeContext, ThemeType, ContextThemeProps }
