import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { changePasswordModalActions } from 'app/components/routes/MyProfile/ChangePasswordModal/logic'
import { CustomerId } from 'app/components/routes/MyProfile/PersonalDetails/CustomerId'
import { Fax } from 'app/components/routes/MyProfile/PersonalDetails/Fax'
import { LoginName } from 'app/components/routes/MyProfile/PersonalDetails/LoginName'
import { OfficePhone } from 'app/components/routes/MyProfile/PersonalDetails/OfficePhone'
import { PracticeName } from 'app/components/routes/MyProfile/PersonalDetails/PracticeName'
import { UserFirstName } from 'app/components/routes/MyProfile/PersonalDetails/UserFirstName'
import { UserLastName } from 'app/components/routes/MyProfile/PersonalDetails/UserLastName'
import { UserMiddleName } from 'app/components/routes/MyProfile/PersonalDetails/UserMiddleName'
import { LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { FormCaption } from 'app/components/ui/Form/FormCaption'
import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

const PersonalDetails: React.FC = () => {
  const dispatch = useDispatch()
  const openChangePasswordPopup = useCallback(() => {
    dispatch(changePasswordModalActions.changePasswordModalTriggered())
  }, [dispatch])

  return (
    <Box marginTop="10px" width="370px" display="block">
      <PersonalDetailsForm name="personalDetailsForm">
        <FormCaption data-testid="MyProfile-FormCaption">
          <FormattedMessage id="preferences.personalDetails.title" />
        </FormCaption>
        <UserFirstName />
        <UserMiddleName />
        <UserLastName />
        <CustomerId />
        <PracticeName />
        <LoginName />
        <OfficePhone />
        <Fax />
        <LightBlueButtonWithBorder
          onClick={openChangePasswordPopup}
          data-testid="MyProfile-LightBlueButtonWithBorder"
        >
          <FormattedMessage id="change.password" />
        </LightBlueButtonWithBorder>
      </PersonalDetailsForm>
    </Box>
  )
}

const PersonalDetailsForm = styled.form`
  display: block;

  box-sizing: border-box;
`

export { PersonalDetails }
