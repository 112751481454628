import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { caseListActions } from 'app/components/routes/CaseList/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { SdsDesktopFilled, SdsGlobalOutlined } from 'app/components/ui/Icons/sds'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case } from 'app/core/domain/Case'
import { ProductTier } from 'app/core/domain/ProductTier'
import { featureFlagSelectors } from 'app/logic/features/logic'

const OpenApproverButtons: React.FC<{ caseData: Case }> = ({ caseData, children }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const openApprover = (isWeb: boolean) => (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(caseListActions.openApproverButtonClicked({ case: caseData, isWeb }))
  }
  const { caseId, productTier } = caseData

  if (!featurePermissions?.approverWeb) {
    return (
      <ActionButton
        data-testid={`OpenApproverButton-${caseId}`}
        onClick={openApprover(false)}
        title={intl.formatMessage({ id: 'cases.list.approver.desktop' })}
        padding="0 7px 1px"
        height="18px"
      >
        <SdsDesktopFilled width="11px" color="secondary300" />

        <Typography
          data-testid={`OpenApproverButtons-Typography-${caseId}`}
          component="span"
          variant="small"
          textOverflow="ellipsis"
          color="base700"
        >
          {children}
        </Typography>
      </ActionButton>
    )
  }

  return (
    <Box flexDirection="row" justify="flex-start">
      <ActionButton
        data-testid={`OpenApproverButton-Desktop-${caseId}`}
        onClick={openApprover(false)}
        title={intl.formatMessage({ id: 'cases.list.approver.open.desktop' })}
        height="18px"
        padding="0 3px"
      >
        <SdsDesktopFilled width="12px" color="secondary300" />
        <Typography textOverflow="ellipsis" color="base700">
          <FormattedMessage id="cases.list.approver.desktop" />
        </Typography>
      </ActionButton>

      <ActionButton
        data-testid={`OpenApproverButton-Web-${caseId}`}
        onClick={openApprover(true)}
        title={intl.formatMessage({ id: 'cases.list.approver.open.web' })}
        height="18px"
        padding="0 3px"
        disabled={productTier === ProductTier.IDB}
      >
        <SdsGlobalOutlined width="11px" color="secondary300" />
        <Typography textOverflow="ellipsis" color="base700">
          <FormattedMessage id="cases.list.approver.web" />
        </Typography>
      </ActionButton>
    </Box>
  )
}

export { OpenApproverButtons }
