import { Typography } from 'app/components/ui/SDS/common/Typography'

interface FooterLinkProps {
  href: string
  'data-testid': string
  margin?: string
}

// TODO: Move to common typography based link component in future
export const FooterLink: React.FC<FooterLinkProps> = ({
  margin,
  children,
  href,
  'data-testid': dataTestId,
}) => (
  <Typography margin={margin} component="span">
    <a data-testid={dataTestId} href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  </Typography>
)
