import { FormattedMessage } from 'react-intl'

import styled from 'styled-components'

import { Typography } from '../SDS/common/Typography'

import { OrmcoModalBody } from './OrmcoModalBody'

interface OrmcoModalBodyTextProps {
  'data-testid'?: string
  textId: string
}

export const OrmcoModalBodyText = (props: OrmcoModalBodyTextProps) => {
  return (
    <ModalBody>
      <Typography
        data-testid={props['data-testid']}
        variant="medium"
        textFontWeight="600"
        color="primary900"
      >
        <FormattedMessage id={props.textId} />
      </Typography>
    </ModalBody>
  )
}

const ModalBody = styled(OrmcoModalBody)`
  width: 560px;
`
