import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import {
  CancelCase,
  CancellationType,
} from 'app/components/routes/CaseWizard/steps/CasePrescription/CancelCase'
import { Button as LegacyButton } from 'app/components/ui/Button'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { OrmcoTextArea } from 'app/components/ui/Form/OrmcoTextArea'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Vertical } from 'app/components/ui/Vertical'
import styled from 'styled-components'

const CancelCaseModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isOpen = useSelector(caseWizardSelectors.getCancelModalState())
  const close = () => {
    dispatch(
      caseWizardActions.cancelCaseUpdate({
        description: '',
        cancellationType: CancellationType.CHANGED_MIND,
      }),
    )
    dispatch(caseWizardActions.openCancelDialogButtonClicked(false))
  }
  const caseCancelInfo = useSelector(caseWizardSelectors.getCancelCaseInfo())
  const id = useSelector(caseWizardSelectors.getDraftId())
  const changeRadio = useCallback(
    (res: CancelCase) => {
      dispatch(caseWizardActions.cancelCaseUpdate(res))
    },
    [dispatch],
  )

  const sendCancelToServer = (res: CancelCase) => {
    if (id) {
      dispatch(caseWizardActions.applyCancelButtonClicked(res))
      history.push('/ui/homePage?tab=actionRequired')
    } else {
      history.push('/ui/homePage?tab=actionRequired')
    }
  }

  return (
    <OrmcoModal isOpen={isOpen} onRequestClose={close}>
      <CaseCancelModalHeader>
        <OrmcoModalHeaderText
          variant="h5"
          headerId="caseWizard.casePrescription.cancelCase.header"
        />
        <Button type="text" onClick={close} data-testid="CancelModal-CloseBtn">
          <Cancel />
        </Button>
      </CaseCancelModalHeader>
      {id !== undefined && (
        <CaseCancelModalBody>
          <Typography margin="0 0 10px" variant="medium" textFontWeight="600">
            <FormattedMessage id="caseWizard.casePrescription.cancelCase.body" />
          </Typography>
          <Vertical>
            <OrmcoRadio
              onChange={() =>
                changeRadio({
                  description: '',
                  cancellationType: CancellationType.CHANGED_MIND,
                })
              }
              checked={caseCancelInfo.cancellationType === CancellationType.CHANGED_MIND}
            >
              <Typography component="span">
                <FormattedMessage id="caseWizard.casePrescription.cancelCase.differentOption" />
              </Typography>
            </OrmcoRadio>
            <OrmcoRadio
              onChange={() =>
                changeRadio({
                  description: '',
                  cancellationType: CancellationType.LEAD_TIME,
                })
              }
              checked={caseCancelInfo.cancellationType === CancellationType.LEAD_TIME}
            >
              <Typography component="span">
                <FormattedMessage id="caseWizard.casePrescription.cancelCase.later" />
              </Typography>
            </OrmcoRadio>

            <OrmcoRadio
              onChange={() =>
                changeRadio({ description: '', cancellationType: CancellationType.OTHER })
              }
              checked={caseCancelInfo.cancellationType === CancellationType.OTHER}
            >
              <Typography component="span">
                <FormattedMessage id="caseWizard.casePrescription.cancelCase.other" />
              </Typography>
            </OrmcoRadio>
            {caseCancelInfo.cancellationType === CancellationType.OTHER && (
              <OrmcoTextArea
                onChange={(event) =>
                  changeRadio({
                    description: event.target.value,
                    cancellationType: CancellationType.OTHER,
                  })
                }
                rows={4}
                placeholder="Reason for cancellation"
              />
            )}
          </Vertical>
        </CaseCancelModalBody>
      )}
      <CaseCancelModalFooter>
        <LegacyButton
          variant="white"
          onClick={close}
          data-testid="CancelCaseModal-ButtonClosePrescription"
        >
          <FormattedMessage id="caseWizard.casePrescription.closePrescription" />
        </LegacyButton>
        <Padder width="15px" />
        <LegacyButton
          variant="danger"
          onClick={() => sendCancelToServer(caseCancelInfo)}
          data-testid="CancelCaseModal-ButtonCancel"
        >
          <FormattedMessage id="caseWizard.casePrescription.cancelCase.cancelCase" />
        </LegacyButton>
      </CaseCancelModalFooter>
    </OrmcoModal>
  )
}

export { CancelCaseModal }

const CaseCancelModalHeader = styled(OrmcoModalHeader)`
  padding: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: none;
`
const CaseCancelModalBody = styled(OrmcoModalBody)`
  padding: 0 20px;

  & > div,
  & > p {
    margin: 10px 0;
    padding-left: 25px;

    border-bottom: none;
  }
`

const CaseCancelModalFooter = styled(OrmcoModalFooter)`
  padding: 20px;

  justify-content: flex-end;

  border-top: none;
`
