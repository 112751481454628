import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { PrimaryButton } from 'app/components/ui/Buttons'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ResponseStatus } from 'app/core/domain/UpdateResult'

interface TruGenErrorModalProps {
  isOpen: boolean
  onClose: () => void
}

const TruGenErrorModal: React.FC<TruGenErrorModalProps> = (props) => {
  const truGenTerms = useSelector(caseWizardSelectors.getTruGenTerms())
  const hasError = truGenTerms?.status === ResponseStatus.BAD_REQUEST

  return (
    <OrmcoModal isOpen={props.isOpen} data-testid="Modal-TruGen" onRequestClose={props.onClose}>
      <OrmcoModalHeader>
        <OrmcoModalHeaderText variant="h5" headerId="truGen.modal.title" />
      </OrmcoModalHeader>
      <OrmcoModalBody>
        {hasError && (
          <Typography>
            <FormattedMessage id={truGenTerms?.message} />
          </Typography>
        )}
      </OrmcoModalBody>
      <OrmcoModalFooter justifyContent="flex-end">
        <PrimaryButton onClick={props.onClose}>
          <FormattedMessage id="button.ok" />
        </PrimaryButton>
      </OrmcoModalFooter>
    </OrmcoModal>
  )
}

export { TruGenErrorModal }
