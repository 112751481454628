import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { appSelectors } from 'app/logic/app/logic'
import { get } from 'lodash'

const SupportText = () => {
  const supportContacts = useSelector(appSelectors.getSupportContacts())
  const region = useSelector(appSelectors.getUserRegion())
  const defaultSupportMail = 'Spark.Support@Ormco.com'
  const email = region
    ? get(supportContacts, [region, 'email'], defaultSupportMail)
    : defaultSupportMail

  return (
    <>
      <Typography>
        <FormattedMessage id="caseSubmitted.modal.support1" />
        <a href={`mailto:${email}`}>{email}</a>
        <FormattedMessage id="caseSubmitted.modal.support2" />
      </Typography>
      <Box display="block" width="auto" margin="10px 0">
        {Object.entries(supportContacts).map(([key, supportContact]) => (
          <Typography key={key}>
            <FormattedMessage id="region" values={{ region: key }} />
            {supportContact.phone}
          </Typography>
        ))}
      </Box>
    </>
  )
}

export { SupportText }
