import { configureStore, PreloadedState } from '@reduxjs/toolkit'
import { rootEpic } from 'app/logic/rootEpic'
import { RootAction, rootReducer, RootState } from 'app/logic/rootReducer'
import { createEpicMiddleware } from 'redux-observable'

import { api } from './api'

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>({
    dependencies: {
      get store() {
        return store
      },
    },
  })

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      epicMiddleware,
      api.middleware,
    ],
  })

  epicMiddleware.run(rootEpic)

  return store
}

export const store = setupStore({})
export type AppStore = ReturnType<typeof setupStore>
