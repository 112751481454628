import React from 'react'

import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import cn from 'classnames'

import { NavigationDropdownProps } from './interfaces'
import { StyledNavigationDropdown } from './styles'

export const HeaderNavigationDropdown: React.FC<NavigationDropdownProps> = ({
  icon,
  menu,
  isActive,
  labelText,
  'data-testid': dataTestId,
  isDark,
  dropdownMenuTheme,
}) => {
  const { className: menuCn, ...restMenuProps } = menu
  const menuClassName = cn(
    `sds-dropdown-menu-${dropdownMenuTheme || (isDark ? 'dark' : 'light')}`,
    { menuCn },
  )

  return (
    <StyledNavigationDropdown
      isActive={isActive}
      width="auto"
      height="100%"
      justify="flex-start"
      isDark={isDark}
      data-testid={dataTestId}
    >
      <Dropdown menu={{ className: menuClassName, ...restMenuProps }} trigger={['click']}>
        <Box
          className="dropdown-content"
          height="100%"
          display="inline-flex"
          width="auto"
          maxWidth="156px"
        >
          {icon && <span className="mainIcon">{icon}</span>}
          <Typography margin="0 12px 0 8px" component="span">
            {labelText}
          </Typography>
          <span className="carpetIcon">
            <CaretDownOutlined />
          </span>
        </Box>
      </Dropdown>
    </StyledNavigationDropdown>
  )
}
