import { FormattedMessage } from 'react-intl'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface ReadonlyValueProps {
  'data-testid'?: string
  labelId: string
  value?: string
}

export const ReadonlyValue = (props: ReadonlyValueProps) => {
  return (
    <Box data-testid={props['data-testid']} justify="flex-start" height="24px" marginBottom="20px">
      <LabelContainer component="label" textFontWeight="600" letterSpacing="0.1px">
        <FormattedMessage id={props.labelId} />
      </LabelContainer>
      <Typography component="span" textFontWeight="600" letterSpacing="0.1px">
        {props.value}
      </Typography>
    </Box>
  )
}

export const LabelContainer = styled(Typography)`
  min-width: 250px;
`
