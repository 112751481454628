import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { ColumnSetting } from 'app/components/ui/ColumnSettings'
import { Column } from 'app/components/ui/Table/Column'
import { Row } from 'app/components/ui/Table/Row'
import { CaseField } from 'app/core/domain/CaseField'
import { SortOrder } from 'app/core/domain/SortOrder'
import styled from 'styled-components'

import { Typography } from '../SDS/common/Typography'

interface TableHeaderProps<T> {
  columnSettings: ColumnSetting<T>[]
  'data-testid'?: string
}

const TableHeader = <T extends any>(props: TableHeaderProps<T>) => {
  const dispatch = useDispatch()
  const caseSorting = useSelector(caseListSelectors.getCaseSorting())
  const intl = useIntl()
  const clickCallback = useCallback(
    (headerName: CaseField, canBeSorted?: boolean) => () => {
      if (canBeSorted !== false) {
        dispatch(caseListActions.sortButtonClicked({ headerName }))
      }
    },
    [dispatch],
  )
  const testId = props['data-testid']

  return (
    <HeaderRow width="auto" data-testid={testId}>
      {props.columnSettings.map((column) => (
        <HeaderColumn
          key={column.header}
          width={column.width}
          sorting={column.sorting}
          onClick={clickCallback(column.header, column.sorting)}
          title={intl.formatMessage({ id: 'cases.list.doctor.header' }, { name: column.header })}
          data-testid={`${testId}-${column.header}`}
        >
          <Typography component="span" color="base900" variant="small" textFontWeight="600">
            <FormattedMessage id="cases.list.doctor.header" values={{ name: column.header }} />
            {caseSorting.sortName === column.header && caseSorting.sortOrder === SortOrder.ASC && (
              <span>&nbsp;▲</span>
            )}
            {caseSorting.sortName === column.header && caseSorting.sortOrder === SortOrder.DESC && (
              <span>&nbsp;▼</span>
            )}
          </Typography>
        </HeaderColumn>
      ))}
    </HeaderRow>
  )
}

const HeaderRow = styled(Row)`
  align-self: stretch;

  box-sizing: content-box;

  min-height: 52px;

  border-right: 1px solid ${({ theme }) => theme.colors.primary600};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary600};
  border-left: 1px solid ${({ theme }) => theme.colors.primary600};
`

const HeaderColumn = styled(Column)`
  padding: 4px;

  display: flex;
  overflow: hidden;
  flex-direction: row;
`

export { TableHeader }
