import { NotificationType } from 'app/core/domain/NotificationType'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface NotificationInfo {
  messageId: string
  type: NotificationType
  displayDuration?: number
}
interface NotificationState {
  isOpen: boolean
  notificationInfo: NotificationInfo
}

const INITIAL_STATE: NotificationState = {
  isOpen: false,
  notificationInfo: {
    messageId: NotificationType.success,
    type: NotificationType.info,
    displayDuration: undefined,
  },
}

const notificationActions = {
  notificationTriggered: createAction('@NOTIFICATION/NOTIFICATION_TRIGGERED')<NotificationInfo>(),
  notificationUnMounted: createAction('@NOTIFICATION/NOTIFICATION_UNMOUNTED')(),
}

type NotificationActions = ActionType<typeof notificationActions>

const notificationReducer = createReducer<NotificationState, NotificationActions>(INITIAL_STATE)
  .handleAction(notificationActions.notificationTriggered, (state, action) => ({
    ...state,
    isOpen: true,
    notificationInfo: action.payload,
  }))
  .handleAction(notificationActions.notificationUnMounted, (state) => ({
    ...state,
    isOpen: false,
  }))

const notificationSelectors = {
  isOpen: () => (state: RootState) => state.notification.isOpen,
  getNotificationInfo: () => (state: RootState) => state.notification.notificationInfo,
}

export {
  NotificationState,
  NotificationActions,
  notificationActions,
  notificationReducer,
  notificationSelectors,
}
