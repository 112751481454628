import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { OrmcoFormErrorMessage } from 'app/components/ui/Form/OrmcoFormErrorMessage'
import { StaffFormItemVertical } from 'app/components/ui/Form/StaffFormItemVertical'
import { StaffInput } from 'app/components/ui/Form/StaffInput'
import { getValidationStatus, ValidationStatus } from 'app/components/ui/Form/ValidationStatus'
import { InputLabel } from 'app/components/ui/Input/InputLabel'
import { EmployeeProfileFields } from 'app/core/domain/EmployeeProfile'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { ReadonlyValue } from '../ReadonlyValue'

const Phone: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const {
    formState: { errors },
  } = useFormContext<EmployeeProfileFields>()
  const form = useFormContext<EmployeeProfileFields>()
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const { phone } = useSelector(appSelectors.getEmployeeContactDetails())
  const updateUserProfileData = () => {
    if (getValidationStatus('phone', errors) !== ValidationStatus.error) {
      dispatch(
        appActions.saveEmployeeDetails({
          part: form.getValues('phone'),
          key: 'employeeContactDetails.phone',
        }),
      )
    }
  }

  return (
    <>
      {featurePermissions?.disableUserProfile ? (
        <ReadonlyValue data-testid="Phone-ProfileContainer" labelId="phone" value={phone} />
      ) : (
        <StaffFormItemVertical
          colon={false}
          label={<InputLabel labelId="phone" />}
          hasFeedback
          validateStatus={getValidationStatus('phone', errors)}
          help={<OrmcoFormErrorMessage errors={errors} name="phone" />}
          data-testid="ContactDetails-Phone"
        >
          <Controller
            name="phone"
            render={({ field }) => (
              <StaffInput
                width="100%"
                name={field.name}
                onChange={field.onChange}
                value={field.value}
                onBlur={updateUserProfileData}
              />
            )}
            rules={{
              required: intl.formatMessage({ id: 'phone.required' }),
            }}
          />
        </StaffFormItemVertical>
      )}
    </>
  )
}

export { Phone }
