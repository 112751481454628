import { useEffect, useState } from 'react'
import { FieldValues, useForm, UseFormProps } from 'react-hook-form'

import { usePrevious } from 'app/core/react/CustomHooks'
import { cloneDeep, get, isEqual } from 'lodash'

interface UseReduxFormParameters<T extends FieldValues> {
  isLoading?: boolean
  debounceTime?: number
  useFormProps?: UseFormProps<T>
}

const useReduxForm = <T extends Record<string, any>>(
  dataFromStore: T,
  updateStoreCallback: (key: keyof T, value: T[keyof T]) => void,
  params?: UseReduxFormParameters<T>,
) => {
  const { isLoading, useFormProps } = params ?? {}
  const [isReady, setIsReady] = useState(false)
  const form = useForm<T>(useFormProps)
  const formValues = form.watch()
  const prevFormValues = usePrevious(cloneDeep(formValues))

  const updateStore = (key: keyof T, value: T[keyof T]) => {
    updateStoreCallback(key, value)
  }

  useEffect(() => {
    if (!isLoading) {
      Object.entries(dataFromStore).forEach(([key, value]) => {
        if (!(key in formValues)) {
          // @ts-ignore
          form.register(key)
        }

        if (!isEqual(formValues[key], value)) {
          // @ts-ignore
          form.setValue(key, value)
        }
      })

      setIsReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, dataFromStore])

  useEffect(() => {
    if (!isLoading) {
      setIsReady(true)
    }

    return () => setIsReady(false)
  }, [isLoading])

  useEffect(() => {
    if (isReady) {
      Object.entries(formValues).forEach(([key, value]) => {
        if (!isEqual(get(prevFormValues, key), value)) {
          updateStore(key as keyof T, cloneDeep(value))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, updateStore, isReady])

  return form
}

export { useReduxForm }
