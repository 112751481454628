import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface CaseSubmittedModalState {
  caseId: Nullable<string>
  isOpen: boolean
  isSecondary: boolean
}

const INITIAL_STATE: CaseSubmittedModalState = {
  isOpen: false,
  isSecondary: false,
  caseId: null,
}

const caseSubmittedModalActions = {
  caseSubmittedModalMounted: createAction('@CASE_SUBMITTED_MODAL/CASE_SUBMITTED_MODAL_MOUNTED')(),
  closeModalClicked: createAction('@CASE_SUBMITTED_MODAL/CLOSE_MODAL_CLICKED')(),
  urlParamsParsed: createAction(
    '@CASE_SUBMITTED_MODAL/URL_PARAMS_PARSED',
  )<CaseSubmittedModalState>(),
  goToPrintPageClicked: createAction('@CASE_SUBMITTED_MODAL/GO_TO_PRINT_PAGE_CLICKED')(),
  saveAndGoToPrintPageEpic: createAction(
    '@CASE_SUBMITTED_MODAL/SAVE_AND_GO_TO_ANDPRINT_PAGE_CLICKED',
  )(),
}

type CaseSubmittedModalActions = ActionType<typeof caseSubmittedModalActions>

const caseSubmittedModalReducer = createReducer<CaseSubmittedModalState, CaseSubmittedModalActions>(
  INITIAL_STATE,
)
  .handleAction(caseSubmittedModalActions.closeModalClicked, (state) => ({
    ...state,
    caseId: null,
    isOpen: false,
  }))
  .handleAction(caseSubmittedModalActions.urlParamsParsed, (state, action) => ({
    ...state,
    isOpen: action.payload.isOpen,
    isSecondary: action.payload.isSecondary,
    caseId: action.payload.caseId,
  }))

const caseSubmittedModalSelectors = {
  isOpen: () => (state: RootState) => state.caseSubmittedModal.isOpen,
  getCaseId: () => (state: RootState) => state.caseSubmittedModal.caseId,
  isSecondary: () => (state: RootState) => state.caseSubmittedModal.isSecondary,
}

export {
  CaseSubmittedModalState,
  CaseSubmittedModalActions,
  caseSubmittedModalActions,
  caseSubmittedModalReducer,
  caseSubmittedModalSelectors,
}
