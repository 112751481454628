import { useSelector } from 'react-redux'

import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'

import { CanineMolarCheckbox, CanineMolarCheckboxProps } from './CanineMolarCheckbox'

const canineMolarDefaultConfigItems: CanineMolarCheckboxProps[] = [
  {
    inputKey: 'aPrelationshipDistalization',
    optionsKey: 'aPrelationshipDistalizationOptions',
    translationKey: 'caseWizard.casePrescription.anteriorPosteriorRelationship.doDistalization',
    testId: 'AnteriorPosteriorRelationship-CanineMolar-Distalization',
  },
  {
    inputKey: 'posteriorIPR',
    optionsKey: 'posteriorIPROptions',
    translationKey: 'caseWizard.casePrescription.anteriorPosteriorRelationship.posteriorIpr',
    testId: 'AnteriorPosteriorRelationship-CanineMolar-PosteriorIPR',
  },
]

const CanineMolarSettings = () => {
  const isV6Form = useSelector(caseWizardSelectors.isV6CaseVersion())
  let itemsToRender = canineMolarDefaultConfigItems

  if (isV6Form) {
    itemsToRender = [
      ...itemsToRender,
      {
        inputKey: 'mesialization',
        optionsKey: 'mesializationOptions',
        translationKey: 'caseWizard.casePrescription.anteriorPosteriorRelationship.mesialization',
        testId: 'AnteriorPosteriorRelationship-CanineMolar-mesialization',
      },
    ]
  }

  return (
    <>
      {itemsToRender.map(({ translationKey, inputKey, optionsKey, testId }) => (
        <CanineMolarCheckbox
          key={inputKey}
          translationKey={translationKey}
          inputKey={inputKey}
          optionsKey={optionsKey}
          testId={testId}
        />
      ))}
    </>
  )
}

export { CanineMolarSettings }
