import { AffectedArche } from 'app/core/domain/AffectedArches'
import { AlignerMaterial } from 'app/core/domain/AlignerMaterial'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { ClinicalPreferences } from 'app/core/domain/ClinicalPreferences'
import { CutoutType } from 'app/core/domain/CutoutType'
import { Gender } from 'app/core/domain/Gender'
import { PatientDetails } from 'app/core/domain/PatientDetails'
import { CrowdingOptions } from 'app/core/domain/PrimaryCasePreferences'
import { ResolveSpacingByAction } from 'app/core/domain/ResolveSpacingByAction'
import { ResolveSpacingByDirection } from 'app/core/domain/ResolveSpacingByDirection'
import { ResponseStatus, UpdateResult } from 'app/core/domain/UpdateResult'
import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { set } from 'lodash'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

import { CancelCase, CancellationType } from './steps/CasePrescription/CancelCase'
import { getMappedClinicalPreferencesHooks } from './utils'

interface GetClinicalPreferencesHooksSelectorResult {
  buttons: number[]
  hooks: number[]
  integratedHooks: number[]
}

const defaultCaseDraft: CaseDraft = {
  primaryCasePreferences: {
    alignerMaterial: AlignerMaterial.TruGEN,
    overbitePrimary: { biteLowerUpper: AffectedArche.BOTH },
    extractionTeeth: [],
    resolveCrowdingByLower: [CrowdingOptions.Expansion],
    resolveCrowdingByUpper: [CrowdingOptions.Expansion],
    biteRampTeeth: [],
    dontPlaceTeeth: [],
    dontMoveTeeth: [],
    resolveSpacingBy: {
      direction: ResolveSpacingByDirection.ANTERIOR,
      action: ResolveSpacingByAction.CLOSE_ALL_SPACES,
    },
  },
  patientDetails: {
    patientFirstName: '',
    patientMiddleName: '',
    patientLastName: '',
    gender: Gender.MALE,
  },
}

interface CaseWizardState {
  draft: CaseDraft
  activeFormIndex: number
  nextActiveFormIndex?: number
  maxFormsIndex: number
  loading: boolean
  clinicalPreferences?: ClinicalPreferences
  clinicalPreferencesForDoctor?: ClinicalPreferences
  isClinicalPreferencesModalOpened: boolean
  isCancelCaseModalOpened: boolean
  cancelCaseInfo: CancelCase
  isOfficeNotesModalState: boolean
  truGenTerms: Nullable<UpdateResult<string>>
  errorMessage: string
}

const INITIAL_STATE: CaseWizardState = {
  draft: { ...defaultCaseDraft },
  activeFormIndex: 0,
  maxFormsIndex: 0,
  loading: false,
  isClinicalPreferencesModalOpened: false,
  isCancelCaseModalOpened: false,
  cancelCaseInfo: { description: '', cancellationType: CancellationType.CHANGED_MIND },
  isOfficeNotesModalState: false,
  truGenTerms: null,
  errorMessage: '',
}

const caseWizardActions = {
  caseWizardMounted: createAction('@CASE_WIZARD/CASE_WIZARD_MOUNTED')<{ maxFormsIndex: number }>(),
  caseWizardUnmounted: createAction('@CASE_WIZARD/CASE_WIZARD_UNMOUNTED')(),
  //
  nextButtonClicked: createAction('@CASE_WIZARD/NEXT_BUTTON_CLICKED')(),
  prevButtonClicked: createAction('@CASE_WIZARD/PREV_BUTTON_CLICKED')(),
  caseStepChosen: createAction('@CASE_WIZARD/CASE_STEP_CHOSEN')<number>(),
  instantCaseStepChosen: createAction('@CASE_WIZARD/INSTANT_CASE_STEP_CHOSEN')<number>(),
  //
  createCaseButtonClicked: createAction(
    '@CASE_WIZARD/CREATE_CASE_BUTTON_CLICKED',
  )<PatientDetails>(),
  createCaseDraftRequestComplete: createAction('@CASE_WIZARD/CREATE_REQUEST_COMPLETE')<CaseDraft>(),
  openCancelDialogButtonClicked: createAction('@CASE_WIZARD/CANCEL_CASE_BUTTON_CLICKED')<boolean>(),
  cancelCaseUpdate: createAction('@CASE_WIZARD/CANCEL_CASE_UPDATE')<CancelCase>(),
  applyCancelButtonClicked: createAction('@CASE_WIZARD/CANCEL_CASE_REQUEST_SERVER')<CancelCase>(),
  //
  caseDraftPartUpdated: createAction('@CASE_WIZARD/CASE_DRAFT_PART_UPDATED')<{
    part?: any
    key: string
  }>(),
  updateCaseDraftRequested: createAction('@CASE_WIZARD/CASE_DRAFT_UPDATE')<CaseDraft>(),
  updateCaseDraftRequestComplete: createAction('@CASE_WIZARD/UPDATE_REQUEST_COMPLETE')<CaseDraft>(),
  //
  officeNotesButtonClicked: createAction(
    '@CASE_WIZARD/OFFICE_NOTES_MODAL_BUTTON_CLICKED',
  )<boolean>(),
  //
  caseIdRequested: createAction('@CASE_WIZARD/CASE_ID_REQUESTED')<{ id: string }>(),
  getCaseDraftRequestComplete: createAction('@CASE_WIZARD/CASE_DRAFT_RECEIVED')<CaseDraft>(),
  //
  requestFailed: createAction('@CASE_WIZARD/REQUEST_FAILED')<string>(),
  //
  removeErrorMessage: createAction('@CASE_WIZARD/REMOVE_ERROR_MESSAGE')(),
  clinicalPreferencesRequested: createAction('@CASE_WIZARD/CLINICAL_PREFERENCES_REQUESTED')<
    string | undefined
  >(),
  clinicalPreferencesReceived: createAction(
    '@CASE_WIZARD/CLINICAL_PREFERENCES_RECEIVED',
  )<ClinicalPreferences>(),
  clinicalPreferencesModalToggled: createAction(
    '@CASE_WIZARD/TOGGLE_CLINICAL_PREFERENCES_MODAL',
  )<boolean>(),
  //
  truGenTermsUnmounted: createAction('@CASE_WIZARD/TRUGEN_TERMS_UNMOUNTED')(),
  truGenTermsRequested: createAction('@CASE_WIZARD/TRUGEN_TERMS_REQUESTED')<{
    truGenPdfURL: string
  }>(),
  truGenTermsReceived: createAction('@CASE_WIZARD/TRUGEN_TERMS_RECEIVED')<string>(),
  truGenTermsFailed: createAction('@CASE_WIZARD/TRUGEN_TERMS_FAILED')<string>(),
  setLoadingStatus: createAction('@CASE_WIZARD/SET_LOADING_STATUS')<boolean>(),
}

type CaseWizardActions = ActionType<typeof caseWizardActions>

const caseWizardReducer = createReducer<CaseWizardState, CaseWizardActions>(INITIAL_STATE)
  .handleAction(caseWizardActions.caseWizardMounted, (state, action) => ({
    ...state,
    activeFormIndex: 0,
    maxFormsIndex: action.payload.maxFormsIndex,
  }))
  .handleAction(caseWizardActions.caseWizardUnmounted, (state) => ({
    ...state,
    draft: { ...defaultCaseDraft },
  }))
  //
  .handleAction(caseWizardActions.nextButtonClicked, (state) => ({
    ...state,
    nextActiveFormIndex:
      state.activeFormIndex < state.maxFormsIndex
        ? state.activeFormIndex + 1
        : state.activeFormIndex,
  }))
  .handleAction(caseWizardActions.prevButtonClicked, (state) => ({
    ...state,
    activeFormIndex: state.activeFormIndex > 0 ? state.activeFormIndex - 1 : 0,
  }))
  .handleAction(caseWizardActions.caseStepChosen, (state, action) => ({
    ...state,
    nextActiveFormIndex: action.payload,
  }))
  .handleAction(caseWizardActions.instantCaseStepChosen, (state, action) => ({
    ...state,
    activeFormIndex: action.payload,
  }))
  // CREATE CASE DRAFT
  .handleAction(caseWizardActions.createCaseButtonClicked, (state, action) => ({
    ...state,
    draft: {
      patientDetails: action.payload,
      primaryCasePreferences: {
        ...defaultCaseDraft.primaryCasePreferences,
      },
    },
  }))
  .handleAction(
    [
      caseWizardActions.createCaseDraftRequestComplete,
      caseWizardActions.updateCaseDraftRequestComplete,
    ],
    (state, action) => {
      const nextIndex = state.nextActiveFormIndex ?? state.activeFormIndex

      return {
        ...state,
        ...{
          draft: {
            ...state.draft,
            ...action.payload,
            id: state.draft && state.draft.id,
          },
          loading: false,
          errorMessage: '',
          nextActiveFormIndex: undefined,
          activeFormIndex: nextIndex,
        },
      }
    },
  )
  // GET CASE DRAFT
  .handleAction(caseWizardActions.caseIdRequested, (state, action) => ({
    ...state,
    draft: {
      id: action.payload.id,
      patientDetails: {},
      primaryCasePreferences: {
        ...defaultCaseDraft.primaryCasePreferences,
      },
    },
  }))
  //
  .handleAction(caseWizardActions.getCaseDraftRequestComplete, (state, action) => {
    const nextIndex = state.nextActiveFormIndex ?? state.activeFormIndex

    return {
      ...state,
      ...{
        draft: {
          ...state.draft,
          ...action.payload,
          id: state.draft && state.draft.id,
        },
        loading: false,
        errorMessage: '',
      },
      nextActiveFormIndex: undefined,
      activeFormIndex: nextIndex,
    }
  })
  .handleAction(caseWizardActions.requestFailed, (state, action) => ({
    ...state,
    loading: false,
    errorMessage: action.payload,
  }))
  .handleAction(caseWizardActions.removeErrorMessage, (state) => ({
    ...state,
    errorMessage: '',
  }))
  .handleAction([caseWizardActions.caseDraftPartUpdated], (state, action) => {
    const newDraft = { ...state.draft }

    set(newDraft, action.payload.key, action.payload.part)
    return { ...state, ...{ draft: newDraft } }
  })
  // CLINICAL PREFERENCES
  .handleAction([caseWizardActions.clinicalPreferencesReceived], (state, action) => ({
    ...state,
    clinicalPreferences: action.payload,
  }))
  //
  .handleAction([caseWizardActions.clinicalPreferencesModalToggled], (state, action) => ({
    ...state,
    isClinicalPreferencesModalOpened: action.payload,
  }))
  .handleAction([caseWizardActions.openCancelDialogButtonClicked], (state, action) => ({
    ...state,
    isCancelCaseModalOpened: action.payload,
  }))
  .handleAction([caseWizardActions.cancelCaseUpdate], (state, action) => ({
    ...state,
    cancelCaseInfo: action.payload,
  }))
  .handleAction([caseWizardActions.officeNotesButtonClicked], (state, action) => ({
    ...state,
    isOfficeNotesModalState: action.payload,
  }))
  .handleAction([caseWizardActions.truGenTermsUnmounted], (state) => ({
    ...state,
    truGenTerms: null,
  }))
  .handleAction([caseWizardActions.truGenTermsReceived], (state, action) => ({
    ...state,
    truGenTerms: {
      status: ResponseStatus.OK,
      message: 'truGen.modal.title',
      payload: action.payload,
    },
  }))
  .handleAction([caseWizardActions.truGenTermsFailed], (state) => ({
    ...state,
    truGenTerms: {
      status: ResponseStatus.BAD_REQUEST,
      message: 'truGen.modal.error',
    },
  }))
  .handleAction([caseWizardActions.setLoadingStatus], (state, action) => ({
    ...state,
    loading: action.payload,
  }))

const caseWizardSelectors = {
  // FORM WIZARD
  getActiveFormIndex: () => (state: RootState) => state.caseWizard.activeFormIndex,
  getNextActiveFormIndex: () => (state: RootState) => state.caseWizard.nextActiveFormIndex,
  // FORM DATA
  getDraftId: () => (state: RootState) => state.caseWizard.draft.patientDetails.caseId,
  getFormData: () => (state: RootState) => state.caseWizard,
  getDraftData: () => (state: RootState) => state.caseWizard.draft,
  getTreatArches: () => (state: RootState) =>
    state.caseWizard.draft.primaryCasePreferences.treatArches,
  getTreatmentType: () => (state: RootState) => state.caseWizard.draft.patientDetails.treatmentType,
  getFormPersonalInfo: () => (state: RootState) => state.caseWizard.draft.patientDetails,
  getClinicalPreferences: () => (state: RootState) => state.caseWizard.clinicalPreferences,
  getClinicalPreferencesHooks:
    (hooksClass: CutoutType) =>
    (state: RootState): GetClinicalPreferencesHooksSelectorResult => {
      const features = state.caseWizard.clinicalPreferences?.features
      let result: GetClinicalPreferencesHooksSelectorResult = {
        buttons: [],
        hooks: [],
        integratedHooks: [],
      }

      if (!features) {
        return result
      }

      if (hooksClass === CutoutType.CLASSII) {
        result = getMappedClinicalPreferencesHooks({
          buttons: features.classIIButtons,
          distalHooks: features.classIIDistalHooks,
          mesialHooks: features.classIIMesialHooks,
          integratedHooks: features.classIIIntegratedHooks,
        })
      }

      if (hooksClass === CutoutType.CLASSIII) {
        result = getMappedClinicalPreferencesHooks({
          buttons: features.classIIIButtons,
          distalHooks: features.classIIIDistalHooks,
          mesialHooks: features.classIIIMesialHooks,
          integratedHooks: features.classIIIIntegratedHooks,
        })
      }

      return result
    },
  getModalState: () => (state: RootState) => state.caseWizard.isClinicalPreferencesModalOpened,
  getCancelModalState: () => (state: RootState) => state.caseWizard.isCancelCaseModalOpened,
  getCancelCaseInfo: () => (state: RootState) => state.caseWizard.cancelCaseInfo,
  getOfficeNotesModalState: () => (state: RootState) => state.caseWizard.isOfficeNotesModalState,
  getLoadingStatus: () => (state: RootState) => state.caseWizard.loading,
  getTruGenTerms: () => (state: RootState) => state.caseWizard.truGenTerms,
  getErrorMessage: () => (state: RootState) => state.caseWizard.errorMessage,
  getVersion: () => (state: RootState) =>
    state.caseWizard.draft.primaryCasePreferences?.versionNumber,
  isV6CaseVersion: () => (state: RootState) =>
    state.caseWizard.draft.primaryCasePreferences?.versionNumber === '6',
}

export {
  CaseWizardState,
  caseWizardActions,
  caseWizardReducer,
  caseWizardSelectors,
  CaseWizardActions,
  defaultCaseDraft,
}
