import { Controller } from 'react-hook-form'

import { StaffInput } from 'app/components/ui/Form/StaffInput'

interface AddressInputProps {
  name: string
  rules?: Object
}

const AddressInput = (props: AddressInputProps) => (
  <Controller
    render={({ field }) => (
      <StaffInput
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value}
      />
    )}
    name={props.name}
    rules={props.rules}
  />
)

export { AddressInput }
