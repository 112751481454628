type FormDataValues = Record<string, string>

export function createFormData(formValues: FormDataValues): FormData {
  const formData = new FormData()

  for (const [key, value] of Object.entries(formValues)) {
    formData.set(key, value)
  }

  return formData
}
