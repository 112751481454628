import React from 'react'

import { SolutionOutlined } from '@ant-design/icons'
import { Box } from 'app/components/ui/SDS/common/Box'

import { DropdownItem } from '../DropDownItem'
import { HeaderNavigationDropdown } from '../HeaderNavigationDropdown'
import { HeaderNavigationLink } from '../HeaderNavigationLink'

import { MenuProps } from './interfaces'

export const HorizontalMenu: React.FC<MenuProps> = ({
  items,
  isDark,
  'data-testid': dataTestId,
  dropdownMenuTheme,
}) => {
  const menu = items?.map((item) => {
    const { key } = item

    if ('options' in item) {
      const { options, label } = item
      const dropDownItemsList = options.map((option) => ({
        key: option.key,
        label: (
          <DropdownItem
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...option}
            isDark={dropdownMenuTheme ? dropdownMenuTheme === 'dark' : isDark}
          >
            {option.label}
          </DropdownItem>
        ),
      }))

      const activeDropdownItem = options.find(
        (menuOption) => location.pathname.indexOf(menuOption.href) !== -1,
      )

      return (
        <HeaderNavigationDropdown
          key={key}
          menu={{
            items: dropDownItemsList,
            selectable: true,
            selectedKeys: [activeDropdownItem ? `${activeDropdownItem.key}` : ''],
          }}
          isActive={!!activeDropdownItem}
          icon={item.icon}
          labelText={label}
          data-testid={`${dataTestId}-patients`}
          isDark={isDark}
          dropdownMenuTheme={dropdownMenuTheme}
        />
      )
    } else if (item.href) {
      const { label, href = '', isActive = false, isLocalRoute } = item

      return (
        <HeaderNavigationLink
          key={key}
          isLocalRoute={isLocalRoute}
          icon={<SolutionOutlined />}
          data-testid={`${dataTestId}-patients`}
          labelText={label}
          href={href}
          isActive={isActive}
          isDark={isDark}
        />
      )
    } else {
      return item.label
    }
  })

  return items ? (
    <Box width="auto" height="58px" backgroundColor={isDark ? 'primary700' : 'white'}>
      {menu}
    </Box>
  ) : null
}
