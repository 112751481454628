import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Address } from 'app/core/domain/Address'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { useDidMount } from 'app/core/react/CustomHooks'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

import { AddressSummary } from './AddressSummary/AddressSummary'
import { DefaultBillingAddress } from './DefaultBillingAddress'
import { DefaultShippingAddress } from './DefaultShippingAddress'
import { OfficeAddress } from './OfficeAddress'
import { PhoneNumber } from './PhoneNumber'
import { addressSelectors } from './logic'

const AddressDefaultsUpdate = () => {
  const addressResult = useSelector(addressSelectors.getAddress())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const form = useFormContext<Address>()
  const addressFetchNetworkStatus = useSelector(addressSelectors.getAddressNetworkStatus())

  useDidMount(() => {
    if (addressFetchNetworkStatus === ResponseStatus.OK && addressResult.id !== undefined) {
      form.register('isBillTo')
      form.register('isShipTo')
      form.register('isOffice')
      form.register('phoneNumber')
      form.setValue('isBillTo', addressResult.isBillTo)
      form.setValue('isShipTo', addressResult.isShipTo)
      form.setValue('isOffice', addressResult.isOffice)
      form.setValue('phoneNumber', addressResult.phoneNumber)
    }

    //TODO: move address checkboxes to common styled component, since for now they are duplicated a lot
    return () => {
      if (addressFetchNetworkStatus === ResponseStatus.OK && addressResult.id !== undefined) {
        form.unregister('isBillTo')
        form.unregister('isShipTo')
        form.unregister('isOffice')
        form.unregister('phoneNumber')
      }
    }
  })
  return (
    <>
      <AddressSummary />
      {featurePermissions?.doctorLocator && <PhoneNumber />}
      <Box marginBottom="32px" display="block">
        <DefaultBillingAddress />
        <DefaultShippingAddress />
        {featurePermissions?.doctorLocator && <OfficeAddress />}
      </Box>
      <AddressDefaultsUpdateNote
        variant="small"
        padding="16px"
        textFontWeight="600"
        color="base800"
        letterSpacing="0.1px"
        wordBreak="break-word"
      >
        <FormattedMessage id="edit.address.note" />
      </AddressDefaultsUpdateNote>
    </>
  )
}

const AddressDefaultsUpdateNote = styled(Typography)`
  margin-bottom: 52.5px;

  overflow: hidden;

  width: 320px;

  background-color: ${({ theme: styledTheme }) => styledTheme.colors.base100};
  border-radius: 4px;
`

export { AddressDefaultsUpdate }
