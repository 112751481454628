import React from 'react'
import { FormattedMessage } from 'react-intl'

import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { Typography } from 'app/components/ui/SDS/common/Typography'

const AssociatedUsersTooltip = () => (
  <OrmcoTooltip
    title={<AssociatedUsersHelpMessage />}
    placement="top"
    overlayInnerStyle={{
      padding: '11px',
      marginLeft: '12px',
    }}
  >
    <HelpIcon data-testid="AssociatedDoctors-HelpIcon" />
  </OrmcoTooltip>
)

const AssociatedUsersHelpMessage = () => {
  return (
    <>
      <Typography variant="small" color="white" align="justify">
        <FormattedMessage id="staff.staffDetails.associatedUsers.help.message1" />
      </Typography>
      <Typography variant="small" color="white" align="justify">
        <FormattedMessage id="staff.staffDetails.associatedUsers.help.message2" />
      </Typography>
      <Typography variant="small" color="white" align="justify">
        <FormattedMessage id="staff.staffDetails.associatedUsers.help.message3" />
      </Typography>
    </>
  )
}

export { AssociatedUsersTooltip }
