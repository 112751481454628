import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import styled from 'styled-components'

const OrmcoSelect = styled(Select)<SelectProps<any>>`
  border: 1px solid ${({ theme }) => theme.colors.base800};

  border-radius: 4px;

  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary800};
  }

  &:not(.ant-select-customize-input) > .ant-select-selector {
    border: none;
  }

  .ant-select-selection-item,
  .ant-select-arrow {
    pointer-events: none;
  }

  && .ant-select-selection-search {
    right: 0;
    left: 0;
  }
`

export { OrmcoSelect }
