import { ResponseStatus, UpdateResult } from 'app/core/domain/UpdateResult'
import { UserProfilePassword, PasswordValidationCode } from 'app/core/domain/UserProfilePassword'
import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface ChangePasswordModalState {
  isOpen: boolean
  userProfilePassword: UserProfilePassword
  updateResult: Nullable<UpdateResult<PasswordValidationCode[]>>
}

interface ChangePasswordFailedRequestPayload {
  errorCodes: PasswordValidationCode[]
}

const INITIAL_STATE: ChangePasswordModalState = {
  isOpen: false,
  userProfilePassword: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  },
  updateResult: null,
}

const changePasswordModalActions = {
  changePasswordRequestComplete: createAction(
    '@CHANGE_PASSWORD_MODAL/CHANGE_PASSWORD_REQUEST_COMPLETE',
  )(),
  changePasswordRequestFailed: createAction(
    '@CHANGE_PASSWORD_MODAL/CHANGE_PASSWORD_REQUEST_FAILED',
  )<ChangePasswordFailedRequestPayload>(),
  updateButtonClicked: createAction(
    '@CHANGE_PASSWORD_MODAL/UPDATE_BUTTON_CLICKED',
  )<UserProfilePassword>(),
  closeModalClicked: createAction('@CHANGE_PASSWORD_MODAL/CLOSE_MODAL_CLICKED')(),
  changePasswordModalTriggered: createAction(
    '@CHANGE_PASSWORD_MODAL/CHANGE_PASSWORD_MODAL_TRIGGERED',
  )(),
  passwordDetailsUnmounted: createAction('@CHANGE_PASSWORD_MODAL/PASSWORD_DETAILS_UNMOUNTED')(),
  changePasswordUnmounted: createAction('@CHANGE_PASSWORD_MODAL/CHANGE_PASSWORD_UNMOUNTED')(),
}

type ChangePasswordModalActions = ActionType<typeof changePasswordModalActions>

const changePasswordModalReducer = createReducer<
  ChangePasswordModalState,
  ChangePasswordModalActions
>(INITIAL_STATE)
  .handleAction(changePasswordModalActions.changePasswordModalTriggered, (state) => ({
    ...state,
    isOpen: true,
  }))
  .handleAction(
    [
      changePasswordModalActions.closeModalClicked,
      changePasswordModalActions.passwordDetailsUnmounted,
    ],
    (state) => ({
      ...state,
      isOpen: false,
    }),
  )
  .handleAction([changePasswordModalActions.updateButtonClicked], (state, action) => ({
    ...state,
    userProfilePassword: action.payload,
  }))
  .handleAction(changePasswordModalActions.changePasswordUnmounted, (state) => ({
    ...state,
    updateResult: null,
  }))
  .handleAction(changePasswordModalActions.changePasswordRequestComplete, (state) => ({
    ...state,
    updateResult: { status: ResponseStatus.OK, message: 'new.password.success' },
  }))
  .handleAction(changePasswordModalActions.changePasswordRequestFailed, (state, action) => ({
    ...state,
    updateResult: {
      status: ResponseStatus.BAD_REQUEST,
      message: 'new.password.failure',
      payload: action.payload.errorCodes,
    },
  }))

const changePasswordModalSelectors = {
  isOpen: () => (state: RootState) => state.changePasswordModal.isOpen,
  getPasswordDetails: () => (state: RootState) => state.changePasswordModal.userProfilePassword,
  getPasswordUpdateResult: () => (state: RootState) => state.changePasswordModal.updateResult,
}

export {
  ChangePasswordModalState,
  ChangePasswordModalActions,
  ChangePasswordFailedRequestPayload,
  changePasswordModalActions,
  changePasswordModalReducer,
  changePasswordModalSelectors,
}
