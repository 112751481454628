import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PrimaryButton } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface ErrorModalProps {
  isOpen: boolean
  onClose: () => void
  headerText: string
  bodyText: string
}

const ErrorModal: React.FC<ErrorModalProps> = (props) => (
  <OrmcoModal
    isOpen={props.isOpen}
    onRequestClose={props.onClose}
    additionalStyles={{
      content: {
        top: '40%',
        padding: '13px',
      },
      overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
    }}
    shouldCloseOnOverlayClick={false}
    data-testId="ErrorModal"
  >
    <ModalHeader data-testid="ErrorModal-Header">
      <Typography component="h2" variant="h4" textFontWeight="600" letterSpacing="0.25px">
        {props.headerText}
      </Typography>

      <Button type="text" onClick={props.onClose} data-testid="ErrorModal-Close">
        <Cancel />
      </Button>
    </ModalHeader>

    <Typography
      data-testid="ErrorModal-Body"
      padding="15px"
      variant="medium"
      textFontWeight="600"
      color="primary900"
    >
      {props.bodyText}
    </Typography>

    <ModalFooter>
      <PrimaryButton as="button" onClick={props.onClose} data-testid="ErrorModal-Cancel">
        <FormattedMessage id="button.ok" />
      </PrimaryButton>
    </ModalFooter>
  </OrmcoModal>
)

export { ErrorModal }

const ModalHeader = styled(OrmcoModalHeader)`
  padding: 10px 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 0;
`

const ModalFooter = styled(OrmcoModalFooter)`
  justify-content: flex-end;

  border-top: 0;
`
