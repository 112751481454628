import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { TruGenTooltip } from 'app/components/routes/CaseWizard/steps/CasePrescription/TruGenTooltip'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { AlignerMaterial } from 'app/core/domain/AlignerMaterial'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

const PROPERTY_KEY = 'primaryCasePreferences.alignerMaterial'

const TruGenRadioButtons = () => {
  const form = useFormContext<CaseDraft>()
  const theme = useContext(ThemeContext)
  const language = useSelector(appSelectors.getLanguage())
  const webContent = useSelector(appSelectors.getWebContentByLanguage(language))
  const alignerMaterialValue = form.getValues(PROPERTY_KEY)

  const changeRadio = (res: AlignerMaterial) => {
    form.setValue(PROPERTY_KEY, res)
  }

  return (
    <ThemedBox contextTheme={theme}>
      <OrmcoFormItemVertical
        label={<TruGenTooltip />}
        hasFeedback
        data-testid="TruGenRadioButtons-OrmcoFormItemVertical"
      >
        <VerticalBlock>
          <OrmcoRadio
            onChange={() => changeRadio(AlignerMaterial.TruGEN_XR)}
            margin="0"
            checked={alignerMaterialValue && alignerMaterialValue === AlignerMaterial.TruGEN_XR}
            data-testid="CasePrescription-Product-TrugenXR"
          >
            <Typography component="span">
              {webContent.patient_primary_prescription__material__truegen}
            </Typography>
          </OrmcoRadio>
        </VerticalBlock>
        <Vertical>
          <OrmcoRadio
            onChange={() => changeRadio(AlignerMaterial.TruGEN)}
            checked={alignerMaterialValue && alignerMaterialValue === AlignerMaterial.TruGEN}
            data-testid="CasePrescription-Product-Trugen"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.productTier.trugen.label" />
            </Typography>
          </OrmcoRadio>
        </Vertical>
      </OrmcoFormItemVertical>
    </ThemedBox>
  )
}

export { TruGenRadioButtons }

const VerticalBlock = styled(Vertical)`
  display: block;
`
