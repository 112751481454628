import { FormattedMessage } from 'react-intl'

import { Col, Select } from 'antd'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { MolarTubeType, MolarTubeTypeLocalizationKey } from 'app/core/domain/MolarTubeType'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'

import { MolarTubeSelector } from './MolarTubeSelector'
import { getMolarTubeKeys } from './molarTubeKeys'
import { useIsArchDisabled } from './useIsArchDisabled'

interface ArchMolarTubesProps {
  arch: TreatArches
}

export const ArchMolarTubes = ({ arch }: ArchMolarTubesProps) => {
  const { Option } = Select

  const molarTubeKeys = getMolarTubeKeys(arch)
  const isDisabled = useIsArchDisabled(arch)

  return (
    <Col span={12}>
      <Typography component="label" textFontWeight="600" margin="0 0 16px">
        <FormattedMessage id="caseWizard.casePrescription.arche" values={{ arch }} />
      </Typography>

      <MolarTubeSelector
        disabled={isDisabled}
        molarTubeNameId="caseWizard.casePrescription.molarTubes.firstMolarTube"
        molarTubeKey={molarTubeKeys?.firstMolarKey as keyof CaseDraft}
      >
        <>
          <Option value={MolarTubeType.SNAPLINK_TUBE}>
            <FormattedMessage
              id="caseWizard.casePrescription.molarTubes"
              values={{ molarTubes: MolarTubeTypeLocalizationKey.SNAPLINK_TUBE }}
            />
          </Option>
          <Option value={MolarTubeType.ACCENT_MINI_TUBE}>
            <FormattedMessage
              id="caseWizard.casePrescription.molarTubes"
              values={{ molarTubes: MolarTubeTypeLocalizationKey.ACCENT_MINI_TUBE }}
            />
          </Option>
        </>
      </MolarTubeSelector>

      <MolarTubeSelector
        disabled={isDisabled}
        molarTubeNameId="caseWizard.casePrescription.molarTubes.secondMolarTube"
        molarTubeKey={molarTubeKeys?.secondMolarKey as keyof CaseDraft}
      >
        <Option value={MolarTubeType.ACCENT_MINI_TUBE}>
          <FormattedMessage
            id="caseWizard.casePrescription.molarTubes"
            values={{ molarTubes: MolarTubeTypeLocalizationKey.ACCENT_MINI_TUBE }}
          />
        </Option>
      </MolarTubeSelector>
    </Col>
  )
}
