import { PropsWithChildren } from 'react'

import { Tooltip as AntdTooltip } from 'antd'
import { sdsTheme } from 'app/components/ui/SDS'

import { TooltipColorSchemeType, TooltipProps } from './interfaces'
import { SdsToolTipChildContainer } from './tooltip.styles'

const TooltipBgColorScheme: TooltipColorSchemeType = {
  black: sdsTheme.colors.base800,
  light: sdsTheme.colors.primary10,
  ormco: sdsTheme.colors.primary700,
  alert: sdsTheme.colors.danger400,
}

const tooltipTextColor = sdsTheme.colors.white
const promptTextColor = sdsTheme.colors.base900

export const Tooltip = ({
  children,
  colorScheme = 'black',
  ...restProps
}: PropsWithChildren<TooltipProps>) => (
  <AntdTooltip
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...restProps}
    color={TooltipBgColorScheme[colorScheme]}
    overlayClassName="sds-tooltip"
    overlayInnerStyle={{
      color: colorScheme === 'light' ? promptTextColor : tooltipTextColor,
    }}
  >
    <SdsToolTipChildContainer>{children}</SdsToolTipChildContainer>
  </AntdTooltip>
)
