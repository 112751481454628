import { EffectCallback, useEffect, useRef, useState } from 'react'

// eslint-disable-next-line
const useDidMount = (effect: EffectCallback) => useEffect(effect, [])

const usePrevious = <T>(value: T) => {
  const ref = useRef<T>(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useEffectSomeDepsChange(fn: EffectCallback, dependenciesToTrigger?: Array<any>) {
  const [changeTarget, setChangeTarget] = useState(dependenciesToTrigger)

  useEffect(() => {
    setChangeTarget((prev) => {
      if (
        prev &&
        dependenciesToTrigger &&
        prev.some((dep, i) => dep !== dependenciesToTrigger[i])
      ) {
        return dependenciesToTrigger
      }

      return prev
    })
  }, [dependenciesToTrigger])

  // eslint-disable-next-line
  useEffect(fn, changeTarget)
}

export { useDidMount, usePrevious, useEffectSomeDepsChange }
