import React from 'react'

import { ErrorMessage } from '@hookform/error-message'
import { FieldErrors } from 'react-hook-form/dist/types'

import { Box } from '../SDS/common/Box'
import { Typography } from '../SDS/common/Typography'

interface OrmcoFormErrorMessageProps {
  errors: FieldErrors<any>
  name: string
}

const OrmcoFormErrorMessage: React.FC<OrmcoFormErrorMessageProps> = (props) => (
  <Box display="block">
    <ErrorMessage
      errors={props.errors}
      name={props.name}
      render={({ message }) => (
        <Typography data-testid="OrmcoForm-ErrorMessage" variant="small" color="danger400">
          {message}
        </Typography>
      )}
    />
  </Box>
)

export { OrmcoFormErrorMessage }
