import { Form, FormItemProps } from 'antd'
import styled from 'styled-components'

interface OrmcoFormItemVerticalProps extends FormItemProps {
  'data-testid'?: string
  marginBottom?: string
}

const StaffFormItemVertical: React.FC<OrmcoFormItemVerticalProps> = (props) => {
  const { label, help, children } = props

  const marginBottom = props.marginBottom ?? !!help ? '0' : undefined

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <OrmcoFormItemVerticalItem {...props} marginBottom={marginBottom} label={label}>
      {children}
    </OrmcoFormItemVerticalItem>
  )
}

const OrmcoFormItemVerticalItem = styled(Form.Item).withConfig<OrmcoFormItemVerticalProps>({
  shouldForwardProp: (propName) => propName !== 'marginBottom',
})`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;

    margin-bottom: ${({ marginBottom }) => marginBottom ?? '24px'};

    .ant-row,
    .ant-form-item-label,
    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-no-colon {
      width: 100%;
      height: 24px;
    }

    .anticon-close-circle {
      display: none;
    }
  }
`

export { StaffFormItemVertical }
