import { TreatmentType } from '../domain/PrimaryCasePreferences'

export const getCaseDraftUpdateUrl = (
  id: string,
  username: string,
  treatmentType: TreatmentType | undefined,
) => {
  return treatmentType === TreatmentType.IDB
    ? `/api/v1/doctors/${username}/cases/${id}/idb`
    : `/api/v1/doctors/${username}/cases/${id}`
}
