import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { LabeledTooltip } from 'app/components/ui/Tooltip/LabeledTooltip'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { featureFlagSelectors } from 'app/logic/features/logic'

import { ArchesToTreatDataTestIdMap } from './ArchesToTreatDataTestIdMap'

const PROPERTY_KEY = 'primaryCasePreferences.treatArches'

interface ArchesTreatRadioProps {
  value: string
}

const ArchesToTreatRadio: React.FC<ArchesTreatRadioProps> = (props) => {
  const form = useFormContext<CaseDraft>()
  const selectedValue = form.getValues(PROPERTY_KEY)
  const isTooltipDisabled =
    !ValuesWithTooltip.some((archType) => archType === props.value) ||
    selectedValue?.toString() !== props.value
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  const text = (
    <FormattedMessage
      id="caseWizard.casePrescription.affectedArche"
      values={{ affectedArche: props.value }}
    />
  )

  return (
    <OrmcoRadio
      value={props.value}
      disabled={Boolean(featurePermissions?.disableArchesToTreat)}
      data-testid={ArchesToTreatDataTestIdMap[props.value]}
    >
      <LabeledTooltip
        data-testid="ArchesToTreat-Tooltip"
        disabled={isTooltipDisabled}
        text={text}
        title={
          <TooltipText
            data-testid="ArchesTreat-TooltipContent"
            textId="caseWizard.casePrescription.occlusion"
          />
        }
        align={{
          offset: [25, 40],
          targetOffset: [30, 40],
          overflow: { adjustX: true, adjustY: true },
        }}
        overlayInnerStyle={{
          textAlign: 'justify',
          padding: '11px',
        }}
      >
        <HelpIcon data-testid="ArchesTreat-TooltipIcon" />
      </LabeledTooltip>
    </OrmcoRadio>
  )
}

const ValuesWithTooltip = [TreatArches.UPPER_ONLY, TreatArches.LOWER_ONLY] as const

export { ArchesToTreatRadio }
