import {
  changePasswordModalActions,
  changePasswordModalSelectors,
} from 'app/components/routes/MyProfile/ChangePasswordModal/logic'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const changePasswordEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([changePasswordModalActions.updateButtonClicked])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const passwordData = changePasswordModalSelectors.getPasswordDetails()(state$.value)

      return from(axios.put(`/api/v1/users/${username}/password`, { ...passwordData })).pipe(
        switchMap(() => of(changePasswordModalActions.changePasswordRequestComplete())),
        catchError((err) =>
          of(changePasswordModalActions.changePasswordRequestFailed(err.response.data)),
        ),
      )
    }),
  )

const changePwdEpic = combineEpics(changePasswordEpic)

export { changePwdEpic }
