import { ReactComponent as USPerksInsiderIcon } from 'app/resources/img/icons/US-perks-Insider.svg'
import { ReactComponent as USPerksVIPIcon } from 'app/resources/img/icons/US-perks-VIP.svg'
import { ReactComponent as USPerksEliteIcon } from 'app/resources/img/icons/US-perks-elite.svg'
import { ReactComponent as ActionRequiredIcon } from 'app/resources/img/icons/action-required.svg'
import { ReactComponent as AddPatientInfoIcon } from 'app/resources/img/icons/add-patient-info.svg'
import { ReactComponent as ArchiveIcon } from 'app/resources/img/icons/archive.svg'
import { ReactComponent as ArrowLeftIcon } from 'app/resources/img/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'app/resources/img/icons/arrow-right.svg'
import { ReactComponent as AuxButtonIcon } from 'app/resources/img/icons/button.svg'
import { ReactComponent as CancelIcon } from 'app/resources/img/icons/cancel.svg'
import { ReactComponent as CreditCardIcon } from 'app/resources/img/icons/credit-card.svg'
import { ReactComponent as CrossRoundedIcon } from 'app/resources/img/icons/cross-rounded.svg'
import { ReactComponent as DesktopIcon } from 'app/resources/img/icons/desktop.svg'
import { ReactComponent as EditIcon } from 'app/resources/img/icons/edit.svg'
import { ReactComponent as ErrorIcon } from 'app/resources/img/icons/error.svg'
import { ReactComponent as EyeOutlinedIcon } from 'app/resources/img/icons/eye-symbol-outlined.svg'
import { ReactComponent as HelpFilledIcon } from 'app/resources/img/icons/help-filled.svg'
import { ReactComponent as HelpIcon } from 'app/resources/img/icons/help.svg'
import { ReactComponent as InProgressIcon } from 'app/resources/img/icons/in-progress.svg'
import { ReactComponent as IntegratedHookIcon } from 'app/resources/img/icons/integrated-hook.svg'
import { ReactComponent as LanguageIcon } from 'app/resources/img/icons/language.svg'
import { ReactComponent as OfficeAddressIcon } from 'app/resources/img/icons/office-address.svg'
import { ReactComponent as OrmcoDTXLogoIcon } from 'app/resources/img/icons/ormco-dtx-logo.svg'
import { ReactComponent as PatIcon } from 'app/resources/img/icons/patient-text.svg'
import { ReactComponent as PDFIcon } from 'app/resources/img/icons/pdf.svg'
import { ReactComponent as PhoneIcon } from 'app/resources/img/icons/phone.svg'
import { ReactComponent as PinnacleIcon } from 'app/resources/img/icons/pinnacle.svg'
import { ReactComponent as SapphireIIcon } from 'app/resources/img/icons/sapphire-I.svg'
import { ReactComponent as SapphireIIIcon } from 'app/resources/img/icons/sapphire-II.svg'
import { ReactComponent as SapphireIIIIcon } from 'app/resources/img/icons/sapphire-III.svg'
import { ReactComponent as SapphireEliteIcon } from 'app/resources/img/icons/sapphire-elite.svg'
import { ReactComponent as SapphirePlusIcon } from 'app/resources/img/icons/sapphire-plus.svg'
import { ReactComponent as ShippingIcon } from 'app/resources/img/icons/shipping.svg'
import { ReactComponent as SpinnerIcon } from 'app/resources/img/icons/spinner.svg'
import { ReactComponent as StandardPersonIcon } from 'app/resources/img/icons/standard-person.svg'
import { ReactComponent as SubmitCaseIcon } from 'app/resources/img/icons/submit-case.svg'
import { ReactComponent as TruckIcon } from 'app/resources/img/icons/truck.svg'
import { ReactComponent as WebIcon } from 'app/resources/img/icons/web.svg'

import { createSVGIcon } from './misc'

const AuxButton = createSVGIcon(AuxButtonIcon, '13px')
const ActionRequired = createSVGIcon(ActionRequiredIcon, '24px')
const Archived = createSVGIcon(ArchiveIcon, '24px')
const Cancel = createSVGIcon(CancelIcon, '16px')
const Desktop = createSVGIcon(DesktopIcon, '12px')
const Error = createSVGIcon(ErrorIcon, '16px')
const Help = createSVGIcon(HelpIcon, '24px')
const HelpFilled = createSVGIcon(HelpFilledIcon, '24px')
const InProgress = createSVGIcon(InProgressIcon, '24px')
const Language = createSVGIcon(LanguageIcon, '16px')
const PDF = createSVGIcon(PDFIcon, '16px')
const Edit = createSVGIcon(EditIcon, '16px')
const Phone = createSVGIcon(PhoneIcon, '16px')
const Pinnacle = createSVGIcon(PinnacleIcon, '64px')
const SapphireElite = createSVGIcon(SapphireEliteIcon, '64px')
const SapphireI = createSVGIcon(SapphireIIcon, '64px')
const SapphireII = createSVGIcon(SapphireIIIcon, '64px')
const SapphireIII = createSVGIcon(SapphireIIIIcon, '64px')
const SapphirePlus = createSVGIcon(SapphirePlusIcon, '64px')
const Truck = createSVGIcon(TruckIcon, '32px')
const SubmitCase = createSVGIcon(SubmitCaseIcon, '16px')
const USPerksElite = createSVGIcon(USPerksEliteIcon, '64px')
const USPerksInsider = createSVGIcon(USPerksInsiderIcon, '32px')
const USPerksVIP = createSVGIcon(USPerksVIPIcon, '64px')
const ArrowLeft = createSVGIcon(ArrowLeftIcon, '32px')
const ArrowRight = createSVGIcon(ArrowRightIcon, '32px')
const PatientText = createSVGIcon(PatIcon, '24px')
const Spinner = createSVGIcon(SpinnerIcon, '100%')
const CreditCard = createSVGIcon(CreditCardIcon, '20px')
const OfficeAddress = createSVGIcon(OfficeAddressIcon, '20px')
const Shipping = createSVGIcon(ShippingIcon, '20px')
const AddPatientInfo = createSVGIcon(AddPatientInfoIcon, '200px')
const OrmcoDTXLogo = createSVGIcon(OrmcoDTXLogoIcon, '170px')
const StandardPerson = createSVGIcon(StandardPersonIcon, '64px')
const AuxIntegratedHook = createSVGIcon(IntegratedHookIcon, '16px')
const CrossRounded = createSVGIcon(CrossRoundedIcon, '14px')
const EyeOutlined = createSVGIcon(EyeOutlinedIcon, '16px')
const Web = createSVGIcon(WebIcon, '12px')

export {
  AuxButton,
  ActionRequired,
  Archived,
  Cancel,
  Desktop,
  Error,
  Help,
  HelpFilled,
  InProgress,
  Language,
  PDF,
  Edit,
  Phone,
  Pinnacle,
  SapphireElite,
  SapphireI,
  SapphireII,
  SapphireIII,
  SapphirePlus,
  Truck,
  SubmitCase,
  USPerksElite,
  USPerksInsider,
  USPerksVIP,
  ArrowLeft,
  ArrowRight,
  PatientText,
  Spinner,
  CreditCard,
  OfficeAddress,
  Shipping,
  AddPatientInfo,
  OrmcoDTXLogo,
  StandardPerson,
  AuxIntegratedHook,
  CrossRounded,
  EyeOutlined,
  Web,
}
