const getCookie = (cname: string) => {
  const name = `${cname}=`
  const cookie =
    decodeURIComponent(document.cookie)
      .split(';')
      .find((item) => item.indexOf(name) !== -1) ?? ''

  return cookie.replace(/^\s+/, '').substring(name.length, cookie.length)
}

const setCookie = (cookieString: string) => {
  document.cookie = cookieString
}

export { getCookie, setCookie }
