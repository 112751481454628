import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

import { AuxComponent } from './AuxComponent'

interface IdbAuxComponentProps extends Pick<AuxComponent<string>, 'Icon' | 'type'> {}

export const IdbAuxComponent = (props: IdbAuxComponentProps) => {
  const { type, Icon } = props

  return (
    <IconContainer data-type={type} width="100%" height="100%">
      <Icon width="16px" />
    </IconContainer>
  )
}

const IconContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
`
