import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { EyeOutlined, CrossRounded, Cancel } from 'app/components/ui/Icons/common'
import { BrandindModalHeader } from 'app/components/ui/Modal/Branding/BrandindModalHeader'
import { BrandindModalBody } from 'app/components/ui/Modal/Branding/BrandingModalBody'
import { BrandingModalFooter } from 'app/components/ui/Modal/Branding/BrandingModalFooter'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { useDidMount } from 'app/core/react/CustomHooks'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import styled, { css } from 'styled-components'

import { integratedHooksModalActions, integratedHooksModalSelectors } from './logic'

export const IntegratedHooksModal = () => {
  const dispatch = useDispatch()

  const [isChecked, setIsChecked] = useState(false)
  const handleToggleIsChecked = () => setIsChecked((state) => !state)

  const { imageUrl } = useSelector(integratedHooksModalSelectors.getIntegratedHooksModalState())
  const language = useSelector(appSelectors.getLanguage())
  const webContent = useSelector(appSelectors.getWebContentByLanguage(language))

  const handleClose = useCallback(() => {
    if (isChecked) {
      dispatch(integratedHooksModalActions.integratedHooksModalDontShowUpdateRequested(isChecked))
    }

    dispatch(integratedHooksModalActions.toggleIntegratedHooksModal(false))
  }, [isChecked, dispatch])

  useDidMount(() => {
    dispatch(integratedHooksModalActions.integratedHooksModalImageRequested())

    return () => {
      dispatch(integratedHooksModalActions.integratedHooksModalUnmounted())
    }
  })

  return (
    <OrmcoModal
      isOpen
      onRequestClose={handleClose}
      additionalStyles={{
        content: {
          top: '10%',
          padding: '0',
          width: '700px',
        },
        overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
      }}
      shouldCloseOnOverlayClick={false}
      data-testid="IntegratedHooksGlobalModal-OrmcoModal"
    >
      <BrandindModalHeader>
        <OrmcoModalHeaderText variant="h5" color="white" headerId="integratedHooks.popup.header" />
        <ButtonClose
          type="text"
          onClick={handleClose}
          data-testid="IntegratedHooksGlobalModal-HeaderClose"
        >
          <Cancel />
        </ButtonClose>
      </BrandindModalHeader>
      <BrandindModalBody>
        <Box minHeight="200px" maxHeight="300px">
          <IntegratedHooksImage alt="IntegratedHooks" src={imageUrl ?? ''} />
        </Box>
        <Typography variant="medium" color="base900" margin="16px 0">
          <FormattedMessage
            id="integratedHooks.popup.content"
            values={{
              link: (
                <OrmcoLink
                  data-testid="integratedHooksPopup-Link"
                  href={webContent.doctor_integrated_hooks_popup_link}
                  target="_blank"
                >
                  <FormattedMessage id="integratedHooks.popup.contentLinkPlaceholder" />
                </OrmcoLink>
              ),
            }}
          />
        </Typography>
        <BrandingModalFooter>
          <WideOrmcoCheckbox
            onClick={handleToggleIsChecked}
            checked={isChecked}
            data-testid="IntegratedHooksGlobalModal-Сheckbox"
          >
            <Typography component="label" textFontWeight="500">
              <FormattedMessage id="integratedHooks.popup.doNotShowAgain" />
            </Typography>
          </WideOrmcoCheckbox>
          <StyledIntegratedHooksButtons
            as="a"
            href={SiteMap.DOCTORS.clinicalPreferences()}
            data-testid="IntegratedHooksGlobalModal-ClinicalPreferencesLink"
            onClick={handleClose}
          >
            <EyeOutlined />
            <span>
              <FormattedMessage id="header.navigation.clinicalPreferences" />
            </span>
          </StyledIntegratedHooksButtons>
          <StyledIntegratedHooksButtons
            onClick={handleClose}
            data-testid="IntegratedHooksGlobalModal-CloseButton"
          >
            <CrossRounded />
            <span>
              <FormattedMessage id="integratedHooks.popup.close" />
            </span>
          </StyledIntegratedHooksButtons>
        </BrandingModalFooter>
      </BrandindModalBody>
    </OrmcoModal>
  )
}

//TODO: move to button base for SDS
const StyledIntegratedHooksButtons = styled(LightBlueButtonWithBorder)`
  &&& {
    margin-left: 12px;
    padding: 0 16px;

    display: inline-flex;

    max-width: 210px;

    border: 1px solid transparent;

    svg {
      margin-right: 6px;

      min-width: 16px;
    }

    &:hover {
      border: 1px solid;
    }
  }
`

const OrmcoLink = styled.a`
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const IntegratedHooksImage = styled.img`
  width: 100%;

  height: auto;

  border-radius: 4px;
`

const BtnWhiteColorStyle = css`
  color: ${({ theme }) => theme.colors.white};
  svg {
    fill: currentColor;
  }
`

const ButtonClose = styled(Button)`
  ${BtnWhiteColorStyle}

  &:hover, &:focus {
    ${BtnWhiteColorStyle}
  }
`

const WideOrmcoCheckbox = styled(OrmcoCheckbox)`
  margin-right: auto;

  width: max-content;
`
