import { FC } from 'react'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface DropdownItemProps {
  'data-testid'?: string
  href?: string
  onClick?: () => void
}

export const DropdownItem: FC<DropdownItemProps> = (props) => (
  <DropdownItemContainer
    data-testid={props['data-testid']}
    href={props.href}
    onClick={props.onClick}
  >
    <Typography
      data-testid={`${props['data-testid']}-Text`}
      component="span"
      textFontWeight="600"
      whiteSpace="nowrap"
      color="primary800"
    >
      {props.children}
    </Typography>
  </DropdownItemContainer>
)

const DropdownItemContainer = styled.a`
  padding: 3px 20px;

  display: block;

  text-decoration: none;

  &:hover {
    text-decoration: none;

    background: ${({ theme }) =>
      `${theme.colors.base300}
       linear-gradient(to bottom, ${theme.colors.base100} 0, ${theme.colors.base300} 100%) repeat-x`};
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  }
`
