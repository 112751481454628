import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { doctorNoteActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/logic'
import { RedButton, InfoButton } from 'app/components/ui/Buttons'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import scrollIntoView from 'scroll-into-view-if-needed'
import styled from 'styled-components'

interface NoteEditProps {
  notes?: string
  'data-testid'?: string
}

const NoteEdit: React.FC<NoteEditProps> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const cancelButtonTitle = intl.formatMessage({ id: 'cases.list.doctor.notes.cancel' })
  const saveButtonTitle = intl.formatMessage({ id: 'cases.list.doctor.notes.save' })
  const node = useRef<HTMLDivElement>(null)

  const [notes, setNotes] = useState(props.notes || '')

  const submitTextCallback = useCallback(
    (value: boolean) => (e?: React.MouseEvent) => {
      if (e) e.stopPropagation()
      if (value) {
        dispatch(doctorNoteActions.submitButtonClicked({ text: notes }))
      } else {
        dispatch(doctorNoteActions.cancelButtonClicked())
      }
    },
    [dispatch, notes],
  )
  const handleChangeCallback = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value)
  }, [])
  const handleKeyPressCallback = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        submitTextCallback(false)()
      }
    },
    [submitTextCallback],
  )

  useEffect(() => {
    if (node.current !== null) {
      scrollIntoView(node.current, { scrollMode: 'if-needed' })
    }
  }, [])

  return (
    <FullNotesEditContainer
      display="block"
      onKeyDown={handleKeyPressCallback}
      ref={node}
      data-testid={props['data-testid']}
    >
      <Back onClick={submitTextCallback(false)} />
      <FullNoteTextarea autoFocus value={notes} onChange={(e) => handleChangeCallback(e)} />
      <Box paddingTop="10px" justify="flex-end">
        <DangerButtonPrivate title={cancelButtonTitle} onClick={submitTextCallback(false)}>
          <GlyphIcon type={IconType.REMOVE} />
          <div>
            <FormattedMessage id="cases.list.doctor.notes.cancel" />
          </div>
        </DangerButtonPrivate>
        <Padder width="3px" />
        <InfoButtonPrivate title={saveButtonTitle} onClick={submitTextCallback(true)}>
          <GlyphIcon type={IconType.SAVED} />
          <div>
            <FormattedMessage id="cases.list.doctor.notes.save" />
          </div>
        </InfoButtonPrivate>
      </Box>
    </FullNotesEditContainer>
  )
}
const DangerButtonPrivate = styled(RedButton)`
  overflow: hidden;

  min-width: auto;

  height: 32px;

  white-space: nowrap;
  text-overflow: ellipsis;
`

const InfoButtonPrivate = styled(InfoButton)`
  overflow: hidden;

  min-width: auto;

  white-space: nowrap;
  text-overflow: ellipsis;
`

const FullNoteTextarea = styled.textarea`
  align-self: center;

  width: 98%;
  height: 200px;
  border: 1px solid ${({ theme }) => theme.colors.base500};
  resize: none;
`

const FullNotesEditContainer = styled(Box)`
  z-index: ${({ theme }) => theme.zIndex.layer1};
`

const Back = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  background: transparent;
`

export { NoteEdit }
