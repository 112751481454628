import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Entry } from 'app/components/routes/CaseWizard/CaseWizard.style'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import styled from 'styled-components'

import { ClinicalPreferencesCaption } from './ClinicalPreferencesCaption'
import { ClinicalPreferencesPropertyCaption } from './ClinicalPreferencesPropertyCaption'
import {
  ClinicalPreferencesBoxPropertyValue,
  ClinicalPreferencesPropertyValue,
} from './ClinicalPreferencesPropertyValue'

const FinalToothPosition = () => {
  const clinicalPreferences = useSelector(caseWizardSelectors.getClinicalPreferences())
  const theme = useContext(ThemeContext)

  return (
    <Section>
      <Entry>
        <ClinicalPreferencesCaption
          data-testid="FinalToothPosition-Caption"
          captionTextId="clinicalPreferences.finalToothPosition.caption"
        />
      </Entry>

      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="FinalToothPosition-PropertyCaptionContainer-TeethLevelUpper"
          captionTextId="clinicalPreferences.finalToothPosition.teeth.level.upper"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.finalToothPosition.atlUpper"
            values={{ atlUpper: clinicalPreferences?.finalToothPosition.atlUpper }}
          />
          {clinicalPreferences?.finalToothPosition.atlUpper === 'CUSTOM' &&
            clinicalPreferences?.finalToothPosition?.atlUpperCustomDesc}
        </ClinicalPreferencesPropertyValue>
      </Entry>

      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="FinalToothPosition-PropertyCaptionContainer-AlignTeethBeforeIpr"
          captionTextId="clinicalPreferences.finalToothPosition.alignTeethBeforeIpr.caption"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.finalToothPosition.alignTeethBeforeIpr && (
            <FormattedMessage
              id="clinicalPreferences.finalToothPosition.alignTeethBeforeIpr"
              values={{
                alignTeethBeforeIpr: clinicalPreferences?.finalToothPosition.alignTeethBeforeIpr,
              }}
            />
          )}
          {clinicalPreferences?.finalToothPosition.limitIprToStages &&
            clinicalPreferences?.finalToothPosition.alignTeethBeforeIpr === 'YES' && (
              <ThemedBox width="60%" padding="1em" contextTheme={theme}>
                <ClinicalPreferencesPropertyCaption captionTextId="clinicalPreferences.finalToothPosition.limitIprToStages.caption" />
                <ClinicalPreferencesBoxPropertyValue>
                  {clinicalPreferences?.finalToothPosition?.listOfStages}
                </ClinicalPreferencesBoxPropertyValue>
              </ThemedBox>
            )}
        </ClinicalPreferencesPropertyValue>
      </Entry>

      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="FinalToothPosition-PropertyCaptionContainer-ArchExpansion"
          captionTextId="clinicalPreferences.finalToothPosition.archExpansion.caption"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.finalToothPosition.archExpansion"
            values={{
              archExpansion: clinicalPreferences?.finalToothPosition.archExpansion,
              upperSymbol: '>',
            }}
          />
        </ClinicalPreferencesPropertyValue>
      </Entry>

      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="FinalToothPosition-PropertyCaptionContainer-ArchForm"
          captionTextId="clinicalPreferences.finalToothPosition.archForm.caption"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.finalToothPosition.archForm"
            values={{ archForm: clinicalPreferences?.finalToothPosition.archForm }}
          />
        </ClinicalPreferencesPropertyValue>
      </Entry>

      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="FinalToothPosition-PropertyCaptionContainer-CorrectPosteriorCrossBite"
          captionTextId="clinicalPreferences.finalToothPosition.correctPosteriorCrossBite.caption"
        />
        <ClinicalPreferencesPropertyValue>
          <FormattedMessage
            id="clinicalPreferences.finalToothPosition.correctPosteriorCrossBite"
            values={{
              correctPosteriorBite: clinicalPreferences?.finalToothPosition.correctPosteriorBite,
            }}
          />
          {clinicalPreferences?.finalToothPosition.correctPosteriorBite === 'YES' &&
            clinicalPreferences?.finalToothPosition.placeCutOuts === true && (
              <ThemedBox width="60%" padding="1em" contextTheme={theme}>
                <ClinicalPreferencesBoxPropertyValue>
                  <FormattedMessage id="clinicalPreferences.finalToothPosition.correctPosteriorCrossBite.placeCutOuts" />
                </ClinicalPreferencesBoxPropertyValue>
              </ThemedBox>
            )}
          {clinicalPreferences?.finalToothPosition.correctPosteriorBite === 'YES' &&
            clinicalPreferences?.finalToothPosition.placeCutOuts === false && (
              <ThemedBox width="60%" padding="1em" contextTheme={theme}>
                <ClinicalPreferencesBoxPropertyValue>
                  <FormattedMessage id="clinicalPreferences.dont" />
                  &nbsp;
                  <FormattedMessage id="clinicalPreferences.finalToothPosition.correctPosteriorCrossBite.placeCutOuts" />
                </ClinicalPreferencesBoxPropertyValue>
              </ThemedBox>
            )}
        </ClinicalPreferencesPropertyValue>
      </Entry>

      <Entry>
        <ClinicalPreferencesPropertyCaption
          data-testid="FinalToothPosition-PropertyCaptionContainer-AdditionalComments"
          captionTextId="clinicalPreferences.finalToothPosition.additionalComments.caption"
        />
        <ClinicalPreferencesPropertyValue>
          {clinicalPreferences?.finalToothPosition.additionalComments ? (
            clinicalPreferences?.finalToothPosition.additionalComments
          ) : (
            <FormattedMessage id="clinicalPreferences.finalToothPosition.additionalComments.none" />
          )}
        </ClinicalPreferencesPropertyValue>
      </Entry>
    </Section>
  )
}

const Section = styled.div`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.base300};

  border-radius: 8px;
`

export { FinalToothPosition, Section }
