import { FC, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { OrmcoSelect } from 'app/components/ui/Form/OrmcoSelect'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { MolarTubeType } from 'app/core/domain/MolarTubeType'
import styled from 'styled-components'

interface MolarTubeSelectorProps {
  disabled?: boolean
  molarTubeNameId: string
  molarTubeKey: keyof CaseDraft
}

export const MolarTubeSelector: FC<MolarTubeSelectorProps> = (props) => {
  const form = useFormContext<CaseDraft>()
  const molarTube = form.getValues(props.molarTubeKey)

  const handleChangeMolarTubeType = useCallback(
    (value: unknown) => {
      form.setValue(props.molarTubeKey, value as MolarTubeType)
    },
    [form, props.molarTubeKey],
  )

  return (
    <MolarTubeSelectorContainer>
      <Typography component="label">
        <FormattedMessage id={props.molarTubeNameId} />
      </Typography>

      <OrmcoSelect
        dropdownStyle={{ zIndex: 10000 }}
        id="ThreeShapeDropDown"
        disabled={props.disabled}
        value={molarTube}
        data-testid="ThreeShapeScan-OrmcoSelect"
        onChange={handleChangeMolarTubeType}
      >
        {props.children}
      </OrmcoSelect>
    </MolarTubeSelectorContainer>
  )
}

const MolarTubeSelectorContainer = styled.div`
  &:not(:last-child) {
    padding-bottom: 16px;
  }
`
