import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { StaffCreationButtonsContainer } from 'app/components/routes/Staff/StaffCreationButtons'
import { InfoButton, LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { Box } from 'app/components/ui/SDS/common/Box'
import { PersonalInformation } from 'app/core/domain/PersonalInformation'
import styled from 'styled-components'

import { doctorDetailsActions, doctorDetailsSelectors } from './logic'

const UpdateDoctor: React.FC = () => {
  const dispatch = useDispatch()
  const form = useFormContext<PersonalInformation>()
  const isUpdateButtonVisible = useSelector(doctorDetailsSelectors.isUpdateButtonVisible())
  const onSubmit = useCallback(
    (personalInfo: PersonalInformation) => {
      dispatch(doctorDetailsActions.updateDoctorButtonClicked(personalInfo))
      window.scroll(0, 0)
    },
    [dispatch],
  )

  return (
    <StaffCreationButtonsContainer padding="12px" data-testid="UpdateDoctor">
      {isUpdateButtonVisible ? (
        <Box justify="space-between">
          <Box width="auto">
            <Link to="/myPractice" data-testid="UpdateDoctor-Cancel">
              <LightBlueButtonWithBorder>
                <FormattedMessage id="staff.creation.cancel" />
              </LightBlueButtonWithBorder>
            </Link>
          </Box>
          <Box width="auto">
            <CreateButton onClick={form.handleSubmit(onSubmit)} data-testid="UpdateDoctor-Update">
              <Box>
                <FormattedMessage id="staff.doctorUpdate" />
              </Box>
            </CreateButton>
          </Box>
        </Box>
      ) : (
        <Box marginLeft="auto" width="auto">
          <Link to="/myPractice" data-testid="UpdateDoctor-Close">
            <CreateButton>
              <Box>
                <FormattedMessage id="staff.staffDetails.close" />
              </Box>
            </CreateButton>
          </Link>
        </Box>
      )}
    </StaffCreationButtonsContainer>
  )
}

export { UpdateDoctor }

const CreateButton = styled(InfoButton)`
  padding: 0;

  justify-content: center;

  min-width: 136px;
  height: 40px;

  letter-spacing: 0.1px;

  font-weight: 600;
  line-height: 18px;
`
