import React from 'react'

import { Typography } from './SDS/common/Typography'

interface ErrorProps {
  errorMessage?: string
}

const Error: React.FC<ErrorProps> = ({ errorMessage }) => (
  <Typography component="span" margin="1% 0 0" variant="small" color="danger500">
    {errorMessage}
  </Typography>
)

export { Error }
