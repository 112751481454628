import { Table } from 'antd'
import styled from 'styled-components'

const OrmcoTable = styled(Table)`
  width: 100%;

  .ant-table {
    table {
      overflow: hidden;

      border: 1px solid ${({ theme }) => theme.colors.base300};

      border-radius: 4px;
      border-collapse: separate;
    }
  }

  .ant-table-thead tr,
  .ant-table-row:nth-child(2n) {
    background: ${({ theme }) => theme.colors.base100};
  }

  .ant-table-thead {
    .ant-table-cell {
      padding-top: 10px;
      padding-bottom: 10px;

      border-bottom: 1px solid ${({ theme }) => theme.colors.base300};
    }
  }

  .ant-table-cell {
    padding: 8px 16px;

    border: none;

    background: none;

    &::before {
      display: none;
    }
  }
`

export { OrmcoTable }
