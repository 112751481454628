import { useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

import { ScanPdfModal } from './ScanPdfModal'
import { editScansSelectors } from './logic'

export const DirectTransferMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalOpen = () => setIsModalOpen((state) => !state)
  const intl = useIntl()
  const selectedScanner = useSelector(editScansSelectors.getSelectedScanner())
  const selectedScanType = selectedScanner?.name ?? ''
  const {
    directTransferPdfGuideUrl,
    directTransferMessageText,
    directTransferLoginLabel,
    directTransferLoginUrl,
  } = useSelector(editScansSelectors.getScansMessagesAndLinks())
  const directTransferMessageProp = `scanner_direct_transfer_message__${selectedScanType}`
  const directTransferDescriptor = defineMessages({
    message: { id: directTransferMessageProp, defaultMessage: directTransferMessageText },
  })

  const directTransferMessage = directTransferMessageText
    ? intl.formatMessage(directTransferDescriptor.message, {
        iteroLoginLink: (
          <AnchorContainer href={directTransferLoginUrl} target="_blank">
            {directTransferLoginLabel}
          </AnchorContainer>
        ),
        pdfGuideLink: (
          <AnchorContainer onClick={toggleModalOpen}>
            <FormattedMessage id="scans.pdf.guide" />
          </AnchorContainer>
        ),
      })
    : ''

  return (
    <DirectTransferContainer>
      <Typography
        data-testid="DirectTransferMessage-Text"
        component="span"
        color="danger400"
        textFontWeight="500"
      >
        {directTransferMessage}
      </Typography>
      {isModalOpen && (
        <ScanPdfModal
          isOpen={isModalOpen}
          onClose={toggleModalOpen}
          scanPdfUrl={directTransferPdfGuideUrl}
          selectedScanType={selectedScanType}
          isDirectTransferPdf
        />
      )}
    </DirectTransferContainer>
  )
}

const DirectTransferContainer = styled.div`
  margin-top: 2%;
`

const AnchorContainer = styled.a`
  text-decoration: underline;
`
