enum ResultStatus {
  'BAD_REQUEST' = 'BAD_REQUEST',
  'OK' = 'OK',
}

interface LastStaffUpdateResult {
  status: ResultStatus
  message: string
}

export { ResultStatus, LastStaffUpdateResult }
