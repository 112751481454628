import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import { ThemeContext, ThemeType } from 'app/components/ui/Theme/ThemeContext'

import { Typography } from '../SDS/common/Typography'

interface OrmcoFormItemVerticalHeaderProps {
  headerTextId: string
  optional?: boolean
}

export const OrmcoFormItemVerticalHeader = (props: OrmcoFormItemVerticalHeaderProps) => {
  const theme = useContext(ThemeContext)

  return (
    <>
      {theme === ThemeType.ANTD ? (
        <Typography component="h2" textFontWeight="600">
          <FormattedMessage id={props.headerTextId} />
          {props.optional && `(${(<FormattedMessage id="caseWizard.optional" />)})`}
        </Typography>
      ) : (
        <Typography component="h2" textFontWeight="600">
          <FormattedMessage id={props.headerTextId} />
        </Typography>
      )}
    </>
  )
}
