const sizeLevels = [10, 8, 6]

interface StyleMap {
  [index: number]: {
    [numberOfElements: number]: Array<{ top: number; left?: number; right?: number; size?: number }>
  }
}

const auxiliariesStylesMap: StyleMap = {
  1: {
    1: [
      {
        top: 22,
        left: 30,
        right: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 15,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  2: {
    1: [
      {
        top: 22,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 15,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  3: {
    1: [
      {
        top: 22,
        left: 40,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  4: {
    1: [
      {
        top: 30,
        left: 28,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        left: 35,
        size: sizeLevels[1],
      },
      {
        top: 50,
        left: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 19,
        left: 40,
        size: sizeLevels[2],
      },
      {
        top: 50,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 50,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  5: {
    1: [
      {
        top: 30,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        left: 30,
        size: sizeLevels[1],
      },
      {
        top: 50,
        left: 30,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 25,
        left: 35,
        size: sizeLevels[2],
      },
      {
        top: 50,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 50,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  6: {
    1: [
      {
        top: 33,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        left: 30,
        size: sizeLevels[1],
      },
      {
        top: 50,
        left: 30,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 30,
        left: 35,
        size: sizeLevels[2],
      },
      {
        top: 52,
        left: 15,
        size: sizeLevels[2],
      },
      {
        top: 52,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  7: {
    1: [
      {
        top: 33,
        left: 25,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        left: 30,
        size: sizeLevels[1],
      },
      {
        top: 50,
        left: 30,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 30,
        left: 33,
        size: sizeLevels[2],
      },
      {
        top: 52,
        left: 15,
        size: sizeLevels[2],
      },
      {
        top: 52,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  8: {
    1: [
      {
        top: 37,
        left: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 20,
        left: 40,
        size: sizeLevels[1],
      },
      {
        top: 53,
        left: 40,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 30,
        left: 40,
        size: sizeLevels[1],
      },
      {
        top: 60,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 60,
        right: 15,
        size: sizeLevels[1],
      },
    ],
  },

  9: {
    1: [
      {
        top: 37,
        right: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 20,
        right: 40,
        size: sizeLevels[1],
      },
      {
        top: 53,
        right: 40,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 30,
        right: 40,
        size: sizeLevels[1],
      },
      {
        top: 60,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 60,
        right: 15,
        size: sizeLevels[1],
      },
    ],
  },
  10: {
    1: [
      {
        top: 33,
        right: 25,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        right: 30,
        size: sizeLevels[1],
      },
      {
        top: 50,
        right: 30,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 30,
        right: 33,
        size: sizeLevels[2],
      },
      {
        top: 52,
        left: 15,
        size: sizeLevels[2],
      },
      {
        top: 52,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  11: {
    1: [
      {
        top: 33,
        right: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        right: 30,
        size: sizeLevels[1],
      },
      {
        top: 50,
        right: 30,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 30,
        right: 35,
        size: sizeLevels[2],
      },
      {
        top: 52,
        left: 15,
        size: sizeLevels[2],
      },
      {
        top: 52,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  12: {
    1: [
      {
        top: 30,
        right: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        right: 30,
        size: sizeLevels[1],
      },
      {
        top: 50,
        right: 30,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 25,
        right: 35,
        size: sizeLevels[2],
      },
      {
        top: 50,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 50,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  13: {
    1: [
      {
        top: 30,
        right: 28,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        right: 35,
        size: sizeLevels[1],
      },
      {
        top: 50,
        right: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 19,
        right: 40,
        size: sizeLevels[2],
      },
      {
        top: 50,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 50,
        right: 15,
        size: sizeLevels[2],
      },
    ],
  },
  14: {
    1: [
      {
        top: 22,
        right: 40,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  15: {
    1: [
      {
        top: 22,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 15,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  16: {
    1: [
      {
        top: 22,
        left: 30,
        right: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 15,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },

  17: {
    1: [
      {
        top: 33,
        left: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  18: {
    1: [
      {
        top: 33,
        left: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  19: {
    1: [
      {
        top: 32,
        left: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  20: {
    1: [
      {
        top: 28,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 14,
        left: 35,
        size: sizeLevels[1],
      },
      {
        top: 53,
        left: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 23,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 23,
        right: 20,
        size: sizeLevels[2],
      },
      {
        top: 60,
        left: 35,
        size: sizeLevels[2],
      },
    ],
  },
  21: {
    1: [
      {
        top: 28,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 12,
        left: 34,
        size: sizeLevels[1],
      },
      {
        top: 54,
        left: 34,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 20,
        left: 17,
        size: sizeLevels[2],
      },
      {
        top: 20,
        right: 17,
        size: sizeLevels[2],
      },
      {
        top: 55,
        left: 35,
        size: sizeLevels[2],
      },
    ],
  },
  22: {
    1: [
      {
        top: 28,
        left: 20,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 14,
        left: 26,
        size: sizeLevels[1],
      },
      {
        top: 54,
        left: 26,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 12,
        left: 30,
        size: sizeLevels[2],
      },
      {
        top: 39,
        left: 30,
        size: sizeLevels[2],
      },
      {
        top: 67,
        left: 30,
        size: sizeLevels[2],
      },
    ],
  },
  23: {
    1: [
      {
        top: 28,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        left: 35,
        size: sizeLevels[1],
      },
      {
        top: 54,
        left: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 15,
        left: 15,
        size: sizeLevels[2],
      },
      {
        top: 15,
        right: 20,
        size: sizeLevels[2],
      },
      {
        top: 48,
        left: 40,
        size: sizeLevels[2],
      },
    ],
  },
  24: {
    1: [
      {
        top: 24,
        left: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 12,
        left: 35,
        size: sizeLevels[1],
      },
      {
        top: 48,
        left: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 16,
        left: 18,
        size: sizeLevels[2],
      },
      {
        top: 16,
        right: 18,
        size: sizeLevels[2],
      },
      {
        top: 52,
        left: 35,
        size: sizeLevels[2],
      },
    ],
  },

  25: {
    1: [
      {
        top: 24,
        right: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 12,
        right: 35,
        size: sizeLevels[1],
      },
      {
        top: 48,
        right: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 16,
        left: 18,
        size: sizeLevels[2],
      },
      {
        top: 16,
        right: 18,
        size: sizeLevels[2],
      },
      {
        top: 52,
        right: 35,
        size: sizeLevels[2],
      },
    ],
  },
  26: {
    1: [
      {
        top: 28,
        right: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 15,
        right: 35,
        size: sizeLevels[1],
      },
      {
        top: 54,
        right: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 15,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 15,
        right: 15,
        size: sizeLevels[2],
      },
      {
        top: 48,
        right: 40,
        size: sizeLevels[2],
      },
    ],
  },
  27: {
    1: [
      {
        top: 28,
        right: 20,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 14,
        right: 26,
        size: sizeLevels[1],
      },
      {
        top: 54,
        right: 26,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 12,
        right: 30,
        size: sizeLevels[2],
      },
      {
        top: 39,
        right: 30,
        size: sizeLevels[2],
      },
      {
        top: 67,
        right: 30,
        size: sizeLevels[2],
      },
    ],
  },
  28: {
    1: [
      {
        top: 28,
        right: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 12,
        right: 34,
        size: sizeLevels[1],
      },
      {
        top: 54,
        right: 34,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 20,
        left: 17,
        size: sizeLevels[2],
      },
      {
        top: 20,
        right: 17,
        size: sizeLevels[2],
      },
      {
        top: 55,
        right: 35,
        size: sizeLevels[2],
      },
    ],
  },
  29: {
    1: [
      {
        top: 28,
        right: 30,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 14,
        right: 35,
        size: sizeLevels[1],
      },
      {
        top: 53,
        right: 35,
        size: sizeLevels[1],
      },
    ],
    3: [
      {
        top: 23,
        left: 20,
        size: sizeLevels[2],
      },
      {
        top: 23,
        right: 20,
        size: sizeLevels[2],
      },
      {
        top: 60,
        right: 35,
        size: sizeLevels[2],
      },
    ],
  },
  30: {
    1: [
      {
        top: 32,
        right: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  31: {
    1: [
      {
        top: 33,
        right: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
  32: {
    1: [
      {
        top: 33,
        right: 35,
        size: sizeLevels[0],
      },
    ],
    2: [
      {
        top: 30,
        left: 20,
        size: sizeLevels[1],
      },
      {
        top: 30,
        right: 20,
        size: sizeLevels[1],
      },
    ],
    3: [{ top: 0 }, { top: 20 }, { top: 45 }],
  },
}

export { auxiliariesStylesMap }
