import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { TableBody } from 'app/components/routes/CaseList/TableBody'
import { caseListSelectors } from 'app/components/routes/CaseList/logic'
import { ColumnSetting } from 'app/components/ui/ColumnSettings'
import { TableColumns } from 'app/components/ui/Table/TableColumns'
import { TableHeader } from 'app/components/ui/Table/TableHeader'
import { TableRow } from 'app/components/ui/Table/TableRow'
import { Case } from 'app/core/domain/Case'
import { SiteMap } from 'app/core/react/SiteMap'

const CaseListTable: React.FC<{ columnSettings: ColumnSetting<CaseTableData>[] }> = (props) => {
  const { columnSettings } = props

  const cases = useSelector(caseListSelectors.getCaseList())
  const filter = useSelector(caseListSelectors.getDoctorActiveCaseFilter())

  const goToDetails = useCallback(
    (caseId: string) => () => {
      window.location.href = SiteMap.patiensDetails(caseId)
    },
    [],
  )

  return (
    <>
      <TableHeader columnSettings={columnSettings} data-testid="CaseListTable-Header" />
      <TableBody length={cases.length} data-testid="CaseListTable-Body">
        {cases.map((caseItem: Case) => {
          const { caseId, orderId } = caseItem
          const id = caseId === orderId ? caseId : orderId

          if (filter !== DoctorCaseTabs.actionRequired) {
            return (
              <TableRow key={id} onClick={goToDetails(id)} data-testid={`CaseListTable-Row-${id}`}>
                <TableColumns
                  columnSettings={columnSettings}
                  data={{ case: caseItem }}
                  data-testid={`CaseListTable-Row-${id}-Column`}
                />
              </TableRow>
            )
          }
          return (
            <TableRow key={id} onClick={goToDetails(id)} data-testid={`CaseListTable-Row-${id}`}>
              <TableColumns
                columnSettings={columnSettings}
                data={{ case: caseItem }}
                data-testid={`CaseListTable-Row-${id}-Column`}
              />
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
}

export { CaseListTable }
