import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { ThreeShapeAccountsWarningModal } from 'app/components/routes/CaseWizard/steps/EditScans/ThreeShapeAccountsWarningModal'
import { ThreeShapeModal } from 'app/components/routes/CaseWizard/steps/EditScans/ThreeShapeModal'
import {
  editScansActions,
  editScansSelectors,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { Button } from 'app/components/ui/Button'
import { OrmcoSelect } from 'app/components/ui/Form/OrmcoSelect'
import { Box } from 'app/components/ui/SDS/common/Box'
import { ThreeShapeAccount } from 'app/core/domain/ThreeShapeAccount'
import { useDidMount } from 'app/core/react/CustomHooks'

const STANDARD_3SHAPE_SCAN_LOADING_INTERVAL = 20_000
const SAVED_ACCOUNT_ID_KEY = '3Shape-account'

const ThreeShapeScan = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isThreeShapePairingSuccess = useSelector(editScansSelectors.getThreeShapePairingSuccess())
  const threeShapeAccounts = useSelector(editScansSelectors.getThreeShapeAccounts())
  const scanViewUrls = useSelector(editScansSelectors.getScanViewUrls())

  const isThreeShapeScanReady = Boolean(scanViewUrls?.length)
  const threeShapePlaceholder = useMemo(() => {
    const id = threeShapeAccounts?.length ? '3shape.accounts.select' : '3shape.accounts.empty'

    return intl.formatMessage({ id })
  }, [intl, threeShapeAccounts])

  const [isScanSelectionModalOpen, setIsScanSelectionModalOpen] = useState(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<ThreeShapeAccount | null>(null)

  const handleSelectScans = useCallback(() => {
    const hasError = !isThreeShapePairingSuccess || !threeShapeAccounts?.length || !selectedAccount

    if (hasError) {
      setIsWarningModalOpen(true)
      return
    }

    setIsScanSelectionModalOpen(true)
  }, [isThreeShapePairingSuccess, selectedAccount, threeShapeAccounts])

  useEffect(() => {
    if (!selectedAccount && threeShapeAccounts) {
      const savedAccountId = Number(localStorage.getItem(SAVED_ACCOUNT_ID_KEY))
      const selected =
        threeShapeAccounts.find(({ id }) => id === savedAccountId) ?? threeShapeAccounts[0] ?? null

      setSelectedAccount(selected)
    }
  }, [selectedAccount, threeShapeAccounts])

  useEffect(() => {
    const requestUrlsInterval = setInterval(
      () => dispatch(editScansActions.scanViewUrlsRequested()),
      STANDARD_3SHAPE_SCAN_LOADING_INTERVAL,
    )

    if (!isThreeShapeScanReady) {
      dispatch(editScansActions.scanViewUrlsRequested())
    } else {
      clearInterval(requestUrlsInterval)
    }

    return () => clearInterval(requestUrlsInterval)
  }, [dispatch, isThreeShapeScanReady])

  useDidMount(() => {
    dispatch(editScansActions.threeShapePairingCheckRequested())
  })

  return (
    <>
      <Box marginRight="20px" display="block" width="40%">
        <OrmcoSelect
          dropdownStyle={{ zIndex: 10000 }}
          id="ThreeShapeDropDown"
          suffixIcon={<div>▼</div>}
          placeholder={threeShapePlaceholder}
          defaultValue={selectedAccount?.id}
          value={selectedAccount?.id}
          disabled={!threeShapeAccounts?.length}
          data-testid="ThreeShapeScan-OrmcoSelect"
          onChange={(selectedAccountId) => {
            const selected = threeShapeAccounts?.find(({ id }) => id === selectedAccountId)

            if (!selected) {
              console.trace('Something went wrong. threeShapeAccounts: ', threeShapeAccounts)
              return
            }

            localStorage.setItem(SAVED_ACCOUNT_ID_KEY, selectedAccountId)
            setSelectedAccount(selected)
            dispatch(editScansActions.threeShapeCaseListUnmounted())
          }}
        >
          {threeShapeAccounts?.map((account) => (
            <OrmcoSelect.Option key={account.id} value={account.id}>
              {account.name}
            </OrmcoSelect.Option>
          ))}
        </OrmcoSelect>
      </Box>
      <Button
        title={intl.formatMessage({ id: 'scanner.uploadButton.title' })}
        data-testid="ThreeShapeScan-selectScans"
        onClick={handleSelectScans}
      >
        <FormattedMessage id="selectScan.button.label" />
      </Button>

      {selectedAccount && (
        <ThreeShapeModal
          isOpen={isScanSelectionModalOpen}
          onClose={() => setIsScanSelectionModalOpen(false)}
          selectedAccount={selectedAccount}
        />
      )}

      <ThreeShapeAccountsWarningModal
        isOpen={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
      />
    </>
  )
}

export { ThreeShapeScan }
