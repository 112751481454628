import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { CheckboxInput } from 'app/components/routes/MyProfile/CheckboxInput'
import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { TooltipText } from 'app/components/ui/Tooltip/TooltipText'
import { EmployeeProfileFields } from 'app/core/domain/EmployeeProfile'
import { appActions } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

const ReceiveEmail: React.FC = () => {
  const dispatch = useDispatch()
  const form = useFormContext<EmployeeProfileFields>()
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const updateUserProfileData = () => {
    dispatch(
      appActions.saveEmployeeDetails({
        part: form.getValues('receiveEmail'),
        key: 'employeeContactDetails.receiveEmail',
      }),
    )
  }

  return (
    <CheckboxContainer marginTop="5px" marginBottom="10px" justify="flex-start">
      <Controller
        name="receiveEmail"
        render={({ field }) => (
          <CheckboxInput
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            checked={field.value}
            data-testid="ContactDetails-ReceiveEmail"
            // @ts-ignore
            onBlur={updateUserProfileData}
            disabled={featurePermissions?.disableUserProfile ?? false}
          />
        )}
      />
      <Typography component="label" letterSpacing="0.1px">
        <FormattedMessage id="receive.email.updates" />
      </Typography>
      <OrmcoTooltip
        title={<TooltipText data-testid="RecieveMail-TooltipContent" textId="receive.email.help" />}
        placement="top"
        overlayInnerStyle={{
          padding: '11px',
          whiteSpace: 'break-spaces',
        }}
      >
        <HelpIcon data-testid="RecieveMail-TooltipIcon" />
      </OrmcoTooltip>
    </CheckboxContainer>
  )
}

export { ReceiveEmail }

const CheckboxContainer = styled(Box)`
   {
    .ant-checkbox-wrapper {
      margin-right: 5px;
    }

    .ant-checkbox-inner {
      border: 2px solid ${({ theme: styledTheme }) => styledTheme.colors.primary800};
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ theme: styledTheme }) => styledTheme.colors.primary800};

      ::after {
        left: 17.5%;
      }
    }
  }
`
