import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Button } from 'app/components/ui/SDS/common/Button'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

interface ImpersonationSectionProps {
  'data-testid': string
}

export const ImpersonationSection = ({ 'data-testid': dataTestId }: ImpersonationSectionProps) => {
  const impersonator = useSelector(appSelectors.getImpersonator())
  const username = useSelector(appSelectors.getUsername())

  const handleBackToAdmin = () => {
    sessionStorage.clear()
  }

  return (
    <Box
      data-testid={dataTestId}
      marginLeft="24px"
      padding="16px"
      width="276px"
      height="100%"
      backgroundColor="primary800"
    >
      <Box flexDirection="column" align="flex-start">
        <Typography
          data-testid={`${dataTestId}-Title`}
          component="span"
          variant="extra-small"
          textFontWeight="400"
          color="white"
        >
          <FormattedMessage id="impersonation.title" />
        </Typography>
        <Typography
          data-testid={`${dataTestId}-Impersonator`}
          component="span"
          variant="extra-small"
          textFontWeight="400"
          color="white"
        >
          <FormattedMessage
            id="impersonation.info"
            values={{
              impersonator,
              username,
            }}
          />
        </Typography>
      </Box>
      <Button
        data-testid={`${dataTestId}-backToAdmin-Button`}
        status="primary"
        size="small"
        type="outline"
        href={SiteMap.clearImpersonation()}
        onClick={handleBackToAdmin}
        isDark
      >
        <FormattedMessage id="impersonation.clear" values={{ impersonator }} />
      </Button>
    </Box>
  )
}
