class Locale {
  languageCode: string

  regionCode: string

  splitter: string = '_'

  constructor(fullLocaleString: string) {
    const [languageCode, regionCode] = fullLocaleString.split(this.splitter)

    this.languageCode = languageCode
    this.regionCode = regionCode
  }

  get localeString() {
    return `${this.languageCode}${this.splitter}${this.regionCode}`
  }
}

export { Locale }
