import React, { ReactNode } from 'react'

import { Col, Row } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

const ClinicalPreferencesTeethNumbers: React.FC<{
  'data-testid': string
  caption: ReactNode
}> = ({ ['data-testid']: dataTestId, caption, children }) => (
  <Container data-testid={dataTestId}>
    <Col span={24}>
      <Typography
        component="h3"
        padding="0 0 0 3px"
        textFontWeight="600"
        variant="small"
        isUppercase
      >
        {caption}
      </Typography>
      <Box justify="flex-start" flexWrap="wrap" maxWidth="200px" minHeight="32px">
        {children}
      </Box>
    </Col>
  </Container>
)

const Container = styled(Row)`
  padding-left: 26px;
`

export { ClinicalPreferencesTeethNumbers }
