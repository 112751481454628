import { FC } from 'react'

import { Box } from 'app/components/ui/SDS/common/Box'

export const BrandingModalFooter: FC = ({ children }) => {
  return (
    <Box paddingTop="16px" justify="space-between" width="100%">
      {children}
    </Box>
  )
}
