import { useDispatch, useSelector } from 'react-redux'

import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { caseFilterActions, caseFilterSelectors } from '../logic'

import { PatientFilterList } from './PatientFilterList'
import { PatientFilterName } from './PatientFilterName'

export const PatientFilter = () => {
  const dispatch = useDispatch()
  const isCaseFilterOpen = useSelector(caseFilterSelectors.isCaseFilterOpen())

  useDidMount(() => {
    dispatch(caseFilterActions.caseFilterMounted())
  })

  const togglePatientFilter = () => {
    dispatch(caseFilterActions.caseFilterClicked())
  }

  return (
    <>
      <PatientFilterName data-testid="CaseFilter-CaseFilterName" />
      {isCaseFilterOpen && (
        <>
          <CaseFilterTransparentOverlay
            onClick={togglePatientFilter}
            data-testid="CaseFilter-TransparentOverlay"
          />
          <PatientFilterList data-testid="CaseFilter-CaseFilterList" />
        </>
      )}
    </>
  )
}

const CaseFilterTransparentOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0);
`
