import React from 'react'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

const PatientAgeCard: React.FC<{ data: CaseTableData }> = (props) => {
  const { age } = props.data.case.patient

  return (
    <Box>
      <Typography data-testid="Case-PatientAge" component="span" title={String(age)}>
        {age}
      </Typography>
    </Box>
  )
}

export { PatientAgeCard }
