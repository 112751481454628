import { DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { CaseField } from 'app/core/domain/CaseField'
import { SortOrder } from 'app/core/domain/SortOrder'

class CaseSorting {
  sortName: CaseField = CaseField.CREATION_DATE

  sortOrder: SortOrder = SortOrder.DESC

  constructor(sortName?: CaseField, sortOrder?: SortOrder) {
    if (sortName) {
      this.sortName = sortName
    }
    if (sortOrder) {
      this.sortOrder = sortOrder
    }
  }

  static getDefaultSortNameForTab(
    tab: keyof typeof DoctorCaseTabs = DoctorCaseTabs.actionRequired,
  ): CaseSorting {
    const sortOrder = SortOrder.DESC
    let sortName = CaseField.SUBMITTED_DATE

    if (tab === DoctorCaseTabs.shipped) {
      sortName = CaseField.SHIP_DATE
    } else if (tab === DoctorCaseTabs.actionRequired) {
      sortName = CaseField.CREATION_DATE
    }
    return { sortOrder, sortName }
  }
}

export { CaseSorting }
