import styled, { css } from 'styled-components'

import { ButtonProps, LinkButtonProps } from './interfaces'

const StandardButtonDisabledStyles = css`
  &:disabled {
    cursor: not-allowed;

    border-color: ${({ theme }) => theme.colors.primary100};

    background: ${({ theme }) => theme.colors.lightBlueGradient};

    &:hover,
    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.colors.primary100};

      background: ${({ theme }) => theme.colors.lightBlueGradient};
    }

    &:active {
      border-color: ${({ theme }) => theme.colors.primary100};

      background: ${({ theme }) => theme.colors.lightBlueGradient};
    }
  }
`

const DangerButtonDisabledStyles = css`
  &:disabled {
    cursor: not-allowed;

    border-color: ${({ theme }) => theme.colors.lightRedGradient};

    background: ${({ theme }) => theme.colors.danger100};

    &:hover,
    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.colors.lightRedGradient};

      background: ${({ theme }) => theme.colors.danger100};
    }

    &:active {
      border-color: ${({ theme }) => theme.colors.lightRedGradient};

      background: ${({ theme }) => theme.colors.danger100};
    }
  }
`

const WhiteButtonDisabledStyles = css`
  &:disabled {
    cursor: not-allowed;

    border-color: ${({ theme }) => theme.colors.base100};

    background: ${({ theme }) => theme.colors.lightBlackGradient};

    &:hover,
    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.colors.base100};

      background: ${({ theme }) => theme.colors.lightBlackGradient};
    }

    &:active {
      border-color: ${({ theme }) => theme.colors.base100};

      background: ${({ theme }) => theme.colors.lightBlackGradient};
    }
  }
`

const StandardButtonStyles = css`
  border-color: ${({ theme }) => theme.colors.primary700};

  background: ${({ theme }) => theme.colors.blueGradient};

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primary700};

    background: ${({ theme }) => theme.colors.darkBlueGradient};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.primary800};

    background: ${({ theme }) => theme.colors.primary700};
  }

  ${StandardButtonDisabledStyles}
`

const DangerButtonStyles = css`
  border-color: ${({ theme }) => theme.colors.danger400};

  background: ${({ theme }) => theme.colors.redGradient};

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.danger400};

    background: ${({ theme }) => theme.colors.darkRedGradient};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.danger600};

    background: ${({ theme }) => theme.colors.danger500};
  }

  ${DangerButtonDisabledStyles}
`

const WhiteButtonStyles = css`
  border-color: ${({ theme }) => theme.colors.base200};

  background: ${({ theme }) => theme.colors.mediumBlackGradient};

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.base200};

    background: ${({ theme }) => theme.colors.blackGradient};
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.base400};

    background: ${({ theme }) => theme.colors.base200};
  }

  ${WhiteButtonDisabledStyles}
`

const StyledButtonMixin = css<ButtonProps>`
  margin: 0;
  padding: 0;

  cursor: pointer;

  width: ${({ width }) => width};
  border: 1px solid;

  border-radius: 4px;

  span {
    padding: 8px 24px;

    display: inline-flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }

  ${({ variant = 'standard' }) => {
    switch (variant) {
      case 'standard':
        return StandardButtonStyles
      case 'danger':
        return DangerButtonStyles
      case 'white':
        return WhiteButtonStyles
      default:
        return null
    }
  }};
`

export const StyledButton = styled.button<ButtonProps>`
  ${StyledButtonMixin}
`

export const StyledLinkButton = styled.button<Omit<LinkButtonProps, 'component'>>`
  ${StyledButtonMixin}
`
