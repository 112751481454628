import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import {
  termsActions,
  termsSelectors,
} from 'app/components/routes/BasicPage/TermsAndConditionsModal/logic'
import { InfoButton } from 'app/components/ui/Buttons'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'

export const TermsAndConditionsModal = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { link, accepted: isAccepted, hasError } = useSelector(termsSelectors.getTermsState())

  const DecisionCallback = useCallback(
    (decision: boolean) => () => {
      dispatch(termsActions.decisionMade(decision))
    },
    [dispatch],
  )

  return (
    <OrmcoModal isOpen={!isAccepted} data-testid="Modal-TermsAndConditions">
      <OrmcoModalHeader>
        <OrmcoModalHeaderText variant="h5" headerId="terms.modal.title" />
      </OrmcoModalHeader>
      <OrmcoModalBody>
        {!hasError ? (
          <iframe
            title={intl.formatMessage({ id: 'terms.document.title' })}
            src={link}
            width="100%"
            height="600px"
            frameBorder="0"
            data-testid="Modal-TermsAndConditions-DocumentIframe"
          />
        ) : (
          <FormattedMessage id="terms.error.message" />
        )}
      </OrmcoModalBody>
      {!hasError && (
        <OrmcoModalFooter>
          <InfoButton
            onClick={DecisionCallback(false)}
            data-testid="Modal-TermsAndConditions-Decline"
          >
            <GlyphIcon type={IconType.THUMBSDOWN} />
            <div>
              &nbsp;
              <FormattedMessage id="terms.buttons.decline" />
            </div>
          </InfoButton>
          <InfoButton
            onClick={DecisionCallback(true)}
            data-testid="Modal-TermsAndConditions-Accept"
          >
            <GlyphIcon type={IconType.THUMBSUP} />
            <div>
              &nbsp;
              <FormattedMessage id="terms.buttons.accept" />
            </div>
          </InfoButton>
        </OrmcoModalFooter>
      )}
    </OrmcoModal>
  )
}
