import { AccessTokenPayload } from 'app/core/auth/AccessTokenPayload'
import { TokenGrantResult } from 'app/core/auth/TokenGrantResult'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { UserProfile } from 'app/core/domain/UserProfile'
import axios, { AxiosResponse } from 'axios'
import { fromUnixTime, isAfter, subSeconds } from 'date-fns/fp'
import jwtDecode from 'jwt-decode'
import URIJS from 'urijs'

import { Nullable } from '../types/utils'
import { createFormData } from '../utils/createFormData'
import { ensure } from '../utils/ensure'

import { AUTHENTICATED_KEY } from './AuthenticationBroadcast'

const AUTHORIZE_ENDPOINT = URIJS(EnvironmentParams.LOGIN_URL).pathname('/oauth/authorize').href()
const TOKEN_ENDPOINT = URIJS(EnvironmentParams.LOGIN_URL).pathname('/oauth/token').href()
const LOGOUT_ENDPOINT = URIJS(EnvironmentParams.LOGIN_URL).pathname('/sso/logout').href()

const UI_CLIENT_ID = 'aligners_ui'
const SAVED_URL_KEY = 'savedUrl'

class AuthenticationManager {
  private _refreshToken?: string

  private _accessToken?: string

  private decodedAccessToken?: AccessTokenPayload

  get accessToken(): string | undefined {
    return this._accessToken
  }

  get refreshToken(): string | undefined {
    return this._refreshToken
  }

  constructor() {
    this.handleTokenGrantResponse = this.handleTokenGrantResponse.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.isExpired = this.isExpired.bind(this)
  }

  async authenticate(): Promise<Nullable<UserProfile>> {
    const authorizationCode: string = URIJS(window.location.href).query(true).code

    if (!authorizationCode) {
      sessionStorage.setItem(SAVED_URL_KEY, window.location.href)

      window.location.href = URIJS(AUTHORIZE_ENDPOINT)
        .addQuery({
          response_type: 'code',
          client_id: UI_CLIENT_ID,
          redirect_uri: window.location.origin,
        })
        .href()

      return Promise.reject(null)
    }

    const response = await axios.post<TokenGrantResult>(
      TOKEN_ENDPOINT,
      createFormData({
        code: authorizationCode,
        grant_type: 'authorization_code',
        client_id: UI_CLIENT_ID,
        redirect_uri: window.location.origin,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )

    AuthenticationManager.restoreUserUrl()

    return this.handleTokenGrantResponse(response.data)
  }

  private handleTokenGrantResponse(tokenResp: TokenGrantResult): UserProfile {
    const tokenData: AccessTokenPayload = jwtDecode(tokenResp.access_token)

    this._accessToken = tokenResp.access_token
    this._refreshToken = tokenResp.refresh_token
    this.decodedAccessToken = tokenData

    localStorage.setItem(AUTHENTICATED_KEY, 'true')
    return this.getProfile()
  }

  private static restoreUserUrl() {
    const savedUrl = sessionStorage.getItem(SAVED_URL_KEY)

    if (savedUrl) {
      window.history.replaceState(null, document.title, savedUrl)
      sessionStorage.removeItem(SAVED_URL_KEY)
    }
  }

  private getProfile(): UserProfile {
    const decodedToken = ensure(this.decodedAccessToken, 'decodedAccessToken')

    return {
      username: decodedToken.user_name,
      firstName: decodedToken.profile.firstName,
      middleName: decodedToken.profile.middleName,
      roles: [...decodedToken.authorities],
      lastName: decodedToken.profile.lastName,
      impersonator: decodedToken.impersonator,
    }
  }

  isExpired(): boolean {
    const decodedToken = ensure(this.decodedAccessToken, 'decodedAccessToken')
    const tokenExpirationGap = 60
    const expirationTime = subSeconds(tokenExpirationGap, fromUnixTime(decodedToken.exp))

    return isAfter(expirationTime, Date.now())
  }

  async updateToken(): Promise<UserProfile> {
    if (this.isExpired()) {
      const refreshToken = ensure(this._refreshToken, 'refreshToken')

      const result = (await axios
        .post<TokenGrantResult>(
          TOKEN_ENDPOINT,
          createFormData({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: UI_CLIENT_ID,
          }),
        )
        .catch(() => {
          localStorage.setItem(AUTHENTICATED_KEY, 'false')
          window.location.href = LOGOUT_ENDPOINT
        })) as AxiosResponse<TokenGrantResult>

      return this.handleTokenGrantResponse(result.data)
    }

    return this.getProfile()
  }
}

const AuthenticationManagerInstance = new AuthenticationManager()

export default AuthenticationManagerInstance
