import { ListType } from 'app/components/routes/CaseList/logic'
import { UserRole } from 'app/core/domain/UserRole'
import { snakeCase } from 'lodash'

enum AdminQueue {
  'new_scan' = 'new_scan',
}

interface UrlStringParams {
  listType: ListType
  userRole?: UserRole
  username: string
  tab?: string
  queue?: string
  browserTimezone?: string
}

const generateCaseListUrlString = (params: UrlStringParams): string => {
  const ListTypeUrl = (_params: UrlStringParams) => {
    const listTypeMap = {
      [ListType.TAB]: _params.tab ? `/tabs/${snakeCase(_params.tab)}` : '',
      [ListType.QUEUE]: _params.queue ? `queues/${_params.queue}/` : '',
      [ListType.SEARCH]: '',
    }

    return `${listTypeMap[_params.listType]}`
  }
  const userRoleUrl = (_params: UrlStringParams) => {
    const userRolesMap = {
      [UserRole.ROLE_NORMAL]: `doctors/${_params.username}/cases`,
      [UserRole.ROLE_STAFF]: `doctors/${_params.username}/cases`,
      [UserRole.ROLE_DESIGNER_QC]: `operators/${_params.username}/cases/`,
      [UserRole.ROLE_DESIGN]: `operators/${_params.username}/cases/`,
      [UserRole.ROLE_OPERATOR]: `operators/${_params.username}/cases/`,
      [UserRole.ROLE_USER_ADMIN]: _params.queue ? `operators/${_params.username}/` : 'cases/',
      none: '',
    }

    return userRolesMap[_params.userRole || 'none']
  }

  return `/api/v1/${userRoleUrl(params)}${ListTypeUrl(params)}`
}

export { generateCaseListUrlString, UrlStringParams, AdminQueue }
