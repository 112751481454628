import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { Nullable } from 'app/core/types/utils'

export const UPPER_BRACKET_KEY = 'primaryCasePreferences.upperBracket'
export const LOWER_BRACKET_KEY = 'primaryCasePreferences.lowerBracket'

export const getBracketPreferencesKey = (arch: TreatArches) => {
  let result: Nullable<string> = null

  switch (arch) {
    case TreatArches.UPPER_ONLY: {
      result = UPPER_BRACKET_KEY
      break
    }
    case TreatArches.LOWER_ONLY: {
      result = LOWER_BRACKET_KEY
      break
    }
  }

  return result
}
