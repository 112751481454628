import { FormattedMessage } from 'react-intl'

import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface ClinicalPreferencesPropertyCaptionProps {
  'data-testid'?: string
  captionTextId: string
}

export const ClinicalPreferencesPropertyCaption = (
  props: ClinicalPreferencesPropertyCaptionProps,
) => {
  return (
    <PropertyCaptionContainer data-testid={props['data-testid']}>
      <Typography
        data-testid={`${props['data-testid']}-Text`}
        component="label"
        variant="small"
        textFontWeight="600"
        color="primary900"
        isUppercase
      >
        <FormattedMessage id={props.captionTextId} />
      </Typography>
    </PropertyCaptionContainer>
  )
}

const PropertyCaptionContainer = styled.div`
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
`
