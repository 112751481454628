import { useCallback } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'

import { TreatmentAction } from 'app/components/routes/CaseWizard/steps/CasePrescription/TreatmentAction'
import { ValidationFieldContainer } from 'app/components/ui/Form/ValidationFieldContainer'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CanineMolar } from 'app/core/domain/CanineMolar'
import { CaseDraft } from 'app/core/domain/CaseDraft'

import { CanineMolarSettings } from './CanineMolarSettings'
import { CanineSettings } from './CanineSettings'
import { CommonSettings } from './CommonSettings'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'

const APRelationsImpovementSettings = () => {
  const form = useFormContext<CaseDraft>()
  const apRelationshipPrimary = form.getValues(PROPERTY_KEY)

  const changeRadio = useCallback(
    (res?: ApRelationshipPrimary) => {
      form.setValue(PROPERTY_KEY, res)
    },
    [form],
  )

  if (apRelationshipPrimary?.apActionPrimary !== TreatmentAction.CORRECT) {
    return null
  }

  const testId =
    apRelationshipPrimary.canineMolarActionPrimary === CanineMolar.CANINE ? 'Canine' : 'CanineMolar'
  const error = form.formState.errors.primaryCasePreferences?.apRelationshipPrimary as FieldError

  return (
    <ValidationFieldContainer
      display="block"
      messagePadding="0px 8px 8px"
      error={error}
      data-testid="APRelationsImpovementSettings-ValidationFieldContainer"
    >
      <CommonSettings testId={testId} onChange={changeRadio} />

      {apRelationshipPrimary.canineMolarActionPrimary === CanineMolar.CANINE && (
        <CanineSettings onChange={changeRadio} />
      )}

      {apRelationshipPrimary.canineMolarActionPrimary === CanineMolar.CANINE_MOLAR && (
        <CanineMolarSettings />
      )}
    </ValidationFieldContainer>
  )
}

export { APRelationsImpovementSettings }
