import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { addressActions } from 'app/components/routes/Address/logic'
import { RedButton, LightBlueButtonWithBorder } from 'app/components/ui/Buttons'
import { Cancel } from 'app/components/ui/Icons/common'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBodyText } from 'app/components/ui/Modal/OrmcoModalBodyText'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Button } from 'app/components/ui/SDS/common/Button'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { deleteAddressModalActions, deleteAddressModalSelectors } from './logic'

const DeleteAddressModal: React.FC = () => {
  const dispatch = useDispatch()
  const isModalOpen = useSelector(deleteAddressModalSelectors.isOpen())

  const handleClose = useCallback(() => {
    dispatch(deleteAddressModalActions.closeDeleteModalClicked())
  }, [dispatch])

  const onDelete = useCallback(() => {
    dispatch(addressActions.deleteAddressButtonClicked())
  }, [dispatch])

  useDidMount(() => {
    dispatch(deleteAddressModalActions.deleteAddressModalMounted())
  })
  return (
    <>
      <OrmcoModal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        additionalStyles={{
          content: {
            top: '40%',
            padding: '13px',
          },
          overlay: { backgroundColor: 'rgba(0,24,43,0.9)' },
        }}
        shouldCloseOnOverlayClick={false}
        data-testId="DeleteAddressModal"
      >
        <DeleteAddressModalHeader>
          <OrmcoModalHeaderText data-testid="DeleteAddressModal-Header" headerId="delete.address" />
          <Button type="text" onClick={handleClose} data-testid="DeleteAddressModal-Close">
            <Cancel width="16px" />
          </Button>
        </DeleteAddressModalHeader>
        <OrmcoModalBodyText
          data-testid="DeleteAddressModal-Body"
          textId="delete.address.modal.body"
        />
        <DeleteAddressModalFooter>
          <LightBlueButtonWithBorder
            onClick={handleClose}
            data-testid="DeleteAddressModal-CancelButton"
          >
            <FormattedMessage id="footer.cancel" />
          </LightBlueButtonWithBorder>
          <Padder width="10px" />
          <RedButton onClick={onDelete} data-testid="DeleteAddressModal-DeleteAddressButton">
            <FormattedMessage id="delete.address.modal.button" />
          </RedButton>
        </DeleteAddressModalFooter>
      </OrmcoModal>
    </>
  )
}

export { DeleteAddressModal }

const DeleteAddressModalHeader = styled(OrmcoModalHeader)`
  padding: 10px 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 560px;
  border-bottom: 0;
`

const DeleteAddressModalFooter = styled(OrmcoModalFooter)`
  margin-left: auto;

  justify-content: space-between;

  border-top: 0;
`
