import { Help } from 'app/components/ui/Icons/common'
import styled from 'styled-components'

interface HelpIconProps {
  fill?: string
}

const HelpIcon = styled(Help)<HelpIconProps>`
  margin-left: 4px;

  align-self: center;

  width: 18px;
  height: 18px;
  fill: ${({ fill, theme }) => fill ?? theme.colors.primary700};
  vertical-align: middle;
`

export { HelpIcon }
