import { useFormContext } from 'react-hook-form'

import { BracketType } from 'app/core/domain/BracketType'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { ProductType } from 'app/core/domain/ProductType'
import { useEffectSomeDepsChange } from 'app/core/react/CustomHooks'

import { LOWER_BRACKET_KEY, UPPER_BRACKET_KEY } from './bracketPreferencesKeys'

export const useDefaultBracketPreferences = () => {
  const form = useFormContext<CaseDraft>()

  const {
    productInfo,
    primaryCasePreferences: { treatArches },
  } = form.getValues()

  useEffectSomeDepsChange(() => {
    if (productInfo?.id !== ProductType.IDB) {
      form.setValue(UPPER_BRACKET_KEY, undefined)
      form.setValue(LOWER_BRACKET_KEY, undefined)
      return
    }

    if (treatArches === TreatArches.LOWER_ONLY) {
      form.setValue(UPPER_BRACKET_KEY, undefined)
      form.setValue(LOWER_BRACKET_KEY, BracketType.DAMON_ULTIMA_STOCK_5_5)
      return
    }

    if (treatArches === TreatArches.UPPER_ONLY) {
      form.setValue(UPPER_BRACKET_KEY, BracketType.DAMON_ULTIMA_STOCK_5_5)
      form.setValue(LOWER_BRACKET_KEY, undefined)
      return
    }

    form.setValue(UPPER_BRACKET_KEY, BracketType.DAMON_ULTIMA_STOCK_5_5)
    form.setValue(LOWER_BRACKET_KEY, BracketType.DAMON_ULTIMA_STOCK_5_5)
  }, [productInfo, treatArches])
}
