const addOrRemoveFromArray = <T extends unknown>(item: T | T[], arr?: T[]): T[] => {
  const res = [...(arr ?? [])]
  const values = Array.isArray(item) ? item : [item]

  values.forEach((value: T) => {
    const index = res.indexOf(value)

    if (index < 0) {
      res.push(value)
    } else {
      res.splice(index, 1)
    }
  })

  return res
}

const includes = <T extends unknown>(value: T, arr?: Array<T>): boolean => {
  let res = false

  if (arr && arr.includes(value)) {
    res = true
  }
  return res
}

export { addOrRemoveFromArray, includes }
