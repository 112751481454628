import React from 'react'
import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

import { AddressFormLabel } from '../AddressFormLabel'
import { addressSelectors } from '../logic'

import { AddressSummaryHeader } from './AddressSummaryHeader'

const getCommaSeparatedAddress = (value?: string) =>
  value === undefined || value === null ? '' : value.concat(', ')

const AddressSummary: React.FC = () => {
  const addressResult = useSelector(addressSelectors.getAddress())

  return (
    <AddressDetailsContainer marginBottom="24px" padding="16px" display="block" width="320px">
      <AddressSummaryHeader />
      <Box flexDirection="column" align="flex-start">
        <AddressFormLabel text={getCommaSeparatedAddress(addressResult.address1)} />
        <AddressFormLabel text={getCommaSeparatedAddress(addressResult.address2)} />
        <AddressFormLabel text={getCommaSeparatedAddress(addressResult.city)} />
        <AddressFormLabel text={getCommaSeparatedAddress(addressResult.stateProvince)} />
        <AddressFormLabel text={getCommaSeparatedAddress(addressResult.zipCode)} />
        <AddressFormLabel text={addressResult.country} />
      </Box>
    </AddressDetailsContainer>
  )
}

const AddressDetailsContainer = styled(Box)`
  border: 1px solid ${({ theme: styledTheme }) => styledTheme.colors.base300};
`

export { AddressSummary }
