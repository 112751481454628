import { Address } from 'app/core/domain/Address'
import { ResponseStatus } from 'app/core/domain/UpdateResult'
import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface LastAddressUpdateResult {
  status: ResponseStatus
  message: string
}

interface AddressState {
  address: Address
  lastAddressAddResult?: Nullable<LastAddressUpdateResult>
  lastAddressDeleteResult?: Nullable<LastAddressUpdateResult>
  loading: boolean
  addressFetchNetworkStatus: Nullable<ResponseStatus>
  addAddressInProgress: boolean
}

const defaultAddress = {
  id: '',
  addressTag: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  stateProvince: '',
  zipCode: '',
  phoneNumber: '',
  isBillTo: false,
  isShipTo: false,
  isOffice: false,
}

const INITIAL_STATE: AddressState = {
  address: { ...defaultAddress },
  lastAddressAddResult: null,
  lastAddressDeleteResult: null,
  loading: false,
  addressFetchNetworkStatus: null,
  addAddressInProgress: false,
}

const addressActions = {
  addressUnmounted: createAction('@ADDRESS/ADDRESS_UNMOUNTED')(),

  getAddressRequested: createAction('@ADDRESS/GET_ADDRESS_REQUESTED')<{
    id: string
    username: string
  }>(),
  getAddressComplete: createAction('@ADDRESS/GET_ADDRESS_REQUEST_COMPLETE')<Address>(),
  getAddressFailed: createAction('@ADDRESS/GET_ADDRESS_REQUEST_FAILED')(),

  updateAddressAsDefaultComplete: createAction('@ADDRESS/UPDATE_ADDRESS_AS_DEFAULT_COMPLETE')<{
    isEdit: boolean
  }>(),
  updateAddressAsDefaultFailed: createAction('@ADDRESS/UPDATE_ADDRESS_AS_DEFAULT_FAILED')(),
  updateAddressAsDefaultRequested: createAction('@ADDRESS/UPDATE_ADDRESS_AS_DEFAULT_REQUESTED')<{
    address: Address
    id: string
  }>(),

  addAddressRequested: createAction('@ADDRESS/ADD_ADDRESS_REQUESTED')<{
    address: Address
    isEdit: boolean
  }>(),
  addAddressComplete: createAction('@ADDRESS/ADD_ADDRESS_REQUEST_COMPLETE')<{ isEdit: boolean }>(),
  addAddressFailed: createAction('@ADDRESS/ADD_ADDRESS_REQUEST_FAILED')(),

  deleteAddressButtonClicked: createAction('@ADDRESS/DELETE_ADDRESS_BUTTON_CLICKED')(),
  deleteAddressSuccess: createAction('@ADDRESS/DELETE_ADDRESS_SUCCESS')(),
  deleteAddressFailure: createAction('@ADDRESS/DELETE_ADDRESS_FAILURE')(),
  addAddressProgressStatusUpdate: createAction('@ADDRESS/ADD_ADDRESS_PROGRESS_STATUS_UPDATE')(),
}

type AddressActions = ActionType<typeof addressActions>
const addressReducer = createReducer<AddressState, AddressActions>(INITIAL_STATE)
  .handleAction(addressActions.addressUnmounted, (state) => ({
    ...state,
    lastAddressAddResult: null,
    lastAddressDeleteResult: null,
  }))
  .handleAction([addressActions.getAddressFailed], (state) => ({
    ...state,
    loading: false,
    addressFetchNetworkStatus: ResponseStatus.BAD_REQUEST,
  }))
  .handleAction(addressActions.addAddressRequested, (state, action) => ({
    ...state,
    address: action.payload.address,
  }))
  .handleAction(addressActions.updateAddressAsDefaultRequested, (state, action) => {
    const { isBillTo, isShipTo, isOffice, phoneNumber } = action.payload.address

    return {
      ...state,
      address: {
        ...state.address,
        id: action.payload.id,
        isBillTo,
        isShipTo,
        isOffice,
        phoneNumber,
      },
    }
  })
  .handleAction(
    [addressActions.addAddressComplete, addressActions.updateAddressAsDefaultComplete],
    (state, action) => ({
      ...state,
      lastAddressAddResult: {
        status: ResponseStatus.OK,
        message: action.payload.isEdit
          ? 'addresses.creation.updated'
          : 'addresses.creation.success',
      },
      addAddressInProgress: false,
    }),
  )
  .handleAction(
    [addressActions.addAddressFailed, addressActions.updateAddressAsDefaultFailed],
    (state) => ({
      ...state,
      address: state.address,
      lastAddressAddResult: {
        status: ResponseStatus.BAD_REQUEST,
        message: 'addresses.creation.failure',
      },
      addAddressInProgress: false,
    }),
  )
  .handleAction(addressActions.deleteAddressSuccess, (state) => ({
    ...state,
    lastAddressDeleteResult: {
      status: ResponseStatus.OK,
      message: 'delete.address.success',
    },
  }))
  .handleAction(addressActions.getAddressRequested, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addressActions.getAddressComplete, (state, action) => ({
    ...state,
    address: action.payload,
    loading: false,
    addressFetchNetworkStatus: ResponseStatus.OK,
  }))
  .handleAction(addressActions.addAddressProgressStatusUpdate, (state) => ({
    ...state,
    addAddressInProgress: true,
  }))

const addressSelectors = {
  getAddress: () => (state: RootState) => state.address.address,
  getLastAddressAddResult: () => (state: RootState) => state.address.lastAddressAddResult,
  getLastAddressDeleteResult: () => (state: RootState) => state.address.lastAddressDeleteResult,
  getLoadingStatus: () => (state: RootState) => state.address.loading,
  getAddressNetworkStatus: () => (state: RootState) => state.address.addressFetchNetworkStatus,
  getAddressProgressStatus: () => (state: RootState) => state.address.addAddressInProgress,
  getAddressPhoneNumber: () => (state: RootState) => state.address.address?.phoneNumber,
}

export { AddressState, AddressActions, addressReducer, addressSelectors, addressActions }
