import { addressActions, addressSelectors } from 'app/components/routes/Address/logic'
import { notificationActions } from 'app/components/ui/notification/logic'
import { NotificationType } from 'app/core/domain/NotificationType'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

const addUpdateAddress: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([addressActions.addAddressRequested])),
    switchMap((action) => {
      const username = appSelectors.getUsername()(state$.value)
      const address = addressSelectors.getAddress()(state$.value)
      const { isEdit } = action.payload

      return from(axios.post(`/api/v1/accounts/${username}/address`, { ...address })).pipe(
        switchMap(() => {
          return of(
            addressActions.addAddressComplete({ isEdit }),
            notificationActions.notificationTriggered({
              messageId: isEdit ? 'addresses.creation.updated' : 'addresses.creation.success',
              type: NotificationType.info,
            }),
          )
        }),
        catchError(() =>
          of(
            addressActions.addAddressFailed(),
            notificationActions.notificationTriggered({
              messageId: 'addresses.creation.failure',
              type: NotificationType.error,
            }),
          ),
        ),
      )
    }),
  )

const deleteAddress: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([addressActions.deleteAddressButtonClicked])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const { id } = addressSelectors.getAddress()(state$.value)

      return from(axios.delete(`/api/v1/accounts/${username}/address?id=${id}`)).pipe(
        switchMap(() => of(addressActions.deleteAddressSuccess())),
        catchError(() => of(addressActions.deleteAddressFailure())),
      )
    }),
  )

const getAddress: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([addressActions.getAddressRequested])),
    switchMap((action) => {
      const { username, id } = action.payload

      return from(axios.get(`/api/v1/accounts/${username}/address?id=${id}`)).pipe(
        switchMap((res) => of(addressActions.getAddressComplete(res.data))),
        catchError(() => of(addressActions.getAddressFailed())),
      )
    }),
  )

const setAddressAsDefault: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([addressActions.updateAddressAsDefaultRequested])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const address = addressSelectors.getAddress()(state$.value)

      return from(
        axios.put(`/api/v1/accounts/${username}/address/updateDefaults`, { ...address }),
      ).pipe(
        switchMap(() => {
          return of(
            addressActions.updateAddressAsDefaultComplete({ isEdit: true }),
            notificationActions.notificationTriggered({
              messageId: 'addresses.creation.updated',
              type: NotificationType.info,
            }),
          )
        }),
        catchError(() =>
          of(
            addressActions.updateAddressAsDefaultFailed(),
            notificationActions.notificationTriggered({
              messageId: 'addresses.creation.failure',
              type: NotificationType.error,
            }),
          ),
        ),
      )
    }),
  )

const addressEpic = combineEpics(addUpdateAddress, deleteAddress, getAddress, setAddressAsDefault)

export { addressEpic }
