import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from 'app/components/ui/Button'

import { STLUploadModal } from './STLUploadModal'

const STLUploadButton = () => {
  const intl = useIntl()

  const [isScanUploadModalOpen, setIsScanUploadModalOpen] = useState(false)

  return (
    <>
      <Button
        onClick={() => setIsScanUploadModalOpen(true)}
        title={intl.formatMessage({ id: 'scanner.uploadButton.title' })}
        data-testid="WizardButtons-uploadScans"
      >
        <FormattedMessage id="editScan.uploadButton.label" />
      </Button>

      <STLUploadModal
        isOpen={isScanUploadModalOpen}
        onClose={() => setIsScanUploadModalOpen(false)}
      />
    </>
  )
}

export { STLUploadButton }
